import '../../assets/styles/stylesheet.css'
import { Typography, Button } from '@mui/material';

type Props = {};

export default function Redirect404 (props: Props) {

    return (
        <div className='PageBody'>
            <div className='CardHolderColumn'>
                <h1 className='h0'>404</h1>
                <Typography variant='h5'>We're sorry, but the page you were trying to reach was not found. Use the navigation bar on the left to head to a new page.</Typography>
            </div>
        </div>
    )
}