import React from 'react';


type Props = {};

const CampaignIndex = (props: Props) => {
  return (
    <div>CampaignIndex</div>
  );
};

export default CampaignIndex;