//@ts-nocheck
//Fwt stands for "Fixed Width Table"
import { Dialog, Button, DialogTitle, DialogContent, DialogActions, TextField, TableContainer, TableHead, TableCell, TableRow, TableBody, Checkbox, FormControlLabel, Divider, Typography, CardContent } from "@mui/material"
import { ChangeEvent, useState, useEffect, useContext } from "react"
// import { fixedWidth } from "../../configs/tableData";
import { dishFontSx, dishFont, buttonPrimary, buttonSecondary, dishFontSxRed } from "../../assets/styles/sxProp";
import { FWTColumn, FWT, postNewFwt } from "../../api/api";
import AppContext from "../../context/AppContext";

export default function CreateFwtModal (props: any) {

    const [fwtModalOpen, setFwtModalOpen] = useState<boolean>(false);
    const [fwtInProgress, setFwtInProgress] = useState<FWTColumn[]>([]);
    const [start, setStart] = useState<string>("1");
    const [length, setLength] = useState<string>("");
    const [totalLength, setTotalLength] = useState<string>("0");
    const [fieldName, setFieldName] = useState<string>("");
    const [formatName, setFormatName] = useState<string>("");
    const [order, setOrder] = useState<string>("0");
    const [phone, setPhone] = useState<string>("false");
    const [phoneBool, setPhoneBool] = useState(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { instance, newFwt, setNewFwt, username } = useContext<any[]>(AppContext);

    const openFwtModal = () => {
        setFwtModalOpen(true)
    }

    const validateFwtColumn = async (subObj: FWTColumn) => {
        const errorArray: string[] = [];

        if (!subObj.FieldName) {
            errorArray.push("Field Name Error: Please enter a field name for the row being added");
        }
        if (!subObj.Length) {
            errorArray.push("Length Error: Please enter a positive whole number for the field length")
        }

        if (errorArray.length === 0) {
            rowAddValidated(subObj)
        }

        setValidationErrors(errorArray);
    }

    const validateFwt = async (subObj: FWT) => {

        const errorArray: string[] = [];
        console.log(props.fwtList)

        const fwtNames: string[] = props.fwtList.map((el: any) => {
            return el.campaignName
        })
        console.log(fwtNames)
        if (!subObj.campaignName) {
            errorArray.push("Name Error: Please select a format name before submitting")
        }
        if (!subObj.fileColumns || subObj.fileColumns.length < 1) {
            errorArray.push("Data Error: Please add at least 1 row before submitting")
        }
        if (fwtNames.indexOf(subObj.campaignName) !== -1) {
            errorArray.push("Name Error: A format with this name already exists")
        }

        if (errorArray.length === 0) {
            // console.log(subObj)
            const reply = await postNewFwt(subObj, instance);
            setNewFwt(newFwt + 1);
            console.log(reply)
            return handleCancel();
        }
        setValidationErrors(errorArray);
    }

    const handleFormUpdate = (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.target.id) {
            case "fieldNameForm":
                setFieldName(e.target.value);
                break;
        
            case "lengthForm":
                setLength(e.target.value);
                break;
        
            case "orderForm":
                setOrder(e.target.value);
                break;

            case "formatNameForm":
                setFormatName(e.target.value);
                break;
        
            default:
                break;
        }
    }

    const togglePhone = () => {
        if (phone === "false") {
            setPhone('true');
        }
        else {
            setPhone('false');
        }
    }

    useEffect(() => {
        if (phone === "false") setPhoneBool(false)
        else if (phone === "true") setPhoneBool(true)
    }, [phone])

    const handleAddRow = async () => {

        let endCalc = (parseInt(start) + parseInt(length) -1).toString()
        let phonebool = false;
        if (phone === "true") phonebool = true;
        let newRowEntry: FWTColumn = {
            FieldName: fieldName,
            Name: fieldName,
            Value: fieldName,
            Start: parseInt(start),
            End: parseInt(endCalc),
            Length: parseInt(length),
            IsPhoneString: phone,
            IsPhone: phonebool,
            Order: parseInt(order),
        }
        
        await validateFwtColumn(newRowEntry);
    }
    
    const rowAddValidated = async (newRowEntry: FWTColumn) => {
        let endCalc = (parseInt(start) + parseInt(length) -1).toString()
        let startCalc = (parseInt(endCalc) + 1).toString()
        let incrementOrder = (parseInt(order) + 1).toString()
        setFwtInProgress([...fwtInProgress, newRowEntry]);
        setFieldName("");
        setOrder(incrementOrder);
        setLength("");
        setStart(startCalc);
        setPhone("false")
        setTotalLength(endCalc);
    }

    const handleSubmit = async () => {
        let user = "";
        username === "" ? user = "SYSTEM" : user = username.substring(5);
        const newFwtObj: FWT = {
            campaignName: formatName,
            instanceId: instance,
            fileColumns: fwtInProgress,
            recordLength: totalLength,
            lastUpdateBy: user,
            lastUpdateDate: ""
        }

        validateFwt(newFwtObj)

    }

    const handleCancel = () => {
        setFwtInProgress([]);
        setStart("1");
        setLength("");
        setTotalLength("0");
        setOrder("0")
        setFieldName("");
        setFwtModalOpen(false);
        setPhone("false");
        setValidationErrors([]);
        setFormatName("");
    }
    
    
    return (
        <>
            <Button
                onClick={openFwtModal}
                sx={buttonPrimary}
                variant="contained"
                >Create Fixed Width Format</Button>

            <Dialog open={fwtModalOpen}>
                <DialogTitle textAlign="center" sx={dishFontSx}>Create New Fixed Width Format</DialogTitle>
                <br />
                <DialogContent>
                    { validationErrors.length > 0 &&
                        <CardContent>
                            <Typography variant="h5" sx={dishFontSxRed}>One or more fields failed to validate. Please check for errors in the following places:</Typography>
                            <ul>
                            {validationErrors.map((err) => {
                            return (
                                <li key={err}><Typography sx={dishFontSxRed}>{err}</Typography></li>
                            )
                            })
                            }
                        </ul>
                      </CardContent>
                    }
                    <TextField id="formatNameForm" label="Format Name" sx={{ my: "1rem", minWidth: '16rem', width: "93%" }} value={formatName} onChange={handleFormUpdate} />
                    <Divider sx={{width: "93%"}} />
                    <TextField id="fieldNameForm" label="Field Name" sx={{my: "1rem", minWidth: '16rem', width: "93%"}} value={fieldName} onChange={handleFormUpdate} />
                    <TextField id="lengthForm" label="Length" sx={{my: "1rem", minWidth: '8rem', maxWidth: "34%", mr: "1.25rem"}} value={length} onChange={handleFormUpdate} />
                    <FormControlLabel  sx={{pt:"1.75rem"}} control={<Checkbox checked={phoneBool} onClick={togglePhone} />} label="Phone Number?" />
                    <Button sx={{pt: "2rem"}} onClick={handleAddRow}>Add Row</Button>
                    <br/>
                    <p>Total Length: {totalLength} characters</p>
                    {fwtInProgress.length > 0 &&
                        <>
                        <Divider />
                        <TableContainer>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontFamily: dishFont, fontWeight: "bold", width: "11rem"}}>Field Name</TableCell>
                                    <TableCell sx={{fontFamily: dishFont, fontWeight: "bold"}}>Start</TableCell>
                                    <TableCell sx={{fontFamily: dishFont, fontWeight: "bold"}}>End</TableCell>
                                    <TableCell sx={{fontFamily: dishFont, fontWeight: "bold"}}>Length</TableCell>
                                    <TableCell sx={{fontFamily: dishFont, fontWeight: "bold"}}>Phone</TableCell>
                                    <TableCell sx={{fontFamily: dishFont, fontWeight: "bold"}}>Order</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fwtInProgress.map((el) => {
                                    return (
                                        <TableRow key={el.FieldName}>
                                        <TableCell sx={dishFontSx}>{el.FieldName}</TableCell>
                                        <TableCell sx={dishFontSx}>{el.Start}</TableCell>
                                        <TableCell sx={dishFontSx}>{el.End}</TableCell>
                                        <TableCell sx={dishFontSx}>{el.Length}</TableCell>
                                        <TableCell sx={dishFontSx}>{el.IsPhoneString}</TableCell>
                                        <TableCell sx={dishFontSx}>{el.Order}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </TableContainer>
                        </>
                    }
                    <br/> 
                </DialogContent>
                <DialogActions>
                    <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                    <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained">Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}