import { Navigate, Outlet, redirect, useNavigate } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import { useContext, useEffect } from "react";
import AppContext from "../../context/AppContext";


const MainLayout = () => {
    const { userGroup }: any = useContext(AppContext);
    const navigate = useNavigate();
    useEffect(() => {
        const goToDashboard = () => navigate('/dashboard/default');
        const goToDashboard2 = () => navigate('/dashboard/dashboardtwo');
      //if (window.location.href.includes("access_token")) {
        if (userGroup === "Admin") {
                goToDashboard();
            //redirect('/#/dashboard')
          } else if (userGroup === "AutoMsg") {
                goToDashboard();
              //redirect('/#/dashboard')
          } else {
              //redirect('/#/dashboard')
              goToDashboard2();
          }
        //}
    }, [userGroup])

  return (
    <Box sx={{ display: "flex" }}>
      <Topbar />
      <Box
        component="nav"
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0
        }}
      >
        <Sidebar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
