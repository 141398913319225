import '../../App.css';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Editor from '../../components/email/editor';
import TemplatePicker from '../../components/email/templatePicker';
import '../../assets/styles/index.css';
import HtmlPreviewer from '../../components/email/previewer';
import BasicSnackbar from '../../components/common/Snackbar';
import TestDataEditor from '../../components/email/testdataEditor';
import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, OutlinedInput, Stack, TextField, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import { buttonPrimary, buttonSecondary, dishFont, errorText, helpText } from "../../assets/styles/sxProp";
import { getTemplateByName, updateEmailTemplate, createEmailTemplate, deleteTemplateByName, testEmailTemplate, getTemplateVersions, getTemplateByNameVersion, activateTemplate, getProject, getAllLobTemplates, getAllEmailTemplates } from '../../api/api';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import { ContentCopy, Verified, CheckCircle, UploadFile, Edit, Check, Close } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
//import AlarmIcon from '@mui/icons-material/Alarm';
import BugReportSharpIcon from '@mui/icons-material/BugReportSharp';
import LoadingModal from '../../components/common/LoadingModal';
import { Option } from 'react-dropdown';
import KVPTable from '../../components/common/KVPTable';
//import { isDisabled, isEditable } from '@testing-library/user-event/dist/utils';
//import Templates from '../../components/email/index';
//import { template } from 'handlebars';
import AppContext from '../../context/AppContext';
//import email from '../../components/email/index';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
//import { green } from '@mui/material/colors';
//import MailtrapPopup from './components/mailtrap';
//const dropdownOptions = Templates.map((item) => {
//    return {
//        label: item.name,
//        value: item.name
//    }
//})

const EmailTemplateCreate = () => {
    const { lob, userRole }: any = useContext(AppContext);
    const [projectId, setProjectId] = useState<string | "">("");
    const [friendlyName, setFriendlyName] = useState<string | "">("")

    const [dialogOpenCreate, setDialogOpenCreate] = useState(false);
    const [dialogOpenUpdate, setDialogOpenUpdate] = useState(false);
    const [dialogOpenCopy, setDialogOpenCopy] = useState(false);
    const [dialogOpenTest, setDialogOpenTest] = useState(false);
    const [dialogOpenSave, setDialogOpenSave] = useState<boolean>(false);


    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const [_emailTemplates, _setEmailTemplates] = useState([]);
    const [_templateList, _setTemplateList] = useState([]);
    const [_chosenTemplate, _setChosenTemplate] = useState("");
    const [_activeVersion, _setActiveVersion] = useState("");
    const [_chosenTemplateVersion, _setChosenTemplateVersion] = useState("");

    const [_code, _setCode] = useState("");
    const [_testData, _setTestData] = useState(JSON.stringify({} , null, "\t"));
    const [_testDataEditorVisible, _setTestDataEditorVisible] = useState(false);
    const [_fullyParsedHtml, _setFullyParsedHtml] = useState('');
    const [_emailTemplateVersions, _setEmailTemplateVersions] = useState([]);
    const [_emailDetails, _setEmailDetails] = useState<any>(null);
    const [_dupTemplateName, _setDupTemplateName] = useState("");

    const [selectedFile, setSelectedFile] = useState<any>();
    const [updateTemp, setUpdateTemp] = useState<Record<string, string>>({});
    const [newVersion, setNewVersion] = useState<boolean>(false);

    const [latestVersionFlag, setLatestVersionFlag] = useState<boolean>(false);
    const [latestVersionNumber, setLatestVersionNumber] = useState<string>("");
    const [editFlag, setEditFlag] = useState<Record<string, boolean>>({});
    const [modifiedFlag, setModifiedFlag] = useState<boolean>(false);
    const [invalidInput, setInvalidInput] = useState<boolean>(false);
    const [minWidth, setMinWidth] = useState<string>("9rem");
    const [subjectHeight, setSubjectHeight] = useState<string>("50px");
    const [descriptionHeight, setDescriptionHeight] = useState<string>("50px");


    useEffect(() => {
        if (lob) {
            getProject(lob).then((res) => {
                setProjectId(res.projectId);
                setFriendlyName(res.friendlyName);
            })   
        }
             
    }, [lob, userRole]);

    useEffect(() => {
        getTemplates("");
        displayCurrentTemplate("New Template");
        _setChosenTemplate("New Template");
    }, [lob, userRole]);

    useEffect(() => {
        _setCode(_emailDetails?.htmlPart);
    }, [_chosenTemplate, _emailDetails?.htmlPart]);

    useEffect(() => {
        _setTemplateList(_templateList);
    }, [_templateList]);

    useEffect(() => {
        if (_emailDetails) {
            const isModified =
                _emailDetails.subject !== updateTemp.subject ||
                _emailDetails.templateDescription !== updateTemp.description ||
                _emailDetails.htmlPart !== updateTemp.content
            setModifiedFlag(isModified);
        }
    }, [_emailDetails, updateTemp])

    useEffect(() => {
        const maxWidth = 70;


        if (updateTemp) {
            if (updateTemp.subject) {
                let maxLength = updateTemp.subject.length;
                let row = 1;
                if (updateTemp.description) {
                    maxLength = Math.max(updateTemp.subject.length, updateTemp.description.length);
                }
                const newWidth = (maxLength > 25) ? (Math.max(150, maxLength * 10)) / 16 : 250 / 16;
                newWidth < maxWidth ? (newWidth < 25 ? setMinWidth("30rem") : setMinWidth(String(newWidth) + "rem")) : setMinWidth("70rem");

                newWidth < maxWidth ? setSubjectHeight("50px") : setSubjectHeight("72px");

                if (updateTemp.description) {
                    row = Math.min(3, updateTemp.description.split('\n').length);
                    if (row === 1) {
                        if (newWidth < maxWidth) {
                            setDescriptionHeight("50px");
                        } else if ((newWidth >= maxWidth) && (newWidth < (2 * maxWidth))) {
                            setDescriptionHeight("72px");
                        } else {
                            setDescriptionHeight("95px");
                        }
                    } else if (row === 2) {
                        const splitedDescription = updateTemp.description.split('\n');
                        const maxLengthDescription = Math.max(splitedDescription[0].length, splitedDescription[1].length);

                        if ((maxLengthDescription * 10 / 16) > maxWidth) {
                            setDescriptionHeight("95px");
                        } else {
                            setDescriptionHeight("72px");
                        }
                    } else {
                        setDescriptionHeight("95px");
                    }
                }

            } else {
                setSubjectHeight("72px");
            }
        }       
    }, [_emailDetails, updateTemp])

    const toggleCopyTemplate = () => {
        _setDupTemplateName(_emailDetails.templateName + "-COPY")
    }

    const _onTemplateChange = async (option: any) => {
        setInvalidInput(false);
        let latestVersion: string = "";
        //resetTemplate()
        // fix to get version correctly
        //getTemplates();
        if (option.value === "New Template") {
            _setEmailTemplateVersions([]);
            _setChosenTemplateVersion("");
            _setActiveVersion("");
            _setEmailDetails(null);
            _setCode('Please enter HTML code.');
            _setChosenTemplate("New Template");
            setNewVersion(false);
            setUpdateTemp({});
            setDialogOpenSave(false);
            _setFullyParsedHtml('Please enter HTML code.');
        } else {
            _setChosenTemplateVersion("1");
            const _template = _templateList.find((item: any) => item.value === option.value) as any;
            const _templateDets = _templateList.find((item: any) => item.templateName === option.value) as any;
            var _details = await getTemplateByName(option.value, "Email");
            console.log("_details-------- ", _details);
            if (_details && option.value !== "New Template") {
                setUpdateTemp(prev => ({ ...prev, subject: _details.subject, description: _details.templateDescription, content: _details.htmlPart }));
                setEditFlag(prev => ({ ...prev, subject: false, description: false, content: false }));
                getVersions(option.value)
                    .then((res) => {
                        latestVersion = res[res.length - 1].label;
                        return _setEmailTemplateVersions(res)
                    }).then(() => {
                        _setChosenTemplateVersion(_details.version)
                        _setActiveVersion(_details.version)
                        setLatestVersionFlag(Number(_details.version) === Number(latestVersion))
                        setNewVersion(Number(_details.version) !== Number(latestVersionNumber))
                        return
                    })
                    /*.then(() => {
                        return getTemplateDetails(option.value);
                    })*/
                _setEmailDetails(_details);
                _setCode(_details.htmlPart);
                _setChosenTemplate(option.value);
                _setFullyParsedHtml(_details.htmlPart);
            } else {
                _setEmailTemplateVersions([]);
                _setChosenTemplateVersion("");
                _setActiveVersion("");
                _setEmailDetails(null);
                _setCode('Please enter HTML code.');
                _setChosenTemplate("New Template");
                setNewVersion(false);
                setDialogOpenSave(false);
                _setFullyParsedHtml('Please enter HTML code.');
            }
        }        
    }

    const _onVersionChange = async (option: any) => {
        setInvalidInput(false);

        var res = await getTemplateByNameVersion(_emailDetails.templateName, option.value, "Email");  
                
        setUpdateTemp(prev => ({ ...prev, subject: res.subject, description: res.templateDescription, content: res.htmlPart, version: res.version }));
        _setChosenTemplateVersion(option.value);
        _setEmailDetails(res);
        _setCode(res.htmlPart);
        _setFullyParsedHtml(res.htmlPart);

        setLatestVersionFlag(Number(option.value) === Number(latestVersionNumber));
        setNewVersion(Number(option.value) !== Number(latestVersionNumber))

        setDialogOpenSave(false);
    }

    const _onCodeChange = (code?: string) => {
        console.log("inside code change")
        _setCode(code || '');
        setUpdateTemp(prev => ({ ...prev, content: code || '' }));   
        _setFullyParsedHtml(code || '');
    }

    const getTemplates = async (value:string) => {
        setLoading(true);
        var res = await getAllLobTemplates(lob, "Email");
        _setEmailTemplates(res);
        var defaultTemplate = { label: "New Template", value: "New Template" }
        if (res.status !== 400) {
            var templateList = res.map((item: any) => {
                return {
                    label: item.templateName,
                    value: item.templateName
                }
            });
            templateList.push(defaultTemplate);
            var sortedList = templateList.sort((a: any, b: any) => (a.label < b.label ? -1 : 1));
            _setTemplateList(sortedList);
        }
        setLoading(false);

        if (value === "") {
            _setChosenTemplate("New Template");
        } else {
            _setChosenTemplate(value);
        }
        setEditFlag({});
        setModifiedFlag(false);
        setInvalidInput(false);
    };

/*    const getTemplateDetails = async (name: string) => {
        var res = await getTemplateByName(name, "Email");
        _setEmailDetails(res);
        _setCode(res.htmlPart);
    }*/

    const getVersions = async (name: string) => {
        var templateType = "Email"
        var res = await getTemplateVersions(name, templateType);
        if (res.item && res.item.length > 0) {
            var versionList = res.item.map((item: any) => {
                return {
                    label: item.version,
                    value: item.version
                }
            })
            var sortedList = versionList.sort((a: any, b: any) => (Number(a.label) < Number(b.label) ? -1 : 1));

            setLatestVersionNumber(sortedList[sortedList.length - 1].label);
            return sortedList;
        }
    }

    const displayCurrentTemplate = (values: any) => {
        var displayTemplate: Record<string, string> = {};
        displayTemplate["label"] = values;
        displayTemplate["value"] = values;        
        _onTemplateChange(displayTemplate);
    }

    const saveTemplate = async (values: any) => {
        var name = values.name;
        var description = values.description;
        var subject = values.subject;
        var content = _code;
        var defaultSub = values.defaultSubstitutions        

        try {
            const attributes = {
                CITY_: "",
                STATE_: "",
                ZIP_:""
            }
            const def = JSON.stringify(attributes);
            var response = await createEmailTemplate(lob, name, subject, content, description, defaultSub);
            setAlertSeverity("success")
            setAlertMessage("Template was created Successfully")
            setAlertOpen(true);

            // reload the created template    
            getTemplates(name);
            displayCurrentTemplate(values.name);

        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to create template!")

            setAlertOpen(true);
        }        
    }

    const updateTemplate = async (values: any) => {
        var name = values.name;
        var description = values.description;
        var subject = values.subject;
        var content = _code;
        var newVersion = values.newVersion
        var attributes = values.defaultSubstitutions;

        try {            
            await updateEmailTemplate(name, subject, content, newVersion, description, attributes);
            setAlertSeverity("success")
            setAlertMessage("Template was update Successfully")
            setAlertOpen(true);
            // reload the current page with the copied template
            getTemplates(name);
            displayCurrentTemplate(values.name);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to update template!")
            setAlertOpen(true);
        }
    }

    const copyTemplate = async (values: any) => {
        var name = values.name;
        var description = values.description;
        var subject = values.subject;
        var content = _code;
        var defaultSub = values.defaultSubstitutions;

        try {
            await createEmailTemplate(lob, name, subject, content, description, defaultSub);
            setAlertSeverity("success")
            setAlertMessage("Template was created Successfully")
            setAlertOpen(true);
            // reload the current page with the copied template
            getTemplates(name);
            displayCurrentTemplate(values.name);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to create template!")
            setAlertOpen(true);
        }        
    }

    const deleteTemplate = async () => {
        var name = _emailDetails?.templateName;
        if (!window.confirm(`Are you sure you want to delete ${name}? This action is irreversible!`)) return;
        else {
            try {
                await deleteTemplateByName(name, lob, "Email");
                setAlertSeverity("success")
                setAlertMessage("Template was deleted Successfully")
                setAlertOpen(true);
                // reload the default template 
                getTemplates("");
                displayCurrentTemplate("New Template");
                _setChosenTemplate("New Template");
            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to delete template!")
                setAlertOpen(true);
            }
        }
    }

    const testTemplate = async (values: any) => {
        var appId = values.appId;
        var templateName = values.name;
        var description = values.description;
        var subject = values.subject;
        var content = _code;
        var defaultSubstitutions = values.defaultSubstitutions;     
        var emailTo = values.sendEmailTo;
        var emailFrom = values.sendEmailFrom;

        try {            
            var temp = await testEmailTemplate(appId, templateName, subject, content, emailTo, emailFrom, defaultSubstitutions, description)
                .then(r => {
                    return r;
            });
            if (temp === 200) {
                setAlertSeverity("success")
                setAlertMessage("Template was tested Successfully")
                setAlertOpen(true);
            } else {
                setAlertSeverity("error")
                setAlertMessage("Unable to test template!")
                setAlertOpen(true);
            }
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to test template!")
            setAlertOpen(true);
        }
    }

    const makeActive = async () => {

        var name = _emailDetails?.templateName;
        var ver = _chosenTemplateVersion;

        try {
            await activateTemplate(name, ver, "Email");
            setAlertSeverity("success")
            setAlertMessage("Version " + ver + " is now active!")
            setAlertOpen(true);
            getTemplates(name);
            displayCurrentTemplate(name);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to change active version!")
            setAlertOpen(true);
        }
    }

    const importFile = () => {
        console.log(selectedFile)
    }

    //const fileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    //    if (!event.target.files || event.target.files[0] === undefined) {
    //        setSelectedFile(null)
    //    } else {
    //        console.log("selected file: ", event.target.files[0])
    //        const formData = new FormData();
    //        formData.append('FileName', event.target.files[0])
    //        setSelectedFile(formData);

    //    }
    //}

    // update (Subject, Description and HTML) from Email page
    const handleUpdateTempChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdateTemp(prev => ({ ...prev, [name]: value }));
        if (name === "subject" && !value) {
            setInvalidInput(true);
        }      
    }
        
    const handleUpdateEdit = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name } = e.target
        setEditFlag(prev => ({ ...prev, [name]: true })); 
    }

    const handleUpdateConfirmEdit = (input: Record<string, string>, newVersion: boolean) => {
        if (!invalidInput) {
            const values: Record<string, any> = {
                name: _emailDetails?.templateName,
                subject: input.subject,
                description: input.description || "",
                content: input.content,
                newVersion: newVersion,
                defaultSubstitutions: _emailDetails?.defaultSubstitutions
            }
            if (modifiedFlag) {
                updateTemplate(values);
            }
            setEditFlag({});
            setDialogOpenSave(false);
        }
    }

    const handleUpdateCancelEdit = (e: React.MouseEvent<HTMLButtonElement>, name: string) => {        
        if (name === "description") {
            setUpdateTemp(prev => ({ ...prev, [name]: _emailDetails?.["templateDescription"] }));
        } else {
            setUpdateTemp(prev => ({ ...prev, [name]: _emailDetails?.[name] }));  
        }
        setEditFlag(prev => ({ ...prev, [name]: false })); 
        setInvalidInput(false);        
    };

    const handleOpenSaveDialog = () => {
        setNewVersion(!latestVersionFlag);
        setDialogOpenSave(true);
    }

    const handleCloseSaveDialog = () => {
        setDialogOpenSave(false);
    }

    const toggleNewVersion = () => {
        setNewVersion(!newVersion)
    }

    const handleOnParse = (fullyParsedHtml: string) => {
        _setFullyParsedHtml(fullyParsedHtml);
    }


            //<Grid xs={12} lg={6} xl={2} item={true}>
            //    <Typography sx={{ marginBottom: "0.5rem", fontFamily: dishFont }}>File Upload (Optional)</Typography>
            //    <input type='file' name='fileUpload' accept='.html' onChange={fileHandler} />
            //    <Button variant='contained' onClick={importFile}>Upload</Button>
            //</Grid>
    return (
        <>
            <LoadingModal open={loading} />
            <Grid>
        <div className="App">
                    <div className='m-2 flex justify-start'>
                        {(Array.isArray(_templateList) && _templateList.length > 0) ? 
                    <TemplatePicker options={_templateList} onChange={_onTemplateChange} selected={{ label: _chosenTemplate, value: _chosenTemplate }} label="Template" />
                            : <></>
                    }
                    &nbsp;
                        {(Array.isArray(_emailTemplateVersions) && _emailTemplateVersions.length > 0) ?
                            <TemplatePicker options={_emailTemplateVersions} onChange={_onVersionChange} selected={{ label: _chosenTemplateVersion, value: _chosenTemplateVersion }} label="Versions" />
                            : <></>
                        }
                </div>
                
                <div className='m-2 flex justify-start'>
                    {(_emailDetails) ?
                        <div>
                            <Grid container item={true} spacing={1}>                                
                                    <Grid item xs={12}>                                        
                                        <TextField
                                            id="subject"
                                            name="subject"
                                            label="Subject"
                                            sx={{ minWidth: minWidth, height: subjectHeight, wordWrap: 'break-word', maxWidth: '100%'}}

                                            size="small"
                                            variant="outlined"
                                            multiline
                                            maxRows={2}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={handleUpdateTempChange}
                                            value={updateTemp.subject || ""}
                                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleUpdateEdit(e)}
                                            error={_emailDetails.subject === "" || invalidInput}
                                            helperText={_emailDetails.subject === "" || invalidInput ? "Please enter a valid Subject" : ""}
                                            fullWidth
                                            disabled={ false}
                                            
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">    
                                                        {editFlag["subject"] && modifiedFlag && (
                                                            <Tooltip title={"Cancel?"}>
                                                                <IconButton onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleUpdateCancelEdit(e, "subject")}><Close /></IconButton>
                                                            </Tooltip>
                                                        )} 
                                                    </InputAdornment>
                                                )
                                            }}
                                            />                                        
                                    </Grid>
                            </Grid>                                                           
                            <Grid container item={true} spacing={1}>                                
                                    <Grid item xs={12}>
                                    <TextField
                                            id="description"
                                            name="description"
                                            label="Description"
                                            sx={{ minWidth: minWidth, height: descriptionHeight, wordWrap: 'break-word', maxWidth: '100%' }}
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            maxRows={3}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={handleUpdateTempChange}
                                            value={updateTemp.description || ""}
                                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleUpdateEdit(e)}
                                            fullWidth
                                            disabled={false}
                                            placeholder="Optional"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">                                                        
                                                        {editFlag["description"] && !invalidInput && modifiedFlag && (
                                                            <Tooltip title={"Cancel?"}>
                                                                <IconButton onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleUpdateCancelEdit(e, "description")}><Close /></IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item={true} spacing={1} >
                                    <Grid item xs={4} container justifyContent='flex-start'>

                                    <TextField
                                            id="version"
                                            label="Version"
                                            sx={{ width: '6rem', height: '50px' }}
                                            size="small"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={_emailDetails.version || ""}
                                            fullWidth
                                            disabled                                            
                                    />

                                    
                                    </Grid>
                                    <Grid item xs={8} container alignItems='center' justifyContent='flex-start'>
                                    {(_emailDetails.version === _activeVersion) ?
                                        <Chip icon={<CheckCircle />} label="Active" color="success" /> : <></>
                                            }
                                    </Grid>

                                    </Grid>                           

                        </div>
                        : <></>
                    }
                </div>

                <div className='m-2 flex justify-start'>
                    {(_chosenTemplate === "New Template") ?
                            <><Tooltip title='Create'>
                                <IconButton onClick={() => setDialogOpenCreate(true)}><AddCircleOutlinedIcon sx={{ variant: "outlined", color: 'green' }} /> </IconButton>
                            </Tooltip>
                                {/*<Tooltip title='Import'>
                                    <IconButton onClick={() => importFile()}><UploadFile sx={{ variant: "outlined", color: 'blue' }} /> </IconButton>
                                </Tooltip>*/}</>
                            : 
                            <>
                                {modifiedFlag && !invalidInput && (
                                    <>
                                    <Tooltip title='Save'>
                                        <IconButton onClick={handleOpenSaveDialog} ><CheckBoxIcon sx={{ variant: "outlined", color: 'green' }} /> </IconButton>
                                    </Tooltip>
                                    <Dialog open={dialogOpenSave} onClose={handleCloseSaveDialog}>                                            
                                            <DialogTitle>
                                                <Typography variant='inherit' sx={{ fontFamily: dishFont, display: 'flex' }}>Update Email Template: </Typography>
                                                <Typography sx={{ fontFamily: dishFont, display: 'flex' }}><br />Template Name: {_chosenTemplate} </Typography>
                                                <Typography sx={{ fontFamily: dishFont, display: 'flex' }}>Current Version: {_chosenTemplateVersion} </Typography>
                                                {latestVersionFlag ?
                                                    <Typography sx={{ fontFamily: dishFont }}>
                                                        <Checkbox onChange={toggleNewVersion} checked={newVersion} />{!newVersion ? "Create New Version" : ("This will create version " + (Number(latestVersionNumber) + 1))}</Typography>
                                                    :
                                                    <Typography sx={{ fontFamily: dishFont, display: 'flex', color: 'red' }} ><br /> This will create version  {Number(latestVersionNumber) + 1}</Typography>
                                                }
                                                                                                                       
                                            </DialogTitle>
                                            
                                            <DialogActions>
                                                <Button sx={buttonSecondary} onClick={handleCloseSaveDialog}>Cancel</Button>
                                                <Button sx={buttonPrimary} variant="contained" onClick={() => handleUpdateConfirmEdit(updateTemp, newVersion)}>{latestVersionFlag ? (!newVersion ? "Update" : "Save as New Version") : "Save as New Version"}</Button>                                                
                                            </DialogActions>
                                    </Dialog>

                                    </>
                                )}
                        <Tooltip title='Update'>
                            <IconButton onClick={() => setDialogOpenUpdate(true)} ><SaveIcon sx={{ variant: "outlined", color: 'blue' }} /> </IconButton>
                        </Tooltip>
                            </>


                    }
                    {(_chosenTemplate !== "New Template") ?
                        <>
                            <Tooltip title='Copy'>
                                <IconButton onClick={() => { setDialogOpenCopy(true); toggleCopyTemplate(); } }><ContentCopy sx={{ variant: "outlined", color: 'blue' }} /> </IconButton>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <IconButton onClick={deleteTemplate}><DeleteIcon sx={{ variant: "outlined", color: 'red' }} /> </IconButton>                    
                            </Tooltip>
                                {(Number(_activeVersion) === Number(_chosenTemplateVersion)) && (
                                    <Tooltip title='Test'>
                                        <IconButton onClick={() => setDialogOpenTest(true)}><BugReportSharpIcon sx={{ variant: "outlined", color: 'purple' }} /> </IconButton>
                                    </Tooltip>)
                                }

                            {(_emailDetails?.version !== _activeVersion) ? 
                                <>
                                    <Tooltip title='Mark Active'>
                                        <IconButton onClick={makeActive}><Verified sx={{ variant: "outlined", color: 'green' }} /></IconButton>
                                    </Tooltip>
                                </> : <></>
                            }                        
                        </> : <></>
                    }
                        
                </div>
                <div className='grid grid-cols-2 h-screen'>
                <div className='h-screen max-h-screen overflow-auto'>

                            <Editor code={_code} onChange={_onCodeChange} templateType={"Email"} />
                </div>
                <div className='h-screen max-h-screen overflow-auto'>
                    <div className='flex items-start justify-end'>
                    </div>
                    {(_code && _code.length > 0) ? 
                                <HtmlPreviewer code={_fullyParsedHtml} testData={_emailDetails?.defaultSubstitutions} onParse={handleOnParse} />
                        :<></>
                    }
                    <TestDataEditor template={_chosenTemplate} json={_testData} onChange={_setTestData} onHide={() => _setTestDataEditorVisible(!_testDataEditorVisible)} visible={_testDataEditorVisible} />
                </div>
            </div>
        </div>
        </Grid>
            <CreateTemplateModal
                open={dialogOpenCreate}
                onClose={() => { setDialogOpenCreate(false); }}
                onSubmit={saveTemplate}
                currentHtmlPart={_code}
                currentLob={lob}
            />
            <UpdateTemplateModal
                open={dialogOpenUpdate}
                onClose={() => { setDialogOpenUpdate(false); }}
                onSubmit={updateTemplate}
                currentName={_emailDetails?.templateName}
                currentSubject={_emailDetails?.subject}
                currentDescription={_emailDetails?.templateDescription}
                currentDefaultSubstitutions={_emailDetails?.defaultSubstitutions}
                currentVersion={_emailDetails?.version}
                currentHtmlPart={_code}
                currentEmailDetails={_emailDetails}
                currentLob={lob}
                latestVersionFlag={latestVersionFlag}
                latestVersionNumber={latestVersionNumber}
            />
            <CopyTemplateModal
                open={dialogOpenCopy}
                onClose={() => { setDialogOpenCopy(false); }}
                onSubmit={copyTemplate}
                currentName={_dupTemplateName}
                currentSubject={_emailDetails?.subject}
                currentDescription={_emailDetails?.templateDescription}
                currentDefaultSubstitutions={_emailDetails?.defaultSubstitutions}
                currentHtmlPart={_code}
                currentLob={lob}

            />
            <TestTemplateModal
                open={dialogOpenTest}
                onClose={() => { setDialogOpenTest(false); }}
                onSubmit={testTemplate}
                currentName={_emailDetails?.templateName}
                currentSubject={_emailDetails?.subject}
                currentDescription={_emailDetails?.templateDescription}
                currentDefaultSubstitutions={_emailDetails?.defaultSubstitutions}
                currentHtmlPart={_emailDetails?.htmlPart}
                currentProjectId={projectId}
            />            
            <BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)}
            />
        </>
    );
}


interface TemplateModalPropsCreate {
    open: boolean,
    onClose: () => void,
    onSubmit: (values: any) => void;
    //currentDefaultSub: string;
    currentHtmlPart: string;
    currentLob: string;
    //currentName: string;
    //currentSubject: string;
    //currentDescription: string;
    //currentVersion: number;
    //duplicateName: string;
};

export const CreateTemplateModal = ({
    open,
    onClose,
    onSubmit,
    //currentDefaultSub,
    currentHtmlPart,
    currentLob,
    //currentName,
    //currentSubject,
    //currentDescription,
    //currentVersion,
    //duplicateName

}: TemplateModalPropsCreate) => {
    //const [newVersion, setNewVersion] = useState<boolean>(false);
    //const [version, setVersion] = useState<number>(0);
    const [isTestModal] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [lob, setLob] = useState<string>(currentLob);

    //const [disabled, setDisabled] = useState<boolean>(false);
    //const [edit, setEdit] = useState<boolean>(false);

    const [validationErrors, setValidationErrors] = useState<any>({
        noName: 0,
        noSubject: 0,
        hasSpaces: 0,
        noValue: 0,
    })

    const [kvps, setKvps] = useState("");
    const handleKvpChange = (newKvps: string) => {
        setKvps(newKvps);
    }

    const [noValueFlag, setNoValueFlag] = useState<boolean>(false);
    const handleNoValueFlag = (newFlag: boolean) => {
        setNoValueFlag(newFlag);
    }

    // nameSubjectFlag[0]: check for no name
    // nameSubjectFlag[1]: check for space in name
    // nameSubjectFlag[2]: check for no subject
    const [nameSubjectFlag, setNameSubjectFlag] = useState<number[]>([0, 0, 0]);

    useEffect(() => {
        setLob(currentLob);
    }, [currentLob, open])

    useEffect(() => {
        setNoValueFlag(noValueFlag);
    }, [noValueFlag])

/*    useEffect(() => {
        setName(currentName)
        if (currentName && currentName !== "New Template") {
            setDisabled(true);
            // setEdit(true);
        }
    }, [currentName])*/

    useEffect(() => {
        setName(name)
    }, [name])

    useEffect(() => {
        setSubject(subject)
    }, [subject])

    useEffect(() => {
        setNameSubjectFlag(nameSubjectFlag)
    }, [nameSubjectFlag])
        
    let details = {
        name: name,
        subject: subject,
        description: description,
        defaultSubstitutions: kvps,
        lob: lob,
        //version: currentVersion,
        //newVersion: newVersion,
        //edit: edit
    };

    const updateTemplateName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        if (!e.target.value.trim()) {
            nameSubjectFlag[0] = 1;
            setNameSubjectFlag(nameSubjectFlag);
        } else if (/\s/g.test(e.target.value)) {
            nameSubjectFlag[1] = 1;
            setNameSubjectFlag(nameSubjectFlag);
        } else {
            nameSubjectFlag[0] = 0;
            nameSubjectFlag[1] = 0;
            setNameSubjectFlag(nameSubjectFlag);
        }
    }

    const updateTemplateSubject = (e: ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value)
        if (!e.target.value) {
            nameSubjectFlag[2] = 1;
            setNameSubjectFlag(nameSubjectFlag);
        } else {
            nameSubjectFlag[2] = 0;
            setNameSubjectFlag(nameSubjectFlag);
        }
    }

    const updateTemplateDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
    }

    //const toggleNewVersion = () => {
    //    setNewVersion(!newVersion)
    //}

    const handleCancel = () => {
        setValidationErrors({
            noName: 0,
            noSubject: 0,
            hasSpaces: 0
        })

        //setNewVersion(false);
        //set
        //setDisabled(false);
        //setEdit(false);

        //details = {
        //    name: "",
        //    subject: "",
        //    description: "",
        //    version: 0,
        //    newVersion: false,
        //    edit: edit
        //};
        setName("");
        setSubject("");
        setDescription("");
        setValidationErrors({});
        setNoValueFlag(false);
        setKvps("");
        setNameSubjectFlag([0, 0, 0]);
        onClose();
    }

    const checkForSpaces = (value: string) => {
        if(value)
            return /\s/g.test(value);
    }

    const handleSubmit = () => {
        async function validator() {
            const newErrorObject = {
                noName: 0,
                noSubject: 0,
                hasSpaces: 0,
                noValue: 0,
            }

            if (!name) {
                newErrorObject.noName = 1
            }
            if (!subject) {
                newErrorObject.noSubject = 1
            }
            if (checkForSpaces(name)) {
                newErrorObject.hasSpaces = 1
            }
            if (noValueFlag) {
                newErrorObject.noValue = 1
            }

            setValidationErrors(newErrorObject);

            if (newErrorObject.noName === 1 || newErrorObject.noSubject === 1 || newErrorObject.hasSpaces === 1) {
                console.log("Missing Template Name / Template Subject / Contains Spaces");
                return null;
            } else if (!isTestModal && newErrorObject.noValue === 1) {
                console.log("Missing -- Values for Attributess");
                setNoValueFlag(true);
                return null;
            } else {
                details = {
                    name: name,
                    subject: subject,
                    description: description,
                    defaultSubstitutions: kvps,
                    lob: lob,
                    //version: version,
                    //newVersion: newVersion,
                    //edit: edit
                }
                console.log("Values before OnSubmit (Create): ", details)
                onSubmit(details);
                setName("");
                setSubject("");
                setDescription("");
                setValidationErrors({});
                setNoValueFlag(false);
                setKvps("");
                setNameSubjectFlag([0, 0, 0]);
                onClose();
                //setDisabled(false);
                //setEdit(false);
            }
        }
        validator();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create Email Template</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Template Name</Typography>
                            <TextField id="templatName" sx={{ minWidth: '16rem', width: "93%" }} onChange={updateTemplateName} value={name || ""} error={name === "" || nameSubjectFlag[0] === 1 || nameSubjectFlag[1] === 1} helperText={((name === "" || nameSubjectFlag[0] === 1) ? "Please enter a valid Template Name" : "") || ((name === "" || nameSubjectFlag[1] === 1) ? "Template Name Contains Spaces" : "")} fullWidth />
                            {validationErrors.noName === 1 &&
                                <Typography key='NameError' sx={errorText}>Please enter a Template Name</Typography>
                            }
                            {validationErrors.hasSpaces === 1 &&
                                <Typography key='NameError' sx={errorText}>Template Name cannot contain spaces</Typography>
                            }
                        </Grid>      
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Subject</Typography>
                            <TextField id="subject" sx={{ minWidth: '16rem', width: "93%" }} onChange={updateTemplateSubject} value={subject || ""} error={subject === "" || nameSubjectFlag[2] === 1} helperText={subject === "" || nameSubjectFlag[2] === 1 ? "Please enter a valid Subject" : ""} fullWidth />
                            {validationErrors.noSubject === 1 &&
                                <Typography key='SubjectError' sx={errorText}>Please enter a Template Subject</Typography>
                            }
                        </Grid>      
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Description</Typography>
                            <TextField id="description" sx={{ minWidth: '16rem', width: "93%" }} placeholder="Optional" onChange={updateTemplateDescription} value={description || ""} fullWidth />
                        </Grid>  
                        {/*{currentVersion &&  */}
                        {/*    <Typography sx={helpText}>Current Version: {currentVersion}</Typography>*/}
                        {/*}*/}
                        {/*{edit &&*/}
                        {/*    <Typography sx={{ fontFamily: dishFont }}>*/}
                        {/*    <Checkbox onChange={toggleNewVersion} checked={newVersion} /> Create New Version</Typography>        */}
                        {/*}*/}

                        {/* <KVPTable kvps={[]} newList={[formValues]} keyName={"name"} keyLabel={"value"} />*/}

                        <KVPTable kvps={kvps} newList={[]} keyName={"Variable Name"} keyLabel={"Value"} onKvpChange={handleKvpChange} onNoValueFlagChange={handleNoValueFlag} emailHtmlPart={currentHtmlPart} valueCheckAlert={!isTestModal} subject={subject} />

                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>                
                <Tooltip title={!isTestModal ? "Missing Values" : ""} disableHoverListener={!noValueFlag}>
                    <span>
                        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained" disabled={name === "" || subject === "" || nameSubjectFlag[0] === 1 || nameSubjectFlag[1] === 1 || nameSubjectFlag[2] === 1 || (!isTestModal && noValueFlag)}>Create</Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

interface TemplateModalPropsUpdate {
    open: boolean,
    onClose: () => void,
    onSubmit: (values: any) => void;
    currentName: string;
    currentSubject: string;
    currentDescription: string;
    currentDefaultSubstitutions: string;
    currentVersion: number;
    currentHtmlPart: string;
    currentEmailDetails: any;
    currentLob: string;
    latestVersionFlag: boolean;
    latestVersionNumber: string;
};

export const UpdateTemplateModal = ({
    open,
    onClose,
    onSubmit,
    currentName,
    currentSubject,
    currentDescription,
    currentDefaultSubstitutions,
    currentVersion,
    currentHtmlPart,
    currentEmailDetails,
    currentLob,
    latestVersionFlag,
    latestVersionNumber

}: TemplateModalPropsUpdate) => {
    const [isTestModal] = useState<boolean>(false);
    const [newVersion, setNewVersion] = useState<boolean>(false);
    const [version, setVersion] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [emailHtmlPart, setEmailHtmlPart] = useState<string>("");
    const [lob, setLob] = useState<string>(currentLob);

    const [validationErrors, setValidationErrors] = useState<any>({
        noName: 0,
        noSubject: 0,
        hasSpaces: 0,
        noValue: 0
    })

    // nameSubjectFlag[0]: check for no name
    // nameSubjectFlag[1]: check for space in name
    // nameSubjectFlag[2]: check for no subject
    const [nameSubjectFlag, setNameSubjectFlag] = useState<number[]>([0, 0, 0]);

    const [kvps, setKvps] = useState("");
    const handleKvpChange = (newKvps: string) => {
        setKvps(newKvps);
    }

    const [noValueFlag, setNoValueFlag] = useState<boolean>(false);
    const handleNoValueFlag = (newFlag: boolean) => {
        setNoValueFlag(newFlag);
    }

    useEffect(() => {
        setLob(currentLob);
    }, [currentLob, open])

    useEffect(() => {
        setName(currentName)
    }, [currentName])

    useEffect(() => {
        setSubject(currentSubject)
    }, [currentSubject])

    useEffect(() => {
        setDescription(currentDescription)
    }, [currentDescription])

    useEffect(() => {
        setKvps(currentDefaultSubstitutions ? currentDefaultSubstitutions : "");
    }, [currentDefaultSubstitutions, open])

    useEffect(() => {
        setKvps(kvps);
    }, [kvps])

    useEffect(() => {
        setVersion(currentVersion)
    }, [currentVersion])

    useEffect(() => {
        setNewVersion(latestVersionFlag ? false : true)
    }, [latestVersionFlag])

    useEffect(() => {
        setEmailHtmlPart(currentHtmlPart);
    }, [currentHtmlPart])

    useEffect(() => {
        setNoValueFlag(noValueFlag);
    }, [noValueFlag])

    let details = {
        name: name,
        subject: subject,
        description: description,
        version: currentVersion,
        newVersion: newVersion,
        defaultSubstitutions: kvps,
        lob: lob,
    };
        
    const updateTemplateName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        if (!e.target.value.trim()) {
            nameSubjectFlag[0] = 1;
            setNameSubjectFlag(nameSubjectFlag);
        } else if (/\s/g.test(e.target.value)) {
            nameSubjectFlag[1] = 1;
            setNameSubjectFlag(nameSubjectFlag);
        } else {
            nameSubjectFlag[0] = 0;
            nameSubjectFlag[1] = 0;
            setNameSubjectFlag(nameSubjectFlag);
        }
    }

    const updateTemplateSubject = (e: ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value)
        if (!e.target.value) {
            nameSubjectFlag[2] = 1;
            setNameSubjectFlag(nameSubjectFlag);
        } else {
            nameSubjectFlag[2] = 0;
            setNameSubjectFlag(nameSubjectFlag);
        }
    }

    const updateTemplateDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
    }

    const toggleNewVersion = () => {
        setNewVersion(!newVersion)
    }

    const handleCancel = () => {
        setValidationErrors({
            noName: 0,
            noSubject: 0,
            hasSpaces: 0
        })

        setNewVersion(false);

        details = {
            name: "",
            subject: "",
            description: "",
            version: 0,
            newVersion: false,
            defaultSubstitutions: "",
            lob: ""
        };
        setName(currentName);
        setSubject(currentSubject);
        setDescription(currentDescription);
        setValidationErrors({});
        setNoValueFlag(false);
        setKvps(currentDefaultSubstitutions);
        setNameSubjectFlag([0, 0, 0]);
        onClose();
    }

    const checkForSpaces = (value: string) => {
        if (value)
            return /\s/g.test(value);
    }

    const handleSubmit = () => {
        async function validator() {
            const newErrorObject = {
                noName: 0,
                noSubject: 0,
                hasSpaces: 0,
                noValue: 0
            }

            if (!name) {
                newErrorObject.noName = 1
            }
            if (!subject) {
                newErrorObject.noSubject = 1
            }
            if (checkForSpaces(name)) {
                newErrorObject.hasSpaces = 1
            }

            if (noValueFlag) {
                newErrorObject.noValue = 1
            }

            setValidationErrors(newErrorObject);

            if (newErrorObject.noName === 1 || newErrorObject.noSubject === 1 || newErrorObject.hasSpaces === 1) {
                return null;
            } else {
                details = {
                    name: name,
                    subject: subject,
                    description: description,
                    version: version,
                    newVersion: newVersion,
                    defaultSubstitutions: kvps,
                    lob: lob
                }
                console.log("Values before OnSubmit (Update): ", details)
                onSubmit(details);
                setName(currentName);
                setSubject(currentSubject);
                setDescription(currentDescription);
                setValidationErrors({});
                setNoValueFlag(false);
                setKvps(currentDefaultSubstitutions);
                setNameSubjectFlag([0, 0, 0]);
                onClose();
            }
        }
        validator();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Update Email Template</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Template Name</Typography>
                            <TextField id="templatName" sx={{ minWidth: '16rem', width: "93%" }} disabled onChange={updateTemplateName} value={name || ""} error={name === "" || nameSubjectFlag[0] === 1 || nameSubjectFlag[1] === 1} helperText={((name === "" || nameSubjectFlag[0] === 1) ? "Please enter a valid Template Name" : "") || ((name === "" || nameSubjectFlag[1] === 1) ? "Template Name Contains Spaces" : "")} fullWidth />
                            {/*{validationErrors.noName === 1 &&*/}
                            {/*    <Typography key='NameError' sx={errorText}>Please enter a Template Name</Typography>*/}
                            {/*}*/}
                            {/*{validationErrors.hasSpaces === 1 &&*/}
                            {/*    <Typography key='NameError' sx={errorText}>Template Name cannot contain spaces</Typography>*/}
                            {/*}*/}
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Subject</Typography>
                            <TextField id="subject" sx={{ minWidth: '16rem', width: "93%" }} onChange={updateTemplateSubject} value={subject || ""} error={subject === "" || nameSubjectFlag[2] === 1} helperText={subject === "" || nameSubjectFlag[2] === 1 ? "Please enter a valid Subject" : ""} fullWidth />
                            {validationErrors.noSubject === 1 &&
                                <Typography key='SubjectError' sx={errorText}>Please enter a Template Subject</Typography>
                            }
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Description</Typography>
                            <TextField id="description" sx={{ minWidth: '16rem', width: "93%" }} placeholder="Optional" onChange={updateTemplateDescription} value={description || ""} fullWidth />
                        </Grid>
                            <Typography sx={helpText}>Current Version: {currentVersion}</Typography>

                        {latestVersionFlag ? 
                            <Typography sx={{ fontFamily: dishFont }}>
                                <Checkbox onChange={toggleNewVersion} checked={latestVersionFlag ? newVersion : true} disabled={!latestVersionFlag} /> {latestVersionFlag ? (!newVersion ? "Create New Version" : ("This will create version  " + (Number(latestVersionNumber) + 1))) : ("This will create version " + (Number(latestVersionNumber) + 1))}</Typography>        
                            :
                            <Typography sx={{ fontFamily: dishFont, display: 'flex', color: 'red' }} > This will create version  {Number(latestVersionNumber) + 1}</Typography>
                        }
                        <KVPTable kvps={kvps} newList={[]} keyName={"Variable Name"} keyLabel={"Value"} onKvpChange={handleKvpChange} onNoValueFlagChange={handleNoValueFlag} emailHtmlPart={emailHtmlPart} valueCheckAlert={!isTestModal} subject={subject} />

                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Tooltip title={!isTestModal ? "Missing Values" : ""} disableHoverListener={!noValueFlag}>
                    <span>
                        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained" disabled={name === "" || subject === "" || nameSubjectFlag[0] === 1 || nameSubjectFlag[1] === 1 || nameSubjectFlag[2] === 1 || (!isTestModal && noValueFlag)}>{latestVersionFlag ? (!newVersion ? "Update" : "Save as New Version") : "Save as New Version"}</Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

interface TemplateModalPropsCopy {
    open: boolean,
    onClose: () => void,
    onSubmit: (values: any) => void;
    currentName: string;
    currentSubject: string;
    currentDescription: string;
    currentHtmlPart: string;
    currentDefaultSubstitutions: string;
    currentLob: string;
};

export const CopyTemplateModal = ({
    open,
    onClose,
    onSubmit,
    currentName,
    currentSubject,
    currentDescription,
    currentDefaultSubstitutions,
    currentHtmlPart,
    currentLob,

}: TemplateModalPropsCopy) => {
    const [isTestModal] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [emailHtmlPart, setEmailHtmlPart] = useState<string>("");
    const [validationErrors, setValidationErrors] = useState<any>({
        noName: 0,
        noSubject: 0,
        hasSpaces: 0
    })

    // nameSubjectFlag[0]: check for no name
    // nameSubjectFlag[1]: check for space in name
    // nameSubjectFlag[2]: check for no subject
    const [nameSubjectFlag, setNameSubjectFlag] = useState<number[]>([0, 0, 0]);
    const [lob, setLob] = useState<string>(currentLob);

    const [kvps, setKvps] = useState("");
    const handleKvpChange = (newKvps: string) => {
        setKvps(newKvps);
    }

    const [noValueFlag, setNoValueFlag] = useState<boolean>(false);
    const handleNoValueFlag = (newFlag: boolean) => {
        setNoValueFlag(newFlag);
    }

    useEffect(() => {
        setLob(currentLob);
    }, [currentLob, open])

    useEffect(() => {
        setName(currentName);
    }, [currentName])

    useEffect(() => {
        setSubject(currentSubject)
    }, [currentSubject])

    useEffect(() => {
        setDescription(currentDescription)
    }, [currentDescription])

    useEffect(() => {
        setEmailHtmlPart(currentHtmlPart);
    }, [currentHtmlPart, open])

    useEffect(() => {
        setNoValueFlag(noValueFlag);
    }, [noValueFlag])


    let details = {
        name: name,
        subject: subject,
        description: description,
        defaultSubstitutions: kvps,
        lob: lob,
    };

    const updateTemplateName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        if (!e.target.value.trim()) {
            nameSubjectFlag[0] = 1;
            setNameSubjectFlag(nameSubjectFlag);
        } else if (/\s/g.test(e.target.value)) {
            nameSubjectFlag[1] = 1;
            setNameSubjectFlag(nameSubjectFlag);
        } else {
            nameSubjectFlag[0] = 0;
            nameSubjectFlag[1] = 0;
            setNameSubjectFlag(nameSubjectFlag);
        }
    }

    const updateTemplateSubject = (e: ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value)
        if (!e.target.value) {
            nameSubjectFlag[2] = 1;
            setNameSubjectFlag(nameSubjectFlag);
        } else {
            nameSubjectFlag[2] = 0;
            setNameSubjectFlag(nameSubjectFlag);
        }
    }

    const updateTemplateDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
    }

    const handleCancel = () => {
        setValidationErrors({
            noName: 0,
            noSubject: 0,
            hasSpaces: 0
        })

        details = {
            name: "",
            subject: "",
            description: "",
            defaultSubstitutions: "",
            lob: "",
        };
        setName(currentName);
        setSubject(currentSubject);
        setDescription(currentDescription);
        setValidationErrors({});
        setNoValueFlag(false);
        setKvps(currentDefaultSubstitutions);
        setNameSubjectFlag([0, 0, 0]);
        onClose();
    }

    const checkForSpaces = (value: string) => {
        if (value)
            return /\s/g.test(value);
    }

    const checkForCopy = (value: string) => {
        if (value)
            return /-COPY/g.test(value);
    }

    const handleSubmit = () => {
        async function validator() {
            const newErrorObject = {
                noName: 0,
                noSubject: 0,
                hasSpaces: 0,
                hasCopy: 0
            }

            if (!name) {
                newErrorObject.noName = 1
            }
            if (!subject) {
                newErrorObject.noSubject = 1
            }
            if (checkForSpaces(name)) {
                newErrorObject.hasSpaces = 1
            }

            if (checkForCopy(name)) {
                newErrorObject.hasCopy = 1
            }

            setValidationErrors(newErrorObject);

            if (newErrorObject.noName === 1 || newErrorObject.noSubject === 1 || newErrorObject.hasSpaces === 1 || newErrorObject.hasCopy === 1) {
                console.log("Missing Template Name / Template Subject / Contains Spaces / Contains -COPY");
                return null;
            } else {
                details = {
                    name: name,
                    subject: subject,
                    description: description,
                    defaultSubstitutions: kvps,
                    lob: lob,
                }
                console.log(details)
                onSubmit(details);
                setName(currentName);
                setSubject(currentSubject);
                setDescription(currentDescription);
                setValidationErrors({});
                setNoValueFlag(false);
                setKvps(currentDefaultSubstitutions);
                setNameSubjectFlag([0, 0, 0]);
                onClose();
            }
        }
        validator();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Copy Email Template</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Template Name</Typography>
                            <TextField id="templatName" sx={{ minWidth: '16rem', width: "93%" }} onChange={updateTemplateName} value={name || ""} error={name === "" || nameSubjectFlag[0] === 1 || nameSubjectFlag[1] === 1} helperText={((name === "" || nameSubjectFlag[0] === 1) ? "Please enter a valid Template Name" : "") || ((name === "" || nameSubjectFlag[1] === 1) ? "Template Name Contains Spaces" : "")} fullWidth />
                            {validationErrors.noName === 1 &&
                                <Typography key='NameError' sx={errorText}>Please enter a Template Name</Typography>
                            }
                            {validationErrors.hasSpaces === 1 &&
                                <Typography key='NameError' sx={errorText}>Template Name cannot contain spaces</Typography>
                            }
                            {validationErrors.hasCopy === 1 &&
                                <Typography key='NameError' sx={errorText}>Template Name must be renamed</Typography>
                            }
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Subject</Typography>
                            <TextField id="subject" sx={{ minWidth: '16rem', width: "93%" }} onChange={updateTemplateSubject} value={subject || ""} error={subject === "" || nameSubjectFlag[2] === 1} helperText={subject === "" || nameSubjectFlag[2] === 1 ? "Please enter a valid Subject" : ""} fullWidth />
                            {validationErrors.noSubject === 1 &&
                                <Typography key='SubjectError' sx={errorText}>Please enter a Template Subject</Typography>
                            }
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Description</Typography>
                            <TextField id="description" sx={{ minWidth: '16rem', width: "93%" }} placeholder="Optional" onChange={updateTemplateDescription} value={description || ""} fullWidth />
                        </Grid>

                        <KVPTable kvps={currentDefaultSubstitutions} newList={[]} keyName={"Variable Name"} keyLabel={"Value"} onKvpChange={handleKvpChange} onNoValueFlagChange={handleNoValueFlag} emailHtmlPart={emailHtmlPart} valueCheckAlert={!isTestModal} subject={subject} />
                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Tooltip title={!isTestModal ? "Missing Values" : ""} disableHoverListener={!noValueFlag}>
                    <span>
                        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained" disabled={name === "" || subject === "" || nameSubjectFlag[0] === 1 || nameSubjectFlag[1] === 1 || nameSubjectFlag[2] === 1 || (!isTestModal && noValueFlag)}>Copy</Button>
                    </span>
                </Tooltip>            </DialogActions>
        </Dialog>
    );
};


interface TemplateModalPropsTest {
    open: boolean,
    onClose: () => void,
    onSubmit: (values: any) => void;
    currentName: string;
    currentSubject: string;
    currentDescription: string;
    currentDefaultSubstitutions: string;
    currentHtmlPart: string;
    currentProjectId: string;
};


export const TestTemplateModal = ({
    open,
    onClose,
    onSubmit,
    currentName,
    currentSubject,
    currentDescription,
    currentDefaultSubstitutions,
    currentHtmlPart,
    currentProjectId,

}: TemplateModalPropsTest) => {
    const [isTestModal] = useState<boolean>(true);
    const [appId, setAppId] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [subject, setSubject] = useState<string>("");

    const [sendEmailTo, setSendEmailTo] = useState<string>("");
    const [sendEmailFrom, setSendEmailFrom] = useState<string>("");
    const [emailValidationFlag, setEmailValidationFlag] = useState<boolean>(false)
    const [description, setDescription] = useState<string>("");
    const [defaultSubstitutions, setDefaultSubstitutions] = useState<Record<string, string[]>>({});
    const [emailHtmlPart, setEmailHtmlPart] = useState<string>("");

    const [validationErrors, setValidationErrors] = useState<any>({
        noName: 0,
        noSubject: 0,
        noSendEmailTo: 0,
        hasSpaces: 0,
        noValue: 0,
    })

    const [kvps, setKvps] = useState("");
    const [kvpsJson, setKvpsJson] = useState<Record<string, string[]>>(defaultSubstitutions);
    const handleKvpChange = (newKvps: string) => {        
        setKvps(newKvps);
    }

    const [noValueFlag, setNoValueFlag] = useState<boolean>(false);
    const handleNoValueFlag = (newFlag: boolean) => {
        setNoValueFlag(newFlag);
    }

    useEffect(() => {
        setAppId(currentProjectId);
    }, [currentProjectId])

    useEffect(() => {
        setName(currentName);
    }, [currentName])

    useEffect(() => {
        setSubject(currentSubject);
    }, [currentSubject])

    useEffect(() => {
        setDescription(currentDescription);
    }, [currentDescription])

    useEffect(() => {
        setEmailHtmlPart(currentHtmlPart);
    }, [currentHtmlPart])

    useEffect(() => {
        let defaultSubstitutionsJson: Record<string, string[]> = { };
        defaultSubstitutionsJson = currentDefaultSubstitutions ? JSON.parse(currentDefaultSubstitutions) : {};
        setDefaultSubstitutions(defaultSubstitutionsJson);        
        setKvpsJson(defaultSubstitutionsJson);
    }, [currentDefaultSubstitutions, open])

    useEffect(() => {
        let substitutionsJsonTemp: Record<string, string> = {};
        try {
            substitutionsJsonTemp = kvps ? JSON.parse(kvps) : {};
        } catch {
            console.log("Error kvps: ", kvps);
        }       
        const substitutionsJson: Record<string, string[]> = substitutionsJsonTemp ? Object.keys(substitutionsJsonTemp).reduce((acc, key) => {
            acc[key] = [substitutionsJsonTemp[key]];
            return acc;
        }, {} as Record<string, string[]>) : {};
        setKvpsJson(substitutionsJson);
    }, [kvps, emailHtmlPart, open])

    useEffect(() => {
        setSendEmailTo(sendEmailTo);
    }, [sendEmailTo])

    useEffect(() => {
        setNoValueFlag(noValueFlag);
    }, [noValueFlag])

    useEffect(() => {
        setEmailValidationFlag(emailValidationFlag);
    }, [emailValidationFlag, kvpsJson])

    let details = {
        appId: appId,
        name: name,
        subject: subject,
        defaultSubstitutions: defaultSubstitutions,
        sendEmailTo: sendEmailTo,
        sendEmailFrom: sendEmailFrom,
        description: description,
    };

    const updateTemplateName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }

    const updateTemplateSubject = (e: ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value);
    }

    const updateTemplateDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    }

    const updateTemplateSendEmailTo = (e: ChangeEvent<HTMLInputElement>) => {
        setSendEmailTo(e.target.value);
        const emailRegex = /[a-zA-Z0-9._% +-]+@[a-zA-Z0-9.-]+\.(com|net|org|co\.[a-zA-Z]{2,})$/;
        if (!e.target.value) {
            setEmailValidationFlag(false);
        } else if (emailRegex.test(e.target.value)) {
            setEmailValidationFlag(false);
        } else {
            setEmailValidationFlag(true);
        }
    }

    const handleCancel = () => {
        setValidationErrors({
            noName: 0,
            noSubject: 0,
            noSendEmailTo: 0,
            hasSpaces: 0,
            noValue: 0,
        })

        details = {
            appId: "",
            name: "",
            subject: "",
            defaultSubstitutions: {},
            sendEmailTo: "",
            sendEmailFrom: "",
            description: "",
        };
        setSendEmailTo("");
        setKvps("");
        setKvpsJson({});
        setEmailHtmlPart(currentHtmlPart);
        setValidationErrors({});
        setNoValueFlag(false);
        setEmailValidationFlag(false);
        onClose();
    }

    const checkForSpaces = (value: string) => {
        if (value)
            return /\s/g.test(value);
    }

    const handleSubmit = () => {
        async function validator() {
            const newErrorObject = {
                noName: 0,
                noSubject: 0,
                noSendEmailTo: 0,
                hasSpaces: 0,
                noValue: 0,
            }

            if (!name) {
                newErrorObject.noName = 1
            }
            if (!subject) {
                newErrorObject.noSubject = 1
            }
            if (!sendEmailTo) {
                newErrorObject.noSendEmailTo = 1
            }
            if (checkForSpaces(name)) {
                newErrorObject.hasSpaces = 1
            }

            if (noValueFlag) {
                newErrorObject.noValue = 1
            }

            setValidationErrors(newErrorObject);

            if (newErrorObject.noName === 1) {
                console.log("Missing -- Template Name");
                return null;
            } else if (newErrorObject.noSubject === 1) {
                console.log("Missing -- Template Subject");
                return null;
            } else if (newErrorObject.hasSpaces === 1) {
                console.log("Email Template Contains Spaces");
                return null;
            } else if (newErrorObject.noSendEmailTo === 1) {
                console.log("Missing -- Send Email To");
                return null;
            } else if (!isTestModal && newErrorObject.noValue === 1) {
                console.log("Missing -- Values for Attributess");
                setNoValueFlag(true);
                return null;
            } else {
                details = {
                    appId: appId,
                    name: name,
                    subject: subject,
                    defaultSubstitutions: kvpsJson,
                    sendEmailTo: sendEmailTo,
                    sendEmailFrom: sendEmailFrom,
                    description: description,
                   }
                onSubmit(details);
                setSendEmailTo("");
                setKvps("");
                setKvpsJson({});
                setEmailHtmlPart(currentHtmlPart);
                setValidationErrors({});
                setNoValueFlag(false);                
                onClose();
            }            
        }
        validator();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Test Email Template</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Send Test Email To</Typography>
                            <TextField id="sendEmailTo" sx={{ minWidth: '16rem', width: "93%" }} required inputProps={{ type: "email", }} onChange={updateTemplateSendEmailTo} value={sendEmailTo || ""} error={sendEmailTo === "" || emailValidationFlag} helperText={sendEmailTo === "" || emailValidationFlag ? "Please enter a valid email" : ""} fullWidth />                            
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Template Name</Typography>
                            <TextField id="templatName" sx={{ minWidth: '16rem', width: "93%" }} disabled inputProps={{readOnly: true,}} onChange={updateTemplateName}  value={name || ""} fullWidth />                            
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Subject</Typography>
                            <TextField id="subject" sx={{ minWidth: '16rem', width: "93%" }} disabled inputProps={{ readOnly: true, }} onChange={updateTemplateSubject} value={subject || ""} fullWidth />                            
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Description</Typography>
                            <TextField id="description" sx={{ minWidth: '16rem', width: "93%" }} disabled placeholder="Optional" inputProps={{ readOnly: true, }} onChange={updateTemplateDescription} value={description || ""} fullWidth />
                        </Grid>

                        <KVPTable kvps={JSON.stringify(defaultSubstitutions)} newList={[]} keyName={"Variable Name"} keyLabel={"Value"} onKvpChange={handleKvpChange} onNoValueFlagChange={handleNoValueFlag} emailHtmlPart={emailHtmlPart} valueCheckAlert={!isTestModal} subject={subject} />

                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Tooltip title={!isTestModal ? "Missing Values" : ""}  disableHoverListener={!noValueFlag}>
                    <span>
                        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained" disabled={isTestModal && (sendEmailTo === "" || emailValidationFlag)}>Test</Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};



export default EmailTemplateCreate;


                        {/*<div className='m-2'>*/}
                        {/*    <button onClick={() => _setTestDataEditorVisible(true)} className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">*/}
                        {/*        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">*/}
                        {/*            set test data*/}
                        {/*        </span>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        {/*<div className='m-2'>*/}
                        {/*    <button onClick={() => _setMailtrapVisible(true)} className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-teal-300 to-lime-300 group-hover:from-teal-300 group-hover:to-lime-300 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800">*/}
                        {/*        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">*/}
                        {/*            Mailtrap*/}
                        {/*        </span>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        {/*<div className='m-2'>*/}
                        {/*    <a href="https://twitter.com/jacksbridger" target={'_blank'} className="bg-blue-400 p-3 font-semibold text-white inline-flex items-center space-x-2 rounded" rel="noreferrer">*/}
                        {/*        <svg className="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg>*/}
                        {/*    </a>*/}
                        {/*</div>*/}




{/*<MailtrapPopup visible={_mailtrapVisible} close={() => _setMailtrapVisible(false)} sendTestEmail={_sendtestEmail} />*/ }