import * as React from 'react';
import BasicSnackbar from '../../components/common/Snackbar';
import {Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, Radio, RadioGroup, Stack, TextField, Tooltip, Typography} from "@mui/material";
import { useContext, useState, useEffect, ChangeEvent } from 'react';
import AppContext from '../../context/AppContext';
import LoadingModal from '../../components/common/LoadingModal';
import TemplatePicker from '../../components/email/templatePicker';
import { getTemplateByName, updateSmsTemplate, deleteTemplateByName, getTemplateVersions, getTemplateByNameVersion, activateTemplate, getProject, getAllLobTemplates, createSmsTemplate, testSmsTemplate } from '../../api/api';
import { CheckCircle, Close, ContentCopy, Verified } from '@mui/icons-material';
import Editor from '../../components/email/editor';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BugReportSharpIcon from '@mui/icons-material/BugReportSharp';
import { buttonPrimary, buttonSecondary, dishFont, errorText, helpText } from '../../assets/styles/sxProp';
import KVPTable from '../../components/common/KVPTable';
import HtmlPreviewer from '../../components/email/previewer';
import SmsPreviewer from '../../components/email/smsPreviewer';






const SMSTemplateCreate = () => {
    const { lob, userRole }: any = useContext(AppContext);
    const [projectId, setProjectId] = useState<string | "">("");

    const [dialogOpenCreate, setDialogOpenCreate] = useState(false);
    const [dialogOpenUpdate, setDialogOpenUpdate] = useState(false);
    const [dialogOpenCopy, setDialogOpenCopy] = useState(false);
    const [dialogOpenTest, setDialogOpenTest] = useState(false);
    const [dialogOpenSave, setDialogOpenSave] = useState<boolean>(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const [_smsTemplates, _setSmsTemplates] = useState([]);
    const [_templateList, _setTemplateList] = useState([]);
    const [_chosenTemplate, _setChosenTemplate] = useState("");
    const [_activeVersion, _setActiveVersion] = useState("");
    const [_chosenTemplateVersion, _setChosenTemplateVersion] = useState("");

    const [_code, _setCode] = useState("");
    const [_smsTemplateVersions, _setSmsTemplateVersions] = useState([]);
    const [_smsDetails, _setSmsDetails] = useState<any>(null);
    const [_dupTemplateName, _setDupTemplateName] = useState("");

    const [updateTemp, setUpdateTemp] = useState<Record<string, string>>({});
    const [newVersion, setNewVersion] = useState<boolean>(false);

    const [latestVersionFlag, setLatestVersionFlag] = useState<boolean>(false);
    const [latestVersionNumber, setLatestVersionNumber] = useState<string>("");
    const [editFlag, setEditFlag] = useState<Record<string, boolean>>({});
    const [modifiedFlag, setModifiedFlag] = useState<boolean>(false);
    const [invalidInput, setInvalidInput] = useState<boolean>(false);
    const [minWidth, setMinWidth] = useState<string>("9rem");
    const [descriptionHeight, setDescriptionHeight] = useState<string>("50px");
    const [previewCode, setPreviewCode] = useState<string>("");

    useEffect(() => {
        if (lob) {
            getProject(lob).then((res) => {
                setProjectId(res.projectId);
            })
        }

    }, [lob, userRole]);

    useEffect(() => {
        getTemplates("");
        displayCurrentTemplate("New Template");
        _setChosenTemplate("New Template");
        setUpdateTemp({});
    }, [lob, userRole]);

    useEffect(() => {
        _setCode(_smsDetails?.body);
    }, [_chosenTemplate, _smsDetails?.body]);

    useEffect(() => {
        _setTemplateList(_templateList);
    }, [_templateList]);

    useEffect(() => {
        if (_smsDetails) {
            const isModified =
                _smsDetails.templateDescription !== updateTemp.description ||
                _smsDetails.body !== updateTemp.content
            setModifiedFlag(isModified);
        }
    }, [updateTemp, _smsDetails])


/*    useEffect(() => {
        if (_smsDetails) {
            console.log("useEffect - _smsDetails.defaultSubstitutions - updateTemp: ", _smsDetails.defaultSubstitutions);
        }
        console.log("useEffect - updateTemp, ", updateTemp);
    }, [updateTemp])*/

/*    useEffect(() => {
        if (_smsDetails) {
            console.log("useEffect - _smsDetails.defaultSubstitutions - _smsDetails: ", _smsDetails.defaultSubstitutions);
        }
    }, [_smsDetails])*/

/*    useEffect(() => {
        _setCode(_code);
    }, [_setCode])*/

    /*useEffect(() => {
        //if (previewCode) {
            //setPreviewCode(previewCode);
        console.log("useEffect - previewCode: ", previewCode ? previewCode : "No previewCode");
        // setPreviewCode(previewCode);

        //}
    }, [previewCode])

    useEffect(() => {        
        console.log("useEffect - _code: ", _code ? _code : "No _code");        
    }, [_code])

    useEffect(() => {
        console.log("useEffect - _smsDetails: ", _smsDetails ? _smsDetails : "No _smsDetails");
    }, [_smsDetails])*/


    useEffect(() => {
        const maxWidth = 70;
        if (updateTemp) {
            if (updateTemp.description) {
                let maxLength = updateTemp.description.length;
                let row = 1;

                maxLength = updateTemp.description ? updateTemp.description.length : 20;
                const newWidth = (maxLength > 25) ? (Math.max(150, maxLength * 10)) / 16 : 250 / 16;
                newWidth < maxWidth ? (newWidth < 25 ? setMinWidth("30rem") : setMinWidth(String(newWidth) + "rem")) : setMinWidth("70rem");

                if (updateTemp.description) {
                    row = Math.min(3, updateTemp.description.split('\n').length);
                    if (row === 1) {
                        if (newWidth < maxWidth) {
                            setDescriptionHeight("50px");
                        } else if ((newWidth >= maxWidth) && (newWidth < (2 * maxWidth))) {
                            setDescriptionHeight("72px");
                        } else {
                            setDescriptionHeight("95px");
                        }
                    } else if (row === 2) {
                        const splitedDescription = updateTemp.description.split('\n');
                        const maxLengthDescription = Math.max(splitedDescription[0].length, splitedDescription[1].length);

                        if ((maxLengthDescription * 10 / 16) > maxWidth) {
                            setDescriptionHeight("95px");
                        } else {
                            setDescriptionHeight("72px");
                        }
                    } else {
                        setDescriptionHeight("95px");
                    }
                }
            } else {
                setMinWidth("15rem")
            }
        }
    }, [_smsDetails, updateTemp])

    const toggleCopyTemplate = () => {
        _setDupTemplateName(_smsDetails.templateName + "-COPY")
    }

    const _onTemplateChange = async (option: any) => {
        setInvalidInput(false);

        let latestVersion: string = "";
        if (option.value === "New Template") {
            _setSmsTemplateVersions([]);
            _setChosenTemplateVersion("");
            _setActiveVersion("");
            _setSmsDetails(null);
            _setCode("Please enter text.");
            _setChosenTemplate("New Template");
            setNewVersion(false);
            setDialogOpenSave(false);
            setUpdateTemp({});
            setPreviewCode("Please enter text.");
        } else {
            _setChosenTemplateVersion("1");

            // const _template = _templateList.find((item: any) => item.value === option.value) as any;
            // const _templateDets = _templateList.find((item: any) => item.templateName === option.value) as any;
            var _details = await getTemplateByName(option.value, "SMS");

            if (_details && option.value !== "New Template") {
                setUpdateTemp(prev => ({ ...prev, description: _details.templateDescription, content: _details.body }));
                setEditFlag(prev => ({ ...prev, description: false, content: false }));
                getVersions(option.value)
                    .then((res) => {
                        latestVersion = res[res.length - 1].label;
                        return _setSmsTemplateVersions(res)
                    }).then(() => {
                        _setChosenTemplateVersion(_details.version)
                        _setActiveVersion(_details.version)
                        setLatestVersionFlag(Number(_details.version) === Number(latestVersion))
                        setNewVersion(Number(_details.version) !== Number(latestVersionNumber))
                        return
                    })
                    /*.then(() => {
                        return getTemplateDetails(option.value);
                    })*/
                _setSmsDetails(_details);
                _setCode(_details.body);
                _setChosenTemplate(option.value);
                setPreviewCode(_details.body);
            } else {
                _setSmsTemplateVersions([]);
                _setChosenTemplateVersion("");
                _setActiveVersion("");
                _setSmsDetails(null);
                _setCode('Please enter text.');
                _setChosenTemplate("New Template");
                setNewVersion(false);
                setDialogOpenSave(false);
                setPreviewCode("Please enter text.");
            }
        }
    }

    const _onVersionChange = async (option: any) => {
        setInvalidInput(false);

        var res = await getTemplateByNameVersion(_smsDetails.templateName, option.value, "SMS");

        setUpdateTemp(prev => ({ ...prev, description: res.templateDescription, content: res.body, version: res.version }));
        _setChosenTemplateVersion(option.value);
        _setSmsDetails(res);
        _setCode(res.body);
        setPreviewCode(res.body);
        setLatestVersionFlag(Number(option.value) === Number(latestVersionNumber));
        setNewVersion(Number(option.value) !== Number(latestVersionNumber))

        setDialogOpenSave(false);
    }

    const _onCodeChange = (code?: string) => {
        console.log("inside code change")
        _setCode(code || '');
        setUpdateTemp(prev => ({ ...prev, content: code || '' }));
        setPreviewCode(code || '');
    }

    const getTemplates = async (value: string) => {
        setLoading(true);
        var res = await getAllLobTemplates(lob, "SMS");
        _setSmsTemplates(res);
        var defaultTemplate = { label: "New Template", value: "New Template" }
        if (res && res.status !== 400) {
            var templateList = res.map((item: any) => {
                return {
                    label: item.templateName,
                    value: item.templateName
                }
            });
            templateList.push(defaultTemplate);
            var sortedList = templateList.sort((a: any, b: any) => (a.label < b.label ? -1 : 1));
            _setTemplateList(sortedList);
        }
        setLoading(false);

        if (value === "") {
            _setChosenTemplate("New Template");
        } else {
            _setChosenTemplate(value);
        }
        setEditFlag({});
        setModifiedFlag(false);
        // setInvalidInput(false);
    };

/*    const getTemplateDetails = async (name: string) => {
        var res = await getTemplateByName(name, "SMS");
        console.log("getTemplateDetails ", res);
        _setSmsDetails(res);
        _setCode(res.body);
    }*/

    const getVersions = async (name: string) => {
        var templateType = "SMS"
        var res = await getTemplateVersions(name, templateType);
        if (res.item && res.item.length > 0) {
            var versionList = res.item.map((item: any) => {
                return {
                    label: item.version,
                    value: item.version
                }
            })
            var sortedList = versionList.sort((a: any, b: any) => (Number(a.label) < Number(b.label) ? -1 : 1));

            setLatestVersionNumber(sortedList[sortedList.length - 1].label);
            return sortedList;
        }
    }

    const displayCurrentTemplate = (values: any) => {
        var displayTemplate: Record<string, string> = {};
        displayTemplate["label"] = values;
        displayTemplate["value"] = values;
        _onTemplateChange(displayTemplate);
    }


    const saveTemplate = async (values: any) => {
        var name = values.name;
        var description = values.description;
        var content = _code;
        var defaultSub = values.defaultSubstitutions

        try {
            const attributes = {
                CITY_: "",
                STATE_: "",
                ZIP_: ""
            }
            const def = JSON.stringify(attributes);
            var response = await createSmsTemplate(lob, name, content, description, defaultSub);
            setAlertSeverity("success")
            setAlertMessage("Template was created Successfully")
            setAlertOpen(true);

            // reload the created template    
            getTemplates(name);
            displayCurrentTemplate(values.name);

        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to create template!")

            setAlertOpen(true);
        }
    }

    const updateTemplate = async (values: any) => {
        var name = values.name;
        var description = values.description;
        var content = _code;
        var newVersion = values.newVersion
        var attributes = values.defaultSubstitutions;

        try {
            await updateSmsTemplate(name, content, newVersion, description, attributes);
            setAlertSeverity("success")
            setAlertMessage("Template was update Successfully")
            setAlertOpen(true);
            // reload the current page with the copied template
            getTemplates(name);
            displayCurrentTemplate(values.name);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to update template!")
            setAlertOpen(true);
        }
    }

    const copyTemplate = async (values: any) => {
        var name = values.name;
        var description = values.description;
        var content = _code;
        var defaultSub = values.defaultSubstitutions;

        try {
            await createSmsTemplate(lob, name, content, description, defaultSub);
            setAlertSeverity("success")
            setAlertMessage("Template was created Successfully")
            setAlertOpen(true);
            // reload the current page with the copied template
            getTemplates(name);
            displayCurrentTemplate(values.name);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to create template!")
            setAlertOpen(true);
        }
    }

    const deleteTemplate = async () => {
        var name = _smsDetails?.templateName;
        if (!window.confirm(`Are you sure you want to delete ${name}? This action is irreversible!`)) return;
        else {
            try {
                await deleteTemplateByName(name, lob, "SMS");
                setAlertSeverity("success")
                setAlertMessage("Template was deleted Successfully")
                setAlertOpen(true);
                // reload the default template 
                getTemplates("");
                displayCurrentTemplate("New Template");
                _setChosenTemplate("New Template");
            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to delete template!")
                setAlertOpen(true);
            }
        }
    }

    const testTemplate = async (values: any) => {
        var appId = values.appId;
        var templateName = values.name;
        var description = values.description;
        var content = _code;
        var defaultSubstitutions = values.defaultSubstitutions;
        var smsTo = values.sendSmsTo;
        var smsFrom = values.sendSmsFrom;
        var messageType = values.messageType;

        try {
            var temp = await testSmsTemplate(appId, templateName, content, smsTo, smsFrom, defaultSubstitutions, messageType, description)
                .then(r => {
                    return r;
                });
            if (temp === 200) {
                setAlertSeverity("success")
                setAlertMessage("Template was tested Successfully")
                setAlertOpen(true);
            } else {
                setAlertSeverity("error")
                setAlertMessage("Unable to test template!")
                setAlertOpen(true);
            }
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to test template!")
            setAlertOpen(true);
        }
    }



    const makeActive = async () => {

        var name = _smsDetails?.templateName;
        var ver = _chosenTemplateVersion;

        try {
            await activateTemplate(name, ver, "SMS");
            setAlertSeverity("success")
            setAlertMessage("Version " + ver + " is now active!")
            setAlertOpen(true);
            getTemplates(name);
            displayCurrentTemplate(name);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to change active version!")
            setAlertOpen(true);
        }
    }

    const handleUpdateTempChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdateTemp(prev => ({ ...prev, [name]: value }));
        /*if (name === "subject" && !value) {
            setInvalidInput(true);
        }*/
    }

    const handleUpdateEdit = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name } = e.target
        setEditFlag(prev => ({ ...prev, [name]: true }));
    }

    const handleUpdateConfirmEdit = (input: Record<string, string>, newVersion: boolean) => {
        if (!invalidInput) {
            const values: Record<string, any> = {
                name: _smsDetails?.templateName,
                description: input.description || "",
                content: input.content,
                newVersion: newVersion,
                defaultSubstitutions: _smsDetails?.defaultSubstitutions
            }
            if (modifiedFlag) {
                updateTemplate(values);
            }
            setEditFlag({});
            setDialogOpenSave(false);
        }
    }

    const handleUpdateCancelEdit = (e: React.MouseEvent<HTMLButtonElement>, name: string) => {
        if (name === "description") {
            setUpdateTemp(prev => ({ ...prev, [name]: _smsDetails?.["templateDescription"] }));
        } else {
            setUpdateTemp(prev => ({ ...prev, [name]: _smsDetails?.[name] }));
        }
        setEditFlag(prev => ({ ...prev, [name]: false }));
        // setInvalidInput(false);
    }



    const handleOpenSaveDialog = () => {
        setNewVersion(!latestVersionFlag);
        setDialogOpenSave(true);
    }

    const handleCloseSaveDialog = () => {
        setDialogOpenSave(false);
    }

    const toggleNewVersion = () => {
        setNewVersion(!newVersion)
    } 

    const handleOnParse = (previewCode: string) => {
        setPreviewCode(previewCode);
    }

/*    const handleOnParse = (newCode: string) => {
        _setCode(newCode);
    }*/


    return (
        <>
            <LoadingModal open={loading} />
            <Grid>
                <div className="App">
                    <div className='m-2 flex justify-start'>
                        {(Array.isArray(_templateList) && _templateList.length > 0) ?
                            <TemplatePicker options={_templateList} onChange={_onTemplateChange} selected={{ label: _chosenTemplate, value: _chosenTemplate }} label="Template" />
                            : <></>
                        }
                        &nbsp;
                        {(Array.isArray(_smsTemplateVersions) && _smsTemplateVersions.length > 0) ?
                            <TemplatePicker options={_smsTemplateVersions} onChange={_onVersionChange} selected={{ label: _chosenTemplateVersion, value: _chosenTemplateVersion }} label="Versions" />
                            : <></>
                        }
                    </div>

                    <div className='m-2 flex justify-start'>
                        {(_smsDetails) ?
                            <div>                            
                                <Grid container item={true} spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="description"
                                            name="description"
                                            label="Description"
                                            sx={{ minWidth: minWidth, height: descriptionHeight, wordWrap: 'break-word', maxWidth: '100%' }}
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            maxRows={3}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={handleUpdateTempChange}
                                            value={updateTemp.description || ""}
                                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleUpdateEdit(e)}
                                            fullWidth
                                            disabled={false}
                                            placeholder="Optional"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {editFlag["description"] && !invalidInput && modifiedFlag && (
                                                            <Tooltip title={"Cancel?"}>
                                                                <IconButton onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleUpdateCancelEdit(e, "description")}><Close /></IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item={true} spacing={1} >
                                    <Grid item xs={4} container justifyContent='flex-start'>

                                        <TextField
                                            id="version"
                                            label="Version"
                                            sx={{ width: '6rem', height: '50px' }}
                                            size="small"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={_smsDetails.version || ""}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={8} container alignItems='center' justifyContent='flex-start'>
                                        {(_smsDetails.version === _activeVersion) ?
                                            <Chip icon={<CheckCircle />} label="Active" color="success" /> : <></>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                            : <></>}
                    </div>
                    <div className='m-2 flex justify-start'>
                        {(_chosenTemplate === "New Template") ?
                            <><Tooltip title='Create'>
                                <IconButton onClick={() => setDialogOpenCreate(true)}><AddCircleOutlinedIcon sx={{ variant: "outlined", color: 'green' }} /> </IconButton>
                            </Tooltip>
                                {/*<Tooltip title='Import'>
                                <IconButton onClick={() => importFile()}><UploadFile sx={{ variant: "outlined", color: 'blue' }} /> </IconButton>
                            </Tooltip>*/}</>
                            :
                            <>
                                {modifiedFlag && !invalidInput && (
                                    <>
                                        <Tooltip title='Save'>
                                            <IconButton onClick={handleOpenSaveDialog} ><CheckBoxIcon sx={{ variant: "outlined", color: 'green' }} /> </IconButton>
                                        </Tooltip>
                                        <Dialog open={dialogOpenSave} onClose={handleCloseSaveDialog}>
                                            <DialogTitle>
                                                <Typography variant='inherit' sx={{ fontFamily: dishFont, display: 'flex' }}>Update SMS Template: </Typography>
                                                <Typography sx={{ fontFamily: dishFont, display: 'flex' }}><br />Template Name: {_chosenTemplate} </Typography>
                                                <Typography sx={{ fontFamily: dishFont, display: 'flex' }}>Current Version: {_chosenTemplateVersion} </Typography>
                                                {latestVersionFlag ?
                                                    <Typography sx={{ fontFamily: dishFont }}>
                                                        <Checkbox onChange={toggleNewVersion} checked={newVersion} />{!newVersion ? "Create New Version" : ("This will create version " + (Number(latestVersionNumber) + 1))}</Typography>
                                                    :
                                                    <Typography sx={{ fontFamily: dishFont, display: 'flex', color: 'red' }} ><br /> This will create version  {Number(latestVersionNumber) + 1}</Typography>
                                                }

                                            </DialogTitle>

                                            <DialogActions>
                                                <Button sx={buttonSecondary} onClick={handleCloseSaveDialog}>Cancel</Button>
                                                <Button sx={buttonPrimary} variant="contained" onClick={() => handleUpdateConfirmEdit(updateTemp, newVersion)}>{latestVersionFlag ? (!newVersion ? "Update" : "Save as New Version") : "Save as New Version"}</Button>
                                            </DialogActions>
                                        </Dialog>

                                    </>
                                )}
                                <Tooltip title='Update'>
                                    <IconButton onClick={() => setDialogOpenUpdate(true)} ><SaveIcon sx={{ variant: "outlined", color: 'blue' }} /> </IconButton>
                                </Tooltip>
                            </>


                        }
                        {(_chosenTemplate !== "New Template") ?
                            <>
                                <Tooltip title='Copy'>
                                    <IconButton onClick={() => { setDialogOpenCopy(true); toggleCopyTemplate(); }}><ContentCopy sx={{ variant: "outlined", color: 'blue' }} /> </IconButton>
                                </Tooltip>
                                <Tooltip title='Delete'>
                                    <IconButton onClick={deleteTemplate}><DeleteIcon sx={{ variant: "outlined", color: 'red' }} /> </IconButton>
                                </Tooltip>
                                {(Number(_activeVersion) === Number(_chosenTemplateVersion)) && (
                                    <Tooltip title='Test'>
                                        <IconButton onClick={() => setDialogOpenTest(true)}><BugReportSharpIcon sx={{ variant: "outlined", color: 'purple' }} /> </IconButton>
                                    </Tooltip>)
                                }

                                {(_smsDetails?.version !== _activeVersion) ?
                                    <>
                                        <Tooltip title='Mark Active'>
                                            <IconButton onClick={makeActive}><Verified sx={{ variant: "outlined", color: 'green' }} /></IconButton>
                                        </Tooltip>
                                    </> : <></>
                                }
                            </> : <></>
                        }

                    </div>
                    <Typography sx={{ fontFamily: dishFont, display: 'flex' }}>Total of {previewCode ? previewCode.length : ((updateTemp.content || updateTemp.content === '') ? updateTemp.content.length : 'Please enter text.'.length)} characters contained pre-populating default values of attributes. </Typography>
                    <div className='grid grid-cols-2 h-screen'>
                        <div className='col-span-1 h-2/3 max-h-2/3 overflow-auto'>
{/*                                <Typography sx={{ fontFamily: dishFont, display: 'flex' }}>The message body contains {updateTemp.content ? updateTemp.content.length : ("Please enter text.".length)} characters </Typography>
*/}                            {/*{(updateTemp.content && updateTemp.content.length > 140) &&
                                <Typography sx={{ fontFamily: dishFont, display: 'flex' }}> The message contains more than 140 characters, and will be splitted into multiple message parts. </Typography>
                            }*/}
                            <Editor code={_code} onChange={_onCodeChange} templateType={"SMS"} />
                        </div>
                        {_code && 
                            <div className='col-span-1 h-2/3 max-h-2/3 overflow-auto items-start' style={{ marginLeft: '20px'}}>                                                                
                                <Divider variant="middle" >
                                    <Typography variant="caption" sx={{ fontFamily: dishFont, fontSize: '24px' }}>Message Preview</Typography>
                                </Divider>
                                
                                <Box>
                                    {/*{(_code && _code.length > 0) ?*/}
                                    {/*    <SmsPreviewer code={previewCode} testData={_smsDetails?.defaultSubstitutions ? JSON.parse(_smsDetails?.defaultSubstitutions) : {}} onParse={handleOnParse} title="Message Preview"></SmsPreviewer>*/}
                                    {/*    <HtmlPreviewer code={previewCode} testData={_smsDetails?.defaultSubstitutions} onParse={handleOnParse}></HtmlPreviewer>*/}

                                    {/*    : */}
                                    {/*    <SmsPreviewer code={'Please enter text.'} testData={{}} onParse={handleOnParse} title="Message Preview"></SmsPreviewer>*/}
                                    {/*}*/}
                                </Box>
                            </div>
                        }
                    </div> 
                </div>



            </Grid>
            
            <CreateTemplateModal
                open={dialogOpenCreate}
                onClose={() => { setDialogOpenCreate(false); }}
                onSubmit={saveTemplate}
                currentBody={_code}
                currentLob={lob}
            />
            <UpdateTemplateModal
                open={dialogOpenUpdate}
                onClose={() => { setDialogOpenUpdate(false); }}
                onSubmit={updateTemplate}
                currentName={_smsDetails?.templateName}
                currentDescription={_smsDetails?.templateDescription}
                currentDefaultSubstitutions={_smsDetails?.defaultSubstitutions}
                currentVersion={_smsDetails?.version}
                currentBody={_code}
                currentSmsDetails={_smsDetails}
                currentLob={lob}
                latestVersionFlag={latestVersionFlag}
                latestVersionNumber={latestVersionNumber}
            />
            <CopyTemplateModal
                open={dialogOpenCopy}
                onClose={() => { setDialogOpenCopy(false); }}
                onSubmit={copyTemplate}
                currentName={_dupTemplateName}
                currentDescription={_smsDetails?.templateDescription}
                currentDefaultSubstitutions={_smsDetails?.defaultSubstitutions}
                currentBody={_code}
                currentLob={lob}

            />      
            <TestTemplateModal
                open={dialogOpenTest}
                onClose={() => { setDialogOpenTest(false); }}
                onSubmit={testTemplate}
                currentName={_smsDetails?.templateName}
                currentDescription={_smsDetails?.templateDescription}
                currentDefaultSubstitutions={_smsDetails?.defaultSubstitutions}
                currentBody={_smsDetails?.body}
                currentProjectId={projectId}
            />            
            <BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)}
            />
        </>
    );
}

interface TemplateModalPropsCreate {
    open: boolean,
    onClose: () => void,
    onSubmit: (values: any) => void;
    //currentDefaultSub: string;
    currentBody: string;
    currentLob: string;
    //currentName: string;
    //currentSubject: string;
    //currentDescription: string;
    //currentVersion: number;
    //duplicateName: string;
};

export const CreateTemplateModal = ({
    open,
    onClose,
    onSubmit,
    currentBody,
    currentLob,

}: TemplateModalPropsCreate) => {
    const [isTestModal] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [lob, setLob] = useState<string>(currentLob);

    const [validationErrors, setValidationErrors] = useState<any>({
        noName: 0,
        hasSpaces: 0,
        noValue: 0,
    })

    const [kvps, setKvps] = useState("");
    const handleKvpChange = (newKvps: string) => {
        setKvps(newKvps);
    }

    const [noValueFlag, setNoValueFlag] = useState<boolean>(false);
    const handleNoValueFlag = (newFlag: boolean) => {
        setNoValueFlag(newFlag);
    }

    // nameSubjectFlag[0]: check for no name
    // nameSubjectFlag[1]: check for space in name
    const [nameErrorFlag, setNameErrorFlag] = useState<number[]>([0, 0]);

    useEffect(() => {
        setLob(currentLob);
    }, [currentLob, open])

    useEffect(() => {
        setNoValueFlag(noValueFlag);
    }, [noValueFlag])

    useEffect(() => {
        setName(name)
    }, [name])

    useEffect(() => {
        setNameErrorFlag(nameErrorFlag)
    }, [nameErrorFlag])

    let details = {
        name: name,
        description: description,
        defaultSubstitutions: kvps,
        lob: lob,
    };

    const updateTemplateName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        let errorFlag: number[] = [0, 0];
        if (!e.target.value.trim()) {
            errorFlag[0] = 1;
            
        } else if (/\s/g.test(e.target.value)) {
            errorFlag[1] = 1;
        } 
        setNameErrorFlag(errorFlag);

    }

    const updateTemplateDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
    }

    const handleCancel = () => {
        setValidationErrors({
            noName: 0,
            hasSpaces: 0
        })
        setName("");
        setDescription("");
        setValidationErrors({});
        setNoValueFlag(false);
        setKvps("");
        setNameErrorFlag([0, 0]);
        onClose();
    }

    const checkForSpaces = (value: string) => {
        if (value)
            return /\s/g.test(value);
    }

    const handleSubmit = () => {
        async function validator() {
            const newErrorObject = {
                noName: 0,
                hasSpaces: 0,
                noValue: 0,
            }

            if (!name) {
                newErrorObject.noName = 1
            }            
            if (checkForSpaces(name)) {
                newErrorObject.hasSpaces = 1
            }
            if (noValueFlag) {
                newErrorObject.noValue = 1
            }

            setValidationErrors(newErrorObject);

            if (newErrorObject.noName === 1) {
                console.log("Missing Template Name");
                return null;
            } else if (newErrorObject.hasSpaces === 1) {
                console.log("Contains Spaces");
                return null;
            } else if (!isTestModal && newErrorObject.noValue === 1) {
                console.log("Missing Values for Attributess");
                setNoValueFlag(true);
                return null;
            } else {
                details = {
                    name: name,
                    description: description,
                    defaultSubstitutions: kvps,
                    lob: lob,
                }
                console.log("Values before OnSubmit (Create): ", details)
                onSubmit(details);
                setName("");
                setDescription("");
                setValidationErrors({});
                setNoValueFlag(false);
                setKvps("");
                setNameErrorFlag([0, 0]);
                onClose();
            }
        }
        validator();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create SMS Template</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Template Name</Typography>
                            <TextField id="templatName" sx={{ minWidth: '16rem', width: "93%" }} onChange={updateTemplateName} value={name || ""} error={name === "" || nameErrorFlag[0] === 1 || nameErrorFlag[1] === 1} helperText={((name === "" || nameErrorFlag[0] === 1) ? "Please enter a valid Template Name" : "") || ((name === "" || nameErrorFlag[1] === 1) ? "Template Name Contains Spaces" : "")} fullWidth />
                            {validationErrors.noName === 1 &&
                                <Typography key='NameError' sx={errorText}>Please enter a Template Name</Typography>
                            }
                            {validationErrors.hasSpaces === 1 &&
                                <Typography key='NameError' sx={errorText}>Template Name cannot contain spaces</Typography>
                            }
                        </Grid>                        
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Description</Typography>
                            <TextField id="description" sx={{ minWidth: '16rem', width: "93%" }} placeholder="Optional" onChange={updateTemplateDescription} value={description || ""} fullWidth />
                        </Grid>

                        <KVPTable kvps={kvps} newList={[]} keyName={"Variable Name"} keyLabel={"Value"} onKvpChange={handleKvpChange} onNoValueFlagChange={handleNoValueFlag} emailHtmlPart={currentBody} valueCheckAlert={!isTestModal} subject="" />

                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Tooltip title={!isTestModal ? "Missing Values" : ""} disableHoverListener={!noValueFlag}>
                    <span>
                        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained" disabled={name === "" || nameErrorFlag[0] === 1 || nameErrorFlag[1] === 1 || (!isTestModal && noValueFlag)}>Create</Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

interface TemplateModalPropsUpdate {
    open: boolean,
    onClose: () => void,
    onSubmit: (values: any) => void;
    currentName: string;
    currentDescription: string;
    currentDefaultSubstitutions: string;
    currentVersion: number;
    currentBody: string;
    currentSmsDetails: any;
    currentLob: string;
    latestVersionFlag: boolean;
    latestVersionNumber: string;
};

export const UpdateTemplateModal = ({
    open,
    onClose,
    onSubmit,
    currentName,
    currentDescription,
    currentDefaultSubstitutions,
    currentVersion,
    currentBody,
    currentSmsDetails,
    currentLob,
    latestVersionFlag,
    latestVersionNumber

}: TemplateModalPropsUpdate) => {
    const [isTestModal] = useState<boolean>(false);
    const [newVersion, setNewVersion] = useState<boolean>(false);
    const [version, setVersion] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [smsBody, setSmsBody] = useState<string>("");
    const [lob, setLob] = useState<string>(currentLob);

    const [validationErrors, setValidationErrors] = useState<any>({
        noName: 0,
        hasSpaces: 0,
        noValue: 0
    })

    // nameSubjectFlag[0]: check for no name
    // nameSubjectFlag[1]: check for space in name
    const [nameErrorFlag, setNameErrorFlag] = useState<number[]>([0, 0]);

    const [kvps, setKvps] = useState("");
    const handleKvpChange = (newKvps: string) => {
        setKvps(newKvps);
    }

    const [noValueFlag, setNoValueFlag] = useState<boolean>(false);
    const handleNoValueFlag = (newFlag: boolean) => {
        setNoValueFlag(newFlag);
    }

    useEffect(() => {
        setLob(currentLob);
    }, [currentLob, open])

    useEffect(() => {
        setName(currentName)
    }, [currentName])

    useEffect(() => {
        setDescription(currentDescription)
    }, [currentDescription])

    useEffect(() => {
        setKvps(currentDefaultSubstitutions ? currentDefaultSubstitutions : "");
    }, [currentDefaultSubstitutions, open])

    useEffect(() => {
        setKvps(kvps);
    }, [kvps])

    useEffect(() => {
        setVersion(currentVersion)
    }, [currentVersion])

    useEffect(() => {
        setNewVersion(latestVersionFlag ? false : true)
    }, [latestVersionFlag])

    useEffect(() => {
        setSmsBody(currentBody);
    }, [currentBody])

    useEffect(() => {
        setNoValueFlag(noValueFlag);
    }, [noValueFlag])

    let details = {
        name: name,
        description: description,
        version: currentVersion,
        newVersion: newVersion,
        defaultSubstitutions: kvps,
        lob: lob,
    };

    const updateTemplateName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        let errorFlag: number[] = [0, 0];
        if (!e.target.value.trim()) {
            errorFlag[0] = 1;
        } else if (/\s/g.test(e.target.value)) {
            errorFlag[1] = 1;
        }
        setNameErrorFlag(errorFlag);

    }

    const updateTemplateDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
    }

    const toggleNewVersion = () => {
        setNewVersion(!newVersion)
    }

    const handleCancel = () => {
        setValidationErrors({
            noName: 0,
            hasSpaces: 0
        })

        setNewVersion(false);

        details = {
            name: "",
            description: "",
            version: 0,
            newVersion: false,
            defaultSubstitutions: "",
            lob: ""
        };
        setName(currentName);
        setDescription(currentDescription);
        setValidationErrors({});
        setNoValueFlag(false);
        setKvps(currentDefaultSubstitutions);
        setNameErrorFlag([0, 0]);
        onClose();
    }

    const checkForSpaces = (value: string) => {
        if (value)
            return /\s/g.test(value);
    }

    const handleSubmit = () => {
        async function validator() {
            const newErrorObject = {
                noName: 0,
                hasSpaces: 0,
                noValue: 0
            }

            if (!name) {
                newErrorObject.noName = 1
            }            
            if (checkForSpaces(name)) {
                newErrorObject.hasSpaces = 1
            }

            if (noValueFlag) {
                newErrorObject.noValue = 1
            }

            setValidationErrors(newErrorObject);

            if (newErrorObject.noName === 1 || newErrorObject.hasSpaces === 1) {
                return null;
            } else {
                details = {
                    name: name,
                    description: description,
                    version: version,
                    newVersion: newVersion,
                    defaultSubstitutions: kvps,
                    lob: lob
                }
                console.log("Values before OnSubmit (Update): ", details)
                onSubmit(details);
                setName(currentName);
                setDescription(currentDescription);
                setValidationErrors({});
                setNoValueFlag(false);
                setKvps(currentDefaultSubstitutions);
                setNameErrorFlag([0, 0]);
                onClose();
            }
        }
        validator();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Update SMS Template</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Template Name</Typography>
                            <TextField id="templatName" sx={{ minWidth: '16rem', width: "93%" }} disabled onChange={updateTemplateName} value={name || ""} error={name === "" || nameErrorFlag[0] === 1 || nameErrorFlag[1] === 1} helperText={((name === "" || nameErrorFlag[0] === 1) ? "Please enter a valid Template Name" : "") || ((name === "" || nameErrorFlag[1] === 1) ? "Template Name Contains Spaces" : "")} fullWidth />
                            {/*{validationErrors.noName === 1 &&*/}
                            {/*    <Typography key='NameError' sx={errorText}>Please enter a Template Name</Typography>*/}
                            {/*}*/}
                            {/*{validationErrors.hasSpaces === 1 &&*/}
                            {/*    <Typography key='NameError' sx={errorText}>Template Name cannot contain spaces</Typography>*/}
                            {/*}*/}
                        </Grid>                        
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Description</Typography>
                            <TextField id="description" sx={{ minWidth: '16rem', width: "93%" }} placeholder="Optional" onChange={updateTemplateDescription} value={description || ""} fullWidth />
                        </Grid>
                        <Typography sx={helpText}>Current Version: {currentVersion}</Typography>

                        {latestVersionFlag ?
                            <Typography sx={{ fontFamily: dishFont }}>
                                <Checkbox onChange={toggleNewVersion} checked={latestVersionFlag ? newVersion : true} disabled={!latestVersionFlag} /> {latestVersionFlag ? (!newVersion ? "Create New Version" : ("This will create version  " + (Number(latestVersionNumber) + 1))) : ("This will create version " + (Number(latestVersionNumber) + 1))}</Typography>
                            :
                            <Typography sx={{ fontFamily: dishFont, display: 'flex', color: 'red' }} > This will create version  {Number(latestVersionNumber) + 1}</Typography>
                        }
                        <KVPTable kvps={kvps} newList={[]} keyName={"Variable Name"} keyLabel={"Value"} onKvpChange={handleKvpChange} onNoValueFlagChange={handleNoValueFlag} emailHtmlPart={smsBody} valueCheckAlert={!isTestModal} subject="" />

                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Tooltip title={!isTestModal ? "Missing Values" : ""} disableHoverListener={!noValueFlag}>
                    <span>
                        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained" disabled={name === "" || nameErrorFlag[0] === 1 || nameErrorFlag[1] === 1 || (!isTestModal && noValueFlag)}>{latestVersionFlag ? (!newVersion ? "Update" : "Save as New Version") : "Save as New Version"}</Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

interface TemplateModalPropsCopy {
    open: boolean,
    onClose: () => void,
    onSubmit: (values: any) => void;
    currentName: string;
    currentDescription: string;
    currentBody: string;
    currentDefaultSubstitutions: string;
    currentLob: string;
};

export const CopyTemplateModal = ({
    open,
    onClose,
    onSubmit,
    currentName,
    currentDescription,
    currentDefaultSubstitutions,
    currentBody,
    currentLob,

}: TemplateModalPropsCopy) => {
    const [isTestModal] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [smsBody, setSmsBody] = useState<string>("");
    const [validationErrors, setValidationErrors] = useState<any>({
        noName: 0,
        hasSpaces: 0
    })

    // nameSubjectFlag[0]: check for no name
    // nameSubjectFlag[1]: check for space in name
    const [nameErrorFlag, setNameErrorFlag] = useState<number[]>([0, 0]);
    const [lob, setLob] = useState<string>(currentLob);

    const [kvps, setKvps] = useState("");
    const handleKvpChange = (newKvps: string) => {
        setKvps(newKvps);
    }

    const [noValueFlag, setNoValueFlag] = useState<boolean>(false);
    const handleNoValueFlag = (newFlag: boolean) => {
        setNoValueFlag(newFlag);
    }

    useEffect(() => {
        setLob(currentLob);
    }, [currentLob, open])

    useEffect(() => {
        setName(currentName);
    }, [currentName])

    useEffect(() => {
        setDescription(currentDescription)
    }, [currentDescription])

    useEffect(() => {
        setSmsBody(currentBody);
    }, [currentBody, open])

    useEffect(() => {
        setNoValueFlag(noValueFlag);
    }, [noValueFlag])


    let details = {
        name: name,
        description: description,
        defaultSubstitutions: kvps,
        lob: lob,
    };

    const updateTemplateName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        let errorFlag: number[] = [0, 0];
        if (!e.target.value.trim()) {
            errorFlag[0] = 1;
        } else if (/\s/g.test(e.target.value)) {
            errorFlag[1] = 1;
        }
        setNameErrorFlag(errorFlag);

    }

    const updateTemplateDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
    }

    const handleCancel = () => {
        setValidationErrors({
            noName: 0,
            noSubject: 0,
            hasSpaces: 0
        })

        details = {
            name: "",
            description: "",
            defaultSubstitutions: "",
            lob: "",
        };
        setName(currentName);
        setDescription(currentDescription);
        setValidationErrors({});
        setNoValueFlag(false);
        setKvps(currentDefaultSubstitutions);
        setNameErrorFlag([0, 0]);
        onClose();
    }

    const checkForSpaces = (value: string) => {
        if (value)
            return /\s/g.test(value);
    }

    const checkForCopy = (value: string) => {
        if (value)
            return /-COPY/g.test(value);
    }

    const handleSubmit = () => {
        async function validator() {
            const newErrorObject = {
                noName: 0,
                hasSpaces: 0,
                hasCopy: 0
            }

            if (!name) {
                newErrorObject.noName = 1
            }            
            if (checkForSpaces(name)) {
                newErrorObject.hasSpaces = 1
            }
            if (checkForCopy(name)) {
                newErrorObject.hasCopy = 1
            }

            setValidationErrors(newErrorObject);

            if (newErrorObject.noName === 1 || newErrorObject.hasSpaces === 1 || newErrorObject.hasCopy === 1) {
                console.log("Missing Template Name / Contains Spaces / Contains -COPY");
                return null;
            } else {
                details = {
                    name: name,
                    description: description,
                    defaultSubstitutions: kvps,
                    lob: lob,
                }
                console.log(details)
                onSubmit(details);
                setName(currentName);
                setDescription(currentDescription);
                setValidationErrors({});
                setNoValueFlag(false);
                setKvps(currentDefaultSubstitutions);
                setNameErrorFlag([0, 0]);
                onClose();
            }
        }
        validator();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Copy SMS Template</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Template Name</Typography>
                            <TextField id="templatName" sx={{ minWidth: '16rem', width: "93%" }} onChange={updateTemplateName} value={name || ""} error={name === "" || nameErrorFlag[0] === 1 || nameErrorFlag[1] === 1} helperText={((name === "" || nameErrorFlag[0] === 1) ? "Please enter a valid Template Name" : "") || ((name === "" || nameErrorFlag[1] === 1) ? "Template Name Contains Spaces" : "")} fullWidth />
                            {validationErrors.noName === 1 &&
                                <Typography key='NameError' sx={errorText}>Please enter a Template Name</Typography>
                            }
                            {validationErrors.hasSpaces === 1 &&
                                <Typography key='NameError' sx={errorText}>Template Name cannot contain spaces</Typography>
                            }
                            {validationErrors.hasCopy === 1 &&
                                <Typography key='NameError' sx={errorText}>Template Name must be renamed</Typography>
                            }
                        </Grid>                        
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Description</Typography>
                            <TextField id="description" sx={{ minWidth: '16rem', width: "93%" }} placeholder="Optional" onChange={updateTemplateDescription} value={description || ""} fullWidth />
                        </Grid>

                        <KVPTable kvps={currentDefaultSubstitutions} newList={[]} keyName={"Variable Name"} keyLabel={"Value"} onKvpChange={handleKvpChange} onNoValueFlagChange={handleNoValueFlag} emailHtmlPart={smsBody} valueCheckAlert={!isTestModal} subject="" />
                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Tooltip title={!isTestModal ? "Missing Values" : ""} disableHoverListener={!noValueFlag}>
                    <span>
                        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained" disabled={name === "" || nameErrorFlag[0] === 1 || nameErrorFlag[1] === 1 || (!isTestModal && noValueFlag)}>Copy</Button>
                    </span>
                </Tooltip>            </DialogActions>
        </Dialog>
    );
};

interface TemplateModalPropsTest {
    open: boolean,
    onClose: () => void,
    onSubmit: (values: any) => void;
    currentName: string;
    currentDescription: string;
    currentDefaultSubstitutions: string;
    currentBody: string;
    currentProjectId: string;
};


export const TestTemplateModal = ({
    open,
    onClose,
    onSubmit,
    currentName,
    currentDescription,
    currentDefaultSubstitutions,
    currentBody,
    currentProjectId,

}: TemplateModalPropsTest) => {
    const [isTestModal] = useState<boolean>(true);
    const [appId, setAppId] = useState<string>("");
    const [name, setName] = useState<string>("");

    const [sendSmsTo, setSendSmsTo] = useState<string>("");
    const [sendSmsFrom, setSendSmsFrom] = useState<string>("");
    // smsValidationFlag[0]: no value error
    // smsValidationFlag[1]: longer than 10 digits error
    // smsValidationFlag[2]: not starts with 1 when it's 10 digits error
    // const [smsValidationFlag, setSmsValidationFlag] = useState<number[]>([0, 0, 0])
    const [smsValidationMessage, setSmsValidationMessage] = useState<string>("")
    const [description, setDescription] = useState<string>("");
    const [defaultSubstitutions, setDefaultSubstitutions] = useState<Record<string, string[]>>({});
    const [smsBody, setSmsBody] = useState<string>("");
    const [messageType, setMessageType] = useState<"TRANSACTIONAL" | "PROMOTIONAL">("TRANSACTIONAL");

    const [validationErrors, setValidationErrors] = useState<any>({
        noName: 0,
        noSendSmsTo: 0,
        hasSpaces: 0,
        noValue: 0,
    })

    const [kvps, setKvps] = useState("");
    const [kvpsJson, setKvpsJson] = useState<Record<string, string[]>>(defaultSubstitutions);
    const handleKvpChange = (newKvps: string) => {
        setKvps(newKvps);
    }

    const [noValueFlag, setNoValueFlag] = useState<boolean>(false);
    const handleNoValueFlag = (newFlag: boolean) => {
        setNoValueFlag(newFlag);
    }

    useEffect(() => {
        setAppId(currentProjectId);
    }, [currentProjectId])

    useEffect(() => {
        setName(currentName);
    }, [currentName])

    useEffect(() => {
        setDescription(currentDescription);
    }, [currentDescription])

    useEffect(() => {
        setSmsBody(currentBody);
    }, [currentBody])

    useEffect(() => {
        let defaultSubstitutionsJson: Record<string, string[]> = {};
        defaultSubstitutionsJson = currentDefaultSubstitutions ? JSON.parse(currentDefaultSubstitutions) : {};
        setDefaultSubstitutions(defaultSubstitutionsJson);
        setKvpsJson(defaultSubstitutionsJson);
    }, [currentDefaultSubstitutions, open])

    useEffect(() => {
        let substitutionsJsonTemp: Record<string, string> = {};
        try {
            substitutionsJsonTemp = kvps ? JSON.parse(kvps) : {};
        } catch {
            console.log("Error kvps: ", kvps);
        }
        const substitutionsJson: Record<string, string[]> = substitutionsJsonTemp ? Object.keys(substitutionsJsonTemp).reduce((acc, key) => {
            acc[key] = [substitutionsJsonTemp[key]];
            return acc;
        }, {} as Record<string, string[]>) : {};
        setKvpsJson(substitutionsJson);
    }, [kvps, smsBody, open])

    useEffect(() => {
        setSendSmsTo(sendSmsTo);
    }, [sendSmsTo])

    useEffect(() => {
        setNoValueFlag(noValueFlag);
    }, [noValueFlag])

    /*useEffect(() => {
        setSmsValidationFlag(smsValidationFlag);
    }, [smsValidationFlag, kvpsJson])*/

/*    useEffect(() => {
        setSmsValidationMessage(smsValidationMessage);
    }, [smsValidationMessage, kvpsJson])*/

    let details = {
        appId: appId,
        name: name,
        defaultSubstitutions: defaultSubstitutions,
        sendSmsTo: sendSmsTo,
        sendSmsFrom: sendSmsFrom,
        description: description,
        messageType: messageType,
    };

    const updateTemplateName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }

    const updateTemplateDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    }

    const updateTemplateSendSmsTo = (e: ChangeEvent<HTMLInputElement>) => {
        setSendSmsTo(e.target.value);

        if (!e.target.value) {
            setSmsValidationMessage("Please enter a valid phone number or short code");
        } else if ((e.target.value.length > 6 && e.target.value.length < 11) || e.target.value.length > 11) {
            setSmsValidationMessage("Phone number should be 11 digits");
        } else if (e.target.value.length === 11 && !/^1/.test(e.target.value)) {
            setSmsValidationMessage("Phone number should start with 1");
        } else if (e.target.value.length < 7 && !/^\d{5,6}$/.test(e.target.value)) {
            setSmsValidationMessage("Short code should have 5 or 6 digits");
        } else {
            setSmsValidationMessage("");
        }
    }

    const handleMessageTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessageType((e.target as HTMLInputElement).value as "TRANSACTIONAL" | "PROMOTIONAL");
    }

    const handleCancel = () => {
        setValidationErrors({
            noName: 0,
            noSubject: 0,
            noSendSmsTo: 0,
            hasSpaces: 0,
            noValue: 0,
        })

        details = {
            appId: "",
            name: "",
            defaultSubstitutions: {},
            sendSmsTo: "",
            sendSmsFrom: "",
            description: "",
            messageType: "TRANSACTIONAL",
        };
        setSendSmsTo("");
        setKvps("");
        setKvpsJson({});
        setSmsBody(currentBody);
        setValidationErrors({});
        setNoValueFlag(false);
        setSmsValidationMessage("");
        onClose();
        setMessageType("TRANSACTIONAL");
    }

    const checkForSpaces = (value: string) => {
        if (value)
            return /\s/g.test(value);
    }

    const handleSubmit = () => {
        async function validator() {
            const newErrorObject = {
                noName: 0,
                noSendSmsTo: 0,
                hasSpaces: 0,
                noValue: 0,
            }

            if (!name) {
                newErrorObject.noName = 1
            }            
            if (!sendSmsTo) {
                newErrorObject.noSendSmsTo = 1
            }
            if (checkForSpaces(name)) {
                newErrorObject.hasSpaces = 1
            }

            if (noValueFlag) {
                newErrorObject.noValue = 1
            }

            setValidationErrors(newErrorObject);

            if (newErrorObject.noName === 1) {
                console.log("Missing -- Template Name");
                return null;
            } else if (newErrorObject.hasSpaces === 1) {
                console.log("SMS Template Contains Spaces");
                return null;
            } else if (newErrorObject.noSendSmsTo === 1) {
                console.log("Missing -- Send Sms To");
                return null;
            } else if (!isTestModal && newErrorObject.noValue === 1) {
                console.log("Missing -- Values for Attributess");
                setNoValueFlag(true);
                return null;
            } else {
                details = {
                    appId: appId,
                    name: name,
                    defaultSubstitutions: kvpsJson,
                    sendSmsTo: sendSmsTo,
                    sendSmsFrom: sendSmsFrom,
                    description: description,
                    messageType: messageType,
                }
                onSubmit(details);
                setSendSmsTo("");
                setKvps("");
                setKvpsJson({});
                setSmsBody(currentBody);
                setValidationErrors({});
                setNoValueFlag(false);
                setSmsValidationMessage("");
                setMessageType("TRANSACTIONAL");
                onClose();
            }
        }
        validator();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Test SMS Template</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Send Test SMS To</Typography>
                            <TextField id="sendSmsTo" sx={{ minWidth: '16rem', width: "93%" }} required inputProps={{ type: "number", }} onChange={updateTemplateSendSmsTo} value={sendSmsTo || ""} error={sendSmsTo === "" || smsValidationMessage !== ""} helperText={sendSmsTo === "" ? "Please enter a valid phone number or short code" : smsValidationMessage} fullWidth />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Template Name</Typography>
                            <TextField id="templatName" sx={{ minWidth: '16rem', width: "93%" }} disabled inputProps={{ readOnly: true, }} onChange={updateTemplateName} value={name || ""} fullWidth />
                        </Grid>                        
                        <Grid item={true} xs={12}>
                            <Typography sx={{ fontFamily: dishFont }}>Description</Typography>
                            <TextField id="description" sx={{ minWidth: '16rem', width: "93%" }} disabled placeholder="Optional" inputProps={{ readOnly: true, }} onChange={updateTemplateDescription} value={description || ""} fullWidth />
                        </Grid>
                        <Grid item={true} xs={12}>

                            <FormControl component="fieldset">
                                <Typography sx={{ fontFamily: dishFont }}>Message Type</Typography>
                                <RadioGroup row value={messageType} onChange={handleMessageTypeChange}>
                                    <Grid item={true} xs={12}>
                                        <FormControlLabel value="TRANSACTIONAL" control={<Radio />} label="Transactional" />
                                        <FormHelperText style={{ marginLeft: 32 }}>Critical or time-sensitive messages</FormHelperText>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <FormControlLabel value="PROMOTIONAL" control={<Radio />} label="Promotional" />
                                        <FormHelperText style={{ marginLeft: 32 }}>Non-critical messages, including marketing messages</FormHelperText>
                                    </Grid>

                                </RadioGroup>

                            </FormControl>
                        </Grid>
                        <KVPTable kvps={JSON.stringify(defaultSubstitutions)} newList={[]} keyName={"Variable Name"} keyLabel={"Value"} onKvpChange={handleKvpChange} onNoValueFlagChange={handleNoValueFlag} emailHtmlPart={smsBody} valueCheckAlert={!isTestModal} subject="" />

                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Tooltip title={!isTestModal ? "Missing Values" : ""} disableHoverListener={!noValueFlag}>
                    <span>
                        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained" disabled={isTestModal && (sendSmsTo === "" || smsValidationMessage !== "" || noValueFlag)}>Test</Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

export default SMSTemplateCreate;

