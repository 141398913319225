import { Dialog } from '@mui/material'
import '../../assets/styles/stylesheet.css'
import assets from '../../assets'

type Props = {
    open: boolean
}

export default function LoadingModal (p: Props) {
    

    return (
        <Dialog open={p.open}>
            <div className='Loadal'>
                <img src={assets.images.logo} className='AnimSpin' alt="Dish-logo, round, spinning" />
            </div>
        </Dialog>
    )
}