export const dishFont = '"Proxima Nova", "Montserrat", sans-serif';

export const dishFontSx = { fontFamily: dishFont};
export const dishFontSxRed = { fontFamily: dishFont, color: "red"};
export const dishFontSxHover = {
  display: "flex",
  flexDirection: "row",
  fontFamily: dishFont,  
  "&:hover": {
    backgroundColor: "#F2F2F2",
    cursor: "pointer"
  },
  "&:active": {
    backgroundColor: "#F2F2F2"
  }
};
export const helpText = { color:"gray", marginBottom: "1rem", fontFamily: dishFont};
export const errorText = { color: "red", fontFamily: dishFont, fontSize: '0.85em', ml: '0.5rem'}
export const helpTextShort = { color:"gray", fontFamily: dishFont};
export const cardLayoutFullscreen = { minWidth: "98.75%", maxWidth: "100", height: "90vh", padding: "2rem", overflow: "auto"};
export const cardLayoutExtraWide = { minWidth: "98.75%", maxWidth: "100", height: "85vh", padding: "2rem", overflow: "auto"};
export const cardLayoutExtraWideShort = { minWidth: "98.75%", maxWidth: "100", padding: "2rem", overflow: "auto"};
export const cardLayoutWide = { minWidth: "97.5%", maxWidth: "100", minHeight: "15rem", padding: "2rem"};
export const cardLayout = { minWidth: "95%", maxWidth: "100%", minHeight: "15rem", padding: "2rem"};
export const cardLayoutNarrow = { minWidth: "75%", maxWidth: "75%", minHeight: "15rem", padding: "2rem"};
export const cardLayoutCentered = { minWidth: "95%", maxWidth: "100%", minHeight: "15rem", padding: "2rem", display: "flex", alignItems: "center", marginBottom:"4rem"};
export const cardLayoutChart = { minWidth: "95%", maxWidth: "100%", height: "45rem", padding: "2rem", paddingBottom: "5rem"};
export const bubbleCheck = { display: "flex", flexDirection: "row"};
export const buttonPrimary = { fontFamily: dishFont, height: "2.5rem"};
export const buttonSecondary = { fontFamily: dishFont, height: "2.5rem"};
export const wideDialog = {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "50%",
      },
    },
  }