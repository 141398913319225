
import { useEffect, useContext, useState, ChangeEvent} from 'react';
import '../../assets/styles/stylesheet.css';
import AppContext from '../../context/AppContext';
import { Button, Card, Checkbox, TextField, Typography, Select, MenuItem, SelectChangeEvent, IconButton, Divider } from '@mui/material';
import { buttonPrimary, dishFontSx } from '../../assets/styles/sxProp';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Download } from '@mui/icons-material';
import { fetchFeedbackFiles, Folder, File, downloadFeedbackFiles } from '../../api/api';
import BasicSnackbar from '../../components/common/Snackbar';

type Props = {};

let data = {} as Folder;

export const utcConvertor = (date: string) => {
    const ltd = new Date(`${date} UTC`).toLocaleString("en-US", {
        timeZone: 'America/Denver',
        hour12: false
    });
    return ltd;
}

const CampaignFilesPage = (props: Props) => {
    const { instance } = useContext<any>(AppContext);
    const [downloadList, setDownloadList] = useState<string[]>([])
    const [feedbackFiles, setFeedbackFiles] = useState<Folder>();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [alertMessage, setAlertMessage] = useState("");
    const [sortOrder, setSortOrder] = useState<string>("abc");
    const [sortOrderOrder, setSortOrderOrder] =  useState<string>("ASC");
    const [search, setSearch] = useState<string>("");
    const [selectedNodes, setSelectedNodes]= useState<string[]>([]);
    const fullList: string[] = [];



    useEffect(() => {
        loadFeedbackFiles();
    }, [sortOrder, search])

    const sortFunction = (files: File[]) => {

        const trueSort = sortOrder+sortOrderOrder;

        switch (trueSort) {
            case "abcASC":
                return files.sort((a,b) => {
                    if (a.name < b.name) return -1
                    else if (a.name > b.name) return 1
                    else return 0
                }) 
            case "abcDESC":
                return files.sort((a,b) => {
                    if (a.name > b.name) return -1
                    else if (a.name < b.name) return 1
                    else return 0
                }) 

            case "dateASC":
                return files.sort((a,b) => {
                    const aDate = new Date(utcConvertor(a.dateTimeStamp))
                    const bDate = new Date(utcConvertor(b.dateTimeStamp))
                    if (aDate < bDate) return -1
                    else if (aDate > bDate) return 1
                    else return 0
                })

            case "dateDESC":
                return files.sort((a,b) => {
                    const aDate = new Date(utcConvertor(a.dateTimeStamp))
                    const bDate = new Date(utcConvertor(b.dateTimeStamp))
                    if (aDate > bDate) return -1
                    else if (aDate < bDate) return 1
                    else return 0
                })

            default:
                break;
        }
    }

    const searchFunction = (files: File[]) => {
        if (search === "" || search.length === 0 || !search) return files;
        else return files.filter((file) => (file.name.includes(search) || file.dateTimeStamp.includes(search)))
    }
    const loadFeedbackFiles = async () => {
        const files = await fetchFeedbackFiles();
        console.log('s3 files count: ', files);
        data = files;
        setFeedbackFiles(files);
    }

    const [stateCheck, setStateCheck] = useState<boolean>(false);

    const fileMultiSelect = (e: any, nodeIds: string[]) => {
        // console.log(nodeIds);
        setSelectedNodes(nodeIds);
    }

    useEffect(() => {
        if (stateCheck === true) {
            downloadSelectedFiles()
        } 
    }, [stateCheck, selectedNodes])

    const downloadSelectedFiles = async () => {
        // console.log(selectedNodes);

        if (selectedNodes.length === 1 && stateCheck === false) {
            setStateCheck(true);
            // return console.log("Possible Bad State");
        } else if (stateCheck === true) {
            setStateCheck(false)
            
            const filterFunction = (arr: string[]) => {
                for (let i = 0; i < arr.length; i++) {
                    // console.log(arr[i].endsWith('.txt'))                
                }
                
                const filteredDownloads = arr.filter((el) => el.endsWith('.txt'))
                return filteredDownloads;
            }
            
            const downloadlist2 = filterFunction(selectedNodes);
            
            // console.log('downloadList: ', downloadlist2);

            const promises = downloadlist2.map((el) => downloadFeedbackFiles("outbound-campaignfiles", el))
            const response = await Promise.all(promises);
            setDownloadList([]);
            return response;
        }
    }


    return (
        <div>
            <div>
                <Typography variant='h4' sx={dishFontSx}>Campaign Files - {data.folderName}</Typography>
                <br />
                <Button
                    onClick={() => {
                        loadFeedbackFiles();
                    }}
                    variant='contained'
                    sx={buttonPrimary}
                >
                    Refresh
                </Button>
                &nbsp;

            &nbsp;
            Search:
            &nbsp;
            <TextField
                id='searchBox'
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                }}
            ></TextField>
            &nbsp;
            Sort:
            &nbsp;
            <Select
                id="sortSelect"
                value={sortOrder}
                onChange={(e: SelectChangeEvent<string>) => {
                    setSortOrder(e.target.value)
                }}
            
            >
                <MenuItem value={'abc'}>Alphabetical</MenuItem>
                <MenuItem value={'date'}>Date</MenuItem>
            </Select>
            &nbsp;
            Order:
            &nbsp;
            <Select
                id="orderSelect"
                value={sortOrderOrder}
                onChange={(e: SelectChangeEvent<string>) => {
                    setSortOrderOrder(e.target.value)
                }}
            
            >
                <MenuItem value={'ASC'}>ASC</MenuItem>
                <MenuItem value={'DESC'}>DESC</MenuItem>
            </Select>
            <Divider 
                sx={{mt: "1rem"}}
            />
            <BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)}
            />
            </div>
            <div className='TreeDiv'> 
            <br />
            {
               (feedbackFiles && Array.isArray(feedbackFiles.folderList)) &&
               <TreeView
               aria-label="file system navigator"
               defaultCollapseIcon={<ExpandMoreIcon />}
               defaultExpandIcon={<ChevronRightIcon />}
               defaultEndIcon={<IconButton size='small' onClick={downloadSelectedFiles}><Download /></IconButton>}
                    defaultParentIcon={<ChevronRightIcon />}
                    onNodeSelect={fileMultiSelect}
                    multiSelect
                    sx={{ height: 240, flexGrow: 1 }}
                    >


                    {
                        feedbackFiles.folderList?.map((item) => <TreeItem nodeId={item.key} label={item.folderName}>
                        {item.folderList?.map((fl) => <TreeItem nodeId={fl.key} label={fl.folderName}>
                            {/* @ts-ignore */}
                            {(Array.isArray(fl.fileList) && fl.fileList.length > 0) ? searchFunction(sortFunction(fl.fileList)).map((s) => 
                                {   
                                    if (fullList.indexOf(`${item.key}/${fl.key}/${s.key}`) === -1) fullList.push(`${item.key}/${fl.key}/${s.key}`)
                                    return(<TreeItem nodeId={`${item.key}/${fl.key}/${s.key}`} label={`${s.name} - ${utcConvertor(s.dateTimeStamp)}`}></TreeItem>)
                                }
                                ) : <></>
                                }
                        </TreeItem>)}

                        {/* @ts-ignore */}   
                        {(Array.isArray(item.fileList) && item.fileList.length > 0) ? searchFunction(sortFunction(item.fileList)).map((file) => 
                            { 
                                if (fullList.indexOf(`${item.key}/${file.key}`) === -1) fullList.push(`${item.key}/${file.key}`)
                                return(<TreeItem nodeId={`${item.key}/${file.key}`} label={`${file.name} - ${utcConvertor(file.dateTimeStamp)}`}></TreeItem>)}
                                ) : <></>
                            }
                    </TreeItem>)}
                    {/* @ts-ignore */}
                    {(Array.isArray(feedbackFiles.fileList) && feedbackFiles.fileList.length > 0) ? searchFunction(sortFunction(feedbackFiles.fileList)).map((item) => 
                        { 
                            if(fullList.indexOf(`${item.key}`) === -1) fullList.push(`${item.key}`)
                            return(<TreeItem nodeId={item.key} label={`${item.name} - ${utcConvertor(item.dateTimeStamp)}`}></TreeItem>)}
                            ) : <></>
                        }
                    
                </TreeView>
            }
            </div>
            {/* <BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)}
            /> */}
        </div>
    );
};
export default CampaignFilesPage;