//@ts-nocheck
import { useEffect, useState } from 'react'
import { getMessageArchive } from '../../api/api';
import '../../assets/styles/stylesheet.css'
import { cardLayoutExtraWide, dishFontSx } from '../../assets/styles/sxProp';
import { Card, CardContent, InputLabel, TextField, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Divider, Typography } from '@mui/material'
import { dishFont, dishFontSxHover } from '../../assets/styles/sxProp';
import MaterialReactTable, {MRT_ColumnDef} from 'material-react-table';
import { utcConvertor } from './CampaignFilesPage';

export default function TTSMessageArchivePage () {

    const [archiveList, setArchiveList] = useState<any>();
    const [search, setSearch] = useState<string>("");
    const [selected, setSelected] = useState<string>("");


    const handleRowSelect = (e) => {
        if (e.target.id) {
            const elementPosition = Object.keys(archiveList).indexOf(e.target.id)
            setSelected(Object.keys(archiveList)[elementPosition])
        }
    }

    const columns = [
        {
            header: 'Message',
            accessorKey: 'message',
            size: "700"
        },
        {
            header: 'Disposition',
            accessorKey: 'disposition',
            size: "100"
        },
        {
            header: 'Changed On',
            accessorKey: 'changeTimestamp',
            size: "200"
        },
        {
            header: 'Change Type',
            accessorKey: 'changeType',
            size: "50"
        },
        {
            header: 'Changed By',
            accessorKey: 'changer',
            size: "50"
        }
    ]


    useEffect(() => {
        const updateList = async () => {

            function isItLater (d1, d2) {
                if (new Date(d1) > new Date(d2)) return true;
                else return false;
            }

            const data = await getMessageArchive();
            const cleanData = {};
            let lastUpdate = "";

            if (Array.isArray(data) && data.length > 0) {
                
                for (let i = 0; i < data.length; i++) {
                    let cpmn = data[i].campaignName;
                    if (!cleanData[cpmn]){
                        cleanData[cpmn] = {}
                        cleanData[cpmn]["Last Update"] = data[i].changeTimestamp
                        cleanData[cpmn]["messages"] = [ 
                        {
                            message: data[i].message,
                            disposition: data[i].disposition,
                            changeTimestamp: utcConvertor(data[i].changeTimestamp),
                            changeType: data[i].changeType,
                            changer: data[i].changer
                        }
                    ]} else {

                        if (isItLater(cleanData[cpmn]["Last Update"], data[i].changeTimestamp) === false) cleanData[cpmn]["Last Update"] = data[i].changeTimestamp;
                        cleanData[cpmn]["messages"] = [...cleanData[cpmn]["messages"],
                        {
                            message: data[i].message,
                            disposition: data[i].disposition,
                            changeTimestamp: utcConvertor(data[i].changeTimestamp),
                            changeType: data[i].changeType,
                            changer: data[i].changer
                        }]
                    }           
                }
            }


            setArchiveList(cleanData);
        }
        updateList();
    }, [])

    useEffect(() => {
        console.log(archiveList);
    }, [archiveList])

    useEffect(() => {
        console.log(selected);
    }, [selected])

    const searchFunction = (files) => {
        if (search === "" || search.length === 0 || !search) return files;
        else return files.filter((file) => {
          let cmlc = file.toLowerCase();
          let sclc = search.toLowerCase();
          return (cmlc.includes(sclc))
        })
    }

    return (
        <>
        <Typography variant='h4' sx={dishFontSx}>Message Archive</Typography>
        <br />
        <Card sx={cardLayoutExtraWide}>
            <CardContent>
                <div className = 'SearchDiv'>
                    <InputLabel id="searchLabel">Search</InputLabel>
                    <TextField
                        id = 'searchBox'
                        value = {search}
                        onChange = {(e: ChangeEvent<HTMLInputElement>) => {
                            setSearch(e.target.value)
                        }}></TextField>
                        <br />
                        <br />
                        <Divider />
                        <br />
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={12} xl={2} sx={{ borderLeft:"1px solid lightgray"}}>
                        { archiveList &&
                            <TableContainer sx={{minWidth: "14rem", maxHeight: "76vh"}}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{fontFamily: dishFont, fontSize: "1.5em", width: "14rem"}}>Campaign</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {searchFunction(Object.keys(archiveList)).map((el) => {
                                            return(
                                                <TableRow key={el}>
                                                    <TableCell sx={dishFontSxHover} id={el} onClick={handleRowSelect}>{el} 
                                                    <br /> Last Updated: {utcConvertor(archiveList[el]["Last Update"])}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer> 
                        }
                    </Grid>
                    { (selected && (Array.isArray(archiveList[selected]["messages"]) && archiveList[selected]["messages"].length > 0)) &&
                        <Grid item xs={12} xl={9} sx={{ borderLeft:"1px solid lightgray", pl: '1rem'}}>
                            <>
                                <br />
                                <Typography variant='h4'>{selected}</Typography>
                                <br />
                                <MaterialReactTable 
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        muiTableHeadCellProps: {
                                            align: 'center',
                                        },
                                        size: 120,
                                    },
                                }}
                                columnResizeMode=''
                                columns={columns}
                                data={archiveList[selected]["messages"]}
                                enableColumnFilters={true}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                enableHiding={false}
                                //Top tool bar with add and search
                                enableTopToolbar={true}
                                enableColumnActions={false}
                                enableBottomToolbar={true}
                                enableSorting={true}
                                initialState={{
                                    sorting: [
                                        {
                                            id:'changeTimestamp',
                                            desc: true
                                        }
                                    ],
                                    pagination: {
                                        pageIndex: 0,
                                        pageSize: 100
                                      },
                                }}
                                muiTableHeadCellProps={{
                                    sx: dishFontSx
                                }}
                                muiTableBodyCellProps={{
                                    sx: dishFontSx
                                }}
                            />
                        </>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
        </>
    )
}