import { RouteType } from "./config";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardPageLayout from "../pages/dashboard/DashboardPageLayout";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DashboardIndex from "../pages/dashboard/DashboardIndex";
import DefaultPage from "../pages/dashboard/DefaultPage";
import CampaignPageLayout from "../pages/campaign/CampaignPageLayout";
import CampaignActivePage from "../pages/campaign/CampaignActivePage";
import CampaignCreatePage from "../pages/campaign/CampaignCreatePage";
import CampaignIndex from "../pages/campaign/CampaignIndex";
import AdminPageLayout from "../pages/admin/AdminPageLayout";
import SystemPageLayout from "../pages/system/SystemPageLayout";
import SystemIndex from "../pages/system/SystemIndex";
import SystemFileInput from "../pages/system/SystemFileInput";
import SystemSchedule from "../pages/system/SystemSchedule";
import SystemCallEvents from "../pages/system/SystemCallEvents";
import SystemTtsMessageInput from "../pages/system/SystemTtsMessageInput";
import SystemOverride from "../pages/system/SystemOverride";
import DialRestrictionsIndex from "../pages/dialRestrictions/DIalRestrictionsIndex";
import PhoneDisabledOutlinedIcon from '@mui/icons-material/PhoneDisabledOutlined';
import DNC from "../pages/dialRestrictions/DoNotCall";
import States from "../pages/dialRestrictions/States";
import Prefixes from "../pages/dialRestrictions/Prefixes";
import FederalHolidays from "../pages/dialRestrictions/FederalHolidays";
import Redirect404 from "../pages/reditect404/Redirect404";
import { Navigate } from "react-router-dom";
import ReportingPageLayout from "../pages/reporting/ReportingPageLayout";
import ReportingIndex from "../pages/reporting/ReportingIndex";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CampaignFilesPage from "../pages/reporting/CampaignFilesPage";
import TTSMessageArchivePage from "../pages/reporting/TTSMessageArchivePage";
import CampaignLogsPage from "../pages/campaign/CampaignLogsPage";
import EmailTemplateCreate from "../pages/templates/EmailTemplateCreate";
//import MailOutlineIcon from '@mui/icons-material/MailOutline';
//import SMSIndex from "../pages/sms/SMSIndex";
import SMSTemplateCreate from "../pages/templates/SMSTemplateCreate";
//import SMSPageLayout from "../pages/sms/SMSPageLayout";
//import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
//import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import TemplateIndex from "../pages/templates/TemplateIndex";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ProjectPageLayout from "../pages/projects/ProjectPageLayout";
import ProjectIndex from "../pages/projects/ProjectIndex";
//import { EmailOutlined } from "@mui/icons-material";
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import CampaignCreate from "../pages/projects/campaigns/CampaignCreate";
import SegmentCreate from "../pages/projects/segments/SegmentCreate";
import DashboardTwo from "../pages/dashboard/DashboardTwo";

const appRoutes: RouteType[] = [
  {
        index: true,
        element: <DefaultPage />,
    state: "home"
  },
  {
    path: "/",
    element: <DashboardPageLayout />,
    state: "dashboard",
  },
  {
    path: "",
    element: <DashboardPageLayout />,
    state: "dashboard",
  },
  {
    path: "/dashboard",
    element: <DashboardPageLayout />,
      state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardOutlinedIcon />
    },
    child: [
      {
        index: true,
        element: <DashboardIndex />,
        state: "dashboard.index"
      },
      {
        path: "/dashboard/default",
        element: <DefaultPage />,
        state: "dashboard.default",
        sidebarProps: {
          displayText: "Dashboard (Auto Msg)"
        },
      },
        {
            path: "/dashboard/dashboardtwo",
            element: <DashboardTwo />,
            state: "dashboard.dashboardtwo",
            sidebarProps: {
                displayText: "Dashboard (Email / SMS / Push)"
            },
        }
    ]
  },
  {
    path: "/campaign",
    element: <CampaignPageLayout />,
    state: "campaign",
    sidebarProps: {
      displayText: "Campaigns",
      icon: <FileDownloadOutlinedIcon />
    },
    child: [
      {
        index: true,
        element: <CampaignIndex />,
        state: "campaign.index"
      },
      {
        path: "/campaign/active",
        element: <CampaignActivePage />,
        state: "campaign.active",
        sidebarProps: {
          displayText: "Campaign List"
        },
      },
      {
        path: "/campaign/create",
        element: <CampaignCreatePage />,
        state: "campaign.create",
        sidebarProps: {
          displayText: "Create"
        },
      },
      {
        path: "/campaign/history",
        element: <CampaignLogsPage />,
        state: "campaign.logs",
        sidebarProps: {
            displayText: "Change History"
        },
      },
    ]
  },
  // {
  //   path: "/user",
  //   element: <UserPageLayout />,
  //   state: "user",
  //   sidebarProps: {
  //     displayText: "Users",
  //     icon: <PeopleOutlineOutlinedIcon />
  //   },
  //   child: [
  //     {
  //       index: true,
  //       element: <UserIndex />,
  //       state: "user.index"
  //     },
  //     {
  //       path: "/user/create",
  //       element: <UserCreatePage />,
  //       state: "user.create",
  //       sidebarProps: {
  //         displayText: "Create User"
  //       },
  //     }
  //   ]
  // },
   //{
   //  path: "/admin",
   //  element: <AdminPageLayout />,
   //  state: "admin",
   //  sidebarProps: {
   //    displayText: "Admin",
   //    icon: <AdminPanelSettingsOutlinedIcon />
   //  },
   //  child: [
   //    {
   //      index: true,
   //      element: <AdminIndex />,
   //      state: "admin.index"
   //    },
   //     {
   //       path: "/user/create",
   //       element: <UserCreatePage />,
   //       state: "user.create",
   //       sidebarProps: {
   //         displayText: "Create User"
   //       },
   //     }
   //  ]
   //},
  {
    path: "/dialRestrictions",
    element: <AdminPageLayout />,
    state: "dr",
    sidebarProps: {
      displayText: "Dial Restrictions",
      icon: <PhoneDisabledOutlinedIcon />
    },
    child: [
      {
        index: true,
        element: <DialRestrictionsIndex />,
        state: "dr.index"
      },
      {
        path: "/dialRestrictions/donotcall",
        element: <DNC />,
        state: "dr.donotcall",
        sidebarProps: {
          displayText: "Do Not Call"
        },
      },
      //{
      //  path: "/dialRestrictions/federaltime",
      //  element: <FederalTime />,
      //  state: "dr.federaltime",
      //  sidebarProps: {
      //    displayText: "Federal Time"
      //  },
      //},
      {
        path: "/dialRestrictions/federalholidays",
        element: <FederalHolidays />,
        state: "dr.federalholidays",
        sidebarProps: {
          displayText: "Federal Holidays"
        },
      },
      {
        path: "/dialRestrictions/states",
        element: <States />,
        state: "dr.states",
        sidebarProps: {
            displayText: "States"
        },
      },
      {
        path: "/dialRestrictions/prefixes",
        element: <Prefixes />,
        state: "dr.prefixes",
        sidebarProps: {
            displayText: "Prefixes"
        },
      }
    ]
  },
  {
    path: "/system",
    element: <SystemPageLayout />,
    state: "system",
    sidebarProps: {
      displayText: "System",
      icon: <SettingsOutlinedIcon />
    },
    child: [
      {
        index: true,
        element: <SystemIndex />,
        state: "system.index"
      },
      {
        path: "/system/file",
        element: <SystemFileInput />,
        state: "system.file",
        sidebarProps: {
          displayText: "File Type"
        },
      },
      {
        path: "/system/schedule",
        element: <SystemSchedule />,
        state: "system.schedule",
        sidebarProps: {
          displayText: "Schedule"
        },
      },
    {
        path: "/system/callEvents",
        element: <SystemCallEvents />,
        state: "system.callEvents",
        sidebarProps: {
            displayText: "Call Events"
        },
      },
       {
         path: "/system/tts",
         element: <SystemTtsMessageInput />,
         state: "system.tts",
         sidebarProps: {
           displayText: "Text to Speech Messages"
         },
       },
       {
         path: "/system/override",
         element: <SystemOverride />,
         state: "system.override",
         sidebarProps: {
            displayText: "Manual Override"
        },
       }
    ]
    },
    {
        path: "/reporting",
        element: <ReportingPageLayout />,
        state: "reporting",
        sidebarProps: {
            displayText: "Reporting",
            icon: <FolderOutlinedIcon />
        },
        child: [
            {
                index: true,
                element: <ReportingIndex />,
                state: "reporting.index"
            },
            {
                path: "/reporting/campaignfiles",
                element: <CampaignFilesPage />,
                state: "reporting.campaignfiles",
                sidebarProps: {
                    displayText: "Campaign Files"
                },
            },
            {
                path: "/reporting/ttsarchive",
                element: <TTSMessageArchivePage />,
                state: "reporting.ttsarchive",
                sidebarProps: {
                    displayText: "Message Archive"
                },
            }
        ]
    },
    {
        path: "/projects",
        element: <ProjectPageLayout />,
        state: "projects",
        sidebarProps: {
            displayText: "Communication",
            icon: <AccountTreeOutlinedIcon />
        },
        child: [
            {
                index: true,
                element: <ProjectIndex />,
                state: "projects.index"
            },
            {
                path: "/projects/campaigns",
                element: <CampaignCreate />,
                state: "projects.campaigns",
                sidebarProps: {
                    displayText: "Campaigns"
                },
            },
            {
                path: "/projects/journeys",
                element: <SMSTemplateCreate />,
                state: "projects.journeys",
                sidebarProps: {
                    displayText: "Journeys"
                },
            },
            {
                path: "/projects/segments",
                element: <SegmentCreate />,
                state: "projects.segments",
                sidebarProps: {
                    displayText: "Segments"
                },
            }
        ]
    },
    {
        path: "/templates",
        element: <ReportingPageLayout />,
        state: "templates",
        sidebarProps: {
            displayText: "Templates",
            icon: <DevicesOutlinedIcon />
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: "templates.index"
            },
            {
                path: "/templates/email",
                element: <EmailTemplateCreate />,
                state: "templates.email",
                sidebarProps: {
                    displayText: "Email",
                    /*icon: <MailOutlineIcon />*/
                },
            },
            {
                path: "/templates/sms",
                element: <SMSTemplateCreate />,
                state: "templates.sms",
                sidebarProps: {
                    displayText: "SMS",
                    /*icon: <SmsOutlinedIcon />*/
                },
            }
        ]
    },
  {
    path: "/404",
    element: <Redirect404 />,
    state: "404",
  },
  {
    path: "/*",
    element: <Navigate to="index.html" />,
    state: "default",
  }
];

export default appRoutes;