import Editor from "@monaco-editor/react";

interface Props {
    code: string;
    onChange: (code: string) => void;
    templateType: string;
}

function BaseEditor(props: Props) {
    return (
        <Editor
            value={props.code}
            language="handlebars"
            defaultValue={props.templateType === "Email" ? "Please enter HTML code." : "Please enter text."}
            theme="vs-dark"
            onChange={(newvalue?: string) => props.onChange(newvalue || '')}
        />
    );
}

export default BaseEditor;