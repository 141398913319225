import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardContent, CardHeader, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, SelectChangeEvent, Stack, StepConnector, StepContent, TextField, Tooltip, useEventCallback } from '@mui/material';
import { buttonPrimary, buttonSecondary, cardLayout, cardLayoutNarrow, cardLayoutWide, dishFont, dishFontSx, helpText } from '../../../assets/styles/sxProp';
import TemplatePicker from '../../../components/email/templatePicker';
import { createSegment, getSegmentsByLob, getSegmentById, updateSegment, getProject, deleteSegment } from '../../../api/api';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BasicSnackbar from '../../../components/common/Snackbar';
import LoadingModal from '../../../components/common/LoadingModal';
import EditIcon from '@mui/icons-material/Edit';
import AppContext from '../../../context/AppContext';

const SegmentCreate = () => {

    const { userRole, lob }: any = useContext(AppContext);

    const [projectId, setProjectId] = useState<string | "">("");
    const [pageReload, setPageReload] = useState<boolean>(false);
    const [name, setName] = useState<string | "">("");
    const [segmentId, setSegmentId] = useState<string | "">("");
    const [audience, setAudience] = useState<string | "">("");
    const [_segmentList, _setSegmentList] = useState<any[]>([]);
    //const [_chosenSegment, _setChosenSegment] = useState("");
    const [criteria, setCriteria] = useState("");
    const [include, setInclude] = useState<string[]>([]);
    const [edit, setEdit] = useState<boolean>(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const [dialogOpen, setDialogOpen] = useState(false);

   
    useEffect(() => {
        getProject(lob).then((res) => {
            setProjectId(res.projectId);
            getSegments(res.projectId);
        });
    }, [lob, userRole, pageReload]);

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    };

    const handleCriteriaChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCriteria(e.target.value)
    };

    //const _onSegmentChange = (option: any) => {
    //    _setChosenSegment(option.value);
    //}

    const handleBaseChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAudience(e.target.value);
    }

    const getSegments = async (id : string) => {
        setLoading(true);
        var res = await getSegmentsByLob(id);
        var defaultSegment = { label: "", value: "" }
        var segmentList = res.item;
        var segmentList = res.item.map((item: any) => {
            return {
                label: item.name,
                value: item.id
            }
        });
        segmentList.push(defaultSegment);
        var sortedList = segmentList.sort((a: any, b: any) => (a.label < b.label ? -1 : 1));
        _setSegmentList(sortedList);
        setLoading(false);
    };

    const getSegmentDetails = async (segId: string) => {

            await getSegmentById(projectId, segId)
            .then((resp) => {
                var channel = resp.segmentGroups.groups[0].dimensions[0].demographic.channel.values[0];
                setName(resp.name);
                setAudience(resp.segmentGroups.groups[0].sourceType.value);
                if (channel !== "EMAIL" && channel !== "SMS") {
                    setCriteria("Push")
                } else {
                    setCriteria(channel)
                }
                let temp: string[] = [];
                var inc = resp.segmentGroups.groups[0].sourceSegments;
                inc.forEach((i: any) => temp.push(i.id));
                setInclude(temp);
                setEdit(true);
            });
    };

    const saveSegment = async () => {
        try {
            await createSegment(projectId, name, audience, criteria, include)
            setAlertSeverity("success")
            setAlertMessage("Segment was created Successfully")
            setAlertOpen(true);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to create segment")
            setAlertOpen(true);
        }
        resetPage();
        //refreshPage();
    }

    const updateSegmentFields = async (option: any) => {
        setSegmentId(option.value);
        getSegmentDetails(option.value);
    }

    const saveUpdateSegment = async() => {
        try {
            await updateSegment(projectId, segmentId, audience, criteria, include)
            setAlertSeverity("success")
            setAlertMessage("Segment was updated Successfully")
            setAlertOpen(true);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to update segment")
            setAlertOpen(true);
        }
        resetPage();
        //refreshPage();

    }

    const deleteSeg = async () => {
        if (!window.confirm(`Are you sure you want to delete the segment ${name}? This action is irreversible!`)) return;
        else {
            try {
                await deleteSegment(projectId, segmentId)
                setAlertSeverity("success")
                setAlertMessage("Segment was deleted Successfully")
                setAlertOpen(true);
            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to delete segment")
                setAlertOpen(true);
            }
            resetPage();
            //refreshPage();
        }
    }

    const editSegment = () => {
        setDialogOpen(true);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const resetPage = () => {
        setName("");
        setAudience("");
        setCriteria("");
        //_setChosenSegment("");
        setInclude([]);
        //setAlertOpen(false);
        setEdit(false);
        setPageReload(true);
    }

    const handleIncludeChange = (event: SelectChangeEvent<typeof include>) => {
        const { target: { value }, } = event;
        setInclude(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <>
            <LoadingModal open={loading} />
            <Card sx={cardLayout}>
                <CardHeader title="Segment Details" action={<>
                    {(!edit) ? 
                    <Tooltip title='Save'>
                        <IconButton onClick={saveSegment}><SaveIcon sx={{ variant: "outlined", color: 'blue' }} /> </IconButton>
                    </Tooltip>
                        : <></>
                    } 
                    {(edit) ? 
                        <><Tooltip title='Update'>
                            <IconButton onClick={saveUpdateSegment}><SaveIcon sx={{ variant: "outlined", color: 'blue' }} /> </IconButton>
                        </Tooltip><Tooltip title='Delete'>
                                <IconButton onClick={deleteSeg}><DeleteIcon sx={{ variant: "outlined", color: 'red' }} /> </IconButton>
                            </Tooltip></>
                        : <></>
                    }
                    <Tooltip title='Edit'>
                        <IconButton onClick={editSegment}><EditIcon sx={{ variant: "outlined", color: 'black' }} /> </IconButton>
                    </Tooltip>
                </>
                } />
                <CardContent>
                    <Grid xs={12} item={true}>
                        <Box component="form">
                            <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>Segment Name</Typography>
                            <TextField id="name" name="name" sx={{ minWidth: '16rem', width: "50%" }} onChange={handleNameChange} value={name || ""} fullWidth disabled={edit} />
                            <Typography sx={helpText}>Name must be between 1 and 64 characters.</Typography>
                        </Box>
                    </Grid>
                    <Divider variant="middle" />
                    <br />
                    <Grid xs={12} item={true}>
                        <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>Segment Group</Typography>
                        <Typography sx={helpText}>A segment group contains filters that you apply to base segments.</Typography>
                        <Box component="form">
                            <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>Base Segments</Typography>
                            <RadioGroup
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="audience"
                                onChange={handleBaseChange}
                                value={audience || ""}
                            >
                                <FormControlLabel value="ANY" control={<Radio />} label="Include any audiences" />
                                <FormControlLabel value="ALL" control={<Radio />} label="Include all audiences"/>
                            </RadioGroup>
                            <Box>
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>All Segments</Typography>
                                    <Typography sx={helpText}>Include audiences that are in any of the following:</Typography>
                                    <Select
                                        id="segment-multiple-checkbox"
                                        multiple
                                        value={include}                                       
                                        onChange={handleIncludeChange}
                                        input={<OutlinedInput label="Segments"/>}
                                        renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={ _segmentList ? _segmentList?.find((e: any) => e.value === value)?.label : ""} />
                                            ))}
                                        </Box>
                                        )}
                                    >
                                        {_segmentList?.map((s: any) => {
                                            if (s.label !== "") {
                                                return (

                                                    <MenuItem key={s.value} value={s.value} disabled={s.label === name && true}>
                                                        <Checkbox checked={include.indexOf(s.value) > -1} />
                                                        <ListItemText primary={s.label} />
                                                    </MenuItem>
                                                )
                                            }
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>
                    <Divider variant="middle" />
                    <br />
                    <Grid xs={12} item={true}>
                        <Box component="form">
                            <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>Segment Criteria</Typography>
                            <RadioGroup
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="criteria"
                                onChange={handleCriteriaChange}
                                value={criteria || ""}
                            >
                                <FormControlLabel value="EMAIL" control={<Radio />} label="Email" />
                                <FormControlLabel value="SMS" control={<Radio />} label="SMS" />
                                <FormControlLabel value="Push" control={<Radio />} label="Push" />
                            </RadioGroup>
                        </Box>
                    </Grid>
                </CardContent>
            </Card>
            <UpdateTemplateModal
                open={dialogOpen}
                onClose={() => { setDialogOpen(false); }}
                onSubmit={updateSegmentFields}
                segments={_segmentList}
            />
            <BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)}
            />
        </>
    )

};

interface SegmentModalProps {
    open: boolean,
    onClose: () => void,
    onSubmit: (value: any) => void;
    segments: any;
};

export const UpdateTemplateModal = ({
    open,
    onClose,
    onSubmit,
    segments,

}: SegmentModalProps) => {
    const [selectedSegment, setSelectedSegment] = useState("");

    useEffect(() => {
    }, [])

    const handleCancel = () => {
        setSelectedSegment("")
        onClose();
    }

    const handleSubmit = () => {
        onSubmit(selectedSegment);
        setSelectedSegment("");
        onClose();
    };

    const _onSegmentChange = (option: any) => {
        setSelectedSegment(option);
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Choose Segment to Edit</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Grid item={true} xs={12}>
                                <Box>
                                    {(Array.isArray(segments) && segments.length > 0) ?
                                        <TemplatePicker options={segments} onChange={_onSegmentChange} selected={{ label: selectedSegment, value: selectedSegment }} label="Segments" />
                                        : <></>
                                    }
                                </Box>
                        </Grid>
                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained">Select</Button>
            </DialogActions>
        </Dialog>
    );
};



export default SegmentCreate;