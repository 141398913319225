import { Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row, MaterialReactTableProps } from 'material-react-table';
import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import * as React from 'react'
import { Edit, Delete } from '@mui/icons-material';
import { buttonPrimary, buttonSecondary, dishFontSx, errorText } from '../../assets/styles/sxProp';
import { Campaign } from '../campaign/CampaignActivePage';
import AppContext from '../../context/AppContext';
import { getCampaigns, postNewTTSMessage, deleteTTSMessage, updateTTSMessage, TtsMessage } from '../../api/api';
import BasicSnackbar from '../../components/common/Snackbar';
import '../../assets/styles/stylesheet.css';

type Props = {};


const TTsMessageInput = (props: Props) => {

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState<TtsMessage[]>([]);
    const [campaignList, setCampaignList] = useState<Campaign[]>([])
    const { instance, newTts, setNewTts, username } = useContext<any>(AppContext);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [campaignNameList, setCampaignNameList] = useState<string[]>();
    const [contactEventList, setContactEventList] = useState<string[]>();
    const [contactEventNameList, setContactEventNameList] = useState<string[]>();

    const handleCreateNewRow = async (values: TtsMessage) => {
        try {
            let user = "";
            username === "" ? user = "SYSTEM" : user = username.substring(5);
            await postNewTTSMessage({ ...values, changer: user, lastUpdateBy: user });
            setAlertSeverity("success")
            setAlertMessage("New Text to Speech Message added.")
            setAlertOpen(true);
            tableData.push(values);
            setTableData([...tableData]);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to add Text to Speech Message!")
            setAlertOpen(true);
            return (err)
        }

        fetchCampaigns();
        fetchTTSData();
    };

    const handleUpdateRow = async (values: TtsMessage) => {
        try {
            let user = "";
            username === "" ? user = "SYSTEM" : user = username.substring(5);
            await updateTTSMessage({ ...values, changer: user, lastUpdateBy: user });
            setAlertSeverity("success")
            setAlertMessage("Text to Speech Message successfully updated.")
            setAlertOpen(true);
            tableData.push(values);
            setTableData([...tableData]);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to update Text to Speech Message!")
            setAlertOpen(true);
            return (err)
        }

        fetchCampaigns();
        fetchTTSData();
    };

    useEffect(() => {
        const campaignNameArray: string[] = [];
        const callEventNameArray: string[] = [];
        
        if (Array.isArray(tableData) && tableData.length > 1) {
            for (const n of tableData) {
                campaignNameArray.push(n.campaignName)
                callEventNameArray.push(n.disposition);
            }
        }

        setCampaignNameList(campaignNameArray);
        setContactEventNameList(callEventNameArray);
    }, [tableData])

    const handleDeleteRow = useCallback(
        async (row: MRT_Row<TtsMessage>) => {
            if (
                !window.confirm(`Are you sure you want to delete ${row.getValue('campaignName')}`)
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            try {

                let user = "";
                username === "" ? user = "SYSTEM" : user = username;
                await deleteTTSMessage(`${row.getValue('campaignName')}`, `${row.getValue('disposition')}`, `${row.getValue('message')}`, user);
                tableData.splice(row.index, 1);
                setTableData([...tableData]);
                setAlertSeverity("success")
                setAlertMessage("Text to Speech Message removed.")
                setAlertOpen(true);
            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to remove Text to Speech Message.")
                setAlertOpen(true);
                return (err)
            }
        },
        [tableData],
    );

    const handleSaveRowEdits: MaterialReactTableProps<TtsMessage>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
            handleUpdateRow(values)            
           exitEditingMode(); //required to exit editing mode and close modal
    };

    async function fetchCampaigns() {
        const reply = await getCampaigns();
        // const parsedReply = await parseCampaigns(reply);
        setCampaignList(reply);
    }

    // async function parseCampaigns(arr: any) {
    //     console.log(arr)
    //     let parsedCampaigns = [];

    //     for (let i = 0; i < arr.length; i++) {
    //         if (arr[i].campaignId) parsedCampaigns.push(arr[i])
    //     }

    //     console.log(parsedCampaigns)
    //     return parsedCampaigns;
    // }

    useEffect(() => {
        fetchContactEvents()
        fetchCampaigns()
        fetchTTSData()
    }, [instance, newTts])

    const url = 'https://' + window.location.toString().split('/')[2];
    const cleanData = (fd: TtsMessage[]) => {
        let clean: TtsMessage[] = [];
        fd.forEach(function (item) {
            let newItem: TtsMessage = {
                campaignName: item.campaignName,
                message: item.message,
                disposition: item.disposition,
                changer: username,
                lastUpdateBy: item.lastUpdateBy,
                lastUpdateDate: item.lastUpdateDate
            }
            clean.push(newItem);
        setTableData(clean);
        })
    };

    const fetchTTSData = () => {
        fetch(`${url}/api/system/messages`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                cleanData(data);
            })
    }

    const fetchContactEvents = () => {
        fetch(`${url}/api/system/callEvents`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setContactEventList(data);
            })
    }

    const columns = useMemo<MRT_ColumnDef<TtsMessage>[]>(
        () => [
            {
                accessorKey: 'campaignName', //access nested data with dot notation
                header: 'Campaign Name',
                enableEditing: false,
            },
            {
                accessorKey: 'message',
                header: 'Message',
            },
            {
                accessorKey: 'disposition',
                header: 'Call Event',
            },
            {
                accessorKey: 'lastUpdateBy',
                header: 'Last Updated By',
                enableEditing: false,
            },
            {
                accessorKey: 'lastUpdateDate',
                header: 'Last Updated Date',
                enableEditing: false,
            },
        ],
        [],
    );

    return (

        <><div><Typography variant='h4' sx={dishFontSx}>Text to Speech Messages</Typography></div>
        <br />
            <div className='FiftyPercentWidth'>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={tableData}
                    enableColumnFilters={true}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    //Top tool bar with add and search
                    enableTopToolbar={true}
                    enableColumnActions={false}
                    enableBottomToolbar={true}
                    enableSorting={true}
                    initialState={{
                        sorting: [
                          {
                            id:'campaignName',
                            desc: false
                          }
                        ],
                        pagination: {
                            pageIndex: 0,
                            pageSize: 100
                          },
                      }}
                    //Action Colums
                    enableEditing
                    onEditingRowSave={handleSaveRowEdits}
                    muiTableHeadCellProps={{
                        sx: dishFontSx
                    }}
                    muiTableBodyCellProps={{
                        sx: dishFontSx
                    }}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton onClick={() => table.setEditingRow(row)}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Delete">
                                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <Button
                            sx={buttonPrimary}
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                        >
                            Create New Message
                        </Button>
                    )}
                />
                <CreateNewMessageModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onSubmit={handleCreateNewRow}
                    campaigns={campaignList}
                    callEvents={contactEventList}
                    campaignNames={campaignNameList}
                    callEventNames={contactEventNameList}
                    tts = {newTts}
                    setTts = {setNewTts}
                    tableData = {tableData}
                />
            </div>
            <BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)}
            />
        </>
    );
};

interface CreateModalProps {
    columns: MRT_ColumnDef<TtsMessage>[];
    onClose: () => void;
    onSubmit: (values: TtsMessage) => void;
    open: boolean;
    campaigns: Campaign[];
    campaignNames: string[] | undefined;
    callEvents: any | undefined;
    callEventNames: string[] | undefined;
    tts: number;
    setTts: any;
    tableData: TtsMessage[];
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewMessageModal = ({
    open,
    columns,
    onClose,
    onSubmit,
    campaigns,
    campaignNames,
    callEvents,
    callEventNames,
    tts,
    setTts,
    tableData
}: CreateModalProps) => {
    const [values, setValues] = useState<any>(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    const [validationErrors, setValidationErrors] = useState<any>({
        noMessage: 0,
        noCampaign: 0,
        noCallEvent: 0
    })


    const handleCancel = () => {
        setValidationErrors({
            noMessage: 0,
            noCampaign: 0,
            noCallEvent: 0
        })
        setValues(() =>
            columns.reduce((acc, column) => {
                acc[column.accessorKey ?? ''] = '';
                return acc;
            }, {} as any))
        onClose()
    }

    const handleSubmit = () => {

        async function validator () {
            const newErrorObject = {
                noMessage: 0,
                noCampaign: 0,
                noCallEvent: 0
            }

            if (!values.message) {
                newErrorObject.noMessage = 1
            }
            if (!values.campaignName) {
                newErrorObject.noCampaign = 1
            }
            if (!values.disposition) {
                newErrorObject.noCallEvent = 1
            }
            setValidationErrors(newErrorObject);
            
            const filteredNames = tableData.filter(e => e.campaignName === values.campaignName);

            const dispositionIndex = filteredNames.map(e => e.disposition).indexOf(values.disposition)


            if ((Array.isArray(campaignNames) && campaignNames.indexOf(values.campaignName) !== -1) && dispositionIndex !== -1) {

                if (newErrorObject.noMessage === 1 || newErrorObject.noCampaign === 1 || newErrorObject.noCallEvent === 1) {
                    console.log("errors found")
                    return null
                } else if (!window.confirm("A message for this Contact Event for this Campaign already exists, would you like to update it with the new message?"))  {
                    return null
                } 
                else {
                    onSubmit(values)
                    onClose();
                setValues(() =>
                columns.reduce((acc, column) => {
                        acc[column.accessorKey ?? ''] = '';
                    return acc;
                }, {} as any))
                setTts(tts + 1)
                return null;
                }
            }

            if (newErrorObject.noMessage === 1 || newErrorObject.noCampaign === 1 || newErrorObject.noCallEvent === 1) {
                console.log("errors found")
                return null
            } else {
                onSubmit(values);
                onClose();
                setValues(() =>
                columns.reduce((acc, column) => {
                        acc[column.accessorKey ?? ''] = '';
                    return acc;
                }, {} as any))
                setTts(tts + 1)
                return null;
            }
        }
        validator();
    };

    const handleDropdownChangeCampaign = (e: SelectChangeEvent<string>) => {
        setValues({...values, "campaignName": e.target.value})
    }

    const handleDropdownChangeCallEvents = (e: SelectChangeEvent<string>) => {
        setValues({ ...values, "disposition": e.target.value })
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create New Message</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => {
                                if (column.accessorKey==="campaignName") {
                                    return (
                                        <>
                                        {(!campaigns || !Array.isArray(campaigns) || campaigns.length < 1) &&
                                            <Select
                                                key={column.accessorKey}
                                                id='campaignSelect'
                                                labelId='campaignSelectLabel'
                                                label='Campaign'
                                                MenuProps={{PaperProps: {sx:{maxHeight: '20rem'}}}}
                                                value='NoData'
                                                onChange={handleDropdownChangeCampaign}>
                                                        <MenuItem key='NoData' value='NoData'>No Data Response from API</MenuItem>
                                            </Select>
                                        }
                                        {Array.isArray(campaigns) &&
                                            <>
                                            <Select
                                                key={column.accessorKey}
                                                id='campaignSelect'
                                                labelId='campaignSelectLabel'
                                                label='Campaign'
                                                MenuProps={{PaperProps: {sx:{maxHeight: '20rem'}}}}
                                                value={values.campaignName}
                                                onChange={handleDropdownChangeCampaign}>
                                                {campaigns.map((el: Campaign) => {
                                                    return (
                                                        <MenuItem key={el.campaignId} value={el.campaignName}>{el.campaignName}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            {validationErrors.noCampaign === 1 &&
                                                <Typography key='CampaignError' sx={errorText}>Please enter a Campaign from the dropdown above</Typography>
                                            }
                                            </>
                                        }
                                        </>
                                    )
                                } else if (column.accessorKey === "disposition") {
                                    return (
                                        <>
                                            {(!callEvents || !Array.isArray(callEvents) || callEvents.length < 1) &&
                                                <Select
                                                    key={column.accessorKey}
                                                    id='callEventsSelect'
                                                    labelId='callEventsSelectLabel'
                                                    label='CallEvents'
                                                    MenuProps={{ PaperProps: { sx: { maxHeight: '20rem' } } }}
                                                    value='NoData'
                                                    onChange={handleDropdownChangeCallEvents}>
                                                    <MenuItem key='NoData' value='NoData'>No Data Response from API</MenuItem>
                                                </Select>
                                            }
                                            {Array.isArray(callEvents) &&
                                                <>
                                                    <Select
                                                        key={column.accessorKey}
                                                        id='callEventsSelect'
                                                        labelId='callEventsSelectLabel'
                                                        label='CallEvents'
                                                        MenuProps={{ PaperProps: { sx: { maxHeight: '20rem' } } }}
                                                        value={values.disposition}
                                                    onChange={handleDropdownChangeCallEvents}>
                                                    {callEvents.map((el: any) => {
                                                            return (
                                                                <MenuItem key={el.eventType} value={el.eventType}>{el.eventType}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                    {validationErrors.noCallEvent === 1 &&
                                                        <Typography key='CallEventError' sx={errorText}>Please enter a Call Event from the dropdown above</Typography>
                                                    }
                                                </>
                                            }
                                        </>
                                    )
                                } else if (column.accessorKey === "lastUpdateBy") {
                                    return (<></>)
                                } else if (column.accessorKey === "lastUpdateDate") {
                                    return (<></>)
                                }else return (
                                    <>
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                                    {validationErrors.noMessage === 1 &&
                                        <Typography key='MessageError' sx={errorText}>Please enter a value</Typography>
                                    }
                                    </>
                                )
                        })}
                        
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TTsMessageInput;