//@ts-nocheck
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, TextField, Tooltip, Typography } from '@mui/material';
import MaterialReactTable, { MRT_Cell, MRT_ColumnDef, MRT_Row, MaterialReactTableProps } from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Edit, Delete } from '@mui/icons-material';
import { buttonPrimary, buttonSecondary, dishFontSx, errorText } from '../../assets/styles/sxProp';
import { putStateUpdate } from '../../api/api';
import AppContext from '../../context/AppContext';


type Props = {};

export type state = {
    stateId: string;
    allDayRestriction: string;
    display: string;
    dowEndTime: string;
    dowRestricton: string[];
    dowStartTime: string;
    endTime: string;
    mandatoryConsent: string;
    repeatCount: string;
    restrictAll: string;
    startTime: string;
    stateCode: string;
    stateHolidays: string[];
    stateName: string;
    lastUpdateBy: string;
    lastUpdateDate: string;
};


const States = (props: Props) => {

    const { instance, username } = useContext<any>(AppContext);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState<state[]>([]);
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});
    const [editRow, setEditRow] = useState<state>();

    const handleCreateNewRow = (values: state) => {
        tableData.push(values);
        setTableData([...tableData]);
    };

    const handleSaveRowEdits: MaterialReactTableProps<state>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values }) => {
            if (!Object.keys(validationErrors).length) {
                tableData[row.index] = values;
                //send/receive api updates here, then refetch or update local table data for re-render
                setTableData([...tableData]);
                exitEditingMode(); //required to exit editing mode and close modal
            }
        };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row: MRT_Row<state>) => {
            if (
                !window.confirm(`Are you sure you want to delete ${row.getValue('name')}`)
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData],
    );

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<state>,
        ): MRT_ColumnDef<state>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'phoneNumber'
                            ? validatePhoneNumber(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );
    const columns = useMemo<MRT_ColumnDef<state>[]>(
        () => [
            {
                accessorKey: 'stateName', //normal accessorKey
                header: 'StateName',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'stateCode', //normal accessorKey
                header: 'StateCode',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'stateHolidays', //normal accessorKey
                header: 'StateHolidays',
                Cell: ({cell}) => {
                    let variable = cell.getValue<string[]>();
                    return (
                        <ul className='NoDots'>
                            {variable.map((el) => {
                                return (
                                    <li key={el}>
                                        {el}
                                    </li>
                                )
                            })}
                        </ul>
                    )}
            },
            // Hide Column
            //{
            //    accessorKey: 'stateId', //access nested data with dot notation
            //    header: 'StateId',
            //    enableEditing: false,
            //    muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //        ...getCommonEditTextFieldProps(cell),
                //    })
            //},
            {
                accessorKey: 'startTime', //normal accessorKey
                header: 'StartTime',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'endTime', //normal accessorKey
                header: 'EndTime',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'allDayRestriction',
                header: 'AllDayRestriction',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            // {
                //     accessorKey: 'display', //normal accessorKey
                //     header: 'Display',
                //     muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    //         ...getCommonEditTextFieldProps(cell),
            //     })
            // },
            {
                accessorKey: 'mandatoryConsent', //normal accessorKey
                header: 'MandatoryConsent',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'repeatCount', //normal accessorKey
                header: 'RepeatCount',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'restrictAll', //normal accessorKey
                header: 'RestrictAll',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'dowRestricton', //normal accessorKey
                header: 'DowRestriction',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'dowStartTime', //normal accessorKey
                header: 'DowStartTime',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'dowEndTime', //normal accessorKey
                header: 'DowEndTime',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'lastUpdateBy', //normal accessorKey
                header: 'Last Updated By',
                enableEditing: false
                //muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //    ...getCommonEditTextFieldProps(cell),
                //})
            },
            {
                accessorKey: 'lastUpdateDate', //normal accessorKey
                header: 'Last Updated Date',
                enableEditing: false
                //muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //    ...getCommonEditTextFieldProps(cell),
                //})
            },


        ],
        [getCommonEditTextFieldProps],
    );

    const url = 'https://' + window.location.toString().split('/')[2];

    const holidayLinebreaks = (arr: string[]) => {
        const zerosReg = /T00:00:00/g;
        const response = arr.map((el, index) => {
            return index !== arr.length -1 ? el.replace(zerosReg, ``) : el.replace(zerosReg, '') 
        });
        

        return response
    }

    const cleanData = (fd: state[]) => {
        let clean: state[] = [];
        fd.forEach(function (item) {

            let newItem: state = {
                allDayRestriction: item.allDayRestriction.toString() === 'false' ? 'false': 'true',
                stateId: item.stateId,
                display: item.display === 'false' ? 'false' : 'true',
                dowEndTime: item.dowEndTime,
                dowRestricton: item.dowRestricton.map(el => el.concat(" ")),
                dowStartTime: item.dowStartTime,
                endTime: item.endTime,
                mandatoryConsent: item.mandatoryConsent === false ? 'false' : 'true',
                repeatCount: item.repeatCount,
                restrictAll: item.restrictAll === false ? 'false' : 'true',
                startTime: item.startTime,
                stateCode: item.stateCode,
                stateHolidays: holidayLinebreaks(item.stateHolidays),
                stateName: item.stateName,
                lastUpdateBy: item.lastUpdateBy,
                lastUpdateDate: item.lastUpdateDate
            }
            clean.push(newItem);
        })
        setTableData(clean);
    };


    const fetchStateData = () => {
        fetch(`${url}/api/dialrestrictions/states`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                cleanData(data);
            })
    }

    useEffect(() => {
        fetchStateData();
    }, []);

    return (
        <><div><Typography variant='h4' sx={dishFontSx}>State Dial Restrictions</Typography></div>
        <br />
            <div>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={tableData}
                    enableColumnFilters={true}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={true}
                    //Top tool bar with add and search
                    enableTopToolbar={true}
                    enableColumnActions={false}
                    enableBottomToolbar={true}
                    enableSorting={true}
                    initialState={{
                        sorting: [
                          {
                            id:'stateCode',
                            desc: false
                          }
                        ],
                        pagination: {
                            pageIndex: 0,
                            pageSize: 100
                          },
                      }}
                    //Action Colums
                    enableEditing
                    onEditingRowSave={handleSaveRowEdits}
                    onEditingRowCancel={handleCancelRowEdits}
                    muiTableHeadCellProps={{
                        sx: dishFontSx
                    }}
                    muiTableBodyCellProps={{
                        sx: dishFontSx
                    }}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton onClick={() => {
                                    setEditRow(tableData[row.index]);
                                    setCreateModalOpen(true);
                                }}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                            {/* <Tooltip arrow placement="right" title="Delete">
                                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip> */}
                        </Box>
                    )}
                    // renderTopToolbarCustomActions={() => (
                    //     <Button
                    //         sx={buttonPrimary}
                    //         onClick={() => setCreateModalOpen(true)}
                    //         variant="contained"
                    //     >
                    //         Create New Restriction
                    //     </Button>
                    // )}
                />
                <CreateNewAccountModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => {
                        setCreateModalOpen(false)
                        fetchStateData()
                    }}
                    onSubmit={handleCreateNewRow}
                    item = {editRow}
                />
            </div>
        </>
    );
};

interface CreateModalProps {
    columns: MRT_ColumnDef<state>[];
    onClose: () => void;
    onSubmit: (values: state) => void;
    open: boolean;
    item: state | undefined;
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({
    open,
    columns,
    onClose,
    onSubmit,
    item
}: CreateModalProps) => {
    const [values, setValues] = useState<any>(() =>
        columns.reduce((acc, column) => {
            if (column.accessorKey === "stateId") acc[column.accessorKey] = new Date().toLocaleString()
            else acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    type StateUpdateErrors = {
        endBeforeStart: number,
        missingTimeStamp: number,
    }

    const [days, setDays] = useState<string[]>([]);
    const [currentHolidays, setCurrentHolidays] = useState<string[]>([]);
    const [updateHolidays, setUpdateHolidays] = useState<string[]>([]);
    const [newHoliday, setNewHoliday] = useState<string>("");
    const [newStart, setNewStart] = useState<string>(null);
    const [newEnd, setNewEnd] = useState<string>(null);
    const [validationErrors, setValidationErrors] = useState<StateUpdateErrors>({
        endBeforeStart: 0,
        missingTimeStamp: 0
    })
    const { username } = useContext<any>(AppContext);


    useEffect(() => {
        if(item?.dowRestricton) {
            // console.log(item)
            const darray = item.dowRestricton.map((el) => el.trim())
            setDays(darray)            
        }

        if(item?.stateHolidays) {
            const ditchTheSpace = item.stateHolidays.map((el) => {
                return el.substring(0, 10)
            })

            const changeItBack = item.stateHolidays.map((el) => {
                const newLineReg = /,\n/g;
                return el.replace(newLineReg, 'T00:00:00')
            })

            setCurrentHolidays(ditchTheSpace)
            setUpdateHolidays(changeItBack)
        }

        if (item?.startTime) {
            setNewStart(item.startTime)
        }

        if (item?.endTime) {
            setNewEnd(item.endTime)
        }
    }, [item])
    const dayStrings = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    const handleCancel = () => {
        setValues(() =>
            columns.reduce((acc, column) => {
                if (column.accessorKey === "allDayRestriction") acc[column.accessorKey] = new Date().toLocaleString()
                else acc[column.accessorKey ?? ''] = '';
                return acc;
            }, {} as any))
        onClose()
    }

    const validator = async () => {
        const newObj: state = item;
        const validation = {
            endBeforeStart: 0,
            missingTimeStamp: 0
        }

        if (newObj.startTime !== newStart && newStart !== null) {
            newObj.startTime = newStart
        }

        if (newObj.endTime !== newEnd && newEnd !== null ) {
            newObj.endTime = newEnd
        }

        if ((newObj.endTime <= newObj.startTime) && (newObj.startTime !== null) && (newObj.endTime !== null)) {
            // console.log(newObj.startTime)
            // console.log(newObj.endTime)
            validation.endBeforeStart = 1
        }

        // console.log(newObj.startTime)
        // console.log(newObj.endTime)
        if ((newObj.startTime === null || newObj.startTime === "") && (newObj.endTime !== null && newObj.endTime !== "")) {
            // console.log("ERROR1")
            validation.missingTimeStamp = 1
        } else if ((newObj.startTime !== null && newObj.startTime !== "") && (newObj.endTime === null || newObj.endTime === "")) {
            // console.log("ERROR2")
            validation.missingTimeStamp = 1            
        }



        const changeItBack = currentHolidays.map((el) => {
            return el.concat('T00:00:00')
        })

        newObj.stateHolidays = changeItBack;
        newObj.dowRestricton = days;
            let user = "";
        username === "" ? user = "SYSTEM" : user = username.substring(5);
        newObj.lastUpdateBy = user;

        // console.log(newObj);
        
        setValidationErrors(validation);
        if (validation.endBeforeStart === 1 || validation.missingTimeStamp === 1) return null;
        else {
            // console.log(validation)
            console.log("PUTTING")
            const response = await putStateUpdate(newObj);

            setNewStart(null);
            setNewEnd(null);
            setValidationErrors({
                endBeforeStart: 0,
                missingTimeStamps: 0
            });
            onClose();
            return response
        }

    }
    const handleSubmit = async () => {
        //put your validation logic here
        // console.log(values)
        const response = await validator();


        setValues(() =>
            columns.reduce((acc, column) => {
                if (column.accessorKey === "display") acc[column.accessorKey] = new Date().toLocaleString()
                else acc[column.accessorKey ?? ''] = '';
                return acc;
            }, {} as any))
    };

    const handleDatePicker = (e: InputEvent) => {
        if (e.target && e.target.value) {
            setNewHoliday(e.target.value)
        }
    }

    const handleStartPicker = (e: InputEvent) => {
        if (e.target && e.target.value) {
            let split = e.target.value.split(":");
            if (split[1] !== "00") split[1] = "00";
            let joined = split.join(":");
            setNewStart(joined)
        }
    }

    const handleEndPicker = (e: InputEvent) => {
        if (e.target && e.target.value) {
            let split = e.target.value.split(":");
            if (split[1] !== "00") split[1] = "00";
            let joined = split.join(":");
            setNewEnd(joined)
        }
    }

    const toggleDay = (e: SelectChangeEvent) => {
        const {
            target: { value }
        } = e;

        setDays(typeof value === 'string' ? value.split(',') : value);

    }

    useEffect(() => {
        console.log(validationErrors)
    }, [validationErrors])
    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Edit State Dial Restriction</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => {

                            // if (item && column.accessorKey) console.log(item[column.accessorKey])
                            if (column.accessorKey==="stateHolidays") {
                                return (
                                <>
                                    {column.header}
                                    <Grid container>
                                    {(currentHolidays &&  Array.isArray(currentHolidays) && currentHolidays.length > 0) &&
                                    currentHolidays.map((el, index) => {
                                        return (
                                            
                                            <Grid id={index} key={index} item={true} xs={4}>
                                                <IconButton aria-label='delete' size="small" 
                                                    onClick={() => setCurrentHolidays(() => {
                                                        const newArr = [...currentHolidays]
                                                        // console.log(newArr[index])
                                                        if (index === 0) newArr.shift()
                                                        else newArr.splice(index, 1)
                                                        return newArr
                                                    })}>
                                                <Delete fontSize='inherit' /></IconButton>
                                                {el.substring(0,10)}
                                                <br />
                                            </Grid>

                                        )
                                    })

                                    }
                                    </Grid>
                                    <input type='date' className='DatePicker' onChange={handleDatePicker} />
                                    <Button sx={{...buttonPrimary, width: "10rem"}}
                                        onClick={(() => {
                                            const dateReg = /^\d{4}-\d{2}-\d{2}$/;

                                            if (dateReg.test(newHoliday) && currentHolidays.indexOf(newHoliday) === -1) {
                                                setCurrentHolidays([...currentHolidays, newHoliday])
                                                setNewHoliday("");
                                            }
                                        })}
                                    >Add Holiday</Button>
                                </>
                                )
                            } else if (column.accessorKey === "startTime") {
                                return (            
                                    <>
                                        {column.header}
                                        <input step="3600" type="time" id="startTime" name="startTime" value={newStart} onChange={handleStartPicker} />
                                    </>
                                )
                            } else if (column.accessorKey==="endTime") {
                                return(
                                    <>
                                        {column.header}
                                        <input step="3600" type="time" id="endTime" name="endTime" value={newEnd} onChange={handleEndPicker} />
                                        { validationErrors.endBeforeStart === 1 &&
                                            <Typography sx={errorText}>Start Time must be before End Time</Typography>
                                        }
                                        { validationErrors.missingTimeStamp === 1 &&
                                            <Typography sx={errorText}>Missing or Incomplete Timestamp</Typography>
                                        }
                                    </>
                                )
                            }
                            else if (column.accessorKey==="dowRestricton") {
                                return (
                                    <>
                                        {column.header}
                                        <Select
                                            multiple={true}
                                            value={days}
                                            renderValue={(selected) => selected.join(', ')}
                                            onChange={toggleDay}
                                        >
                                            {dayStrings.map((el) => {
                                                return (
                                                    <MenuItem key={el} value={el}>
                                                        <Checkbox checked = {days.indexOf(el) > -1} />
                                                        <ListItemText primary={el} />
                                                    </MenuItem>
                                                )
                                            })

                                            }
                                        </Select>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        {column.header}
                                        <TextField
                                            key={column.accessorKey}
                                            name={column.accessorKey}
                                            value={(item && column.accessorKey ? item[column.accessorKey] : "")}
                                            disabled={true}
                                            onChange={(e) =>
                                                setValues({ ...values, [e.target.name]: e.target.value })
                                            }
                                        />
                                    </>
                                )
                            }
                        })}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value: string) => !!value.length;
const validatePhoneNumber = (number: string) => !!number.length;
//&&
// email
//   .toLowerCase()
//   .match(
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
// );
const validateAge = (age: number) => age >= 18 && age <= 50;

export default States;