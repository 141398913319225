//@ts-nocheck
import { useState, useEffect, useContext } from 'react';
import '../../assets/styles/stylesheet.css'
import { cardLayoutExtraWide, dishFont, dishFontSx, dishFontSxHover } from '../../assets/styles/sxProp';
import { Card, CardContent, Divider, Grid,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import FixedWidthTable from '../../components/common/FixedWidthTable';
import CreateFwtModal from '../../components/common/CreateFwtModal';
import { getFwtList } from '../../api/api';
import AppContext from '../../context/AppContext';

type Props = {};

const SystemFileInput = (props: Props) => {
  const [fwtList, setFwtList] = useState(null);
  const [displayTable, setDisplayTable] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [formatDisplayName, setFormatDisplayName] = useState("");
  const [formatUpdateBy, setFormatUpdateBy] = useState("");
  const [formatUpdateDate, setFormatUpdateDate] = useState("");
  const {newFwt} = useContext(AppContext);
  const [isItOne, setIsItOne] = useState<boolean>(false);

  const handleDisplayTable = (e) => {
    const arrIndex = fwtList.findIndex(obj => obj.campaignName === e.target.id);
    if (fwtList[arrIndex]?.fileColumns && fwtList[arrIndex]?.campaignName) {
      setTableData(fwtList[arrIndex].fileColumns);
      setDisplayTable(true);
      setFormatDisplayName(fwtList[arrIndex].campaignName);
      setFormatUpdateBy(fwtList[arrIndex].lastUpdateBy);
      setFormatUpdateDate(fwtList[arrIndex].lastUpdateDate);
    }
  }

  useEffect(() => {
    async function setFwt () {
      const response = await getFwtList();
      setFwtList(response)
    }

    setFwt()
  }, [newFwt])

  useEffect(() => {
    if (Array.isArray(fwtList) && fwtList.length > 0) {
      const sortedFwtList = fwtList.sort((a:any,b:any) => {
        if (a.campaignName < b.campaignName) return -1;
        else if (a.campaignName > b.campaignName) return 1;
        else return 0
      })

      if (sortedFwtList !== fwtList) setFwtList(sortedFwtList)
      else setIsItOne(true)
    }
  }, [fwtList])

  return (
          <>
          <Typography variant='h4' sx={dishFontSx}>File Type</Typography>
          <br/>
          <Card sx={cardLayoutExtraWide}>
          <CardContent>
            <CreateFwtModal fwtList={fwtList}/>
            <br /><br /><br />
            <Divider />
            <br />
            <Grid container>
              <Grid xs={12} lg={2} item={true} sx={{ borderLeft:"1px solid lightgray"}}>
                {(Array.isArray(fwtList) && isItOne === true) &&
                    <TableContainer sx={{minWidth:"11rem", maxHeight: "70vh"}}>
                        <Table>
                          <TableHead>
                            <TableRow>
                                <TableCell sx={{fontFamily: dishFont, fontSize: "1.5em", width: "11rem"}}>Format Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {fwtList.map((el) => {
                              return(
                                <TableRow key={el.campaignName}>
                                      <TableCell sx={dishFontSxHover} id={el.campaignName} onClick={handleDisplayTable}><b>{el.campaignName}</b></TableCell>
                                  </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                    </TableContainer>
                }
                </Grid>
                {displayTable &&
                  <Grid xs={11} lg={9} sx={{ ml: "3rem", pl: "1rem", borderLeft: "1px solid lightgray" }} item={true}>
                    <FixedWidthTable tableData={tableData} formatName={formatDisplayName} lastUpdateBy={formatUpdateBy} lastUpdateDate={formatUpdateDate} />
                  </Grid>
                }
            </Grid>
          </CardContent>
        </Card>
        </>
  );
};

export default SystemFileInput;