import MaterialReactTable, { MRT_Row, type MRT_ColumnDef, MRT_Cell, MaterialReactTableProps } from 'material-react-table';
import { useEffect, useMemo, useState, useCallback, useContext, ChangeEvent, useRef } from 'react';
import '../../assets/styles/stylesheet.css';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { PlayArrow, Stop, Pause, Edit, Delete, ChangeHistory } from '@mui/icons-material';
import { buttonPrimary, dishFont, dishFontSx, errorText } from '../../assets/styles/sxProp';
import { getCampaigns, startCampaign, pauseCampaign, stopCampaign, uploadFile, deleteCampaign, populateCFDropdown, populateQueueDropdown, populatePhoneDropdown, Schedule, fetchSchedules, editCampaign, getCampaignLogs } from '../../api/api';
import AppContext from '../../context/AppContext';
import BasicSnackbar from '../../components/common/Snackbar';


type Props = {};
export type Campaign = {
  campaignId: string;
  campaignName: string;
  priority: number;
  callAttempt: number;
  startTime: string;
  endTime: string;
  connectQueue: string;
  contactFlowId: string;
  phoneNumber: string;
  fileType: string;
  schedule: string;
  status: number;
  expirationHours: number;
  callDuration: string;
  instanceId: string;
  lastUpdate: string;
  lob: string;
  recurring: boolean;
};

export type CamapignChangeLog = {
    campaignName: string,
    date: string,
    changeLogs: ChangeLog[]
}

export type ChangeLog = {
    change: string,
    user: string,
    changeDateTime: string
}

export const dateConvertor = (date: string) => {
    let d = date.split("T")
    let formattedDate = d[0] + " " + d[1];
    return formattedDate;
}

const CampaignActivePage = (props: Props) => {
    const ref = useRef(null);
    const { instance, username } = useContext<any>(AppContext);
    const [campaignList, setCampaignList] = useState<Campaign[]>([])
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [selectedFile, setSelectedFile] = useState<any>();
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});
    const [contactFlowList, setContactFlowList] = useState<any>([]);
    const [contactQueueList, setContactQueueList] = useState<any>([]);
    const [scheduleList, setScheduleList] = useState<any>([]);
    const [phoneNumberList, setPhoneNumberList] = useState<any>([]);
    const retryPriority = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const availableLobs = ["DISH", "WIRELESS"];
    const boolArray = ["True", "False"];
    const [logModalOpen, setLogModalOpen] = useState(false);
    const [campaignLogs, setCampaignLogs] = useState<CamapignChangeLog[]>([]);

    useEffect(() => {
        async function setPageData() {
            const phones: any = await populatePhoneDropdown(instance);
            if (Array.isArray(phones) && phones.length > 0) setPhoneNumberList(phones.sort());
            else phoneNumberList(phones);

            const cfs: any = await populateCFDropdown(instance);
            setContactFlowList(cfs);

            const queues: any = await populateQueueDropdown(instance);
            setContactQueueList(queues);

            const schedules: Schedule[] = await fetchSchedules();
            setScheduleList(schedules);

            const activeCampaigns: Campaign[] = await getCampaigns();
            const parsedReply = await parseCampaigns(activeCampaigns);
            setCampaignList(parsedReply);
        }
        setPageData()
    }, [instance])

    useEffect(() => {
        if (Array.isArray(phoneNumberList) && phoneNumberList.length > 0) {
            const sortedPhoneList = phoneNumberList.sort((a: any, b: any) => {
                if (a.phoneNumber < b.phoneNumber) return -1;
                else if (a.phoneNumber > b.phoneNumber) return 1;
                else return 0
            })

            if (sortedPhoneList !== phoneNumberList) setPhoneNumberList(sortedPhoneList)
        }
    }, [phoneNumberList])

    useEffect(() => {
        if (Array.isArray(contactFlowList) && contactFlowList.length > 0) {
            const sortedCfList = contactFlowList.sort((a: any, b: any) => {
                if (a.Name < b.Name) return -1;
                else if (a.Name > b.Name) return 1;
                else return 0
            })

            if (sortedCfList !== contactFlowList) setContactFlowList(sortedCfList)
        }
    }, [contactFlowList])

    useEffect(() => {
        if (Array.isArray(contactQueueList) && contactQueueList.length > 0) {
            const sortedQueueList = contactQueueList.sort((a: any, b: any) => {
                if (a.Name < b.Name) return -1;
                else if (a.Name > b.Name) return 1;
                else return 0
            })

            if (sortedQueueList !== contactQueueList) setContactQueueList(sortedQueueList)
        }
    }, [contactQueueList])

    useEffect(() => {
        if (Array.isArray(scheduleList) && scheduleList.length > 0) {
            const sortedScheduleList = scheduleList.sort((a: any, b: any) => {
                if (a.Name < b.Name) return -1;
                else if (a.Name > b.Name) return 1;
                else return 0
            })

            if (sortedScheduleList !== scheduleList) setContactQueueList(scheduleList)
        }
    }, [scheduleList])

    async function reloadCampaigns() {
        const reply = await getCampaigns();
        const parsedReply = await parseCampaigns(reply);
        setCampaignList(parsedReply);
    }

    async function parseCampaigns(arr: any) {
        let parsedCampaigns:any[] = [];

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].campaignId) {
                let campaign = arr[i];
                switch (campaign.status) {
                    case 0:
                        campaign.status = "Active"
                        break;
                
                    case 1:
                        campaign.status = "Paused"
                        break;

                    case 2: 
                        campaign.status = "Stopped";
                        break;

                    default:
                        break;
                }

                switch (campaign.recurring) {
                    case true:
                        campaign.recurring = "True"
                        break;
                    case false:
                        campaign.recurring = "False"
                        break;
                    default:
                        campaign.recurring = "False"
                        break;
                }
                campaign.startTime = campaign.startTime.substring(0, 10);
                campaign.endTime = campaign.endTime.substring(0, 10);
                campaign.lastUpdate = campaign.lastUpdate.substring(0, 10);
                parsedCampaigns.push(campaign);
            }
        }

        return parsedCampaigns;
    }

    const handleStartCampaign = useCallback(
        async (row: MRT_Row<Campaign>) => {
            try {
                await startCampaign(`${row.getValue('campaignName')}`);
                setAlertSeverity("success")
                setAlertMessage("Campaign Started.")
                setAlertOpen(true);
            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to start campaign!")
                setAlertOpen(true);
                return (err)
            }
            reloadCampaigns();
        }, 
        []
    );

    const handlePauseCampaign = useCallback(
        async (row: MRT_Row<Campaign>) => {
            console.log(row)
            try {
                await pauseCampaign(`${row.getValue('campaignName')}`);
                setAlertSeverity("success")
                setAlertMessage("Campaign Paused.")
                setAlertOpen(true);
            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to pause campaign!")
                setAlertOpen(true);
                return (err)
            }
            reloadCampaigns();
        },
        [],);

    const handleStopCampaign = useCallback(
        async (row: MRT_Row<Campaign>) => {
            if (
                !window.confirm(`Are you sure you want to stop ${row.getValue('campaignName')}`)
            ) {
                return;
            }
            try {
                await stopCampaign(`${row.getValue('campaignName')}`);
                setAlertSeverity("success")
                setAlertMessage("Campaign Stopped.")
                setAlertOpen(true);
            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to stop campaign!")
                setAlertOpen(true);
                return (err)
            }
            reloadCampaigns();
        },
        [],
    );

    const handleDeleteCampaign = useCallback(
        async (row: MRT_Row<Campaign>) => {
            if (
                !window.confirm(`Are you sure you want to delete ${row.getValue('campaignName')} ? This action is irreversible!`)
            ) {
                return;
            }
            try {
                await deleteCampaign(`${row.getValue('campaignName')}`);
                setAlertSeverity("success")
                setAlertMessage("Campaign has been deleted!")
                setAlertOpen(true);
            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to delete campaign!")
                setAlertOpen(true);
                return (err)
            }
            reloadCampaigns();
        },
        []
    );

    const handleCampaignLogs = useCallback(
        async (row: MRT_Row<Campaign>) => {
            
            try {
                const res = await getCampaignLogs();
                const filteredRes = res.filter((x: { campaignName: string; }) => x.campaignName === `${row.getValue('campaignName')}`)
                if (filteredRes && filteredRes.length > 0) {
                    setCampaignLogs(filteredRes);
                    setLogModalOpen(true);
                }
            } catch (err) {
                console.log(err)
                return (err)
            }
        },
        []
    );

    const fileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files[0] === undefined) {
            setSelectedFile(null)
        } else {
            console.log("selected file: ", event.target.files[0])
            const formData = new FormData();
            formData.append('FileName', event.target.files[0])
            setSelectedFile(formData);
           
        }
    }

    async function upload() {
        console.log(selectedFile)
      if (selectedFile !== null && selectedFile !== undefined) {
          console.log(selectedFile)
            try {
                const upload = await uploadFile(selectedFile);
                if (upload.status !== 202) {
                    setAlertSeverity("error")
                    setAlertMessage("Unable to upload file!")
                    setAlertOpen(true);
                } else {
                setAlertSeverity("success")
                setAlertMessage("File Uploaded Successfully to Import Bucket.")
                setAlertOpen(true);
                setSelectedFile(null);
                }

            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to upload file!")
                setAlertOpen(true);
                return (err)
            }
        } else {
            setAlertSeverity("error")
            setAlertMessage("No file selected")
            setAlertOpen(true);
        }
    }

    const handleSaveRowEdits: MaterialReactTableProps<Campaign>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values, }) => {
            if (!Object.keys(validationErrors).length) {
                updateNewRow(values)
                exitEditingMode(); //required to exit editing mode and close modal
            }
        };

    const updateNewRow = async (values: Campaign) => {
        let user = "";
        username === "" ? user = "SYSTEM" : user = username.substring(5);
        const newObj = {
            CallAttempt: values.callAttempt,
            CampaignName: values.campaignName,
            ConnectQueue: values.connectQueue,
            ContactFlowId: values.contactFlowId,
            PhoneNumber: values.phoneNumber,
            Priority: values.priority,
            Schedule: values.schedule,
            StartTime: values.startTime,
            EndTime: values.endTime,
            Lob: values.lob,
            //@ts-ignore
            Recurring: values.recurring === "True" ? true : false,
            Username: user,
            LastUpdate: values.lastUpdate,
            Status: 2 // Can only edit when status is Stopped (2)
        }
        try {
            await editCampaign(newObj);
            setAlertSeverity("success")
            setAlertMessage("Campaign successfully updated.")
            setAlertOpen(true);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to update campaign!")
            setAlertOpen(true);
            return (err)
        }
        reloadCampaigns();
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<Campaign>,
            row: MRT_Row<Campaign>,
        ): MRT_ColumnDef<Campaign>['muiTableBodyCellEditTextFieldProps'] => {
            return {
            };
        },
        [validationErrors],
    );

  const columns = useMemo<MRT_ColumnDef<Campaign>[]>(
    () => [
      {
        accessorKey: 'campaignName', //access nested data with dot notation
        header: 'Campaign Name',
        enableEditing: false,
      },
      {
        accessorKey: 'startTime',
          header: 'Start Date',
          muiTableBodyCellEditTextFieldProps: ({ cell, row }) => ({
              ...getCommonEditTextFieldProps(cell, row),
              type:"date", 
              children: <TextField
                  id="startTime"
                  label="startTime"
                  InputLabelProps={{
                      shrink: true,
                  }}      
              />
          })
      },
      {
        accessorKey: 'endTime', //normal accessorKey
          header: 'End Date',
          muiTableBodyCellEditTextFieldProps: ({ cell, row }) => ({
              ...getCommonEditTextFieldProps(cell, row),
              type: "date",
              children: <TextField
                  id="endTime"
                  label="endTime"
                  InputLabelProps={{
                      shrink: true,
                      
                  }}
              />
          })
          },
          {
              accessorKey: 'lastUpdate', //normal accessorKey
              header: 'Created Date',
              enableEditing: false,
          },
      {
        accessorKey: 'lob',
        header: "Line of Business",
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell, row }) => ({
            ...getCommonEditTextFieldProps(cell, row),
            select: true,
            children: availableLobs.map((item) => (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            )),
        })
      },
      {
        accessorKey: 'recurring',
        header: "Recurring",
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell, row }) => ({
            ...getCommonEditTextFieldProps(cell, row),
            select: true,
            children: boolArray.map((item) => (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            )),
        })
      },
      {
        accessorKey: 'status',
          header: 'Status',
          enableEditing: false,
      },
      {
        accessorKey: 'callAttempt',
          header: 'Retry Attempts',
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
              //...getCommonEditTextFieldProps(cell),
              select: true, //change to select for a dropdown
              children: retryPriority.map((item) => (
                  <MenuItem key={item} value={item}>
                      {item}
                  </MenuItem>
              )),
          })
      },
      {
        accessorKey: 'contactFlowId',
          header: "Contactflow",
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
              //...getCommonEditTextFieldProps(cell),
              select: true, //change to select for a dropdown
              children: contactFlowList.map((cf: any) => (
                  <MenuItem key={cf.ContactFlowId} value={cf.ContactFlowId}>
                      {cf.Name}
                  </MenuItem>
              )),
          })
      },
      {
        accessorKey: 'connectQueue',
          header: "Queue",
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
              //...getCommonEditTextFieldProps(cell),
              select: true, //change to select for a dropdown
              children: contactQueueList.map((q: any) => (
                  <MenuItem key={q.Id} value={q.Id}>
                      {q.Name}
                  </MenuItem>
              )),
          })
      },
      {
        accessorKey: 'priority',
          header: "Priority",
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
              //...getCommonEditTextFieldProps(cell),
              select: true, //change to select for a dropdown
              children: retryPriority.map((item) => (
                  <MenuItem key={item} value={item}>
                      {item}
              </MenuItem>
              )),
          })
    },
    {
        accessorKey: 'schedule',
        header: "Schedule",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
               // ...getCommonEditTextFieldProps(cell),
            select: true, //change to select for a dropdown
            children: scheduleList.map((s: any) => (
                <MenuItem key={s.scheduleName} value={s.scheduleName}>
                    {s.scheduleName}
            </MenuItem>
            )),
        })
    },
    {
        accessorKey: 'phoneNumber',
        header: "Display Phone Number",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            //...getCommonEditTextFieldProps(cell),
            select: true, //change to select for a dropdown
            children: phoneNumberList.map((ph: any) => (
                <MenuItem key={ph.phoneNumber} value={ph.phoneNumber}>
                    {ph.phoneNumber}
            </MenuItem>
            )),
        })
    }
    ],
      [contactFlowList, contactQueueList, getCommonEditTextFieldProps, phoneNumberList, retryPriority, scheduleList],
  );
    return (
    <div>
            <div><Typography variant='h4' sx={dishFontSx}>Active Campaigns</Typography></div>
            <Grid xs={12} lg={6} xl={2} item={true}>
                <Typography sx={{ marginBottom: "0.5rem", fontFamily: dishFont }}>File Upload (Optional)</Typography>
                <input type='file' name='fileUpload' accept='.txt' onChange={fileHandler} />
                <Button variant='contained' onClick={upload}>Upload</Button>
            </Grid>
       <br />
      <div>
        {(campaignList && Array.isArray(campaignList) && campaignList.length > 0) &&
            <MaterialReactTable 
            columns={columns} 
            data={campaignList}
            initialState={{
                sorting: [
                    {
                        id: 'campaignName',
                        desc: false
                    }
                ],
                pagination: {
                  pageIndex: 0,
                  pageSize: 100
                },
                columnVisibility: {campaignId: false, contactFlowId: false, connectQueue: false}
            }}
            enableRowActions={true}
            enableTopToolbar={true}
            enableHiding={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            //enableRowSelection
            enableColumnActions={false}
            enableSorting={true}
            onEditingRowSave={handleSaveRowEdits}
            onEditingRowCancel={handleCancelRowEdits}
            muiTableHeadCellProps={{
              sx: dishFontSx
            }}
            muiTableBodyCellProps={{
              sx: dishFontSx
            }}
            renderRowActionMenuItems={({ closeMenu, row, table }) => [
              <MenuItem
                key={0}
                    disabled={`${row.getValue('status')}` === "Active" ? true : false}
                    onClick={() => {
                        handleStartCampaign(row)
                        closeMenu();
                    }}
                sx={{ m: 0, fontFamily: dishFont }}
              >
                    <ListItemIcon>
                        <PlayArrow />
                </ListItemIcon>
                Start
              </MenuItem>,
              <MenuItem
                  key={1}
                  disabled={`${row.getValue('status')}` === "Paused" ? true : `${row.getValue('status')}` === "Stopped" ? true : false}
                onClick={() => {
                    handlePauseCampaign(row)
                    closeMenu();
                }}
                sx={{ m: 0, fontFamily: dishFont }}
              >
                <ListItemIcon>
                  <Pause />
                </ListItemIcon>
                Pause
                </MenuItem>,
                <MenuItem
                    key={2}
                    disabled={`${row.getValue('status')}` === "Stopped" ? true : false}
                    onClick={() => {
                        handleStopCampaign(row)
                        closeMenu();
                    }}
                    sx={{ m: 0, fontFamily: dishFont }}
                >
                    <ListItemIcon>
                        <Stop />
                    </ListItemIcon>
                    Stop
                </MenuItem>,
                <MenuItem
                    key={3}
                    disabled={`${row.getValue('status')}` !== "Stopped"? true : false }
                    onClick={() => {
                        table.setEditingRow(row)
                        closeMenu();
                    }}
                    sx={{ m: 0, fontFamily: dishFont }}
                >
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    Edit
                </MenuItem>,
                <MenuItem
                    key={4}
                    disabled={`${row.getValue('status')}` !== "Stopped" ? true : false}
                    onClick={() => {
                        handleDeleteCampaign(row)
                        closeMenu();
                    }}
                    sx={{ m: 0, fontFamily: dishFont }}
                >
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    Delete
                </MenuItem>,
                <MenuItem
                    key={5}
                    onClick={() => {
                        handleCampaignLogs(row)
                        closeMenu();
                    }}
                    sx={{ m: 0, fontFamily: dishFont }}
                >
                    <ListItemIcon>
                        <ChangeHistory />
                    </ListItemIcon>
                    Logs
                </MenuItem>

            ]}/>
        }

          </div>
          <BasicSnackbar
              open={alertOpen}
              severity={alertSeverity}
              message={alertMessage}
              onClose={() => setAlertOpen(false)}
            />
            <CampaignLogModal
                open={logModalOpen}
                onClose={() => {
                    setLogModalOpen(false);
                }}
                logs={campaignLogs}
            />
            </div>
  );
};
const validateDates = (startTime: any, endTime: any) => {
    console.log("startTime: ", startTime); console.log("endTime: ", endTime)
    if (new Date(startTime) >= new Date(endTime)) return false
    return true
};
const validateRequired = (value: string) => !!value.length;

interface LogModalProps {
    open: boolean
    onClose: () => void;
    logs: CamapignChangeLog[]
}
export const CampaignLogModal = ({
    open,
    onClose,
    logs
}: LogModalProps) => {
    const handleClose = () => {
        logs = [];
        onClose();
    }
    return (
        <Dialog open={open}
          sx={{
            m: 'auto',
            height: '75%'
           }}>
            <DialogTitle textAlign="center">
                Change Logs - {logs[0]?.campaignName}
            </DialogTitle>
            <DialogContent>
                <div key={`${logs[0]?.campaignName}`}>
                    {logs.map((item) => {
                        return (<><h5 key={`${item.date}`}>{item.date}</h5>
                            {item.changeLogs.map((l) => {
                                return (<><label key={l.changeDateTime}>{l.user} changed {l.change} on {dateConvertor(l.changeDateTime.substring(0, l.changeDateTime.length - 4))}</label>{"\n"}</>)
                            })}
                        </>)
                        
                    })}
                </div>

            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonPrimary} onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog >
    )
}
export default CampaignActivePage;