import { Alert, Snackbar } from "@mui/material";
import * as React from 'react';

type Props = {
    open: boolean;
    onClose?: any;
    severity: any;
    message: string;
}

const BasicSnackbar = ({ open, onClose, severity, message } : Props) => {
    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={onClose}
            >
                <Alert
                    severity={severity}
                    onClose={onClose}
                >
                    { message }
                </Alert>
            </Snackbar>
        </>
    );
};

export default BasicSnackbar;
