import '../../assets/styles/stylesheet.css';
import { helpText, cardLayout, cardLayoutWide, bubbleCheck, helpTextShort, dishFontSx, dishFont, buttonPrimary, buttonSecondary, cardLayoutExtraWide, dishFontSxRed, cardLayoutExtraWideShort } from '../../assets/styles/sxProp';
import { Card, CardContent, Typography, Box, TextField, Select, MenuItem, InputLabel, FormControl, Checkbox, Button, SelectChangeEvent, Grid, Divider, } from "@mui/material";
import { PanoramaFishEye, Lens } from '@mui/icons-material';
import { useState, ChangeEvent, useEffect, useRef, useContext } from 'react';
import { Campaign, populateCFDropdown, populatePhoneDropdown, populateQueueDropdown, postNewCampaign, getFwtList, Schedule, fetchSchedules, uploadFile, getCampaigns, EventMessage } from '../../api/api';
import AppContext from '../../context/AppContext';
import BasicSnackbar from '../../components/common/Snackbar';

type Props = {};

const CampaignCreatePage = (props: Props) => {

  const ref = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [campName, setCampName] = useState("");
  const [phoneBool, setPhoneBool] = useState<boolean>(true);
  const [swapBool, setSwapBool] = useState<boolean>(true);
  const [flow, setFlow] = useState("");
  const [queue, setQueue] = useState("");
  const [phone, setPhone] = useState("");
  const [fwt, setFwt] = useState("");
  const [schedule, setSchedule] = useState("");
  const [dialerType, setDialerType] = useState("Predictive");
  const [bandwidth, setBandwidth] = useState("");
  const [priority, setPriority] = useState("1");
  const [retries, setRetries] = useState("3");
  const [startDate, setStartDate] = useState("")  
  const [endDate, setEndDate] = useState("")
  const [queueListData, setQueueListData] = useState<any>(null);
  const [cfListData, setCfListData] = useState<any>(null);
  const [phoneListData, setPhoneListData] = useState<any>(null);
  const [fwtListData, setFwtListData] = useState<any>(null);
  const [scheduleListData, setScheduleListData] = useState<Schedule[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<any>();
  const [machineDetection, setMachineDetection] = useState<boolean>(true);
  const [recurring, setRecurring] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState();
  const [activeCampaigns, setActiveCampaigns] = useState<any[]>([]);
  const [humanMessage, setHumanMessage] = useState<string>("");
  const [voicemailMessage, setVoicemailMessage] = useState<string>("");
  const [noBeepMessage, setNoBeepMessage] = useState<string>("");
  const [unansweredMessage, setUnansweredMessage] = useState<string>("");
  const [lob, setLob] = useState<string>("DISH");

  const availableLobs = ["DISH", "WIRELESS"];

  const {instance} = useContext<any>(AppContext);

    useEffect(() => {
        //setAlertOpen(true);
      async function setDropdowns (){
          const phones: any = await populatePhoneDropdown(instance);
          if (Array.isArray(phones) && phones.length > 0) setPhoneListData(phones.sort());
          else setPhoneListData(phones);

          const cfs: any = await populateCFDropdown(instance);
          setCfListData(cfs);

          const queues: any = await populateQueueDropdown(instance);
          setQueueListData(queues);

          const fwts: any = await getFwtList();
          setFwtListData(fwts);

          const schedules: Schedule[] = await fetchSchedules();
          setScheduleListData(schedules);

          const activeCampaigns: Campaign[] = await getCampaigns();
          setActiveCampaigns(activeCampaigns);
          }

        setDropdowns()
    
    }, [instance])

    const fileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            //setSelectedFile(null)
        } else {
            console.log("selected file: ", event.target.files[0])
            //setSelectedFile(event.target.files[0])
            /*const files = event.target.files*/
            const formData = new FormData();
            formData.append('FileName', event.target.files[0])
            const responseJson = await uploadFile(formData);
        }
    }

    const validateCampaignPost = async (subObj: Campaign) => {
      const errorArray: string[] = [];
      const isItNumbers: RegExp = /^[0-9]+$/
      const hasWhitespace: RegExp = /\s/
      const activeCampaignNames: string[] = activeCampaigns.map((el) => {
        return el.campaignName;
      })
        if (typeof subObj.CampaignName !== "string" || subObj.CampaignName.length < 1 || subObj.CampaignName.length > 127) {
          errorArray.push("Campaign Name Error: Make sure the name of the campaign is between 1 and 127 characters")
        }
        if (activeCampaignNames.indexOf(subObj.CampaignName) !== -1){
          errorArray.push("Campaign Name Error: A Campaign with this name already exists")
        }
        if (hasWhitespace.test(subObj.CampaignName)) {
          errorArray.push("Campaign Name Error: No spaces allowed in the Campaign Name")
        }
        if (!subObj.ContactFlowId) {
          errorArray.push("Contact Flow Error: Please select a Contact Flow")
        }
        if (!subObj.CampaignQueueId) {
          errorArray.push("Queue Error: Please select a Queue")
        }
        if (!subObj.Priority || !isItNumbers.test(subObj.Priority)) {
          errorArray.push("Priority Error: Please enter a whole number for Priority")
        }
        if (!subObj.Bandwidth || !isItNumbers.test(subObj.Bandwidth)) {
          errorArray.push("Bandwidth Error: Please enter a percentage without decimals for bandwidth")
        }
        if (!subObj.FileType) {
          errorArray.push("Fixed Width Format error: Please select a Fixed Width Format")
        }
        if (!subObj.DialerType) {
          errorArray.push("Dialer Error: Please select a dialer type. How did you manage to not have one?")
        }
        if (!subObj.RetryCount || !isItNumbers.test(subObj.RetryCount)) {
          errorArray.push("Retries Error: Please enter a whole number for Retries")
        }
        if (!subObj.Schedule) {
          errorArray.push("Schedule Error: Please select a schedule")
        }
        if (!subObj.StartTime || !Date.parse(subObj.StartTime)) {
          errorArray.push("Start Date Error: Please select the month, day and year for the campaign start")
        }
        if (!subObj.EndTime || !Date.parse(subObj.EndTime)) {
          errorArray.push("End Date Error: Please select the month, day and year for the campaign end")
        }
        if (!subObj.PhoneNumber) {
          errorArray.push("Phone Number Error: Please select an existing Phone Number")
        }
        if (!subObj.Lob) {
          errorArray.push("LOB Error: Please select a line of business")
        }
        let anError = 0
        for (let i = 0; i < subObj.Messages.length; i++) {
          if (!subObj.Messages[i].message || subObj.Messages[i].message.trim() === "") {
            anError = 1;
          }
          if (anError === 1) break;
        }
        if (anError === 1) {
          errorArray.push("One or more messages is missing a value")
        }
        
        if (errorArray.length === 0) {
          handleSubmit(subObj);
        }


        setValidationErrors(errorArray);
  }

  const togglePhone = () => {
    setPhoneBool(!phoneBool);
  };

  const toggleDetection = () => {
    setMachineDetection(!machineDetection)
  }
  
  const toggleRecurring = () => {
    setRecurring(!recurring)
  }

  useEffect(() => {
    setCharCount(campName.length);
  }, [campName]);
  
  /* swapBool controls the dialerType variable. If swapBool toggles, we switch dialerType 
  These can probably be handled with a single State variable, instead of two */
  const swapSwapBool = () => {
    setSwapBool(!swapBool);
  };
  
  useEffect(() => {
    if (swapBool) setDialerType("Predictive");
    else setDialerType("Progressive");
  }, [swapBool, dialerType]);

  useEffect(() => {
    if (Array.isArray(queueListData) && queueListData.length > 0) {
      const sortedQueueList = queueListData.sort((a:any,b:any) => {
        if (a.Name < b.Name) return -1;
        else if (a.Name > b.Name) return 1;
        else return 0
      })

      if (sortedQueueList !== queueListData) setQueueListData(sortedQueueList)
    }
  }, [queueListData])
  useEffect(() => {
    if (Array.isArray(cfListData) && cfListData.length > 0) {
      const sortedCfList = cfListData.sort((a:any,b:any) => {
        if (a.Name < b.Name) return -1;
        else if (a.Name > b.Name) return 1;
        else return 0
      })

      if (sortedCfList !== cfListData) setCfListData(sortedCfList)
    }
  }, [cfListData])
  useEffect(() => {
    if (Array.isArray(phoneListData) && phoneListData.length > 0) {
      const sortedPhoneList = phoneListData.sort((a:any,b:any) => {
        if (a.PhoneNumber < b.PhoneNumber) return -1;
        else if (a.PhoneNumber > b.PhoneNumber) return 1;
        else return 0
      })

      if (sortedPhoneList !== phoneListData) setPhoneListData(sortedPhoneList)
    }
  }, [phoneListData])
  
  /* Set values to default, then navigate to Active campaigns */
  const cancelButton = () => {
    console.log(queueListData)
    setCampName("");
    setPhoneBool(true);
    setSwapBool(true);
    setFlow("");
    setQueue("");
    setFwt("");
    setSchedule("");
    setDialerType("Predictive");
    setBandwidth("");
    setPriority("1");
    setRetries("3");
    setStartDate("");
    setEndDate("");
    setPhone("");
    setHumanMessage("");
    setVoicemailMessage("");
    setNoBeepMessage("");
    setUnansweredMessage("");
    setValidationErrors([]);
    setMachineDetection(true);
    setRecurring(true);
    setLob("DISH");
  };

    const saveButton = async () => {

      const messages: EventMessage[] = [
        {
          message: humanMessage,
          event: "HUMAN_ANSWERED"
        },
        {
          message: voicemailMessage,
          event: "VOICEMAIL_BEEP"
        },
        {
          message: noBeepMessage,
          event: "VOICEMAIL_NO_BEEP"
        },
        {
          message: unansweredMessage,
          event: "AMD_UNANSWERED"
        },
      ]

      const submitObject: Campaign = {
          InstanceId: instance,
          CampaignName: campName,
          CampaignQueueId: queue,
          ContactFlowId: flow,
          PhoneNumber: phone,
          DialerType: dialerType,
          Bandwidth: bandwidth,
          StartTime: startDate,
          EndTime: endDate,
          FileType: fwt,
          Schedule: schedule,
          Priority: priority,
          RetryCount: retries,
          Messages: messages,
          Lob: lob,
          Recurring: recurring
    }
      await validateCampaignPost(submitObject);
    }
    const handleSubmit = async (submitObject: Campaign) => {
      try {
          const responseJson = await postNewCampaign(submitObject, instance);
          cancelButton();
          setAlertSeverity("success")
          setAlertMessage("Campaign created successfully")
          setAlertOpen(true);
          return responseJson
      }
      catch (err) {
          setAlertSeverity("error")
          setAlertMessage("Unable to create campaign")
          setAlertOpen(true);
          console.log(err)
          return(err)
      }
  }
  
  /* Normally I'd have a single change-handler function manage statechanges,
  But I was having trouble identifying the element being interacted with with Typescript types
  So instead I've made 10-ish tiny functions, below */
  const updateName = (e: ChangeEvent<HTMLInputElement>) => {
    setCampName(e.target.value);
  };
  const handleDropdownChangeFlow = (e: SelectChangeEvent<string>) => {
    setFlow(e.target.value);
  }
  const handleDropdownChangeQueue = (e: SelectChangeEvent<string>) => {
    setQueue(e.target.value);
  }
  const handleDropdownChangePhone = (e: SelectChangeEvent<string>) => {
    setPhone(e.target.value);
  }
  const handleDrowndownChangeFwt = (e: SelectChangeEvent<string>) => {
    setFwt(e.target.value);
  }
  const handleDropdownChangeLob = (e: SelectChangeEvent<string>) => {
    setLob(e.target.value);
  }
  const handleDateSelection = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.id === "endDatePicker" ? setEndDate(e.target.value) : setStartDate(e.target.value)
  }
  const handleDropdownChangeSchedule = (e: SelectChangeEvent<string>) => {
    setSchedule(e.target.value);
  }
  const updateBandwidth = (e: ChangeEvent<HTMLInputElement>) =>{
    setBandwidth(e.target.value);
  }
  const updatePriority = (e: ChangeEvent<HTMLInputElement>) => {
    setPriority(e.target.value);
  }
  const updateRetries = (e: ChangeEvent<HTMLInputElement>) => {
    setRetries(e.target.value);
  }
  const updateHumanMessage = (e:ChangeEvent<HTMLInputElement>) => {
    setHumanMessage(e.target.value)
  }
  const updateVoicemailMessage = (e:ChangeEvent<HTMLInputElement>) => {
    setVoicemailMessage(e.target.value)
  }
  const updateNoBeepMessage = (e:ChangeEvent<HTMLInputElement>) => {
    setNoBeepMessage(e.target.value)
  }
  const updateUnansweredMessage = (e:ChangeEvent<HTMLInputElement>) => {
    setUnansweredMessage(e.target.value)
  }

  return (
    <div>

      {/* H1 at page's top */}
      <Box sx={{display: 'flex'}}>
        <Typography variant='h4' sx={dishFontSx}>Create Campaign</Typography>
        <span className='GrowSpan' />
        {/* <Button onClick={myDebugger}>Debug</Button> */}
        <Button sx={buttonSecondary} onClick={cancelButton}>Cancel</Button>
        <Button variant='contained' sx={buttonPrimary} onClick={saveButton}>Save</Button>
      </Box>
        <br />
      <Grid container spacing={0}>
          {validationErrors.length > 0 &&
              <div className='ErrorCard'>
              <Grid xs={12} item={true}>
              <Card sx={cardLayoutExtraWideShort}>
                <CardContent>
                  <Typography sx={dishFontSxRed} variant='h5'>One or more values failed to validate. Please check for errors in the following places:</Typography>
                  <ul>
                  {validationErrors.map((err) => {
                    return (
                      <li key={err}><Typography sx={dishFontSxRed}>{err}</Typography></li>
                    )
                  })
                  }
                </ul>
                </CardContent>
              </Card>
            </Grid>
            </div>
          }
        <Grid xs={12} item={true}>
          {/* Campaign Details */}
          <div>
            <Card sx={cardLayoutWide}>
              <CardContent sx={{ minHeight: '12rem'}}>
                <Typography variant='h4' sx={dishFontSx}>Campaign Details</Typography>
                <Grid container spacing = {1} sx={{paddingTop: "3rem"}}>
                  <Grid xs={12}  item={true}>
                    <Box component="form">
                      <Typography sx={{fontFamily: dishFont}}>Campaign Name</Typography>
                      <TextField id="nameForm" sx={{minWidth: '16rem', width: "93%"}} value={campName} onChange={updateName}/>
                      <Typography sx={helpText}>{charCount} / 127</Typography>
                      <br />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg = {6} xl={2}  item={true}>
                    <Box component="form">
                      <Typography sx={{fontFamily: dishFont}}>Priority</Typography>
                      <TextField size="small" id="priorityForm" sx={{minWidth: '8rem', width: "90%", paddingTop:'0.5rem'}} value={priority} onChange={updatePriority} /> 
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={6} xl={2}  item={true}>
                    <Box component="form">
                      <Typography sx={{fontFamily: dishFont}}>Max Retries</Typography>
                      <TextField size="small" id="retriesForm" sx={{minWidth: '8rem', width: "90%", paddingTop:'0.5rem'}} value={retries} onChange={updateRetries} /> 
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={6} xl={2}  item={true}>
                  <FormControl  sx={{minWidth: '8rem', width: '90%'}}>

                    {/* <InputLabel id='fwtSelectLabel'>Fixed Width Format</InputLabel>  */}
                    <Typography sx={{fontFamily: dishFont, mb: '0.5rem'}}>Fixed Width Format</Typography>
                    {(!fwtListData || !Array.isArray(fwtListData) || fwtListData.length === 0) &&
                    
                    <Select
                    id='fwtSelect'
                    labelId='fwtSelectLabel'
                    label="Required"
                    value={fwt}
                    size='small'
                    MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}
                    onChange={handleDrowndownChangeFwt}
                    >
                     <MenuItem value={'NoData'}>No Data Response from API</MenuItem>
                    </Select>
                    }
                    {Array.isArray(fwtListData) &&
                      <Select
                      id='fwtSelect'
                      labelId='fwtSelectLabel'
                      label="Required"
                      value={fwt}
                      size='small'
                      MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}
                      onChange={handleDrowndownChangeFwt}
                      >
                        {fwtListData.map((el) => {
                          return(
                            <MenuItem key={el.campaignName} value={el.campaignName}>{el.campaignName}</MenuItem>
                          )
                        })}
                      </Select>
                    }      
                    </FormControl> 
                  </Grid>
                  <Grid xs={12} lg={6} xl={2}  item={true}>
                  <FormControl  sx={{minWidth: '8rem', width: '90%'}}>

                    {/* <InputLabel id='fwtSelectLabel'>Fixed Width Format</InputLabel>  */}
                    <Typography sx={{fontFamily: dishFont, mb: '0.5rem'}}>Line of Business</Typography>
                    {Array.isArray(availableLobs) &&
                      <Select
                      id='lobSelect'
                      labelId='lobSelectLabel'
                      label="Required"
                      value={lob}
                      size='small'
                      MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}
                      onChange={handleDropdownChangeLob}
                      >
                        {availableLobs.map((el) => {
                          return(
                            <MenuItem key={el} value={el}>{el}</MenuItem>
                          )
                        })}
                      </Select>
                    }      
                    </FormControl> 
                  </Grid>
                  <Grid xs={12} lg={6} xl={1.1}  item={true}>
                    <Typography sx={{fontFamily: dishFont, mb:'0.5rem'}}>Start Date</Typography>
                    <input className='DatePicker' type="date" id="startDatePicker" name="startDatePicker" ref={ref} onChange={handleDateSelection} value={startDate} />
                  </Grid>
                  <Grid xs={12} lg={6} xl={1.5}  item={true}>
                    <Typography sx={{fontFamily: dishFont, mb:'0.5rem'}}>End Date</Typography>
                    <input className='DatePicker' type="date" id="endDatePicker" name="endDatePicker" ref={ref} onChange={handleDateSelection} value={endDate} />
                  </Grid>
                  <Divider sx={{my: "5rem"}} />
                    <Grid item={true} xs={12}>
                      <Typography sx={{fontFamily: dishFont}}>Message on HUMAN_ANSWERED</Typography>
                      <TextField id="HUMAN"sx={{minWidth: '16rem', width: "93%"}} multiline onChange={updateHumanMessage} value={humanMessage} fullWidth/>
                    </Grid>            
                    <Grid item={true} xs={12}>
                      <Typography sx={{fontFamily: dishFont}}>Message on VOICEMAIL_BEEP</Typography>
                      <TextField id="VM"sx={{minWidth: '16rem', width: "93%"}} multiline onChange={updateVoicemailMessage} value={voicemailMessage} fullWidth/>
                    </Grid>            
                    <Grid item={true} xs={12}>
                      <Typography sx={{fontFamily: dishFont}}>Message on VOICEMAIL_NO_BEEP</Typography>
                      <TextField id="VMNB"sx={{minWidth: '16rem', width: "93%"}} multiline onChange={updateNoBeepMessage} value={noBeepMessage} fullWidth/>
                    </Grid>            
                    <Grid item={true} xs={12}>
                      <Typography sx={{fontFamily: dishFont}}>Message on AMD_UNANSWERED</Typography>
                      <TextField id="UNANSWERED"sx={{minWidth: '16rem', width: "93%"}} multiline onChange={updateUnansweredMessage} value={unansweredMessage} fullWidth/>
                    </Grid>            
                  {/*<Grid xs={12} lg={6} xl={2} item={true}>*/}
                  {/*  <Typography sx={{marginBottom: "0.5rem", fontFamily: dishFont}}>File Upload (Optional)</Typography>*/}
                  {/*  <input type='file' name='fileUpload' onChange={fileHandler} />*/}
                  {/*</Grid>*/}
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
{/*accept={".csv"}*/}

      {/* Outbound Config */}
      <Grid xs={12} xl={6} item={true}>
      <div className='CardHolder'>
        <Card sx={cardLayout}>
          <CardContent>
            <Typography variant='h4' sx={dishFontSx}>Outbound Configuration</Typography>
            <br />
            <Typography variant='h5' sx={dishFontSx}>Contact flow</Typography>
            <Typography sx={helpText}>Select an existing contact flow.</Typography>
              <InputLabel id = 'flowSelectLabel'>Contact flow</InputLabel>

              {(!cfListData || !Array.isArray(cfListData)|| cfListData.length === 0) &&
            <FormControl sx={{ width: '75%', maxWidth: '30rem'}}>
                <Select
                id='flowSelect'
                labelId='flowSelectLabel'
                label="Required"
                MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}            
                value={flow}
                onChange={handleDropdownChangeFlow}
              >
                <MenuItem value={'NoData'}>No Data Response from API</MenuItem>

                </Select>
                </FormControl>
              }
              {Array.isArray(cfListData) &&
                  <FormControl sx={{ width: '75%', maxWidth: '30rem'}}>
                  <Select
                  id='flowSelect'
                  labelId='flowSelectLabel'
                  label="Required"
                  MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}
                  value={flow}
                  onChange={handleDropdownChangeFlow}
                >
                    {cfListData.map((el:any) => {
                      return (
                        <MenuItem key={el.Name} value={el.ContactFlowId}>{el.Name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                }
              
              <Typography sx={helpText}> Required</Typography>

            <br />
            <Typography variant='h5' sx={dishFontSx}>Queue</Typography>
            <Typography sx={helpText}>Select an existing queue. Queues cannot be changed after campaign creation.</Typography>
              <InputLabel id = 'queueSelectLabel'>Queue</InputLabel>

              {(!queueListData || !Array.isArray(queueListData)|| queueListData.length === 0) &&
                  <FormControl sx={{width: '75%', maxWidth: '30rem'}}>
                  <Select
                    id='queueSelect'
                    labelId='queueSelectLabel'
                    label="Required"
                    MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}
                    value={queue}
                    onChange={handleDropdownChangeQueue}
                  >
                      <MenuItem value={'NoData'}>No Data Response from API</MenuItem>
                    </Select>
                    </FormControl>
                }
              {Array.isArray(queueListData) &&
                  <FormControl sx={{width: '75%', maxWidth: '30rem'}}>
                  <Select
                  id='queueSelect'
                  labelId='queueSelectLabel'
                  label="Required"
                  MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}
                  value={queue}
                  onChange={handleDropdownChangeQueue}>
                    {queueListData.map((el:any) => {
                      return (
                        <MenuItem key={el.Id} value={el.Id}>{el.Name}</MenuItem>
                      )
                    })}
                  </Select>
                 </FormControl>
                }
              <Typography sx={helpText}> Required</Typography>
            
              <br />
              <Typography variant='h5' sx={dishFontSx}>Phone Number</Typography>
              <Typography sx={helpText}>Select a phone number.</Typography>

            {phoneBool &&
                <>
                <InputLabel id = 'phoneSelectLabel'>Display Phone number</InputLabel>

                {(!phoneListData || !Array.isArray(phoneListData)|| phoneListData.length === 0) &&
                <FormControl sx={{width: '75%', maxWidth: '30rem'}}>
                  <Select
                    id='phoneSelect'
                    labelId='phoneSelectLabel'
                    label="Required"
                    MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}
                    value={phone}
                    onChange={handleDropdownChangePhone}
                  >
                      <MenuItem value={'NoData'}>No Data Response from API</MenuItem>
                    </Select>
                    </FormControl>
                }
                
                {Array.isArray(phoneListData) &&
                  <FormControl sx={{width: '75%', maxWidth: '30rem'}}>
                  <Select
                  id='phoneSelect'
                  labelId='phoneSelectLabel'
                  label="Required"
                  MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}
                  value={phone}
                  onChange={handleDropdownChangePhone}
                >
                    {phoneListData.map((el:any) => {
                      return (
                        <MenuItem key={el.Id} value={el.phoneNumber}>{el.phoneNumber}</MenuItem>
                      )
                    })}
                  </Select>
                  </FormControl>
                }
         
                
                <Typography sx={helpText}> Required</Typography>
              </>
            }
            <Typography variant='h6' sx={dishFontSx}>Answering machine detection</Typography>
            <Typography sx={helpText}> Detect whether the call was answered by human or by an answering machine</Typography>
            <Typography sx={{...helpText, mb: "29px"}}> <Checkbox onChange={toggleDetection} checked={machineDetection}/> Enable answering machine detection</Typography>
            {!phoneBool &&
              <div className='SpaceDiv'></div>
            }
          </CardContent>
        </Card>
      </div>
      </Grid>
      <Grid xs={12} xl={6} item={true}>
          {/* Dialer Config */}
          <div className='CardHolder'>
            <Card sx={cardLayout}>
              <CardContent>
                <Typography variant='h4' sx={dishFontSx}>Schedule configuration</Typography>
                <br />
                <Typography variant='h5' sx={dishFontSx}>Dial Schedule</Typography>
                <Typography sx={helpText}>Select a Dial Schedule</Typography>
                <InputLabel id = 'scheduleSelectLabel'>Schedule</InputLabel>
                  {(!scheduleListData || !Array.isArray(scheduleListData)|| scheduleListData.length === 0) &&
                <FormControl sx={{width: '75%', maxWidth: '30rem'}}>
                    <Select
                    id='scheduleSelect'
                    labelId='scheduleSelectLabel'
                    label="Required"
                    MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}            
                    value={schedule}
                    onChange={handleDropdownChangeSchedule}
                  >
                    <MenuItem value={'NoData'}>No Data Response from API</MenuItem>

                    </Select>
                    </FormControl>
                  }
                  
                    {(Array.isArray(scheduleListData) && scheduleListData.length > 0) &&
                      <FormControl sx={{width: '75%', maxWidth: '30rem'}}>

                      <Select
                      id='scheduleSelect'
                      labelId='scheduleSelectLabel'
                      label="Required"
                      MenuProps={{ PaperProps: {sx: {maxHeight: "20rem"}}}}
                      value={schedule}
                      onChange={handleDropdownChangeSchedule}
                    >
                        {scheduleListData.map((el:any) => {
                          return (
                              <MenuItem key={el.scheduleName} value={el.scheduleName}>{el.scheduleName}</MenuItem>
                          )
                        })}
                      </Select>
                      </FormControl>
                    }
                <Typography sx={helpText}> Required</Typography>
                <br />
                {/*<Typography variant='h6' sx={dishFontSx}>Recurring campaign</Typography>*/}
                {/*<Typography sx={helpText}> <Checkbox disabled onChange={toggleRecurring} checked={recurring}/> Toggle recurring campaign</Typography>   */}
                <Typography variant='h4' sx={dishFontSx}>Dialer configuration</Typography>
                <Typography sx={helpText}>Select a dialer type.</Typography>
                
                <Box sx={bubbleCheck}>
                  <Checkbox icon={<PanoramaFishEye />} checkedIcon={<Lens />} checked={swapBool} onClick={swapSwapBool} disableRipple/>
                  <Box sx={{paddingLeft: '1rem'}}>
                  <Typography variant='h6' sx={dishFontSx}>Predictive</Typography>
                  <Typography sx={helpTextShort}>In this mode, pacing of calls is based on predicted agent availability and near-real time statistics. Use this mode for campaigns where maximizing agent talk time is the most important metric.</Typography>
                  </Box>
                </Box>
                <br />
                <Box sx={bubbleCheck}>
                  <Checkbox icon={<PanoramaFishEye />} checkedIcon={<Lens />} checked={!swapBool} onClick={swapSwapBool} disableRipple/>
                  <Box sx={{paddingLeft: '1rem'}}>
                    <Typography variant='h6'  sx={dishFontSx}>Progressive</Typography>
                    <Typography sx={helpText}>In this mode, the dialer doesn't dial until the agent becomes available. Use this mode when reaching customers is more important than optimizing agent talk time.</Typography>
                  </Box>
                </Box>
                <br />
                <Typography variant='h5'  sx={dishFontSx}>Bandwidth allocation</Typography>
                <Typography sx={helpText}>This is a percentage of messages available in the queue that will be considered for a particular campaign at the time of dialing.</Typography>
                <TextField id="nameForm" label="1 - 100%" sx={{minWidth: '24rem'}} value={bandwidth} onChange={updateBandwidth}/>
                <Typography sx={helpTextShort}> Required</Typography>
              </CardContent>
            </Card>  
          </div>
      </Grid>
      </Grid>
          <BasicSnackbar
              open={alertOpen}
              severity={alertSeverity}
              message={alertMessage}
              onClose={() => setAlertOpen(false)}
          />
    </div>
  );
};

export default CampaignCreatePage;