import { Autocomplete, FormControl, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import 'react-dropdown/style.css';
import * as React from "react";


interface Props {
    options: Option[];
    onChange: (template: any) => void;
    selected: Option;
    label: string;
    
}
function TemplatePicker(props: Props) {
    const [_value, _setValue] = useState<Option | null>(null);

    useEffect(() => {
        _setValue(props.selected)
    }, [props.selected])

    const handleChange = (event: any, value: any) => {
        if (value) { 
            props.onChange(value);
            _setValue(value)
        }
    }

    return (
        <div>
           

            {(!props.options || !Array.isArray(props.options) || props.options.length === 0) &&
                <FormControl sx={{maxWidth: '30rem' }}>
                    <Select
                        id={`select${props.label}` }
                        labelId={`${props.label}SelectLabel`}
                        MenuProps={{ PaperProps: { sx: { maxHeight: "20rem" } } }}
                        value={""}
                        onChange={props.onChange}
                    >
                        <MenuItem value={'NoData'}>No Data Response from API</MenuItem>
                    </Select>
                </FormControl>
            }
            {Array.isArray(props.options) &&
                <><FormControl sx={{ maxWidth: '30rem' }}>
                <Autocomplete
                    id={`searchable${props.label}`}
                    sx={{ width: 300 }}
                    options={props.options}
                    onChange={handleChange}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    autoHighlight
                    renderInput={(params) => <TextField
                        {...params}
                        label={props.label}
                    />}
                    value={_value}
                />
                </FormControl></>
            }
        </div>
    )
}

export default TemplatePicker;