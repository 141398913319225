import { AppBar, InputLabel, MenuItem, Select, SelectChangeEvent, Toolbar, Typography } from "@mui/material";
import '../../assets/styles/stylesheet.css'
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { useContext, useEffect, useState } from "react";
import { dishFont, dishFontSx } from "../../assets/styles/sxProp";
import AppContext from "../../context/AppContext";
import { getInstances, getUser } from "../../api/api";
import BasicSnackbar from "../common/Snackbar";

const Topbar = () => {


    const { instance, setInstance, setLob, lob, setUserRole, setUserGroup, userGroups, userGroup }: any = useContext(AppContext)
    const [myData, setMyData] = useState<any>(null);
    const [details, setDetails] = useState<any | null>(null);
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [instanceName, setInstanceName] = useState<string>("");



    useEffect(() => {
    async function setData() {
        const response = await getInstances();
        setMyData(response)
        if(instance === null) 
          { 
            if (Array.isArray(response) && response.length > 0) {  
              const instanceNames = response.map((el) => el.instanceName);
              
              if (instanceNames.indexOf('dish-cc') !== -1) {
                let index = instanceNames.indexOf('dish-cc');
                setInstance(response[index].instanceId)
                setInstanceName(response[index].instanceName);
              } else {                
                setInstance(response[0].instanceId);
                setInstanceName(response[0].instanceName);
              }
            }
          }
    }
      setData()
    }, []);

    useEffect(() => {
        if (userGroups?.some((str: any) => str.includes("Administrator"))) { setUserGroup("Admin") }
        else if (userGroups?.some((str: any) => str.includes("AutoMessage"))) { setUserGroup("AutoMsg") }
        else { setUserGroup("Other") }
        
        setDetails([]);
        userGroups.forEach((item: string) => {
            if (item.indexOf("Administrator") === -1 && item.indexOf("AutoMessage") === -1 ) {
                getUser(item).then((res) => { setDetails((details: any) => [...details, res]) });
            }
        })
    }, [])

    useEffect(() => {
        if (details !== null && details.length >= 1) {
            setLob(details[0].lob)
            setUserRole(details[0].access)
        }
    }, [details, setLob, setUserRole])

    const handleChange = (e: SelectChangeEvent<string>) => {
        setInstance(e.target.value)
        setAlertSeverity("info")
        setAlertMessage("Instance has changed")
        setOpen(true);
    }

    const handleLobChange = (e: SelectChangeEvent<string>) => {
        const lobDets = details.find((item: any) => item.lob === e.target.value) as any;
        setUserRole(lobDets.access)
        setLob(lobDets.lob);
        setAlertSeverity("info")
        setAlertMessage("Line of Business has changed")
        setOpen(true);
    }

    //const handleClose = (event: Event, reason: string) => {
    //    if (reason === 'clickaway') {
    //        return
    //    }
    //    setOpen(false);
    //};
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "1",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color
      }}
      >
          <Toolbar> 
        <Typography variant="h6" sx={dishFontSx}>
                  Campaign Manager
              </Typography>
              {userGroup === "Admin" ? 

              // UNCOMMENT ONCE READY FOR LOB AND SMS/EMAIL
              //<span className="GrowSpan" />
              //<InputLabel sx={{ color: "white", fontFamily: dishFont }} id="lobSelectLabel">Line of Business</InputLabel>
              //{(!details || !Array.isArray(details) || details.length === 0) &&

              //    <Select
              //        labelId="lobSelectLabel"
              //        id="lobeSelect"
              //        value={""}
              //        onChange={handleLobChange}
              //        sx={{ minWidth: "12rem", marginLeft: "2rem", backgroundColor: "white", fontFamily: dishFont }}
              //    >
              //        <MenuItem value={"NoData"}>No Data Response from API</MenuItem>

              //    </Select>
              //}
              //{ Array.isArray(details) &&
              //    <Select
              //        labelId="lobSelectLabel"
              //        id="lobeSelect"
              //        value={lob || ''}
              //        onChange={handleLobChange}
              //        sx={{ minWidth: "12rem", marginLeft: "2rem", backgroundColor: "white", fontFamily: dishFont }}
              //    >
              //        {details.map((el: any) => {
              //            return (
              //                <MenuItem key={el.lob} value={el.lob || ""}>{el.lob}</MenuItem>
              //            )
              //        })}
              //    </Select>
              //  }
                 <>   <span className="GrowSpan" />
                    <InputLabel sx={{ color: "white", fontFamily: dishFont }} id="instanceSelectLabel">Instance</InputLabel>
                    {myData &&
                        <Select
                            labelId="instanceSelectLabel"
                            id="instanceSelect"
                            value={instance}
                            onChange={handleChange}
                            sx={{ minWidth: "12rem", marginLeft: "2rem", backgroundColor: "white", fontFamily: dishFont }}
                        >
                            {myData.map((el: any) => {
                                return (
                                    <MenuItem key={el.instanceId} value={el.instanceId}>{el.instanceName}</MenuItem>
                                )
                            })}
                        </Select>
                }
        
        
                  </>
                  : userGroup === "AutoMsg" ? 

                      <>

                        <span className="GrowSpan" />
                        <InputLabel sx={{color: "white", fontFamily: dishFont}} id="instanceSelectLabel">Instance</InputLabel>
                        {myData &&
                          <Select
                          labelId="instanceSelectLabel"
                          id="instanceSelect"
                          value={instance}
                          onChange={handleChange}
                          sx={{ minWidth: "12rem", marginLeft: "2rem", backgroundColor: "white", fontFamily: dishFont}}
                        >
                              {myData.map((el: any) => {
                                  return (
                                      <MenuItem key={el.instanceId} value={el.instanceId}>{el.instanceName}</MenuItem>
                                  )
                              }) }
                        </Select>
                        }
                      </>
                      :

                      userGroup === "Other" ? 
                      <>
                              <span className="GrowSpan" />
                              <InputLabel sx={{ color: "white", fontFamily: dishFont }} id="lobSelectLabel">Line of Business</InputLabel>
                              {(!details || !Array.isArray(details) || details.length === 0) &&

                                  <Select
                                      labelId="lobSelectLabel"
                                      id="lobeSelect"
                                      value={""}
                                      onChange={handleLobChange}
                                      sx={{ minWidth: "12rem", marginLeft: "2rem", backgroundColor: "white", fontFamily: dishFont }}
                                  >
                                      <MenuItem value={"NoData"}>No Data Response from API</MenuItem>

                                  </Select>
                              }
                              {Array.isArray(details) &&
                                  <Select
                                      labelId="lobSelectLabel"
                                      id="lobeSelect"
                                      value={lob || ''}
                                      onChange={handleLobChange}
                                      sx={{ minWidth: "12rem", marginLeft: "2rem", backgroundColor: "white", fontFamily: dishFont }}
                                  >
                                      {details.map((el: any) => {
                                          return (
                                              <MenuItem key={el.lob} value={el.lob || ""}>{el.lob}</MenuItem>
                                          )
                                      })}
                                  </Select>
                              }
                      </> : <></>
        }



          </Toolbar>
          <BasicSnackbar
              open={open}
              onClose={() => { setOpen(false) }}
              severity={alertSeverity}
              message={alertMessage}
          />
    </AppBar>
  );
};

export default Topbar;