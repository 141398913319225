//@ts-nocheck
import { useEffect, useState } from 'react'
import { getCampaignLogs } from '../../api/api';
import '../../assets/styles/stylesheet.css'
import { cardLayoutExtraWide, dishFontSx } from '../../assets/styles/sxProp';
import { Card, CardContent, InputLabel, TextField, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Divider, Typography } from '@mui/material'
import { dishFont, dishFontSxHover } from '../../assets/styles/sxProp';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { ChangeLog, dateConvertor } from './CampaignActivePage';

export default function CampaignLogsPage() {

    const [logList, setLogList] = useState<any>();
    const [search, setSearch] = useState<string>("");
    const [selected, setSelected] = useState<string>("");
    const [data, setData] = useState<ChangeLog[]>([]);


    const handleRowSelect = (e) => {
        const rowID = e.target.id
        if (rowID) {
            const elementPosition = logList.findIndex((item,index) => item.campaignName+index === rowID)
            setSelected(logList[elementPosition])
            setData(logList[elementPosition].changeLogs)
        }
    }

    const columns = [
        {
            header: 'Change',
            accessorKey: 'change',
            size: "200"
        },
        {
            header: 'User',
            accessorKey: 'user',
            size: "200"
        },
        {
            header: 'Change Date',
            accessorKey: 'changeDateTime',
            size: "200",
            accessorFn: (row) => dateConvertor(row.changeDateTime.substring(0, row.changeDateTime.length - 4))
        }
    ]


    useEffect(() => {
        const updateList = async () => {
            const data = await getCampaignLogs();
            setLogList(data);
        }
        updateList();
    }, [])

    useEffect(() => {
        //console.log('loglist use effect: ', logList);
    }, [logList])

    useEffect(() => {
        //console.log('selected: ', selected);
    }, [selected])

    const searchFunction = (logs) => {
        if (search === "" || search.length === 0 || !search) return logs;
        else
            return logs.filter((log) => {
                let cmlc = log.key.toLowerCase();
                let cmdt = log.props.id.toLowerCase();
            let sclc = search.toLowerCase();
            return (cmlc.includes(sclc) || cmdt.includes(sclc))
        })
    }

    return (
        <>
            <Typography variant='h4' sx={dishFontSx}>Campaign Change History</Typography>
            <br />
            <Card sx={cardLayoutExtraWide}>
                <CardContent>
                    <div className='SearchDiv'>
                        <InputLabel id="searchLabel">Search</InputLabel>
                        <TextField
                            id='searchBox'
                            value={search}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSearch(e.target.value)
                            }}></TextField>
                        <br />
                        <br />
                        <Divider />
                        <br />
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} xl={2} sx={{ borderLeft: "1px solid lightgray" }}>
                            {logList &&
                                <TableContainer sx={{ minWidth: "14rem", maxHeight: "76vh" }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontFamily: dishFont, fontSize: "1.5em", width: "14rem" }}>Campaign</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {searchFunction(logList.map((log, index) => { 
                                                return (<TableRow key={`${log.campaignName}${index}`} id={`${log.date}`} > 
                                                    <TableCell sx={dishFontSxHover} id={`${log.campaignName}${index}`} onClick={handleRowSelect}>{log.campaignName}
                                                             <br /> Log Date: {log.date}</TableCell> 
                                                        </TableRow> )
                                            }))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Grid>
                        {(selected && (Array.isArray(data) && data.length > 0)) &&
                            <Grid item xs={12} xl={9} sx={{ borderLeft: "1px solid lightgray", pl: '1rem' }}>
                                <>
                                    <br />
                                    <Typography variant='h4'>{selected.campaignName} - {selected.date}</Typography>
                                    <br />
                                    <MaterialReactTable
                                        displayColumnDefOptions={{
                                            'mrt-row-actions': {
                                                muiTableHeadCellProps: {
                                                    align: 'center',
                                                },
                                                size: 120,
                                            },
                                        }}
                                        columnResizeMode=''
                                        columns={columns}
                                        data={data}
                                        enableColumnFilters={true}
                                        enableDensityToggle={false}
                                        enableFullScreenToggle={false}
                                        enableHiding={false}
                                        //Top tool bar with add and search
                                        enableTopToolbar={true}
                                        enableColumnActions={false}
                                        enableBottomToolbar={true}
                                        enableSorting={true}
                                        initialState={{
                                            sorting: [
                                                {
                                                    id: 'changeDateTime',
                                                    desc: true
                                                }
                                            ],
                                            pagination: {
                                                pageIndex: 0,
                                                pageSize: 100
                                            },
                                        }}
                                        muiTableHeadCellProps={{
                                            sx: dishFontSx
                                        }}
                                        muiTableBodyCellProps={{
                                            sx: dishFontSx
                                        }}
                                    />
                                </>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}