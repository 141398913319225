import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, Stack, TextField, TextareaAutosize, Tooltip, Typography } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row, MRT_RowSelectionState } from 'material-react-table';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { buttonPrimary, buttonSecondary, dishFontSx, errorText } from '../../assets/styles/sxProp';
import BasicSnackbar from '../../components/common/Snackbar';
import '../../assets/styles/stylesheet.css';
import { postNewOverrideRestriction, deleteOverrideRestriction, getOverrideData } from '../../api/api';
import assets from '../../assets';
import LoadingModal from '../../components/common/LoadingModal';
import { utcConvertor } from '../reporting/CampaignFilesPage';
import AppContext from '../../context/AppContext';
type Props = {};

type Override = {
    id: number;
    overrideType: string;
    overrideValue: string;
    startTime: string;
    lastUpdateBy: string;
    lastUpdateDate: string;
}

const SystemOverride = (props: Props) => {

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState<Override[]>([]);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [deleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [overrideValues, setOverrideValues] = useState<string[]>([]);
    const [overrideIds, setOverrideIds]= useState<string[]>([]);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [loading, setLoading] = useState<boolean>(false);
    const { username } = useContext<any>(AppContext);

    const handleCreateNewRow = async (values: string) => {
        setLoading(true)
        try {
            let user = "";
            username === "" ? user = "SYSTEM" : user = username.substring(5);
            const responseJson = await postNewOverrideRestriction(values, user);
            setLoading(false)
            setAlertSeverity("success")
            setAlertMessage("Manual Override restriction added.")
            setAlertOpen(true);
            fetchOverrideData();
            return responseJson
        } catch (err) {
            console.log(err)
            setLoading(false)
            setAlertSeverity("error")
            setAlertMessage("Unable to add Manual Override restriction!")
            setAlertOpen(true);
        }
            fetchOverrideData();
    };

    const handleDeleteMany = async (bulkDeleteList?: string[]) => {
        
        let deleteList: string[] = [];
        if (bulkDeleteList && bulkDeleteList.length > 0) {
            deleteList = [...bulkDeleteList]
        } else deleteList = Object.keys(rowSelection);

        if (window.confirm(`Are you sure you wish to delete the ${deleteList.length} selected record${deleteList.length === 1 ? '' : 's'}?`)){
            setLoading(true)
            try {
                const promises = deleteList.map((el) => deleteOverrideRestriction(el));
                let results = await Promise.all(promises);
                if (results) {
                    setLoading(false)
                    fetchOverrideData();
                    setRowSelection({})
                    setAlertSeverity("success")
                    setAlertMessage(`Override restriction${deleteList.length === 1 ? '' : 's'} removed.`)
                    setAlertOpen(true);
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
                setAlertSeverity("error")
                setAlertMessage(`Unable to remove override restriction${deleteList.length === 1 ? '' : 's'}.`)
                setAlertOpen(true);
                return (err)
            }   
        }



    }

    const columns = useMemo<MRT_ColumnDef<Override>[]>(
        () => [
            {
                accessorKey: 'overrideValue', //normal accessorKey
                header: 'Value',
            },
            {
                accessorKey: 'startTime', //normal accessorKey
                header: 'Start Time',
            },
            {
                accessorKey: 'overrideType',
                header: 'Override',
            },
            {
                accessorKey: 'lastUpdateBy',
                header: 'Created By',
            },
            {
                accessorKey: 'lastUpdateDate',
                header: 'Created Date',
            }
        ],
        [],
    );

    const url = 'https://' + window.location.toString().split('/')[2];


    const cleanData = (fd: Override[]) => {
        let clean: Override[] = [];
        let listValues: string[] = [];
        let listIds: string[] = [];
        if (Array.isArray(fd) && fd.length > 0){
            fd.forEach(function (item) {
                let newItem: Override = {
                    id: item.id,
                    overrideType: item.overrideType,
                    overrideValue: item.overrideValue,
                    startTime: utcConvertor(item.startTime.replace("T", " ")),
                    lastUpdateBy: item.lastUpdateBy,
                    lastUpdateDate: item.lastUpdateDate
                }
                clean.push(newItem);
                listValues.push(newItem.overrideValue);
                listIds.push(newItem.id.toString());

            })
            setTableData(clean);
            setOverrideValues(listValues);
            setOverrideIds(listIds);
        } else {
            setTableData([])
            setOverrideValues([]);
            setOverrideIds([]);
        }

    };


    const fetchOverrideData = async () => {
        const data = await getOverrideData();
        cleanData(data);
    }

    useEffect(() => {
        fetchOverrideData();
    }, []);

    useEffect(() => {
        if (loading === true) console.log("true")
        else console.log("false")
    }, [loading])

    return (
        <>
        <LoadingModal open={loading} />
        <br />
            <div className='FortyPercentWidth'>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={tableData}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableTopToolbar={true}
                    enableColumnActions={false}
                    enableBottomToolbar={true}
                    enableSorting={true}
                    enableRowSelection={true}
                    getRowId={(row) => row.id.toString()}
                    onRowSelectionChange={setRowSelection}
                    state={{ rowSelection }}
                    initialState={{
                        sorting: [
                          {
                            id:'startTime',
                            desc: false
                          }
                        ],
                        pagination: {
                            pageIndex: 0,
                            pageSize: 100
                          },
                      }}
                    muiTableHeadCellProps={{
                        sx: dishFontSx
                    }}
                    muiTableBodyCellProps={{
                        sx: dishFontSx
                    }}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <>
                            <Button
                                sx={buttonPrimary}
                                onClick={() => setCreateModalOpen(true)}
                                variant="contained"
                            >
                                Create New Restriction
                            </Button>
                        </>
                    )}
                    renderBottomToolbarCustomActions={() => (
                        <div>
                            <Button color='error' variant='contained' onClick={() => handleDeleteMany()}>Delete Selected</Button>
                            &nbsp;
                            <Button color='error' variant='contained' onClick={() => setDeleteAlertOpen(true)}>Delete List</Button>
                        </div>
                    )}
                />
                <CreateNewAccountModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => {
                        setCreateModalOpen(false);
                        fetchOverrideData();
                    }}
                    onSubmit={handleCreateNewRow}
                    overrideValues={overrideValues}
                />
                <BulkDeleteEventsModal
                    deleteOpen={deleteAlertOpen}
                    onClose={() => {
                        setDeleteAlertOpen(false);
                        fetchOverrideData();
                    }}
                    onSubmit={handleDeleteMany}
                    overrideValues={overrideValues}
                    overrideIds={overrideIds}
                    />
            </div>
            <BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)}
            />
        </>
    );
};

interface CreateModalProps {
    columns: MRT_ColumnDef<Override>[];
    onClose: () => void;
    onSubmit: (values: string) => void;
    open: boolean;
    overrideValues: string[]
}

interface DeleteModalProps {
    deleteOpen: boolean
    onClose: () => void;
    onSubmit: (values: string[]) => void;
    overrideValues: string[]
    overrideIds: string[]
}

export const CreateNewAccountModal = ({
    open,
    columns,
    onClose,
    onSubmit,
    overrideValues
}: CreateModalProps) => {
    const [values, setValues] = useState<any>(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    const [validationError, setValidationError] = useState<boolean>(false);
    const [errorEntries, setErrorEntries] = useState<string[]>([]);
    const [dupEntries, setDupEntries] = useState<string[]>([]);

    const handleCancel = () => {
        setValidationError(false)
        setValues(() =>
            columns.reduce((acc, column) => {
                acc[column.accessorKey ?? ''] = '';
                return acc;
            }, {} as any))
        onClose()
    }

    const handleSubmit = () => {
        const areaZipMatch = /^(\d{3}|\d{5})$/
        let invalidList = false;
        const dirtyList:string[] = values.overrideValue.split('\n');;
        const cleanList:string[] = [];
        const errorEntries: string[] = [];
        const dupEntries: string[] = [];
        dirtyList.forEach(function (item: string) {
            let res = areaZipMatch.test(item.trim())

            if (item.trim() === "" || !item.trim()) {
            }
            else if (!res) { 
                errorEntries.push(item.trim())
                invalidList = true;
             }
            else {
                if ((overrideValues.indexOf(item.trim()) !== -1) || cleanList.indexOf(item.trim()) !== -1) {
                    dupEntries.push(item.trim())
                } else if (item.trim().length === 3) {
                    cleanList.push(item.trim())
                } else if (item.trim().length === 5){
                    cleanList.push(item.trim())
                } else {
                    errorEntries.push(item.trim())
                }
            }
        })
        setErrorEntries(errorEntries);
        setDupEntries(dupEntries);
        setValidationError(false);
        
        if (!values.overrideValue || errorEntries.length > 0 || dupEntries.length > 0) {
            return setValidationError(true);
        }
        else if (invalidList || cleanList.length < 1) {
            return setValidationError(true);
        } else {
            setValidationError(false);
            values.overrideValue = cleanList.toString();

            onSubmit(cleanList.toString());
            onClose();
            setValues(() =>
                columns.reduce((acc, column) => {
                    acc[column.accessorKey ?? ''] = '';
                    return acc;
                }, {} as any))
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create New Override</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => {
                            if (column.accessorKey === "id") {
                                return (<></>)
                            }
                            else if (column.accessorKey === "overrideValue") {
                                return (
                                    <>
                                        <TextareaAutosize
                                            key={column.accessorKey}
                                            minRows={10}
                                            aria-multiline={true}
                                            placeholder="List of Area Codes & Zip Codes 
                                            ###
                                            ###
                                            ###
                                            #####
                                            #####
                                            #####"
                                            name={column.accessorKey}
                                            onChange={(e) =>
                                                setValues({ ...values, [e.target.name]: e.target.value })
                                            }
                                        />
                                        {validationError &&
                                            <>
                                            <Typography sx={errorText}>One or more entries failed to validate. This could be because they already exist, or don't fit the format of a Zip or Area code. Please resolve the following items and then try again: </Typography>
                                            {dupEntries.length > 0 && 
                                                <Typography sx={errorText}>The following values were duplicates: {dupEntries.map((el) => `${el}, `)} </Typography>
                                            }
                                            {errorEntries.length > 0 && 
                                                <Typography sx={errorText}>The following values were invalid: {errorEntries.map((el) => `${el}, `)} </Typography>
                                            }                                     
                                            </>
                                        }
                                    </>
                                )
                            }
                        })}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const BulkDeleteEventsModal = ({
    deleteOpen,
    onClose,
    onSubmit,
    overrideValues,
    overrideIds
}: DeleteModalProps) => {

    const [deleteList, setDeleteList] = useState<string>("")
    const [validationError, setValidationError] = useState<boolean>(false);
    const [errorEntries, setErrorEntries] = useState<string[]>([]);
    const [dupEntries, setDupEntries] = useState<string[]>([]);
    const [missingEntries, setMissingEntries] = useState<string[]>([]);

    const handleCancel = () => {
        setDeleteList("");
        setDupEntries([]);
        setMissingEntries([]);
        setErrorEntries([]);
        setValidationError(false);
        onClose();
    };

    const handleSubmit = () => {
        const areaZipMatch: RegExp = /^(\d{3}|\d{5})$/
        let invalidList: boolean = false;
        const dirtyList:string[] = deleteList.split('\n');;
        const cleanList:string[] = [];
        const errorEntries: string[] = [];
        const dupEntries: string[] = [];
        const missingEntries: string[] = [];
        setDupEntries([]);
        setMissingEntries([]);
        setErrorEntries([]);

        dirtyList.forEach(function (item: string) {
            let res = areaZipMatch.test(item.trim())

            if (item.trim() === "" || !item.trim()) {
            }
            else if (!res) { 
                errorEntries.push(item.trim())
                invalidList = true;
             }
            else {
                if ((overrideValues.indexOf(item.trim()) === -1)) {
                } else if (  cleanList.indexOf(item.trim()) !== -1){
                } else if (item.trim().length === 3) {
                    cleanList.push(item.trim())
                } else if (item.trim().length === 5){
                    cleanList.push(item.trim())
                } else {
                    errorEntries.push(item.trim())
                }
            }
        })
        const cleanIds = cleanList.map((el) => {
            return overrideIds[overrideValues.indexOf(el)]
        })
        setErrorEntries(errorEntries);
        setDupEntries(dupEntries);
        setMissingEntries(missingEntries);
        setValidationError(false);
        
        if (!deleteList || errorEntries.length > 0 || dupEntries.length > 0 || missingEntries.length > 0) {
            return setValidationError(true);
        }
        else if (invalidList || cleanList.length < 1) {
            return setValidationError(true);
        } else {
            setValidationError(false);

            onSubmit(cleanIds);
            setDeleteList("");
            onClose();
        }
        onClose();
    }

    return (
        <Dialog open={deleteOpen}>
            <DialogTitle textAlign="center">
                Delete Overrides
            </DialogTitle>
            <DialogContent>
                <Stack
                    sx={{
                        width: '100%',
                        minWidth: { xs: '300px', sm: '360px', md: '400px' },
                        gap: '1.5rem',
                    }}
                >
                    <TextareaAutosize
                        key={"deleteArea"}
                        minRows={10}
                        aria-multiline={true}
                        placeholder="List of Area Codes & Zip Codes 
                        ###
                        ###
                        ###
                        #####
                        #####
                        #####"
                        name={"column.accessorKey"}
                        value={deleteList}
                        onChange={(e) =>
                            {
                             setDeleteList(e.target.value);
                            }
                        }
                    />
                    {validationError &&
                                            <>
                                            <Typography sx={errorText}>One or more entries failed to validate. Please resolve the following items: </Typography>
                                            {missingEntries.length > 0 &&
                                                <Typography sx={errorText}>The following values do not exist in the database to be deleted: {missingEntries.map((el) => `${el}, `)}</Typography>
                                            }
                                            {errorEntries.length > 0 && 
                                                <Typography sx={errorText}>The following values were invalid: {errorEntries.map((el) => `${el}, `)} </Typography>
                                            }                                     
                                            </>
                                        }
                </Stack>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Button color='error' sx={buttonPrimary} onClick={handleSubmit} variant="contained">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SystemOverride;