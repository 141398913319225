const colorConfigs = {
  sidebar: {
    bg: "#292938",
    bgDisabled: "#9d2235",
    color: "#FFFFFF",
    hoverBg: "#525259",
    hoverBgDisabled: "#f01446",
    activeBg: "#525259",
    activeBgDisabled: "#f01446"
  },
  topbar: {
    bg: "#292938",
    bgDisabled: "#9d2235",
    color: "#FFFFFF"
  },
  mainBg: "#F5F5F6"
};

export default colorConfigs;

