//@ts-nocheck
import { useEffect, useContext } from "react"
import AppContext from "../context/AppContext";
import { setTokenToLocalStorage, stripTokenFromUrl } from "./authJwt";

export function AuthProvider({ children }) {

    const { setUsername, setUserGroup, userGroups, setUserGroups } = useContext(AppContext);

    useEffect(() => {

        let url = "https://campaignmanager.auth.us-west-2.amazoncognito.com/oauth2/authorize?response_type=token&identity_provider=Okta&client_id=6b7dqqf4t78r5oh6lfq25c71os&redirect_uri=https://campaign-manager.dish.com/&scope=email+openid";

        //let url = "https://campaignmanagernp.auth.us-west-2.amazoncognito.com/oauth2/authorize?response_type=token&identity_provider=Okta&client_id=6pgb9kfke516ta38mje3la2u08&redirect_uri=https://vcynnruzjn.us-west-2.awsapprunner.com/&scope=email+openid";

        const authenticateUser = async () => {

            const authResponse: JwtResponse = await setTokenToLocalStorage();

            if (authResponse.type === "ERROR") {
                console.log(authResponse.type);
                console.log(authResponse.message);
                setUserGroup("NoAuth");
                setTimeout(() => {
                    window.location.href = url;
                }, 3000)

            } else if (authResponse.type === "SUCCESS") {
                console.log(authResponse.type);
                setUsername(authResponse.username);
                setUserGroups(authResponse.group)
            }
        }

        //Uncomment the line below for deployment builds
        authenticateUser();

       //Uncomment lines below for local dev:
       //const successResponse: JwtResponse = {
       //     type: "SUCCESS",
       //    username: "okta_kyle.fusco@dish.com ",
       //    group: ["APP-CampaignManager-Prod_Administrator"]
       // };
       //group: ["APP-CampaignManager-NP_EmailSMSPSHReadOnly_DBS", "APP-CampaignManager-NP_Administrator", "APP - CampaignManager - NP_OBAgentAutoMessageContentMgr", "APP-CampaignManager-NP_EmailSMSPSHContentMgr_OnTech", "APP-CampaignManager-NP_Administrator", "APP-CampaignManager-NP_OBAgentAutoMessageContentMgr", "APP-CampaignManager-NP_EmailSMSPSHContentMgr_OnTech"]
       //setUserGroups(successResponse.group)
       //setUsername(successResponse.username);

    },[])

    useEffect(() => {
    }, [userGroups])

    if (userGroups?.some((str) => str.includes("CampaignManager"))) {
        stripTokenFromUrl();
        return (
        <div>
            {children}
        </div>
    )
    } else {
        return (
            <h1>Please wait for authentication...</h1>
        )
    }
}