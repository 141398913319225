//@ts-nocheck
import { Button, Card, CardContent, Checkbox, FormControl, Grid, TextField, Typography } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { ChangeEvent, useMemo, useState, useEffect, useContext } from 'react';
import { buttonPrimary, cardLayout, cardLayoutExtraWide, dishFont, dishFontSx, dishFontSxRed, helpText } from '../../assets/styles/sxProp';
import '../../assets/styles/stylesheet.css'
import { Schedule, Week, postNewSchedule, fetchSchedules } from '../../api/api';
import BasicSnackbar from '../../components/common/Snackbar';
import AppContext from '../../context/AppContext';

type Props = {};

const weekArray = (el: Week) => {
  const activeDayArray: string[] = []
  for (const n in el) {
    if(el[n] === "true" || el.n === "true") {
      activeDayArray.push(`${n[0].toUpperCase()}${n[1]}`)
      activeDayArray.push(', ')
    }
  }
  activeDayArray.pop();
  return activeDayArray
}

const SystemSchedule = (props: Props) => {
    const [tableData, setTableData] = useState<Schedule[]>([]);
    const [schName, setSchName] = useState("");
    const [activeDays, setActiveDays] = useState<Week>({
      sunday: "false",
      monday: "false",
      tuesday: "false",
      wednesday: "false",
      thursday: "false",
      friday: "false",
      saturday: "false"
    })
    const [isChecked, setIsChecked] = useState<boolean>({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
    })
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [validationErrors, setValidationErrors] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [schedNames, setSchedNames] = useState<string[]>([]);
    const { username } = useContext<any>(AppContext);

    const updateName = (e: ChangeEvent<HTMLInputElement>) => {
        setSchName(e.target.value);
      };
 
    const toggleDay = (e: any) => {
        let updateWeek: Week = activeDays;

        switch (e.target.id) {
          case 'SundayToggle':
                updateWeek.sunday === "false" ? updateWeek.sunday = "true" : updateWeek.sunday = "false";
                const sun = isChecked.sunday = !isChecked.sunday
                setIsChecked({ ...isChecked, sun })
            break;
        
          case 'MondayToggle':
                updateWeek.monday === "false" ? updateWeek.monday = "true" : updateWeek.monday = "false"
                const mon = isChecked.monday = !isChecked.monday
                setIsChecked({ ...isChecked, mon })
            break;
        
          case 'TuesdayToggle':
                updateWeek.tuesday === "false" ? updateWeek.tuesday = "true" : updateWeek.tuesday = "false"
                const tue = isChecked.tuesday = !isChecked.tuesday
                setIsChecked({ ...isChecked, tue })
            break;
        
          case 'WednesdayToggle':
                updateWeek.wednesday === "false" ? updateWeek.wednesday = "true" : updateWeek.wednesday = "false"
                const wed = isChecked.wednesday = !isChecked.wednesday
                setIsChecked({ ...isChecked, wed })
            break;
        
          case 'ThursdayToggle':
                updateWeek.thursday === "false" ? updateWeek.thursday = "true" : updateWeek.thursday = "false"
                const thu = isChecked.thursday = !isChecked.thursday
                setIsChecked({ ...isChecked, thu })
            break;
        
          case 'FridayToggle':
                updateWeek.friday === "false" ? updateWeek.friday = "true" : updateWeek.friday = "false"
                const fri = isChecked.friday = !isChecked.friday
                setIsChecked({ ...isChecked, fri })
            break;
        
          case 'SaturdayToggle':
                updateWeek.saturday === "false" ? updateWeek.saturday = "true" : updateWeek.saturday = "false"
                const sat = isChecked.saturday = !isChecked.saturday
                setIsChecked({ ...isChecked, sat })
            break;
      
          default:
            break;
        }
        setActiveDays({ ...activeDays, updateWeek });

      };

      const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.id === "startTime") {
          setStartTime(e.target.value)
        } else if (e.target.id === "endTime") {
          setEndTime(e.target.value)
        }
      }

      const handleSubmit = async () => {
          let user = "";
          username === "" ? user = "SYSTEM" : user = username.substring(5);
        const submitSchedule: Schedule = {
          scheduleName: schName,
          activeDays: activeDays,
          startTime: startTime,
          endTime: endTime,
          lastUpdateBy: user,
          lastUpdateDate: ""
          }

          console.log('submit object: ', submitSchedule);

        const errorsFound = await validator(submitSchedule);

        if (errorsFound.length > 0) {
          console.log("Errors found")
          return null;
        }

          try {
            const reply = await postNewSchedule(submitSchedule);
              cleanData();
              resetFields();
              setAlertSeverity("success")
              setAlertMessage("New Schedule added.")
              setAlertOpen(true);
            return reply;

          }
          catch (err) {
              console.log(err)
              setAlertSeverity("error")
              setAlertMessage("Unable to add Schedule!")
              setAlertOpen(true);
              return (err)
          }
    }

    const resetFields = () => {
        setSchName("");
        setStartTime("");
        setEndTime("");       
        setActiveDays({
            sunday: "false",
            monday: "false",
            tuesday: "false",
            wednesday: "false",
            thursday: "false",
            friday: "false",
            saturday: "false"
        });
        setIsChecked({
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        })
    }

    const validator = async (obj) => {
      const responseArray = [];
      const timeReg = /^[0-2][0-9]:[0-5][0-9]$/;

      let atLeastOne = false;

      for (const n in activeDays) {
        if (activeDays[n] === "true") {
          atLeastOne = true;
          break;
        }
      }
      console.log(schedNames)

      if (!obj.scheduleName) {
        responseArray.push("Name error: Please include a Schedule Name")
      }
      if (schedNames.indexOf(obj.scheduleName) !== -1) {
        responseArray.push("Name error: A schedule with this name already exists")
      }
      if (!obj.startTime || !timeReg.test(obj.startTime)){
        responseArray.push("Start Time Error: Invalid Start Time");
      }
      if (!obj.endTime || !timeReg.test(obj.endTime)) {
        responseArray.push("End Time Error: Invalid End Time");
      }
      if (atLeastOne === false) {
        responseArray.push("Active Days Error: Please select at least one day")
      }

      setValidationErrors(responseArray);
      return responseArray
    }

    const columns = useMemo<MRT_ColumnDef<Schedule>[]>(
        () => [
          {
            accessorKey: 'scheduleName', //access nested data with dot notation
            header: 'Schedule Name',

          },
          {
            accessorKey: 'activeDays',
            header: 'Active Days',
          },
          {
            accessorKey: 'startTime', //normal accessorKey
            header: 'Start Time',
          },
          {
              accessorKey: 'endTime', //normal accessorKey
              header: 'End Time',
          }
        ,
        {
            accessorKey: 'lastUpdateBy', //normal accessorKey
            header: 'Created By',
        }
        ,
        {
            accessorKey: 'lastUpdateDate', //normal accessorKey
            header: 'Created Date',
        }
        ],
        [],
    );

    const cleanData = async () => {
        let clean: Schedule[] = [];
        let scheduleNames: string[] = [];
        let fd = await fetchSchedules();
        if (Array.isArray(fd)){
        fd.forEach(function (item) {
            let newItem: Schedule = {
                scheduleName: item.scheduleName,
                activeDays: weekArray(item.activeDays),
                startTime: item.startTime,
                endTime: item.endTime,
                lastUpdateBy: item.lastUpdateBy,
                lastUpdateDate: item.lastUpdateDate
            }
            clean.push(newItem);
            scheduleNames.push(item.scheduleName);
        })}
        setTableData(clean);
        setSchedNames(scheduleNames);
    };

    useEffect(() => {
        cleanData();

    }, []);

  return (
      <><Typography sx={dishFontSx} variant="h4">Outbound Campaign Schedule</Typography>
      <br />
      <div>
        { validationErrors.length > 0 &&
          <div className='ErrorCard'>
            <Card sx={cardLayout}>
                  <Typography sx={dishFontSxRed} variant='h5'>One or more values failed to validate. Please check for errors in the following places: </Typography>
                  <ul>
                    {validationErrors.map((err) => {
                      return (
                        <li key={err}><Typography sx={dishFontSxRed}>{err}</Typography></li>
                      )
                    })}
                  </ul>
            </Card>
          </div>
        }
        <Card sx={cardLayoutExtraWide}>
          <CardContent>
      <Grid container spacing={1}>
      <Grid xs={12} xl={5} item={true}>
            <Typography variant='h4' sx={dishFontSx}>New Schedule</Typography>
            <br />
            <Typography sx={helpText}>Schedule Name</Typography>
            <FormControl sx={{marginBottom: '3rem', minWidth: '30rem'}}>
            <TextField id="nameForm" label="New Schedule Name" sx={{maxWidth: '24rem', fontFamily: dishFont}} value={schName} onChange={updateName}/>
              <Typography sx={helpText}> Required</Typography>
            </FormControl>
            <Typography sx={helpText}>
                <Checkbox id='SundayToggle' onClick={toggleDay} checked={isChecked.sunday} />Sunday
                <Checkbox id='MondayToggle' onClick={toggleDay} checked={isChecked.monday} />Monday
                <Checkbox id='TuesdayToggle' onClick={toggleDay} checked={isChecked.tuesday} />Tuesday
                <Checkbox id='WednesdayToggle' onClick={toggleDay} checked={isChecked.wednesday} />Wednesday
                <Checkbox id='ThursdayToggle' onClick={toggleDay} checked={isChecked.thursday} />Thursday
                <Checkbox id='FridayToggle' onClick={toggleDay} checked={isChecked.friday} />Friday
                <Checkbox id='SaturdayToggle' onClick={toggleDay} checked={isChecked.saturday} />Saturday
             </Typography>
            <br />
            <small className='MarginRight1'>Start Time</small>
            <input type="time" id="startTime" className="MarginRight2" name="startTime" onChange={handleTimeChange} value={startTime} />
            <small className='MarginRight1'>End Time</small>
            <input type="time" id="endTime" className="MarginRight2" name="endTime" onChange={handleTimeChange} value={endTime} />
            <Button sx={buttonPrimary} variant='contained' onClick={handleSubmit}>Submit</Button>
            <br />

        </Grid>
        <Grid xs={12} xl={7} item={true} sx={{minHeight: '80vh', borderLeft: '1px solid lightgray'}}>
            <MaterialReactTable 
            displayColumnDefOptions={{
              'mrt-row-actions': {
                  muiTableHeadCellProps: {
                      align: 'center',
                  },
                  size: 120,
              },
          }}
          columns={columns}
          data={tableData}
          initialState={{
            sorting: [
              {
                id:'scheduleName',
                desc: false
              }
            ],
            pagination: {
              pageIndex: 0,
              pageSize: 100
            },
          }}
          enableColumnFilters={true}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding={false}
          //Top tool bar with add and search
          enableTopToolbar={true}
          enableColumnActions={false}
          enableBottomToolbar={true}
          enableSorting={true}
          //Action Colums
          //enableEditing
          muiTableHeadCellProps={{
              sx: dishFontSx
          }}
          muiTableBodyCellProps={{
              sx: dishFontSx
          }}
            />
      </Grid>
      </Grid>
          </CardContent>
        </Card>
          </div>
          <BasicSnackbar
              open={alertOpen}
              severity={alertSeverity}
              message={alertMessage}
              onClose={() => setAlertOpen(false)}
          />
    </>
  );
};

export default SystemSchedule;