import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, TextareaAutosize, Tooltip, Typography, InputAdornment } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { useCallback, useMemo, useState, useEffect, useContext, ChangeEvent } from 'react';
import { Delete, Search, SearchOff, Clear } from '@mui/icons-material';
import { buttonPrimary, buttonSecondary, dishFontSx, errorText } from '../../assets/styles/sxProp';
import '../../assets/styles/stylesheet.css';
import { postNewDoNotCallRestriction, deleteDoNotCallRestriction, fetchSingleDNC, downloadFeedbackFiles } from '../../api/api';
import AppContext from '../../context/AppContext';
import BasicSnackbar from '../../components/common/Snackbar';
import { utcConvertor } from '../reporting/CampaignFilesPage';
import LoadingModal from '../../components/common/LoadingModal';

type Props = {};

type dnc = {
    ani: string;
    dateAdded: string;
};

type dncArchive = {
    ani: string;
    dateRemoved: string;
    removedBy: string;
};

type errorResponse = {
  type: string,
  title: string,
  status: number,
  traceId: string
}

const defaultNoPhone: errorResponse = {
  type: "default",
  title: "Default NoPhone",
  status: 300,
  traceId: "default"
}

const DNC = (props: Props) => {

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<dnc[]>([]);

    const { instance, username } = useContext<any>(AppContext);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [phoneNums, setPhoneNums] = useState<string[]>([]);
    const [search, setSearch] = useState<string>("");
    const [dncCount, setdncCount] = useState<number>(0);
    const [singlePhone, setSinglePhone] = useState<dnc | errorResponse>(defaultNoPhone);
    const [displaySearch, setDisplaySearch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleCreateNewRow = async (values: dnc) => {
        try {
            await postNewDoNotCallRestriction(values);
            setAlertSeverity("success")
            setAlertMessage("ANI added to Do Not Call List.")
            setAlertOpen(true);
            setSinglePhone(defaultNoPhone);
            setSearch("");
            fetchDNCData();
            //tableData.push(responseJson);
            //setTableData([ ...tableData ]);
            //return responseJson
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to add ANI to Do Not Call List!")
            setAlertOpen(true);
            return (err)
        }
        fetchDNCData();
        fetchDNCDataCount();
  };

  const handleDeleteRow = useCallback(
    async (row: MRT_Row<dnc>) => {
      if (!window.confirm(`Are you sure you want to delete ${row.getValue('ani')}`)) return;
      //@ts-ignore
      else if (singlePhone && singlePhone.ani && singlePhone.ani === "Number not found") {
        return;
      }
      else {
          try {
              let user = "";
              username === "" ? user = "SYSTEM" : user = username.substring(5);
            await deleteDoNotCallRestriction(`${ row.getValue('ani') }`, user);
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            setAlertSeverity("success")
            setAlertMessage("ANI removed from Do Not Call List.")
            setAlertOpen(true);
            setSinglePhone(defaultNoPhone);
            setSearch("");
            fetchDNCData();
            fetchDNCDataCount();
            //return responseJson
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to remove ANI from Do Not Call List.")
            setAlertOpen(true);

            return (err)
        }
      } 
    },
    [tableData],
  );

    const columns = useMemo<MRT_ColumnDef<dnc>[]>(
        () => [
          {
            accessorKey: 'ani',
            header: 'Phone Number',
          },
          {
            accessorKey: 'dateAdded', //normal accessorKey
            header: 'Date Added',
            enableEditing: false,
          }
        ],
        [],
    );

    const url = 'https://' + window.location.toString().split('/')[2];

    const cleanData = (fd: dnc[]) => {
        let clean: dnc[] = [];
        fd.forEach(function (item) {
            let newItem: dnc = {
                ani: item.ani,
                dateAdded: utcConvertor(item.dateAdded.replace("T", " ")),
            }
            clean.push(newItem);
        setTableData(clean);
        })
    };

    const fetchDNCData = async () => {
        fetch(`${url}/api/dialrestrictions/dnc`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                cleanData(data);
            })
    }

    const fetchDNCDataCount = async () => {
        fetch(`${url}/api/dialrestrictions/bigdnc`)
            .then(response => {
                return response.json()
            })
            .then(res => {
                let total = res.length
                setdncCount(total);
            })
    }

    useEffect(() => {  
        fetchDNCData();
        fetchDNCDataCount();
    }, [instance]);

    useEffect(() => {
      if(Array.isArray(tableData) && tableData.length > 0) {
        let numbers: string[] = []  
        for (const dnc of tableData) {
          numbers.push(dnc.ani)
        }

        setPhoneNums(numbers);
      }
    }, [tableData])

    const downloadTextFile = async () => {
      setLoading(false)
      try {
        setLoading(true)
          const response = await fetch('/api/dialrestrictions/bigdnc');
          const content = await response.json();

        const anis = content.map((el:any) => {
          return el.ani
        })

        const downloadContent = new Blob([anis.join('\n')], {
          type: "text/plain"
        })

        let objectUrl = window.URL.createObjectURL(downloadContent);
        let tempLink = document.createElement("a");
        tempLink.href = objectUrl;
        tempLink.setAttribute('download', 'dnc-export.txt');
        tempLink.click();
        setLoading(false)

      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }

    const downloadArchiveTextFile = async () => {
        setLoading(false)
        try {
            setLoading(true)
            const response = await fetch('/api/dialrestrictions/dncarchive');
            const content = await response.json();

            const anis = content.map((el: dncArchive) => {
                return el.ani + " deleted by " + el.removedBy + " on " + el.dateRemoved
            })

            const downloadContent = new Blob([anis.join('\n')], {
                type: "text/plain"
            })

            let objectUrl = window.URL.createObjectURL(downloadContent);
            let tempLink = document.createElement("a");
            tempLink.href = objectUrl;
            tempLink.setAttribute('download', 'deleted-dnc-export.txt');
            tempLink.click();
            setLoading(false)

        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const handleSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    }

    const handleSearch = async (ani: string) => {
      const reply = await fetchSingleDNC(ani);
      if (reply && reply === 404) {
        setAlertSeverity("error")
        setAlertMessage("Number not found")
        setAlertOpen(true);
        setSinglePhone({
          ani: "Number not found",
          dateAdded: "(Sorry)"
        })
      }
      else if (reply && reply !== 404) {
        setSinglePhone(reply);
      }

      return reply;
    }

    const textInputProp = {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={() => setSearch("")}>
            <Clear fontSize='small' />
          </IconButton>
        </InputAdornment>
      )
    }

    useEffect(() => {
      const phoneNumberStrRegEx = /^\d{10}$/
      if ((search.length !== 10 || !phoneNumberStrRegEx.test(search))) {
        if((JSON.stringify(singlePhone) !== JSON.stringify(defaultNoPhone))) setSinglePhone(defaultNoPhone);
      } else {
        handleSearch(search);
      }
    }, [search])

    useEffect(() => {
      if (singlePhone.hasOwnProperty('ani')){
      }
    }, [singlePhone])

    return (
      <>
      <LoadingModal open={loading} />
            <div><Typography variant='h4' sx={dishFontSx}>Do Not Call List ({dncCount})</Typography></div>
      <br />
    <div className='ThirtyPercentWidth'>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
                sx: dishFontSx
              },
              size: 120,
            },
          }} 

          //@ts-ignore 
          columns={columns}
          
          //@ts-ignore 
          data={singlePhone.hasOwnProperty("ani") ? [singlePhone] : tableData}
          //The above ^ has a typescript error because I can't convince typescript that data
          //Will never be assigned the type "errorResponse". @ts-ignore allows us to ignore that error

          enableColumnFilters={true}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding={false}
          enableTopToolbar={true}
          enableColumnActions={false}
          enableBottomToolbar={true}
          enableGlobalFilter={false}
          enableSorting={true}
          initialState={{
            sorting: [
              {
                id:'ani',
                desc: false
              }
            ],
            pagination: {
              pageIndex: 0,
              pageSize: 100
            }
          }}
          enableEditing
          //Editing Row
          muiTableHeadCellProps={{
            sx: dishFontSx
          }}
          muiTableBodyCellProps={{
            sx: dishFontSx
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Delete">
                {/*@ts-ignore*/}
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <>
            <Button
              onClick={() => setCreateModalOpen(true)}
              variant="contained"
              sx={{...buttonPrimary}}
            >
              Add New Suppression
            </Button>
            <span className='GrowSpan' />
            <Button
              onClick={downloadTextFile}
              variant='contained'
              sx={buttonPrimary}
              >Download Active
                  </Button>
                  <span className='GrowSpan' />
            <Button
                onClick={downloadArchiveTextFile}
                variant='contained'
                sx={buttonPrimary}
            >Download Deleted
            </Button>
              {!displaySearch && 
                <IconButton>
                  <Search onClick={() => setDisplaySearch(!displaySearch)} />
                </IconButton>
              }
              {displaySearch &&
                <>
                  <TextField sx={{my: 0, ml: "2rem" }} variant="standard" onChange={handleSearchFieldChange} value={search} InputProps={textInputProp}></TextField>
                  <IconButton>
                    <SearchOff onClick={() => setDisplaySearch(!displaySearch)} />
                  </IconButton>
                </>
              }
            </>
          )}
        />
        <CreateNewAccountModal
          columns={columns}
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          onSubmit={handleCreateNewRow}
          numbers={phoneNums}
        />
            </div>
            <BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)}
            />
    </>
  );
};

interface CreateModalProps {
  columns: MRT_ColumnDef<dnc>[];
  onClose: () => void;
  onSubmit: (values: dnc) => void;
  open: boolean;
  numbers: string[];
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  numbers
}: CreateModalProps) => {
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const [validationError, setValidationError] = useState<boolean>(false);
  const [duplicateError, setDuplicateError] = useState<boolean>(false);


  const handleCancel = () => {
    setValidationError(false);
    setDuplicateError(false);
    setValues(() =>
    columns.reduce((acc, column) => {
      if (column.accessorKey === "dateAdded") acc[column.accessorKey] = new Date().toLocaleString()
      else acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any))
    onClose()
  }

    const handleSubmit = () => {
        const phoneNumberStrRegEx = /^\d{10}$/
        let invalidList = false;
        let dirtyList: string[] = [];
        let cleanList: string[] = [];
        dirtyList = values.ani.split('\n');
        dirtyList.forEach(function (pn: string) {
            let res = phoneNumberStrRegEx.test(pn.trim())
            if (!res) { invalidList = true }
            else { cleanList.push(pn.trim()) }
        })


      setValidationError(false);
      setDuplicateError(false);


      if (!values.ani) {
          return setValidationError(true);
      }
      else if (invalidList) {
        return setValidationError(true);
    } else {
          setValidationError(false);
          values.ani = cleanList.toString();
          onSubmit(values);
          onClose();
          setValues(() =>
          columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
          }, {} as any))
      }
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Add New Restriction</DialogTitle>
          <DialogContent>
              <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => { 
              if (column.accessorKey === "dateAdded") {
                  return <></>
              } else {
                return (
                    <>
                        <TextareaAutosize
                            key={column.accessorKey}
                            minRows={10}
                            aria-multiline={true}
                            placeholder="List of ANI's 
                                            ##########
                                            ##########
                                            ##########"
                            name={column.accessorKey}
                            onChange={(e) =>
                                setValues({ ...values, [e.target.name]: e.target.value })
                            }
                        />
                {validationError &&
                  <Typography sx={errorText}>Please enter 10 digit ANIs in a single column</Typography>
                }
                {duplicateError &&
                  <Typography sx={errorText}>Number already exists in database</Typography>
                }
                </>
                )
              }
            })}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DNC;