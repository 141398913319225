import React from "react";

type Props = {};

const DashboardIndex = (props: Props) => {
  return (
    <div>Index text of dashboard route</div>
  );
};

export default DashboardIndex;