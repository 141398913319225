import Box from "@mui/material/Box";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';


const SystemPageLayout = () => {
  // const [value, setValue] = React.useState('1');
  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setValue(newValue);
  //   // const navigate = useNavigate();
  // };
    return (
      <>
         {/* <Box sx={{ width: 500 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Recents" value="file" icon={<RestoreIcon />} LinkComponent={Link}/>
        <BottomNavigationAction label="Favorites" value="scheudle" icon={<FavoriteIcon />} LinkComponent={Link} />
        <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
      </BottomNavigation>
    </Box> */}
    <Outlet /></>
    );
  };

export default SystemPageLayout;