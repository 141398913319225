import React from 'react';

type Props = {};

const DialRestrictionsIndex = (props: Props) => {
  return (
    <div>Index text of dial restrictions route</div>
  );
};

export default DialRestrictionsIndex;