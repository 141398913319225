import React from 'react';


type Props = {};

const ProjectIndex = (props: Props) => {
    return (
        <div>Index</div>
    );
};

export default ProjectIndex;