import React from 'react';


type Props = {};

const ReportingIndex = (props: Props) => {
  return (
    <div>ReportingIndex</div>
  );
};

export default ReportingIndex;