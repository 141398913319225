import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import MaterialReactTable, { MRT_Cell, MRT_ColumnDef, MRT_Row, MaterialReactTableProps } from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit, Delete } from '@mui/icons-material';
import { buttonPrimary, buttonSecondary, dishFontSx } from '../../assets/styles/sxProp';

type Props = {};

type prefix = {
    prefix: string;
    dst: string;
    gmtOffSet: number;
    stateId: string;
};

const Prefixes = (props: Props) => {

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState<prefix[]>([]);
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});

    const handleCreateNewRow = (values: prefix) => {
        tableData.push(values);
        setTableData([...tableData]);
    };

    const handleSaveRowEdits: MaterialReactTableProps<prefix>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values }) => {
            if (!Object.keys(validationErrors).length) {
                tableData[row.index] = values;
                //send/receive api updates here, then refetch or update local table data for re-render
                setTableData([...tableData]);
                exitEditingMode(); //required to exit editing mode and close modal
            }
        };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row: MRT_Row<prefix>) => {
            if (
                !window.confirm(`Are you sure you want to delete ${row.getValue('name')}`)
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData],
    );

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<prefix>,
        ): MRT_ColumnDef<prefix>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'phoneNumber'
                            ? validatePhoneNumber(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );
    const columns = useMemo<MRT_ColumnDef<prefix>[]>(
        () => [
            // Hide Field
            //{
            //    accessorKey: 'stateId', //access nested data with dot notation
            //    header: 'StateId',
            //    enableEditing: false,
            //    muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            //        ...getCommonEditTextFieldProps(cell),
            //    })
            //},
            {
                accessorKey: 'prefix',
                header: 'Prefix',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'dst', //normal accessorKey
                header: 'DST',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'gmtOffSet', //normal accessorKey
                header: 'gmtOffSet',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            }

        ],
        [getCommonEditTextFieldProps],
    );

    const url = 'https://' + window.location.toString().split('/')[2];

    const cleanData = (fd: prefix[]) => {
        let clean: prefix[] = [];
        fd.forEach(function (item) {
            let newItem: prefix = {
                prefix: item.prefix,
                stateId: item.stateId,
                dst: item.dst == 'false' ? 'false' : 'true',
                gmtOffSet: item.gmtOffSet
            }
            clean.push(newItem);
        })
        setTableData(clean);
    };


    const fetchPrefixData = () => {
        fetch(`${url}/api/dialrestrictions/prefixes`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                cleanData(data)
            })
    }

    useEffect(() => {
        fetchPrefixData();

    }, []);

    return (
        <><div><Typography variant='h4' sx={dishFontSx}>Prefix Restrictions</Typography></div>
        <br />
            <div className='ThirtyPercentWidth'>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={tableData}
                    enableColumnFilters={true}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableTopToolbar={true}
                    enableColumnActions={false}
                    enableBottomToolbar={true}
                    enableSorting={true}
                    initialState={{
                        sorting: [
                          {
                            id:'prefix',
                            desc: false
                          }
                        ],
                        pagination: {
                            pageIndex: 0,
                            pageSize: 100
                          },
                      }}
                    //Action Colums
                    //enableEditing
                    onEditingRowSave={handleSaveRowEdits}
                    onEditingRowCancel={handleCancelRowEdits}
                    muiTableHeadCellProps={{
                        sx: dishFontSx
                    }}
                    muiTableBodyCellProps={{
                        sx: dishFontSx
                    }}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton onClick={() => table.setEditingRow(row)}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Delete">
                                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    // renderTopToolbarCustomActions={() => (
                    //     <Button
                    //         sx={buttonPrimary}
                    //         onClick={() => setCreateModalOpen(true)}
                    //         variant="contained"
                    //     >
                    //         Create New Restriction
                    //     </Button>
                    // )}
                />
                <CreateNewAccountModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onSubmit={handleCreateNewRow}
                />
            </div>
        </>
    );
};

interface CreateModalProps {
    columns: MRT_ColumnDef<prefix>[];
    onClose: () => void;
    onSubmit: (values: prefix) => void;
    open: boolean;
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({
    open,
    columns,
    onClose,
    onSubmit,
}: CreateModalProps) => {
    const [values, setValues] = useState<any>(() =>
        columns.reduce((acc, column) => {
            if (column.accessorKey === "stateId") acc[column.accessorKey] = new Date().toLocaleString()
            else acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    const handleCancel = () => {
        setValues(() =>
            columns.reduce((acc, column) => {
                if (column.accessorKey === "dst") acc[column.accessorKey] = new Date().toLocaleString()
                else acc[column.accessorKey ?? ''] = '';
                return acc;
            }, {} as any))
        onClose()
    }

    const handleSubmit = () => {
        //put your validation logic here
        // console.log(values)
        onSubmit(values);
        onClose();
        setValues(() =>
            columns.reduce((acc, column) => {
                if (column.accessorKey === "gmtOffSet") acc[column.accessorKey] = new Date().toLocaleString()
                else acc[column.accessorKey ?? ''] = '';
                return acc;
            }, {} as any))
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create New Account</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => {
                            if (column.accessorKey === "stateId") {
                                return (<TextField
                                    key={column.accessorKey}
                                    label={column.header}
                                    name={column.accessorKey}
                                    defaultValue={values.datetime}
                                    onChange={(e) =>
                                        setValues({ ...values, [e.target.name]: e.target.value })
                                    }
                                />)
                            } else {
                                return (
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                                )
                            }
                        })}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value: string) => !!value.length;
const validatePhoneNumber = (number: string) => !!number.length;
//&&
// email
//   .toLowerCase()
//   .match(
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
// );
const validateAge = (age: number) => age >= 18 && age <= 50;

export default Prefixes;