import Handlebars from 'handlebars';

export interface HandlebarsContext {
    [key: string]: any;
}

export const eqHelper = function (this: HandlebarsContext, ...args: any[]) {
    const options = args.pop();
    const [arg1, arg2] = args;
    if (typeof options.fn !== 'function') {       
        return arg1 === arg2;
    } else {
        return (arg1 === arg2) ? options.fn(this) : options.inverse(this);
    }
}
export const neqHelper = function (this: HandlebarsContext, ...args: any[]) {
    const options = args.pop();
    const [arg1, arg2] = args;
    if (typeof options.fn !== 'function') {
        return arg1 !== arg2;
    } else {
        return (arg1 !== arg2) ? options.fn(this) : options.inverse(this);
    }
}

export const notHelper = function (this: HandlebarsContext, value: any, options: any) {
    if (typeof options.fn !== 'function') {
        return !value;
    } else {
        return !value ? options.fn(this) : options.inverse(this);
    }
}

export const andHelper = function (this: HandlebarsContext, ...args: any[]) {
    const options: any = args.pop();
    const result = args.every(Boolean);
    if (typeof options.fn !== 'function') {
        return result;
    } else {
        return result ? options.fn(this) : options.inverse(this);
    }
}

export const orHelper = function (this: HandlebarsContext, ...args: any[]) {
    const options = args.pop();
    const result = args.some(Boolean);
    if (typeof options.fn !== 'function') {
        return result;
    } else {
        return result ? options.fn(this) : options.inverse(this);
    }
}

export const capitalizeHelper = function (this: HandlebarsContext, str: string) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

export const capitalizeFirstHelper = function (this: HandlebarsContext, str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const lowerHelper = function (this: HandlebarsContext, str: string) {
    return str.toLowerCase();
}

export const upperHelper = function (this: HandlebarsContext, str: string) {
    return str.toUpperCase();
}

export const replaceHelper = function (this: HandlebarsContext, str: string, search: string, replacement: string) {
    return str.split(search).join(replacement);
}

export const substringHelper = function (this: HandlebarsContext, str: string, start: number, end?: number) {
    return str.substring(start, end);
}

// Helper function to pad single digit numbers with a leading zero
const padZero = (num: number): string => (num < 10 ? `0${num}` : num.toString());
// Function to format date according to the given format string
const formatDate = (date: Date, formatString: string): string => {
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());
    return formatString
        .replace('yyyy', year.toString())
        .replace('MM', month)
        .replace('dd', day)
        .replace('mm', minutes)
        .replace('ss', seconds);
}
// Function to convert date to the specified timezone
const convertToTimeZone = (date: Date, timeZone: string): Date => {
    const options: Intl.DateTimeFormatOptions = {
        timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };
    const dateString = new Intl.DateTimeFormat('en-US', options).format(date);
    const [month, day, year, hour, minute, second] = dateString.match(/\d+/g)!.map(Number);
    return new Date(year, month - 1, day, hour, minute, second);
}
export const nowHelper = function (this: HandlebarsContext, formatString: string, options: any) {
    const now = new Date();
    let zonedTime = now;

    if (options.hash.tz) {
        try {
            zonedTime = convertToTimeZone(now, options.hash.tz);
        } catch (e) {
            console.error(`Invalid time zone: ${options.hash.tz}`);
        }
    }
    if (formatString) {
        return formatDate(zonedTime, formatString);
    } else {
        return zonedTime.toString();
    }
}





export const registerHelpers = () => {
    Handlebars.registerHelper('eq', eqHelper);
    Handlebars.registerHelper('neq', neqHelper);
    Handlebars.registerHelper('not', notHelper);
    Handlebars.registerHelper('and', andHelper);
    Handlebars.registerHelper('or', orHelper);
    Handlebars.registerHelper('capitalize', capitalizeHelper);
    Handlebars.registerHelper('capitalizeFirst', capitalizeFirstHelper);
    Handlebars.registerHelper('lower', lowerHelper);
    Handlebars.registerHelper('upper', upperHelper);
    Handlebars.registerHelper('replace', replaceHelper);
    Handlebars.registerHelper('substring', substringHelper);
    Handlebars.registerHelper('now', andHelper);

}