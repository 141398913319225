import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material';
import MaterialReactTable, { MRT_Cell, MRT_ColumnDef, MRT_Row, MaterialReactTableProps } from 'material-react-table';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Edit, Delete } from '@mui/icons-material';
import { buttonPrimary, buttonSecondary, dishFontSx } from '../../assets/styles/sxProp';
import '../../assets/styles/stylesheet.css';
import BasicSnackbar from '../../components/common/Snackbar';
import { updateCallEvents } from '../../api/api';
import AppContext from '../../context/AppContext';
import { utcConvertor } from '../reporting/CampaignFilesPage';



type Props = {};

type CallEvent = {
    id: number;
    eventType: string;
    retry: number;
    secondNumber: string;
    timeFrame: number;
    feedbackDisposition: number;
    lastUpdateBy: string;
    lastUpdateDate: string;
}

const timeFrames = [0, 15, 30, 45, 60]
const retryCounts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const CallEvents = (props: Props) => {

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState<CallEvent[]>([]);
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const { username } = useContext<any>(AppContext);

    const handleCreateNewRow = (values: CallEvent) => {
        tableData.push(values);
        setTableData([...tableData]);
    };

    const handleSaveRowEdits: MaterialReactTableProps<CallEvent>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values }) => {
            if (!Object.keys(validationErrors).length) {
                updateNewRow(values)            
               exitEditingMode(); //required to exit editing mode and close modal
            }
        };

    const updateNewRow = async (values: CallEvent) => {
        console.log('inside update new row: ', values)
        let user = "";
        username === "" ? user = "SYSTEM" : user = username.substring(5);
        const newObj = {
            id: values.id,
            eventType: values.eventType,
            retry: values.retry,
            secondNumber: values.secondNumber.toLowerCase() === 'true' ? true : false,
            timeFrame: values.timeFrame,
            feedbackDisposition: values.feedbackDisposition,
            lastUpdateBy: user,
            lastUpdateDate: ""
        }
        try {
            await updateCallEvents(newObj);
            setAlertSeverity("success")
            setAlertMessage("Call Event successfully updated.")
            setAlertOpen(true);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to update call event!")
            setAlertOpen(true);
            return (err)
        }
        fetchOBDEventsData();
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<CallEvent>,
        ): MRT_ColumnDef<CallEvent>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onChange: (event) => {
                    console.log(event.target.value)
                    const isValid =
                        cell.column.id === 'secondNumber'
                            ? validateSecNum(event.target.value) 
                                : validateRequired(event.target.value)
                    if (!isValid) {
                        console.log('errors: ', validationErrors)
                        cell.column.id === 'secondNumber'
                            ? setValidationErrors({
                                ...validationErrors,
                                [cell.id]: `${cell.column.columnDef.header} is required. (true / false)`,
                            })
                            : setValidationErrors({
                                ...validationErrors,
                                [cell.id]: `${cell.column.columnDef.header} is required`
                            })    
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );
    const columns = useMemo<MRT_ColumnDef<CallEvent>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
            {
                accessorKey: 'eventType',
                header: 'Event Type',
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            },
             {
                 accessorKey: 'retry',
                 header: 'Retry',
                 muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                     //...getCommonEditTextFieldProps(cell),
                     select: true, //change to select for a dropdown
                     children: retryCounts.map((item) => (
                         <MenuItem key={item} value={item}>
                             {item}
                         </MenuItem>
                     )),
                 })
             },
             {
                 accessorKey: 'feedbackDisposition',
                 header: 'Feedback Disposition',
                 muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                     ...getCommonEditTextFieldProps(cell),
                 })
             },
             {
                 accessorKey: 'secondNumber',
                 header: 'Second Number',
                 muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                     ...getCommonEditTextFieldProps(cell),
                 })
             },
             {
                 accessorKey: 'timeFrame',
                 header: 'Time Frame (Min)',
                 muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                     //...getCommonEditTextFieldProps(cell),
                     select: true, //change to select for a dropdown
                     children: timeFrames.map((t) => (
                         <MenuItem key={t} value={t}>
                             {t}
                         </MenuItem>
                     )),
                 })
            }, {
                accessorKey: 'lastUpdateBy',
                 header: 'Last Updated By',
                 enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            }, {
                accessorKey: 'lastUpdateDate',
                 header: 'Last Updated Date',
                 enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                })
            }
        ],
        [getCommonEditTextFieldProps],
    );

    const url = 'https://' + window.location.toString().split('/')[2];

    //export const utcConvertor = (date: string) => {
    //    const ltd = new Date(`${date} UTC`).toLocaleString("en-US", {
    //        timeZone: 'America/Denver',
    //        hour12: false
    //    });
    //    return ltd;
    //}

    const cleanData = (fd: CallEvent[]) => {
        let clean: CallEvent[] = [];
        
        if (Array.isArray(fd) && fd.length > 0){
            fd.forEach(function (item) {
            //    let formattedDate = new Date(`${item.lastUpdateDate} UTC`).toLocaleString("en-US", {
            //timeZone: 'America/Denver',
            //hour12: true
        //});
            let newItem: CallEvent = {
                id: item.id,
                eventType: item.eventType,
                retry: item.retry,
                secondNumber: item.secondNumber? "true" : "false",
                timeFrame: item.timeFrame,
                feedbackDisposition: item.feedbackDisposition,
                lastUpdateBy: item.lastUpdateBy,
                lastUpdateDate: item.lastUpdateDate
            }
            clean.push(newItem);
        })
        }
        setTableData(clean);
    };


    const fetchOBDEventsData = () => {
        fetch(`${url}/api/system/callEvents`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                cleanData(data);
            })
    }

    useEffect(() => {
        fetchOBDEventsData();

    }, []);

    return (
        <><Typography variant="h4" sx={dishFontSx}>Outbound Dialing Events</Typography>
        <br />
            <div className='SeventyPercentWidth'>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={tableData}
                    enableColumnFilters={true}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    //Top tool bar with add and search
                    enableTopToolbar={true}
                    enableColumnActions={false}
                    enableBottomToolbar={true}
                    enableSorting={true}
                    initialState={{
                        sorting: [
                          {
                            id:'eventType',
                            desc: false
                          }
                        ],
                        pagination: {
                            pageIndex: 0,
                            pageSize: 100
                          },
                      }}
                    //Action Colums
                    enableEditing
                    onEditingRowSave={handleSaveRowEdits}
                    onEditingRowCancel={handleCancelRowEdits}
                    muiTableHeadCellProps={{
                        sx: dishFontSx
                    }}
                    muiTableBodyCellProps={{
                        sx: dishFontSx
                    }}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton onClick={() => table.setEditingRow(row)}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />
                <CreateNewAccountModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onSubmit={handleCreateNewRow}
                />
            </div>
            <BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)}
            />
        </>
    );
};

interface CreateModalProps {
    columns: MRT_ColumnDef<CallEvent>[];
    onClose: () => void;
    onSubmit: (values: CallEvent) => void;
    open: boolean;
}

export const CreateNewAccountModal = ({
    open,
    columns,
    onClose,
    onSubmit,
}: CreateModalProps) => {
    const [values, setValues] = useState<any>(() =>
        columns.reduce((acc, column) => {
            if (column.accessorKey === "id") acc[column.accessorKey] = new Date().toLocaleString()
            else acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    const handleCancel = () => {
        setValues(() =>
            columns.reduce((acc, column) => {
                if (column.accessorKey === "id") acc[column.accessorKey] = new Date().toLocaleString()
                else acc[column.accessorKey ?? ''] = '';
                return acc;
            }, {} as any))
        onClose()
    }

    const handleSubmit = () => {
        onSubmit(values);
        onClose();
        setValues(() =>
            columns.reduce((acc, column) => {
                if (column.accessorKey === "id") acc[column.accessorKey] = new Date().toLocaleString()
                else acc[column.accessorKey ?? ''] = '';
                return acc;
            }, {} as any))
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create New Account</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => {
                            if (column.accessorKey === "id") {
                                return (<TextField
                                    key={column.accessorKey}
                                    label={column.header}
                                    name={column.accessorKey}
                                    defaultValue={values.datetime}
                                    onChange={(e) =>
                                        setValues({ ...values, [e.target.name]: e.target.value })
                                    }
                                />)
                            } else {
                                return (
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                                )
                            }
                        })}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
                <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};
const numberRegEx = /^\d+$/
const boolRegEx = /^(?:true|false)$/ig
const validateRequired = (value: string) => !!value.length;
const validateSecNum = (value: string) => !!value.length && boolRegEx.test(value);
export default CallEvents;