import React from 'react';


type Props = {};

const SystemIndex = (props: Props) => {
  return (
    <div>SettingsIndex</div>
  );
};

export default SystemIndex;