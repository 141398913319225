import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip, FormGroup, FormControlLabel, Switch, Typography } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { Delete } from '@mui/icons-material';
import { buttonPrimary, buttonSecondary, dishFontSx, errorText } from '../../assets/styles/sxProp';
import { postNewFederalHolidayRestriction, deleteFederalHolidayRestriction } from '../../api/api';
import AppContext from '../../context/AppContext';
import BasicSnackbar from '../../components/common/Snackbar';
import '../../assets/styles/stylesheet.css';



type Props = {};

type FederalHoliday = {
    id: number;
    ihsHoliday: string;
    date: string;
    lastUpdateBy: string;
    lastUpdateDate: string;
  };

const FederalHolidays = (props: Props) => {

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState<FederalHoliday[]>([]);

    const { instance, username } = useContext<any>(AppContext);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [datesArray, setDatesArray] = useState<string[]>([]);

    const handleCreateNewRow = async (values: FederalHoliday) => {
        if (values.date) console.log(new Date(values.date));
        let user = "";
        username === "" ? user = "SYSTEM" : user = username.substring(5);
        const newOBJ = {
            id: values.id,
            ihsHoliday: values.ihsHoliday === "true" ? true : false,
            date: new Date(values.date).toISOString(),
            lastUpdateBy: user,
            lastUpdateDate: ""

        }
        try {
            const responseJson = await postNewFederalHolidayRestriction(newOBJ);
            setAlertSeverity("success")
            setAlertMessage("Federal Holiday successfully added.")
            setAlertOpen(true);
            tableData.push(values);
            setTableData([...tableData]);
            console.log('table data after create call : ', tableData);
        } catch (err) {
            console.log(err)
            setAlertSeverity("error")
            setAlertMessage("Unable to add Federal Holiday!")
            setAlertOpen(true);
        }
        fetchFederalHolidayData()
    };

  const handleDeleteRow = useCallback(
      async (row: MRT_Row<FederalHoliday>) => {
      if (
        !window.confirm(`Are you sure you want to delete Holiday ${row.getValue('date')}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
          try {
              const responseJson = await deleteFederalHolidayRestriction(`${row.getValue('id')}`);
              tableData.splice(row.index, 1);
              cleanData(tableData);
              setAlertSeverity("success")
              setAlertMessage("Federal Holiday successfully removed")
              setAlertOpen(true);
          } catch (err) {
              console.log(err)
              setAlertSeverity("error")
              setAlertMessage("Unable to remove Federal Holiday!")
              setAlertOpen(true);
              return (err)
          }
    },
    [tableData],
    );

   const columns = useMemo<MRT_ColumnDef<FederalHoliday>[]>(
        () => [
          {
            accessorKey: 'id', //access nested data with dot notation
            header: 'ID',
            enableEditing: false
          },
          {
            accessorKey: 'ihsHoliday',
            header: 'IHS Holiday'
          },
          {
            accessorKey: 'date', //normal accessorKey
            header: 'Date'
           },
           {
            accessorKey: 'lastUpdateBy', //normal accessorKey
            header: 'Last Updated By'
           },
           {
            accessorKey: 'lastUpdateDate', //normal accessorKey
            header: 'Last Updated Date'
           }
        ],
        [],
    );

   const url = 'https://' + window.location.toString().split('/')[2];


    const cleanData = (fd: FederalHoliday[]) => {
        let clean: FederalHoliday[] = [];
        let someDates: string[] = [];
        fd.forEach(function (item) {
            let newItem: FederalHoliday = {
                id: item.id,
                ihsHoliday: item.ihsHoliday? "TRUE" : "FALSE",
                date: item.date.substring(0, 10),
                lastUpdateBy: item.lastUpdateBy,
                lastUpdateDate: item.lastUpdateDate
            }
            clean.push(newItem);
            someDates.push(item.date.substring(0, 10))
        })
        setTableData(clean);
        setDatesArray(someDates);
    };

    const fetchFederalHolidayData = () => {
        fetch(`${url}/api/dialrestrictions/federalHolidays`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                cleanData(data);
            })
    }

    useEffect(() => {
        fetchFederalHolidayData();

    }, [instance]);

  return (
    <><div><Typography variant='h4' sx={dishFontSx}>Federal Holiday Restrictions</Typography></div>
      <br />
    <div className='FortyPercentWidth'>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }} 
          columns={columns} 
          data={tableData}

          enableColumnFilters={true}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding={false}
          enableTopToolbar={true}
          enableColumnActions={false}
          enableBottomToolbar={true}
          enableSorting={true}
          initialState={{
            sorting: [
              {
                id:'date',
                desc: false
              }
            ],
            pagination: {
              pageIndex: 0,
              pageSize: 100
            },
          }}
          enableEditing
          muiTableHeadCellProps={{
            sx: dishFontSx
          }}
          muiTableBodyCellProps={{
            sx: dishFontSx
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <Button
              sx={buttonPrimary}
              onClick={() => setCreateModalOpen(true)}
              variant="contained"
            >
              Create New Restriction
            </Button>
          )}
        />
        <CreateNewAccountModal
          columns={columns}
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          onSubmit={handleCreateNewRow}
          datesArray={datesArray}
        />
          </div>
          <BasicSnackbar
              open={alertOpen}
              severity={alertSeverity}
              message={alertMessage}
              onClose={() => setAlertOpen(false)}
          />
    </>
  );
};

interface CreateModalProps {
  columns: MRT_ColumnDef<FederalHoliday>[];
  onClose: () => void;
  onSubmit: (values: FederalHoliday) => void;
  open: boolean;
  datesArray: string[]
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  datesArray
}: CreateModalProps) => {
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const [ihsBool, setIhsBool] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState({
    id: 0,
    date: 0,
    dupDate: 0
  });

  const handleIhsToggle = () => {
    setIhsBool(!ihsBool);
  }
  
  const handleCancel = () => {
    setValues(() =>
    columns.reduce((acc, column) => {
      if (column.accessorKey === "date") acc[column.accessorKey] = new Date().toLocaleString()
      else acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any))
    setValidationErrors({
      id: 0,
      date: 0,
      dupDate: 0
    })
    onClose()
  }

  const handleSubmit = () => {
    validator()
  };

  const validator = () => {
    const datereg = /^\d{4}-\d{2}-\d{2}$/

    const freshObj = {
      id: 0, 
      date: 0,
      dupDate: 0
    }

    if (!values.date || !datereg.test(values.date)) {
      console.log("Date Error")
      freshObj.date = 1
    } else if (datesArray.indexOf(values.date) !== -1) {
      freshObj.dupDate = 1;
    }

    setValidationErrors(freshObj)
    if ( freshObj.date === 1 || freshObj.dupDate === 1) {
      console.log("errors found");
      return null;
    }
    else {
      onSubmit(values);
      onClose();
      setValues(() =>
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ''] = '';
        return acc;
      }, {} as any))
    }

    return validationErrors;
  }

  useEffect(() => {
    if (ihsBool) setValues({...values, "ihsHoliday": "true"});
    else setValues({...values, "ihsHoliday": "false"})
  }, [ihsBool])

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Restriction</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => {    
                if (column.accessorKey === "date") {
                    return (
                        <>
                            <input
                                key="date"
                                className='DatePicker'
                                type="date"
                                name="date"
                                //ref={ref}
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                } />
                            {validationErrors.date === 1 &&
                                <Typography sx={errorText}>Please enter a date</Typography>
                            }
                            {validationErrors.dupDate === 1 &&
                                <Typography sx={errorText}>Holiday already exists in table</Typography>
                            }
                        </>
                    )
                } else if (column.accessorKey === "ihsHoliday") {
                    return (
                        <FormGroup>
                            <FormControlLabel control={<Switch onChange={handleIhsToggle} />} label="IHS Holiday" />
                        </FormGroup>
                    )
                } else if (column.accessorKey === 'id') {
                  return (<></>)
                }
                else if (column.accessorKey === 'lastUpdateBy') {
                    return (<></>)
                }
                else if (column.accessorKey === 'lastUpdateDate') {
                  return (<></>)
              } else {
                return (
                  <>
                  <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  sx={{mt: '2rem'}}
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                />
                {validationErrors.id === 1 &&
                  <Typography sx={errorText}>Please enter an ID number</Typography>
                }
                </>
                )
              }
            })}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button sx={buttonSecondary} onClick={handleCancel}>Cancel</Button>
        <Button sx={buttonPrimary} onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FederalHolidays;