
import { Card, CardContent, Grid } from "@mui/material";
import '../../assets/styles/stylesheet.css';
import { cardLayoutFullscreen } from "../../assets/styles/sxProp";
import { getBatchMetrics, getDomainStats, getIdentities, getIdentityDkim, getMessageStats, getSendStats } from "../../api/api";
import { useEffect } from 'react';
import * as React from "react";




const DashboardTwo = () => {

    useEffect(() => {
        //var res1 = getIdentities();
        //var res2 = getSendStats();
        //var res3 = getIdentityDkim();
        //var res4 = getDomainStats();
        //var res5 = getMessageStats();
        //var res6 = getBatchMetrics();
        //console.log(res1)
        //console.log(res2)
        //console.log(res3)
        //console.log(res4)
        //console.log(res5)
        //console.log(res6)
    }, [])

    return (
        <Card sx={cardLayoutFullscreen}>
            <CardContent>
                <div className='SearchDiv'>
                  
                    <Grid container >
                        <h1>Email SMS Push Dashboard (Under Construction)</h1>
     
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
}

export default DashboardTwo;