export interface JwtResponse {
    type: string,
    message?: string,
    username?: string,
    group?: string[]
}


export const stripTokenFromUrl = () => {
    let url = window.location.href;
    if (url.includes("access")){
        console.log(url.split('access'))
        window.location.href = (url.split('access')[0]) + "#/"
    }
}

export const setTokenToLocalStorage = () => {
    let url = window.location.href;
    if (url.includes("access_token")) {
        const token = url.substring(
            url.indexOf('=') + 1,
            url.lastIndexOf('&')
        );
        console.log(token);
        const existingToken = localStorage.getItem("cmToken");

        if (typeof (existingToken) === "undefined" || existingToken === "null" || existingToken === null || token !== existingToken ) {
            localStorage.setItem('cmToken', token)
        }

        return convertJwt(token)
    }
    else {
        const existingToken = localStorage.getItem("cmToken");
        if (typeof existingToken ==="string" && existingToken.length > 0) return convertJwt(existingToken);
        else {
            const errorResponse: JwtResponse = {
                type: "ERROR",
                message: "Missing or incomplete token 3"
            };
            return errorResponse;
        }
    }
}

export const convertJwt = async (token?: string) => {
    try {

        if (!token) {
            const errorResponse: JwtResponse = {
                type: "ERROR",
                message: "Incomplete or missing token 2"
            };

            return errorResponse;
        }

        //const response = await fetch(`https://3skcl7d1yk.execute-api.us-west-2.amazonaws.com/v1/convertJWT?token=${token}`);
        const response = await fetch(`https://ecc60siwib.execute-api.us-west-2.amazonaws.com/v1/convertjwtcm?token=${token}`);

        console.log(response)
        const jsonData = await response.json();
        console.log(jsonData)
        
        if (!jsonData.username){
            const errorResponse: JwtResponse = {
                type: "ERROR",
                message: "Missing username"
            };

            return errorResponse;
        }

        const username = jsonData.username;

        if (jsonData.errorMessage && jsonData.errorMessage.includes("expired")) {
            const errorResponse: JwtResponse = {
                type: "ERROR",
                message: "Expired token"
            };

            return errorResponse;
        } else if (jsonData.errorMessage && jsonData.errorMessage === "JWT string does not consist of exactly 3 parts (header, payload, signature)") {
            const errorResponse: JwtResponse = {
                type: "ERROR",
                message: "Incomplete or missing token 1"
            };

            return errorResponse;
        } else if (!jsonData["cognito:groups"]) {
            const errorResponse: JwtResponse = {
                type: "ERROR",
                message: "Unauthorized use of token"
            };

            return errorResponse;
        } else {

            const cognitoGroups = jsonData["cognito:groups"]

            const successResponse: JwtResponse = {
                type: "SUCCESS",
                username: username,
                group: cognitoGroups
            };
            console.log(successResponse)
            return successResponse;
        }

    } catch (err) {
        console.error(err);
        let message = "";

        typeof err === "string" ? message = err : message = "Unknown Error"


        const errorMessage: JwtResponse = {
            type: "ERROR",
            message: message
        };    
        return errorMessage;
    }
}