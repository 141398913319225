//@ts-nocheck
import { Button, Card, CardContent, Grid, InputLabel, MenuItem, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography, Divider, Select, TextField } from "@mui/material";
import '../../assets/styles/stylesheet.css';
import { buttonPrimary, cardLayoutFullscreen, dishFont, dishFontSx } from "../../assets/styles/sxProp";
import { getCampaigns, Campaign, getStats, getCampaignLogs } from "../../api/api";
import { useEffect, useState, useRef } from 'react';
import { Chart, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend} from 'chart.js'
import { Doughnut } from "react-chartjs-2";
import { utcConvertor } from "../reporting/CampaignFilesPage";
import { CamapignChangeLog } from "../campaign/CampaignActivePage";

Chart.register(ArcElement, CategoryScale, LineElement, LinearScale, PointElement, Tooltip, Legend);



export default function DefaultPage () {

  const todayDate = new Date()
  const today = `${todayDate.getFullYear()}-${todayDate.getMonth() < 9 ? `0${todayDate.getMonth()+1}` : `${todayDate.getMonth()+1}`}-${(todayDate.getDate() < 10) ? `0${todayDate.getDate()}` : `${todayDate.getDate()}`}`

  const statuses: string[] = ["Active", "Paused", "Stopped"];
  const ref = useRef(null)
  const [campaignList, setCampaignList] = useState<Campaign[]>([]);
  const [campaign, setCampaign] = useState<Campaign>(null);
  const [isItOne, setIsItOne] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);
  const [search, setSearch] = useState<string>("")
  const [sortOrder, setSortOrder] = useState<string>('abc');
  const [sortOrderOrder, setSortOrderOrder] = useState<string>('ASC');
  const [campaignData, setCampaignData] = useState<any>({});
  const [dChart, setDChart] = useState<number[]>([]);
  const [displayLegend, setDisplayLegend] = useState<boolean>(false);
  const [lastChange, setLastChange] = useState<CamapignChangeLog>(null)
  
  
  const handleRowSelect = (e) => {
    if (e.target.id) {
      const elementPosition = campaignList.map((el) => el.campaignName).indexOf(e.target.id) 
      setCampaign(campaignList[elementPosition]);
    }
  }
  
  async function setCampaigns() {
    const response = await getCampaigns();
    setCampaignList(response)
    };

    const getRecentChange = async () => {
        const res = await getCampaignLogs();
        if (campaign !== null && campaign.campaignName) {
            const filteredRes = res.filter((x: { campaignName: string; }) => x.campaignName === campaign.campaignName)
            setLastChange(filteredRes[0]) 
        }
    }
  
  const fetchStats = async () => {
    if (campaign !== null && campaign.campaignName) {
      const data = await getStats(campaign.campaignName, startDate, endDate);

      const dataValues: number[] = [];
      
      for (const n in data) {
        dataValues.push(data[n])
      }
      setDChart(dataValues)
      setCampaignData(data)
    }
  }
  useEffect(() => {
      fetchStats();
      getRecentChange();
  }, [campaign, startDate, endDate])

  const handleDateSelection = (e) => {
    if (e.target.id === "endDatePicker" && e.target.value >= startDate) setEndDate(e.target.value)
    else if (e.target.id === "endDatePicker" && e.target.value < startDate) {
  setStartDate(e.target.value)
  setEndDate(e.target.value)
} 
else if (e.target.id ==="startDatePicker" && e.target.value <= endDate) {
     setStartDate(e.target.value)
    } else {
      setStartDate(e.target.value)
      setEndDate(e.target.value)
    }
  }

  const sortFunction = (files) => {

    const trueSort = sortOrder+sortOrderOrder;

    switch (trueSort) {
        case "abcASC":
            return files.sort((a,b) => {
                if (a.campaignName < b.campaignName) return -1
                else if (a.campaignName > b.campaignName) return 1
                else return 0
            }) 
        case "abcDESC":
            return files.sort((a,b) => {
                if (a.campaignName > b.campaignName) return -1
                else if (a.campaignName < b.campaignName) return 1
                else return 0
            }) 

        case "dateASC":
            return files.sort((a,b) => {
                const aDate = new Date(a.lastUpdate)
                const bDate = new Date(b.lastUpdate)
                if (aDate < bDate) return -1
                else if (aDate > bDate) return 1
                else return 0
            })

        case "dateDESC":
            return files.sort((a,b) => {
                const aDate = new Date(a.lastUpdate)
                const bDate = new Date(b.lastUpdate)
                if (aDate > bDate) return -1
                else if (aDate < bDate) return 1
                else return 0
            })

        default:
            break;
    }
}

const searchFunction = (files) => {
    if (search === "" || search.length === 0 || !search) return files;
    else return files.filter((file) => {
      let cmlc = file.campaignName.toLowerCase();
      let lulc = file.lastUpdate.toLowerCase();
      let sclc = search.toLowerCase();

      return (cmlc.includes(sclc) || lulc.includes(sclc))

    })
}

const bigRefresh = () => {
  setCampaigns();
    fetchStats();
    getRecentChange();
}

  useEffect(() => {
    setCampaigns();
  }, [])

  useEffect(() => {
    if (Array.isArray(campaignList) && campaignList.length > 0 && isItOne === true && campaign === null) setCampaign(campaignList[0])
  }, [isItOne])

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      bigRefresh();
    }, 30000)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [endDate, today, campaign])


  useEffect(() => {
    if (Array.isArray(campaignList) && campaignList.length > 1) {
      const sortedCampaignList = campaignList.sort((a: any, b: any) => {
        if (a.campaignName < b.campaignName) return -1;
        else if (a.campaignName > b.campaignName) return 1;
        else return 0
      })

      if (sortedCampaignList !== campaignList) setCampaignList(sortedCampaignList)
      else setIsItOne(true);
    }
  }, [campaignList])

  const chartData = {
    labels: [
      "Failed",
      "Completed",
      "Processed",    
      "Queued",  
      "Uploaded",
      "Callable",
      "Noncallable",
      "Files"

    ],
    datasets: [{
      data: dChart,
      backgroundColor: [
        
        'rgb(255, 0, 0)',
        'rgb(0, 255, 0)',
        'rgb(200,100,100)',
        'rgb(255,255,0)',
        'rgb(0,200,200)',
        'rgb(155, 250, 86)',
        'rgb(0, 0, 0)',
        'rgb(0,0,255)'
      ],
      hoverOffset: 4
    }],
  }

  Chart.overrides.doughnut.plugins.legend.position = "bottom";

  useEffect(() => {
    let sum = 0;
      for (const n in dChart) {
      if (typeof dChart[n] === "number") sum += dChart[n]
      else if (!isNaN(parseInt(dChart[n]))) sum += parseInt(dChart[n])
      else continue;
    }

    if (sum > 0) setDisplayLegend(true);
    else (setDisplayLegend(false))

  }, [dChart])

  useEffect(() =>{
    if (displayLegend === true) Chart.overrides.doughnut.plugins.legend.display = true;
    else Chart.overrides.doughnut.plugins.legend.display = false;
  }, [displayLegend])

  return (
    <Card sx={cardLayoutFullscreen}>
      <CardContent>
        <div className = 'SearchDiv'>
          <InputLabel id="searchLabel">Search</InputLabel>
        <TextField
                id='searchBox'
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                }}
            ></TextField>
          {/* <InputLabel id="sortLabel">Sort</InputLabel>   */}
          &ensp;
          <Select 
            id="sortSelect"
            value={sortOrder}
            labelId="sortLabel"
            onChange={(e: SelectChangeEvent<string>) => {
              setSortOrder(e.target.value)
            }}
          sx={{}}>
              <MenuItem value={'abc'}>Alphabetical</MenuItem>
              <MenuItem value={'date'}>Last Update</MenuItem>
          </Select>
          &ensp;
          <Select 
            id="orderSelect"
            value={sortOrderOrder}
            onChange={(e: SelectChangeEvent<string>) => {
              setSortOrderOrder(e.target.value)
            }}
          sx={{}}>
              <MenuItem value={'ASC'}>ASC</MenuItem>
              <MenuItem value={'DESC'}>DESC</MenuItem>
          </Select>
          &emsp;
          <Button sx={buttonPrimary} variant="contained" onClick={bigRefresh}>
            Refresh
          </Button>
        </div>
          <br />
        <Grid container >
          <Grid item xs={12} xl={3} sx={{
          border: "1px solid lightgray"
          }}>
            {(Array.isArray(campaignList) && campaignList.length > 0 && isItOne === true) &&
              <TableContainer sx={{minWidth: "14rem", maxHeight: "76vh"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontFamily: dishFont, fontSize: "1.5em", width: "14rem"}}>Campaign</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchFunction(sortFunction(campaignList)).map((el) => {
                      return(
                        <TableRow key={el.campaignName}>
                          <TableCell sx={{
                            display: "flex",
                            flexDirection: "row",
                            pl: "1.5rem",
                            pr: "1.5rem",
                            justifyContent: "space-between",
                            "&:hover": {
                              backgroundColor: "#F2F2F2",
                              cursor: "pointer"
                            },
                            "&:active": {
                              backgroundColor: "#F2F2F2"
                            }
                          }} id={el.campaignName} onClick={handleRowSelect}>
                            {el.campaignName}
                            {/* Empty Typography below allows for space-between without creating deadzones for onClick function */}
                            <Typography/>
                            {statuses[el.status]}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Grid>
            {(campaign !== null && campaign.campaignName) &&
          <Grid item xs={12} xl={9} sx = {{border: "1px solid lightgray", maxHeight: "85vh"}}>
              <div className="DashDiv">
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography sx={dishFontSx}><b>Campaign:</b> {campaign.campaignName}&emsp;<b>Status:</b> {statuses[campaign.status]}</Typography>
                    <Typography sx={dishFontSx}><b>Line of Business:</b> {campaign.lob}&emsp;<b>Recurring:</b> {campaign.recurring === true ? "True" : "False"}</Typography>
                    <Typography sx={dishFontSx}><b>Start Date:</b> {campaign.startTime.substring(0, 10)}&emsp;<b>End Date:</b> {campaign.endTime.substring(0, 10)}&emsp;<b>Schedule Name:</b> {campaign.schedule}</Typography>
                    {(lastChange && lastChange.changeLogs) &&
                                          <Typography sx={dishFontSx}><b>Last Updated By:</b> {lastChange.changeLogs[0].user}  &emsp;<b>Last Update Date:</b> {lastChange.changeLogs[0].changeDateTime.replace("T", " ").substring(0, lastChange.changeLogs[0].changeDateTime.length - 4)}</Typography>
                                      }

                    {(lastChange && lastChange.changeLogs) &&
                                          <Typography sx={dishFontSx}><b>Last Updated:</b> {lastChange.changeLogs[0].change}  &emsp;</Typography>
                                      }
                    <br />
                    <Divider />
                    <br />
                    <Typography sx={{fontFamily: dishFont, mb:'0.5rem'}}><b>Start Date:</b>&ensp;
                    <input className='DatePicker' type="date" id="startDatePicker" name="startDatePicker" ref={ref} onChange={handleDateSelection} value={startDate} />
                    &emsp;
                    </Typography>
                    <Typography sx={{fontFamily: dishFont, mb:'0.5rem'}}>
                    <b>End Date:</b>&emsp;
                    <input className='DatePicker' type="date" id="endDatePicker" name="endDatePicker" ref={ref} onChange={handleDateSelection} value={endDate} />
                    </Typography>
                    <br />
                    {campaignData &&
                      (
                        <Typography sx={{...dishFontSx, fontSize: '1em'}}>
                          <b>Total Uploaded Records</b>: {campaignData.totalRecords} <br /> 
                          <b>Total Processed Records</b>: {campaignData.totalProcessed} <br />
                          <b>Total Completed</b>: {campaignData.totalCompleted} <br />
                          <b>Total Failed</b>: {campaignData.totalFailed} <br />
                          <b>Total Queued</b>: {campaignData.totalQueued} <br />
                          <b>Total Callable Records</b>:  {campaignData.totalValidRecords} <br />
                          <b>Total NonCallable Records</b>: {campaignData.totalInvalidRecords} <br />
                          <b>Total Uploaded Files</b>: {campaignData.uploadedFileCount}
                        </Typography>
                      )
                    }
                  </Grid>
                  {campaignData && 
                    <Grid item xs={6}>
                      <div className="ChartDiv">
                        <Doughnut 
                        data={chartData} 
                        />
                      </div>
                    </Grid>
                  }
                </Grid>
              </div>
          </Grid> 
            }  
        </Grid>
      </CardContent>
    </Card>
  )
}