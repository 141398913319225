import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardContent, CardHeader, Divider, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, StepConnector, StepContent, TextField, useEventCallback } from '@mui/material';
import { buttonPrimary, cardLayout, cardLayoutNarrow, cardLayoutWide, dishFont, dishFontSx, helpText } from '../../../assets/styles/sxProp';
import TemplatePicker from '../../../components/email/templatePicker';
import { createPinpointCampaign, getAllEmailTemplates, getAllLobTemplates, getChannelByLob, getProject, getSegmentsByLob } from '../../../api/api';
import AppContext from '../../../context/AppContext';
import BasicSnackbar from '../../../components/common/Snackbar';
import LoadingModal from '../../../components/common/LoadingModal';


const CampaignCreate = () => {
    const steps = ['Create a campaign', 'Choose a segment', 'Create your message', 'Choose when to send the campaign', 'Review and launch'];
    const { userRole, lob }: any = useContext(AppContext);
    const [projectId, setProjectId] = useState<string | "">("");
    const [friendlyName, setFriendlyName] = useState<string | "">("")


    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [campaignObj, setCampaignObj] = useState<any | null>({
        step0: {},
        step1: {},
        step2: {},
        step3: {},
        step4: {},
    })
    const [step0, setStep0] = useState<any | null>({})
    const [step1, setStep1] = useState<any | null>({})
    const [step2, setStep2] = useState<any | null>({})
    const [step3, setStep3] = useState<any | null>({})
    const [step4, setStep4] = useState<any | null>({})

    const [stepFailed, setStepFailed] = useState<boolean | null>(false);

    const[alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        handleReset("");
        getProject(lob).then((res) => {
            setProjectId(res.projectId);
            setFriendlyName(res.friendlyName);
        });
    }, [lob, userRole])


    const handleStateChange0 = useCallback((newValue: any) => {
        let s0 = Object.assign(step0, newValue);
        setStep0(s0);
        setCampaignObj((campaignObj: any) => ({ ...campaignObj, step0: newValue }))
    }, []);

    const handleStateChange1 = useCallback((newValue: any) => {
        let s1 = Object.assign(step1, newValue);
        setStep1(s1);
        setCampaignObj((campaignObj: any) => ({ ...campaignObj, step1: newValue }))
    }, []);

    const handleStateChange2 = useCallback((newValue: any) => {
        let s2 = Object.assign(step2, newValue);
        setStep2(s2);
        setCampaignObj((campaignObj: any) => ({ ...campaignObj, step2: newValue }))
    }, []);

    const handleStateChange3 = useCallback((newValue: any) => {
        let s3 = Object.assign(step3, newValue);
        setStep3(s3);
        setCampaignObj((campaignObj: any) => ({ ...campaignObj, step3: newValue }))
    }, []);

    const handleStateChange4 = useCallback((newValue: any) => {
        let s4 = Object.assign(step4, newValue);
        setStep4(s4);
    }, []);



    const validateStep = (step: number) => {
        switch (step) {
            case 0:
                if (step0.name && step0.type && step0.channel) {
                    if (step0.type === "com") {
                        if (step0.variable) {
                            return true
                        } else {
                            setErrorMessage("Missing Channel");
                            return false
                        }
                    }
                    return true
                } else {
                    setErrorMessage(!step0.name ? "Missing Campaign Name" : (!step0.type ? "Missing Campaign Type" : "Missing Channel"));
                    return false
                }
            case 1:
                if (step1.segmentId) {
                    return true
                } else {
                    setErrorMessage("Missing Segment");
                    return false
                }
            case 2:
                
                if (step2.template && step2.sender.split("@")[0] && step2.template !== "" && (step2.channel !== "SMS" || step2.messageType)) {
                    return true
                }
                else {
                    if (!step2.messageType && step2.channel === "SMS") {
                        setErrorMessage(!step2.messageType ? "Missing MessageType" : "Missing MessageType");
                    }
                    else {
                        if (step2.channel === "Email")
                            setErrorMessage(!step2.template ? "Missing Template" : "Missing Sender Email");
                        else if (step2.channel === "SMS")
                            setErrorMessage(!step2.template ? "Missing Template" : "Missing Origination Number");
                    }
                    
                    return false
                }
            case 3:
                if (step3.timeFrame && step3.specific && (step3.endDate ? (step3.startDate <= step3.endDate) : true)) {
                    return true
                } else {
                    setErrorMessage("Choose When or Check End Date");
                    return false
                }
            case 4: 
                return true
            default :
                return false
        }

    }



    const handleNext = async () => {
        var res = validateStep(activeStep);

        if (res && activeStep === 4) {
            setLoading(true)
            try {
                if (campaignObj.step3.timeFrame === "IMMEDIATE") {
                    campaignObj.step3.timeFrame = "ONCE";                    
                    campaignObj.step3.startDate = "IMMEDIATE";
                    campaignObj.step3.endDate = campaignObj.step3.startDate;
                } else if (campaignObj.step3.timeFrame === "ONCE") {
                    campaignObj.step3.endDate = campaignObj.step3.startDate;
                }
                console.log("campaignObj.step0.name " + campaignObj.step0.name + " campaignObj.step2.template " + campaignObj.step2.template + " campaignObj.step0.channel " + campaignObj.step0.channel + " campaignObj.step2.messageType " + campaignObj.step2.messageType);
                await createPinpointCampaign(projectId, campaignObj.step0.name, campaignObj.step2.sender, campaignObj.step2.template, campaignObj.step1.segmentId, campaignObj.step3.timeFrame,
                    campaignObj.step3.startDate, campaignObj.step3.endDate, campaignObj.step0.channel, campaignObj.step2.messageType, campaignObj.step2.friendlyName);
                setAlertSeverity("success")
                setAlertMessage("Campaign was created Successfully")
                setAlertOpen(true);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setStepFailed(false)
            } catch (err) {
                console.log(err)
                setAlertSeverity("error")
                setAlertMessage("Unable to create campaign!")
                setAlertOpen(true);
            }
            setLoading(false)
        } else if (res) {

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setStepFailed(false)
            //setSkipped(newSkipped);

        } else {
            setStepFailed(true)
            isStepFailed(activeStep);
        }
    };

    const handleBack = () => {
        setErrorMessage("");
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //const handleSkip = () => {
    //    if (!isStepOptional(activeStep)) {
    //        // You probably want to guard against something like this,
    //        // it should never occur unless someone's actively trying to break something.
    //        throw new Error("You can't skip a step that isn't optional.");
    //    }

    //    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //    setSkipped((prevSkipped) => {
    //        const newSkipped = new Set(prevSkipped.values());
    //        newSkipped.add(activeStep);
    //        return newSkipped;
    //    });
    //};

    const handleReset = (Channel: String) => {
        setActiveStep(0);
        setStep0({});
        setStep1({});
        setStep2({});
        setStep3({});
        setStep4({});
        setCampaignObj({
            step0: {},
            step1: {},
            step2: {},
            step3: {},
            step4: {},
        });
    };

    const isStepFailed = (step: number) => {
        if (stepFailed) {
            return step === activeStep;
        }
    };

    return (
        <>
            <LoadingModal open={loading} />
            <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean; } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                        error?: boolean;
                    } = {};
                    //if (isStepOptional(index)) {
                    //    labelProps.optional = (
                    //        <Typography variant="caption">Optional</Typography>
                    //    );
                    //}
                    //if (isStepSkipped(index)) {
                    //    stepProps.completed = false;
                    //}
                    if (isStepFailed(index)) {
                        labelProps.optional = (
                            <Typography variant="caption" color="error">
                                {errorMessage}
                            </Typography>
                        );
                        labelProps.error = (errorMessage !== "") ? true : false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                            {<Button onClick={() =>handleReset("")}>Reset</Button>}
                    </Box>
                </>
            ) : (
                <>
                    {(activeStep === 0) ?
                                <Step0 onChange={handleStateChange0} handleReset={handleReset} value={step0} channel={step0.channel} /> : <></>}
                    {(activeStep === 1) ?
                                <Step1 onChange={handleStateChange1} value={step1} appId={projectId} channel={step0.channel} /> : <></>}
                    {(activeStep === 2) ?
                                <Step2 onChange={handleStateChange2} value={step2} appId={projectId} lob={lob} friendlyName={friendlyName} channel={step0.channel} /> : <></>}

                    {(activeStep === 3) ?
                                <Step3 onChange={handleStateChange3} value={step3} channel={step0.channel} /> : <></>}

                    {(activeStep === 4) ?
                        <Step4 onChange={handleStateChange4} value={campaignObj} /> : <></>}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/*{isStepOptional(activeStep) && (*/}
                        {/*    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>*/}
                        {/*        Skip*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </>
            )}
        </Box><BasicSnackbar
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={() => setAlertOpen(false)} /></>
    );
};

interface Props {
    onChange: (value: any) => void;
    value: any;
    lob?: string;
    appId?: string;
    channel?: string;
    friendlyName?: string;
    handleReset?: (value: any) => void;
}

const Step0 = (props: Props) => {
    const [state, setState] = useState<any | null>(
        {
            name: props.value ? props.value.name : "",
            type: props.value ? props.value.type : "",
            variable: props.value ? props.value.variable : "",
            channel: props.channel ? props.channel : props.channel
        });

    const [showHide, setShowHide] = useState<boolean | null>(false);

    useEffect(() => {
        if (props.value && props.value.type === "com") {
            setShowHide(true)
        }
    }, []);

    useEffect(() => {
        props.onChange(state)
    }, [state]);

    const handleStateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setState((state: any) => ({ ...state, [e.target.name]: e.target.value }))
        if (e.target.name === 'channel' && e.target.value !== props.channel && props.channel)
            props.handleReset?.("");
        if (e.target.name === 'type' && e.target.value === 'com') setShowHide(true); 

        if (e.target.name === 'type' && e.target.value === 'std') {
            setShowHide(false);
            setState((state: any) => ({ ...state, "variable": "" }))
        }       
    }

    return (
        <>
            <Card sx={cardLayout}>
                <CardHeader title="Campaign Details" />
                <CardContent>
                    <Grid xs={12} item={true}>
                        <Box component="form">
                            <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>Campaign Name</Typography>
                            <TextField id="campaignName" name="name" sx={{ minWidth: '16rem', width: "50%" }} onChange={handleStateChange} value={state.name || ""} fullWidth />
                            <Typography sx={helpText}>Campaign names can contain up to 64 characters</Typography>
                        </Box>
                    </Grid>
                    <Divider variant="middle" />
                    <Grid xs={12} item={true}>
                        <Box component="form">
                            <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>Campaign Type</Typography>
                                <RadioGroup
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="type"
                                    onChange={handleStateChange}
                                    value={state.type || ""}
                                >
                                <FormControlLabel value="std" control={<Radio />} label="Standard" />
                                <Typography sx={helpText}>Send messages to your segments on a schedule or when specific events occur.</Typography>
                                <FormControlLabel value="com" control={<Radio />} label="A/B" disabled />
                                <Typography sx={helpText}>Create multiple message treatments and compare their performance.</Typography>
                                </RadioGroup>  
                        </Box>
                    </Grid>
                    {(showHide) ?  
                    <><Divider variant="middle" /><Grid xs={12} item={true}>
                            <Box component="form">
                                <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>Choose a variable to compare</Typography>
                                <RadioGroup
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="variable"
                                    onChange={handleStateChange}
                                    value={state.variable || ""}
                                >
                                    <FormControlLabel value="msg" control={<Radio />} label="Message" />
                                    <Typography sx={helpText}>Compare the performance of two or more message treatments with different content.</Typography>
                                    <FormControlLabel value="del" control={<Radio />} label="Delivery" />
                                    <Typography sx={helpText}>Compare the performance of two or more message treatments with the same content but sent at different times.</Typography>
                                </RadioGroup>
                            </Box>
                        </Grid></>
                        : <></>
                    }
                    <Divider variant="middle" />

                    <Grid xs={12} item={true}>
                        <Box component="form">
                            <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>Channel + {props.channel} + {state.channel} +  {state.lob} + {state.friendlyName}</Typography>
                            <RadioGroup
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="channel"
                                onChange={handleStateChange}
                                value={state.channel || ""}
                            >
                                <FormControlLabel value="Email" control={<Radio />} label="Email" />
                                <FormControlLabel value="SMS" control={<Radio />} label="SMS" />
                                <FormControlLabel value="Push" control={<Radio />} label="Push" disabled />
                            </RadioGroup>
                        </Box>
                    </Grid>
                </CardContent>
            </Card>
        </>
        
    
    )
};

const Step1 = (props: Props) => {
    useEffect(() => {
        getSegments();
        _setChosenSegment("")
    }, []);
    const [state, setState] = useState<any | null>(
        {
            segmentName: props.value ? props.value.segmentName : "",
            segmentId: props.value ? props.value.segmentId : ""
        });

    useEffect(() => {
        if (props.value && props.value.segmentName) {
            _setChosenSegment(props.value.segmentName)
        }
    }, []);

    useEffect(() => {
        props.onChange(state)
    }, [state]);

    const handleStateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setState((state: any) => ({ ...state, [e.target.name]: e.target.value }))
    }

    const [_segmentList, _setSegmentList] = useState([]);
    const [_chosenSegment, _setChosenSegment] = useState("");
    const _onSegmentChange = (option: any) => {
        _setChosenSegment(option.label);
        setState((state: any) => ({ ...state, segmentName: option.label }))
        setState((state: any) => ({ ...state, segmentId: option.value }))
    }

    const getSegments = async () => {
        var res = await getSegmentsByLob(props.appId!);
        var defaultSegment = { label: "", value: "" }
        var segmentList = res.item.map((item: any) => {
            return {
                label: item.name,
                value: item.id
            }
        });
       segmentList.push(defaultSegment);
        var sortedList = segmentList.sort((a: any, b: any) => (a.label < b.label ? -1 : 1));
        _setSegmentList(sortedList);
    };
    return (<>
        <Card sx={cardLayout}>
            <CardHeader title={props.channel + " Details "} />
            <CardContent>
                <Grid xs={12} item={true}>
                    <Box component="form">
                        {(Array.isArray(_segmentList) && _segmentList.length > 0) ?
                            <TemplatePicker options={_segmentList} onChange={_onSegmentChange} selected={{ label: _chosenSegment, value: _chosenSegment }} label="Segments" />
                            : <></>
                        }
                    </Box>
                </Grid>
            </CardContent>
        </Card>
    </>)
};

const Step2 = (props: Props) => {
    const [state, setState] = useState<any | null>(
        {
            fromAddress: props.value ? props.value.fromAddress : "",
            sender: props.value ? props.value.sender : "",
            friendlyName: props.value ? props.value.friendlyName : "",
            template: props.value ? props.value.template : "",
            origin: props.value ? props.value.temple : "",
            messageType: props.value ? props.value.messageType : "",
            channel: props.value ? props.value.channel : ""
        });

    const [showHide, setShowHide] = useState<boolean | null>(true);

    const [_emailTemplates, _setEmailTemplates] = useState([]);
    const [_templateList, _setTemplateList] = useState([]);
    const [_chosenTemplate, _setChosenTemplate] = useState("");
    const [tempUser, setTempUser] = useState<string>("");
    const [tempSender, setTempSender] = useState<string>("");
    const [_friendlySenderList, _setFriendlySenderList] = useState([]);
    const [_messageTypeList, _setMessageTypeList] = useState([]);
    const [_chosenSender, _setChosenSender] = useState<string>("");
    const [_chosenMessageType, _setChosenMessageType] = useState<string>("");
    const [domain, setDomain] = useState<string>("");

    useEffect(() => {   
        getTemplates();
        getChannels();
        getFriendlySenders();        
    }, []);

    
    useEffect(() => {
        if (props.value && props.value.template) {
            _setChosenTemplate(props.value.template);
            if (props.value.friendlyName) {
                _setChosenSender(props.value.friendlyName);
            }
            if (props.value.friendlyName) {
                _setChosenMessageType(props.value.messageType);
            }
            
        }
    }, []);

    useEffect(() => {
        props.onChange(state)
    }, [state]);
       
    useEffect(() => {
        setState((state: any) => ({ ...state, sender: (tempUser + domain) }))
    }, [tempUser]);

    useEffect(() => {               
        handleSenderSplit(tempSender);
    }, [tempSender])

    const handleSenderSplit = ((sender: string) => {
        if (props.channel === "Email") {
            if (sender || sender !== "") {
                const part1 = sender ? sender.split("<") : "";
                if (part1.length === 2) {
                    setTempUser(part1[1].split("@")[0]);
                    setDomain("@" + part1[1].split("@")[1].replace(">", ""));
                } else if (part1.length === 1) {
                    const value = part1[0].split("@");
                    setTempUser(value[0]);
                    setDomain("@" + value[1]);
                } else {
                    setTempUser("");
                }
            }
        }
        else if (sender !== "" && state.messageType){
                setTempUser(sender);
                _setChosenMessageType(state.messageType);
            }

    });

    const handleStateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setState((state: any) => ({ ...state, [e.target.name]: e.target.value}))
    }

    const handleTempUserChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTempUser(e.target.value);       
    }

    const _onTemplateChange = (option: any) => {
        const _template = _templateList.find((item: any) => item.value === option.value) as any;
        _setChosenTemplate(_template.value);
        setState((state: any) => ({ ...state, template: _template.value }))
    }

    const getChannels = async () => {
       
        var res = await getChannelByLob(props.appId!, props.channel!);
       
        setTempSender((state.sender) ? state.sender : res.fromAddress);
    };

       
    const friendlySenderChange = (option: any) => {
        const _friendlySender = _friendlySenderList.find((item: any) => item.value === option.value) as any;
        _setChosenSender(_friendlySender.value);
        setState((state: any) => ({ ...state, friendlyName: _friendlySender.value }))
    }
    const MessageTypeChange = (option: any) => {
        if (option.value === "TRANSACTIONAL")
            setTempUser("21296");
        else if ("PROMOTIONAL")
            setTempUser("24656");
        const _messageType = _messageTypeList.find((item: any) => item.value === option.value) as any;
        _setChosenMessageType(_messageType.value);
        setState((state: any) => ({ ...state, messageType: _messageType.value }))
    }

    const getFriendlySenders = () => {
        if (props.channel === "SMS") {//21296 transactional we do not have any promotianl approved           
            var p1 = ["TRANSACTIONAL (21296)"]
            var p2: any = p1.map((name: any) => {
                return {
                    label: name,
                    value: name
                }
            });
            state.origin = 'Origination Number';
            _setMessageTypeList(p2.sort((a: any, b: any) => (a.label < b.label ? -1 : 1)));
        }
        else {
           // props.value.messageType(" ");
            
            state.origin = 'Friendly Sender Name';
        
        const friendlyNames = props.friendlyName ? props.friendlyName.split(",") : [];
        var defaultName = { label: "", value: "" };
        
        var sendersList: any = friendlyNames.map((name: any) => {
            return {
                label: name,
                value: name
            }
        });
        
        sendersList.push(defaultName);
        var sortedList = sendersList.sort((a: any, b: any) => (a.label < b.label ? -1 : 1));
        
         _setFriendlySenderList(sortedList);
        }
        
    };

    const getTemplates = async () => {
        var res = await getAllLobTemplates(props.lob!, props.channel!);
        _setEmailTemplates(res);
        var defaultTemplate = { label: "", value: "" };
        var templateList = res.map((item: any) => {
            return {
                label: item.templateName,
                value: item.templateName
            }
        });
        templateList.push(defaultTemplate);
        var sortedList = templateList.sort((a: any, b: any) => (a.label < b.label ? -1 : 1));
        _setTemplateList(sortedList);
    };
    return (<>
        <Card sx={cardLayout}>
            <CardHeader title={props.channel + " Details"} />
            <CardContent>
                <Grid xs={12} item={true}>
                    <Box component="form">
                        {(Array.isArray(_templateList) && _templateList.length > 0) ?
                            <><Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }} >{state.channel} Template</Typography>
                                <TemplatePicker options={_templateList} onChange={_onTemplateChange} selected={{ label: _chosenTemplate, value: _chosenTemplate }} label="Templates" /></>
                            : <></>
                        }
                    </Box>
                </Grid>
                <br /> <br />
                <Divider variant="middle" />
                {(showHide) ?
                    <><Grid xs={12} item={true}>
                        <Box component="form" >         
                            {(props.channel === "Email") &&
                                <>
                            <Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}>Sender email</Typography>
                                <TextField id="sender" name="sender" sx={{ minWidth: '16rem', width: "40%" }} onChange={handleTempUserChange} label={state.sender ? state.sender : tempSender} value={tempUser || ""} fullWidth />   
                                </>
                            }
                        </Box>

                        <Box component="form" >
                            {(Array.isArray(_friendlySenderList) && _friendlySenderList.length > 0 && props.channel === "Email") ?

                                <><Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}  >{state.origin} </Typography >
                                    <TemplatePicker options={_friendlySenderList} onChange={friendlySenderChange} selected={{ label: _chosenSender, value: _chosenSender }} label={state.origin} /></>
                                : <></>
                            }
                        </Box>

                        <Box component="form" >
                            {(props.channel === "SMS") ?
                                <><Typography sx={{ fontFamily: dishFont, fontWeight: "bold" }}  >MessageType </Typography >
                                    <TemplatePicker options={_messageTypeList} onChange={MessageTypeChange} selected={{ label: _chosenMessageType, value: _chosenMessageType }} label="MessageType" /></>
                                : <></>
                            }
                        </Box>
                    </Grid></>
                    : <></>
                }
            </CardContent>
        </Card>
    </>)

};

const Step3 = (props: Props) => {

    const handleCurrentDate = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const currentDay = currentDate.getDate().toString().padStart(2, '0');
        const currentMinute = currentDate.getMinutes().toString().padStart(2, '0');   
        const currentHour = currentDate.getHours().toString().padStart(2, '0');
        return `${currentYear}-${currentMonth}-${currentDay}T${currentHour}:${currentMinute}`;        
    }

    const [state, setState] = useState<any | null>(
        {
            specific: "spc",
            timeFrame: props.value ? props.value.timeFrame : "",
            startDate: props.value ? props.value.startDate : "",
            endDate: props.value ? props.value.endDate : "",

        });

    const handleStateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setState((state: any) => ({ ...state, [e.target.name]: e.target.value }));        
    }

    useEffect(() => {
        props.onChange(state)
    }, [state]);

    // should always set the state.dates for imidiate and once to "2024-01-01T08:00"
    // otherwise it should not change them
    useEffect(() => {
        if (state.timeFrame) {
            setState((prev: any) => (
                {
                    ...prev,
                    startDate: (state.timeFrame === "IMMEDIATE") ? "IMMEDIATE" : (state.startDate === "IMMEDIATE" ? handleCurrentDate() : (state.startDate ? state.startDate : handleCurrentDate() )),
                    endDate: (state.timeFrame === "ONCE" || state.timeFrame === "IMMEDIATE") ? "" : (state.endDate === "" ? handleCurrentDate() : (state.endDate ? state.endDate : handleCurrentDate() ))
                }));            
        }        
    }, [state.timeFrame])

    return (<>
        <Card sx={cardLayout}>
            <CardHeader title={props.channel + " Setup"} />
            <CardContent>
                <Grid xs={12} item={true}>
                    <Box component="form">
                            <Typography sx={{ fontFamily: dishFont }}>Choose when the campaign should be sent</Typography>
                            <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="specific"
                            onChange={handleStateChange}
                            value={ state.specific || ""}
                            >
                            <FormControlLabel value="spc" control={<Radio />} label="At A Specific Time" />
                            <Typography sx={helpText}> Send the campaign at specific time, or on a recurring basis.</Typography>
                            </RadioGroup>
                    </Box>
                </Grid>
                <br />
                <Divider variant="middle" />
                    <><Grid xs={12} item={true}>
                        <Box component="form">
                        <Typography sx={{ fontFamily: dishFont }}>Choose when the campaign should be sent</Typography>
                             <RadioGroup
                                 
                                 aria-labelledby="demo-row-radio-buttons-group-label"
                                 name="timeFrame"
                                 onChange={handleStateChange}
                                 value={state.timeFrame || ""}
                             >
                            <FormControlLabel value="IMMEDIATE" control={<Radio />} label="Immediately" />
                            <FormControlLabel value="ONCE" control={<Radio />} label="Once" />
                            <FormControlLabel value="HOURLY" control={<Radio />} label="Hourly"  />
                            <FormControlLabel value="WEEKLY" control={<Radio />} label="Weekly"  />
                            <FormControlLabel value="DAILY" control={<Radio />} label="Daily"  />
                            <FormControlLabel value="MONTHLY" control={<Radio />} label="Monthly"  />
                             </RadioGroup>
                        </Box>
                    <Box component="form">

                        </Box>
                </Grid></>
                    <br/>
                <Divider variant="middle" />
                <br />
                {(state.timeFrame !== "IMMEDIATE") ? 
                    <Grid xs={12} item={true}>
                        <Box component="form">
                            <TextField
                                //key={ state.timeFrame}
                                id="datetime-local"
                                name="startDate"
                                label="Start Date"
                                type="datetime-local"
                                defaultValue={!state.timeFrame ? handleCurrentDate() : ((state.startDate && state.startDate !== "IMMEDIATE") ? state.startDate : handleCurrentDate())}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleStateChange}
                            />
                        </Box>
                    </Grid>
                    : <></>}
                <br />
                {(state.timeFrame !== "IMMEDIATE") && (state.timeFrame !== "ONCE") ?
                    <Grid xs={12} item={true}>
                        <Box component="form">
                            <TextField
                                //key={state.timeFrame}
                                id="datetime-local"
                                name="endDate"
                                label="End Date"
                                type="datetime-local"
                                defaultValue={!state.timeFrame ? handleCurrentDate() : (state.endDate ? state.endDate : handleCurrentDate())}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleStateChange}
                            />
                        </Box>
                    </Grid>
                    : <></>}
            </CardContent>
        </Card>
    </>
    )

};

const Step4 = (props: Props) => {
    console.log("step 4 : " , props)
    return (<>
        <Card sx={cardLayout}>
            <CardHeader title="Campaign Review" />
            <CardContent>
                <Grid xs={12} item={true}>
                    <Typography sx={{ fontFamily: dishFont }}><b>Campaign Name : </b>{props?.value.step0.name}</Typography>
                    <Typography sx={{ fontFamily: dishFont }}><b>Campaign Type : </b>{props?.value.step0.type === "std" ? "Standard" : ""}</Typography>
                    <Typography sx={{ fontFamily: dishFont }}><b>Campaign Channel : </b>{props?.value.step0.channel}</Typography>
                </Grid>
                <br />
                <Divider variant="middle" />
                <Grid xs={12} item={true}>
                    <Typography sx={{ fontFamily: dishFont }}><b>Segment Name : </b>{props?.value.step1.segmentName}</Typography>
                </Grid>
                <br />
                <Divider variant="middle" />
                <Grid xs={12} item={true}>
                    <Typography sx={{ fontFamily: dishFont }}><b> {props?.value.step2.origin} : </b>{props?.value.step2.sender}</Typography>
                    {(props?.value.step0.channel === "Email") &&
                        <><Typography sx={{ fontFamily: dishFont }} ><b>  Friendly Name : </b>{props?.value.step2.friendlyName}</Typography> </>
                       
                    }
                    {(props?.value.step0.channel === "SMS") &&
                        <><Typography sx={{ fontFamily: dishFont }} ><b>  MessageType : </b>{props?.value.step2.messageType}</Typography> </>

                    }
                </Grid>
                <br />
                <Divider variant="middle" />
                <Grid xs={12} item={true}>
                    <Typography sx={{ fontFamily: dishFont }}><b>Template Name : </b>{props?.value.step2.template}</Typography>
                    <Typography sx={{ fontFamily: dishFont }}><b>Frequncy : </b>{props?.value.step3.timeFrame}</Typography>
                    <Typography sx={{ fontFamily: dishFont }}><b>Schedule : </b>{props?.value.step3.timeFrame === "IMMEDIATE" ? "Immediately" : ((props?.value.step3.startDate ? ("Start from " + props?.value.step3.startDate.replace('T', ' ')) : "") + ((props?.value.step3.endDate && props?.value.step3.timeFrame !== "ONCE") ? (" and End at " + props?.value.step3.endDate.replace('T', ' ')) : "")) }</Typography>
                </Grid>
            </CardContent>
        </Card>

    </>)

};

export default CampaignCreate;