import { HashRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layouts/MainLayout";
import { routes } from "./routes";
import { AppContextProvider } from "./context/AppContext";
import { AuthProvider } from "./auth/AuthProvider";
import * as React from 'react';


function App() {

    /*
        Hello Developer, 
        If you're planning to run this application in dev mode on localhost, make sure to
        go to ./auth/AuthProvider.tsx and update which functions are called at the end of 
        the first useEffect
    */

    return (
    <AppContextProvider>
        <AuthProvider>
            <HashRouter>
                <Routes>
                    <Route path="/" element={<MainLayout />}>
                            {routes}
                    </Route>
                </Routes>
            </HashRouter>
        </AuthProvider>
    </AppContextProvider>
  );
}

export default App;
