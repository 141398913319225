import React from 'react';


type Props = {};

const TemplateIndex = (props: Props) => {
  return (
    <div>Index</div>
  );
};

export default TemplateIndex;