import { state } from "../pages/dialRestrictions/States"

export type Campaign = {
    InstanceId: string,
    CampaignName: string,
    CampaignQueueId: string,
    ContactFlowId: string,
    PhoneNumber: string,
    DialerType: string,
    Bandwidth: string,
    StartTime: string,
    EndTime: string,
    FileType: string,
    Schedule: string,
    Priority: string,
    RetryCount: string,
    Messages: EventMessage[],
    Lob: string,
    Recurring: boolean
}

export type EventMessage = {
    message: string,
    event: string,
  }

export type FWTColumn = {
    FieldName: string,
    Name: string,
    Value: string,
    Start: number,
    End: number,
    Length: number,
    IsPhoneString: string,
    IsPhone: boolean,
    Order: number
}

export type FWT = {
    campaignName: string,
    instanceId: string,
    fileColumns: FWTColumn[],
    recordLength: string,
    lastUpdateBy: string,
    lastUpdateDate: string;
}

export type UserCreds = {
    username: string,
    password: string
}

export type Schedule = {
    scheduleName: string;
    activeDays: Week;
    startitme: string;
    endtime: string;
    lastUpdateBy: string;
    lastUpdateDate: string;
  };

export type Week = {
  sunday: string,
  monday: string,
  tuesday: string,
  wednesday: string,
  thursday: string,
  friday: string,
  saturday: string
}

export type DNC = {
    ani: string;
    dateAdded: string;
};

export type FederalHoliday = {
    id: number;
    ihsHoliday: boolean;
    date: string;
    lastUpdateBy: string;
    lastUpdateDate: string;
};

export type Override = {
    overrideType: string;
    overrideValue: string;
    startTime: string;
    lastUpdateBy: string;
    lastUpdateDate: string;
}

export type TtsMessage = {
    campaignName: string,
    message: string,
    disposition: string,
    changer: string,
    lastUpdateBy: string,
    lastUpdateDate: string,
}

type CallEvent = {
    id: number;
    eventType: string;
    retry: number;
    secondNumber: boolean;
    timeFrame: number;
    feedbackDisposition: number;
    lastUpdateBy: string;
    lastUpdateDate: string;
}

export type Folder = {
    folderName: string;
    key: string;
    folderList: Folder[];
    fileList: File[];
}

export type File = {
    name: string;
    key: string;
    dateTimeStamp: string;
}

export type CreateCampaignRequest = {
    name: string;
    key: string;
    dateTimeStamp: string;
}



export async function getStats (campaign: string, fromDate: string, toDate: string) {

    try {

        const response = await fetch (`/api/campaignstats/stats?campaign=${campaign}&fromDate=${fromDate}&toDate=${toDate}`,
        {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })

        const reply = await response.json();
        return reply;

    } catch (err) {
        console.error(err);
        return err;
    }

}


//Called in DoNotCall
export async function fetchSingleDNC (ani: string) {
    try {

      const response = await fetch(`/api/dialrestrictions/isondnc?ani=${ani}`, {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })
        const reply = await response.json();
        if (response.status === 404) {
            return 404;
        }
        return reply;

    } catch (err) {
        console.log(err);
        console.error(err);
        return err;
    }
  }

//Called in TopBar
export async function getInstances() {
    try {
        const response = await fetch(`/api/dashboard/instances`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const responseJ = await response.json()        
        return responseJ
    } catch (err) {
        console.log(err);
        //console.error(err);
        return err;
    }
}

//Used in CampaignCreatePage
export async function populatePhoneDropdown(instance:string) {
    // console.log("Fetching phone dropdown data")
    try {
        const data = await fetch(`/api/campaign/phonenumbers?id=${instance}`)
        const response = await data.json()
        return response
    } catch (err) {
        console.log(err);
        console.error(err);
        return err;
    }
}

//Used in CampaignCreatePage
export async function populateCFDropdown(instance:string) {
    // console.log("Fetching cf dropdown data")
    try {
        const data = await fetch(`/api/campaign/contactflows?id=${instance}`)
        const response = await data.json()
        return response
    } catch (err) {
        console.log(err);
        console.error(err);
        return err;
    }
}

//Used in CampaignCreatePage
export async function populateQueueDropdown(instance:string) {
    // console.log("Fetching queue dropdown data")
    try {
        const data = await fetch(`/api/campaign/queues?id=${instance}`)
        const response = await data.json()
        return response
    } catch (err) {
        console.log(err);
        console.error(err);
        return err;
    }
}

//Used in SystemFileInput and CampaignCreatePage
export async function getFwtList () {
    try {
        const data = await fetch(`/api/CampaignFileFormat/FileFormats`);
        const response = await data.json();
        return response;
    } catch (err) {
        console.log(err)
        console.error(err)
        return err
    }
}

//Used in CampaignActivePage
export async function getCampaigns () {
    try{
        const data = await fetch(`/api/campaign/campaigns`);
        const response = await data.json()
        return response;
    } catch(err) {
        console.log(err)
        console.error(err)
        return err
    }
}

export async function startCampaign(name: string) {
    try {
        const data = await fetch(`/api/campaign/StartCampaign?campaignName=${name}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            //body: JSON.stringify(obj)
        });
        const response = await data
        return response;
    } catch (err) {
        console.log(err)
        console.error(err)
        return err
    }
}

export async function getCampaignLogs() {
    try {
        const data = await fetch(`/api/campaign/campaignLogs`);
        const response = await data.json()
        return response;
    } catch (err) {
        console.log(err)
        console.error(err)
        return err
    }
}

export async function pauseCampaign(name: string) {
    try {
        const data = await fetch(`/api/campaign/PauseCampaign?campaignName=${name}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
        });
        const response = await data
        return response;
    } catch (err) {
        console.log(err)
        console.error(err)
        return err
    }
}

export async function stopCampaign(name: string) {
    try {
        const data = await fetch(`/api/campaign/StopCampaign?campaignName=${name}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
        });
        const response = await data
        return response;
    } catch (err) {
        console.log(err)
        console.error(err)
        return err
    }
}

export async function editCampaign(obj: any) {
    try {
        const data = await fetch(`/api/campaign/Campaign`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        });
        const response = await data
        return response;
    } catch (err) {
        console.log(err)
        console.error(err)
        return err
    }
}

export async function deleteCampaign(name: string) {
    try {
        const data = await fetch(`/api/campaign/Campaign?campaignName=${name}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
        });
        const response = await data
        return response;
    } catch (err) {
        console.log(err)
        console.error(err)
        return err
    }
}

export async function fetchSchedules() {
    try{
        const data = await fetch(`/api/system/schedule`);
        const response = await data.json();
        return response;
    } catch(err) {
        console.log(err)
        console.error(err)
        return err
    }
}

export async function fetchFeedbackFiles() {
    try {
        const data = await fetch(`/api/campaignfileupload/allfolders`);
        const response = await data.json();
        return response;
    } catch (err) {
        console.log(err);
        console.error(err);
        return err;
    }
}

export async function downloadFeedbackFiles(str: string, str2: string) {
    try {
                
        const data = await fetch(`/api/campaignfileupload/DownLoadFiles?bucketName=${str}&fileName=${str2}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.blob()).then((blob) => {

            const url = window.URL.createObjectURL(
                new Blob([blob])
            );

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                str2,
            );
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
           //link.parentNode.removeChild(link);

        });

        return data
    } catch (err) {
        console.log(err);
        console.error(err);
        return err;
    }
}

//Used in CampaignCreatePage
export async function postNewCampaign(obj: Campaign, instance: string) {
    const response = await fetch(`/api/campaign/campaign?campaignName=${instance}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },    
        body: JSON.stringify(obj)
    })    
    const reply = await response.json()
    return reply;
}    

//Used in States
export async function putStateUpdate(obj: state) {
    const response = await fetch(`/api/dialrestrictions/states`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
    })
    const reply = await response
    return reply;
}

//Used in CreateFwtModal
export async function postNewFwt(obj: FWT, instance: string) {
    const response = await fetch('/api/CampaignFileFormat/AddFileFormat', {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
    })
    return response;
}

//Used in SystemSchedule
export async function postNewSchedule (obj: Schedule) {
    // console.log(obj)
    const response = await fetch(`/api/system/Schedule`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
    })
    const reply = await response
    return reply;
}

export async function postNewTTSMessage(obj: TtsMessage) {
    const response = await fetch(`/api/system/messages`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
    })
    const reply = await response
    return reply;
}

export async function updateTTSMessage(obj: TtsMessage) {
    const response = await fetch(`/api/system/messages`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
    })
    const reply = await response
    return reply;
}

export async function deleteTTSMessage(campaign: string, disposition: string, message: string, changer: string) {
    const response = await fetch(`/api/system/messages?name=${campaign}&disposition=${disposition}&message=${message}&changer=${changer}&change=DELETE`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    })
    const reply = await response
    return reply;
}

export async function getMessageArchive() {
    try {
        const response = await fetch('/api/system/messageArchive');
        const reply = await response.json();
        return reply
    } catch (err) {
        console.error(err);
        return(err)
    }
}

export async function updateCallEvents(obj: CallEvent) {
    console.log('inside api tsx call: ', obj)
    const response = await fetch(`/api/system/callEvents`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
    })
    const reply = await response
    return reply;
}

//Used in DialRestrictionsPage
export async function postNewDoNotCallRestriction(obj: DNC) {
    const response = await fetch(`/api/dialrestrictions/dnc`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
    })
    const reply = await response
    return reply;
}

export async function deleteDoNotCallRestriction(ani: string, user: string) {
    const response = await fetch(`/api/dialrestrictions/dnc?ani=${ani}&user=${user}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    })
    const reply = await response
    return reply;
}

export async function postNewFederalHolidayRestriction(obj: FederalHoliday) {
    const response = await fetch(`/api/dialrestrictions/federalHolidays`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
    })
    const reply = await response
    return reply;
} 

export async function deleteFederalHolidayRestriction(id: string) {

    const response = await fetch(`/api/dialrestrictions/federalHolidays?id=${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    })
    const reply = await response
    return reply;
}

export async function getOverrideData () {
    const response = await fetch(`/api/system/overrides`,
    {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    })
    const reply = await response.json();
    return reply;
}

export async function postNewOverrideRestriction(val: string, user: string) {
    const b = {
        Item: val,
        User: user
}

    const response = await fetch(`/api/system/overrides`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(b)
    })
    const reply = await response
    return reply;
}

export async function deleteOverrideRestriction(startTime: string) {
    const response = await fetch(`/api/system/overrides?id=${startTime}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    })
    const reply = await response
    return reply;
}

export async function uploadFile(file: FormData) {
    const response = await fetch(`/api/campaignfileupload/Upload`,
        {
        method: "POST",
        //headers: {
        //    "Content-Type": file.type
        //},
        body: file
    }
    )
    const reply = await response
    console.log(reply);
    return reply;
}

//Used for Email Campiagns
export async function getAllEmailTemplates() {
    try {
        const response = await fetch(`/api/templates/allemail`);
        const templates = await response.json();
        return templates;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getAllLobTemplates(lob: string, templateType: string) {
    try {

        let response = new Response();
        if (templateType === "Email") {
            response = await fetch(`/api/templates/lobemail?lob=${lob}`);
        } else if (templateType === "SMS") {
            // response = await fetch(`/api/sms/temp`);

            response = await fetch(`/api/sms/lobsms?lob=${lob}`);
        }

        const templates = response ? await response.json() : {};
        return templates;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getTemplateByName(name: string, templateType: string) {
    try {
        let response = new Response();
        if (templateType === "Email") {
            response = await fetch(`/api/templates/emailtemplate?name=${name}`);
        } else if (templateType === "SMS") {
            response = await fetch(`/api/sms/smstemplate?name=${name}`);
        }
        const templates = await response.json();
        return templates;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getTemplateByNameVersion(name: string, version: string, templateType: string) {
    try {
        let response = new Response();
        if (templateType === "Email") {
            response = await fetch(`/api/templates/emailtemplateversion?name=${name}&version=${version}`);
        } else if (templateType === "SMS") {
            response = await fetch(`/api/sms/smstemplateversion?name=${name}&version=${version}`);
        }

        const templates = await response.json();
        return templates;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getTemplateVersions(name: string, templateType: string) {
    try {
        let response = new Response();
        if (templateType === "Email") {
            templateType = "EMAIL"
            response = await fetch(`/api/templates/templateVersions?name=${name}&templateType=${templateType}`);
        } else if (templateType === "SMS") {
            response = await fetch(`/api/sms/smsVersions?name=${name}&templateType=${templateType}`);
        }
        const versions = await response.json();
        return versions;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function createEmailTemplate(lob: string, templateName: string, subject: string, content: string, description: string, def: string) {
    try {
        const obj = {
            Lob: lob,
            TemplateName: templateName,
            Subject: subject,
            Content: content,
            Description: description,
            DefaultSubstitutions: def
        }
        console.log("post obj: ", obj)
        const response = await fetch(`/api/templates/createemailtemplate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        })
        const reply = await response
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}

export async function createSmsTemplate(lob: string, templateName: string, content: string, description: string, def: string) {
    try {
        const obj = {
            Lob: lob,
            TemplateName: templateName,
            Content: content,
            Description: description,
            DefaultSubstitutions: def
        }
        console.log("post obj: ", obj)
        const response = await fetch(`/api/sms/createsmstemplate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        })
        const reply = await response
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}

export async function testEmailTemplate(appId: string, templateName: string, subject: string, content: string, emailTo: string, emailFrom: string, def: Record<string, string[]>, description: string) {
    try {
        const obj = {
            AppId: appId,
            TemplateName: templateName,
            Subject: subject,
            Content: content,
            Description: description,
            DefaultSubstitutions: def,
            EmailTo: emailTo,
            EmailFrom: emailFrom

        }
        console.log("api obj - test - ", obj)
        const url = '/api/templates/testemailtemplate';
        const requestData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        }
        const response = await fetch(url, requestData)

        const reply = await response.status
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}

export async function testSmsTemplate(appId: string, templateName: string, content: string, smsTo: string, smsFrom: string, def: Record<string, string[]>, messageType: string, description: string) {
    try {
        const obj = {
            AppId: appId,
            TemplateName: templateName,
            Content: content,
            SmsTo: smsTo,
            SmsFrom: smsFrom,
            Description: description,
            DefaultSubstitutions: def,
            MessageType: messageType
        }
        console.log("api obj - test - ", obj)
        const url = '/api/sms/testsmstemplate';
        const requestData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        }
        const response = await fetch(url, requestData)

        const reply = await response.status
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}

export async function updateEmailTemplate(templateName: string, subject: string, content: string, newVersion: boolean, description: string , def: string) {
    try {
        const obj = {
            TemplateName: templateName,
            Subject: subject,
            Content: content,
            NewVersion: newVersion,
            Description: description,
            DefaultSubstitutions: def,
        }
        console.log("api obj - update - ", obj);
        const response = await fetch(`/api/templates/updateemailtemplate`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        })
        const reply = await response
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}

export async function updateSmsTemplate(templateName: string, content: string, newVersion: boolean, description: string, def: string) {
    try {
        const obj = {
            TemplateName: templateName,
            Content: content,
            NewVersion: newVersion,
            Description: description,
            DefaultSubstitutions: def,
        }
        console.log("api obj - update - ", obj);
        const response = await fetch(`/api/sms/updatesmstemplate`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        })
        const reply = await response
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}

export async function activateTemplate(name: string, version: string, templateType: string) {
    try {
        let response = new Response();
        if (templateType === "Email") {
            const response = await fetch(`/api/templates/activatetemplate?templateName=${name}&version=${version}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                }
            })
        } else if (templateType === "SMS") {
            response = await fetch(`/api/sms/activatetemplate?templateName=${name}&version=${version}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
            });
        }
        const reply = await response
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}

export async function deleteTemplateByName(name: string, lob: string, templateType: string) {
    try {        
        let response = new Response();
        if (templateType === "Email") {
            response = await fetch(`/api/templates/deletetemplate?name=${name}&lob=${lob}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
            });
        } else if (templateType === "SMS") {
            response = await fetch(`/api/sms/deletetemplate?name=${name}&lob=${lob}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
            });
        }
        const result = await response.json();
        return result;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

//Used for Pinpoint Campaigns obj : CreateCampaignRequest

export async function createPinpointCampaign(appId: string, campaignName: string, sender: string, templateName: string, segmentId: string, frequency: string, startTime: string, endTime: string, channel: string, messageType:string, friendlyName?: string  ) {
    try {
        const obj = {
            AppId: appId,
            CampaignName: campaignName,
            TemplateName: templateName,
            SegmentId: segmentId,
            Sender: sender,
            StartTime: startTime,
            EndTime: endTime,
            Frequency: frequency,
            FriendlyName: friendlyName,
            Channel: channel,
            MessageType: messageType
        }

        console.log("post obj: ", obj)
        const response = await fetch(`/api/pinpointcampaign/createcampaign`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        })
        const reply = await response
        if (reply.status === 200) {
            console.log("The campaign is successfully created with the code ", reply.status);
        } else {
            console.log("Campaign creation failed ", reply.status);
        }
        
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}

export async function getSegmentsByLob(lob: string) {
    try {
        const response = await fetch(`/api/segments/segments?lob=${lob}`);
        const segments = await response.json();
        return segments;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getSegmentById(lob: string, id: string) {
    try {
        const response = await fetch(`/api/segments/segment?lob=${lob}&id=${id}`);
        const segment = await response.json();
        return segment;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getChannelByLob(lob: string, channel: string) {
    try {
        if (channel === "Email") {
            const response = await fetch(`/api/segments/emailChannel?lob=${lob}`);
            const emailChannels = await response.json();
            return emailChannels;
        }
        else if (channel === "SMS") {
            const response = await fetch(`/api/segments/smsChannel?lob=${lob}`);
            const smsChannels = await response.json();
            return smsChannels;
        }
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function createSegment(appId: string, segmentName: string, audience: string, criteria: string, include: string[]) {
    try {
        const obj = {
            SegmentName: segmentName,
            Criteria: criteria,
            Audience: audience,
            AppId: appId,
            Include: include
        }
        console.log("post obj: ", obj)
        const response = await fetch(`/api/segments/createSegment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        })
        const reply = await response
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}



export async function importSegment(file: FormData) {
    try {
        console.log("post Segment file: ", file)
        const response = await fetch(`/api/segments/ImportSegments`,
            {
                method: "POST",
                // headers: {
                //     "Content-Type": "application/csv"
                // },
                body: file

            }
        )
        const reply = await response
        console.log(reply);
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}



//export async function updateSegment(appId: string, segmentId: string, audience: string, criteria: string) {
export async function updateSegment(appId: string, segmentId: string, audience: string, criteria: string, include: string[]) {
    try {
        const obj = {
            SegmentId: segmentId,
            Criteria: criteria,
            Audience: audience,
            AppId: appId,
            Include: include
        }
        console.log("put obj: ", obj)
        const response = await fetch(`/api/segments/updateSegment`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        })
        const reply = await response
        return reply;
    } catch (err) {
        console.error(err)
        return (err);
    }
}

export async function deleteSegment(appId: string, segmentId: string) {
    try {
        const obj = {
            SegmentId: segmentId,
            AppId: appId
        }
        const response = await fetch(`/api/segments/deletesegment`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        });
        const result = await response.json();
        return result;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getUser(group: string) {
    try {
        const response = await fetch(`/api/authuser/user?group=${group}`)
        const responseJ = await response.json()
        return responseJ
    } catch (err) {
        console.log(err);
        console.error(err);
        return err;
    }
}

export async function getProject(lob: string) {
    try {
        const response = await fetch(`/api/authuser/project?lob=${lob}`)
        const responseJ = await response.json()
        return responseJ
    } catch (err) {
        console.log(err);
        console.error(err);
        return err;
    }
}

//Used for SES
export async function getIdentities() {
    try {
        const response = await fetch(`/api/ses/identities`);
        const identities = await response.json();
        return identities;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getSendStats() {
    try {
        const response = await fetch(`/api/ses/sendStats`);
        const stats = await response.json();
        return stats;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getIdentityDkim() {
    try {
        const response = await fetch(`/api/ses/identityAttr`);
        const attr = await response.json();
        return attr;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getDomainStats() {
    try {
        const response = await fetch(`/api/ses/domainStats`);
        const attr = await response.json();
        return attr;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getMessageStats() {
    try {
        const response = await fetch(`/api/ses/messageStats`);
        const attr = await response.json();
        return attr;
    } catch (err) {
        console.log(err);
        return (err);
    }
}

export async function getBatchMetrics() {
    try {
        const response = await fetch(`/api/ses/batchMetrics`);
        const attr = await response.json();
        return attr;
    } catch (err) {
        console.log(err);
        return (err);
    }
}



