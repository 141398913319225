import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import * as React from 'react';
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
//import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { RouteType } from "../../routes/config";
import DefaultPage from "../../pages/dashboard/DefaultPage";
import DashboardPageLayout from "../../pages/dashboard/DashboardPageLayout";
import DashboardIndex from "../../pages/dashboard/DashboardIndex";
import { useContext, useEffect } from "react";
import AppContext from "../../context/AppContext";
import CampaignPageLayout from "../../pages/campaign/CampaignPageLayout";
import CampaignIndex from "../../pages/campaign/CampaignIndex";
import CampaignActivePage from "../../pages/campaign/CampaignActivePage";
import CampaignCreatePage from "../../pages/campaign/CampaignCreatePage";
import CampaignLogsPage from "../../pages/campaign/CampaignLogsPage";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AdminPageLayout from "../../pages/admin/AdminPageLayout";
import DialRestrictionsIndex from "../../pages/dialRestrictions/DIalRestrictionsIndex";
import DNC from "../../pages/dialRestrictions/DoNotCall";
import FederalHolidays from "../../pages/dialRestrictions/FederalHolidays";
import States from "../../pages/dialRestrictions/States";
import Prefixes from "../../pages/dialRestrictions/Prefixes";
import PhoneDisabledOutlinedIcon from '@mui/icons-material/PhoneDisabledOutlined';
import SystemPageLayout from "../../pages/system/SystemPageLayout";
import SystemIndex from "../../pages/system/SystemIndex";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Navigate } from "react-router-dom";
import Redirect404 from "../../pages/reditect404/Redirect404";
import SystemSchedule from "../../pages/system/SystemSchedule";
import SystemFileInput from "../../pages/system/SystemFileInput";
import SystemCallEvents from "../../pages/system/SystemCallEvents";
import SystemTtsMessageInput from "../../pages/system/SystemTtsMessageInput";
import SystemOverride from "../../pages/system/SystemOverride";
import ReportingPageLayout from "../../pages/reporting/ReportingPageLayout";
import ReportingIndex from "../../pages/reporting/ReportingIndex";
import ProjectPageLayout from "../../pages/projects/ProjectPageLayout";
import CampaignFilesPage from "../../pages/reporting/CampaignFilesPage";
import TTSMessageArchivePage from "../../pages/reporting/TTSMessageArchivePage";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ProjectIndex from "../../pages/projects/ProjectIndex";
import SMSTemplateCreate from "../../pages/templates/SMSTemplateCreate";
import CampaignCreate from "../../pages/projects/campaigns/CampaignCreate";
import SegmentCreate from "../../pages/projects/segments/SegmentCreate";
import TemplateIndex from "../../pages/templates/TemplateIndex";
import EmailTemplateCreate from "../../pages/templates/EmailTemplateCreate";
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DashboardTwo from "../../pages/dashboard/DashboardTwo";




let appRoutes: RouteType[] = [];

const setSideBar = (g: string) => {
    console.log("role: ", g)
    switch (g) { 
        case "Admin":
            console.log("Inside Admin routes")
            appRoutes = [{
                index: true,
                element: <DefaultPage />,
                state: "home"
            },
                {
                    path: "/",
                    element: <DashboardPageLayout />,
                    state: "dashboard",
                },
                {
                    path: "",
                    element: <DashboardPageLayout />,
                    state: "dashboard",
                },
                {
                    path: "/dashboard",
                    element: <DashboardPageLayout />,
                    state: "dashboard",
                    sidebarProps: {
                        displayText: "Dashboard",
                        icon: <DashboardOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <DashboardIndex />,
                            state: "dashboard.index"
                        },
                        {
                            path: "/dashboard/default",
                            element: <DefaultPage />,
                            state: "dashboard.default",
                            sidebarProps: {
                                displayText: "Dashboard (Auto Msg)"
                            },
                        },
                        // UNCOMMENT ONCE READY FOR LOB AND SMS/EMAIL
                        //{
                        //    path: "/dashboard/dashboardtwo",
                        //    element: <DashboardTwo />,
                        //    state: "dashboard.dashboardtwo",
                        //    sidebarProps: {
                        //        displayText: "Dashboard (Email / SMS / Push)"
                        //    },
                        //}
                    ]
                },
                {
                    path: "/campaign",
                    element: <CampaignPageLayout />,
                    state: "campaign",
                    sidebarProps: {
                        displayText: "Campaigns",
                        icon: <FileDownloadOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <CampaignIndex />,
                            state: "campaign.index"
                        },
                        {
                            path: "/campaign/active",
                            element: <CampaignActivePage />,
                            state: "campaign.active",
                            sidebarProps: {
                                displayText: "Campaign List"
                            },
                        },
                        {
                            path: "/campaign/create",
                            element: <CampaignCreatePage />,
                            state: "campaign.create",
                            sidebarProps: {
                                displayText: "Create"
                            },
                        },
                        {
                            path: "/campaign/history",
                            element: <CampaignLogsPage />,
                            state: "campaign.logs",
                            sidebarProps: {
                                displayText: "Change History"
                            },
                        },
                    ]
                },
                // {
                //   path: "/user",
                //   element: <UserPageLayout />,
                //   state: "user",
                //   sidebarProps: {
                //     displayText: "Users",
                //     icon: <PeopleOutlineOutlinedIcon />
                //   },
                //   child: [
                //     {
                //       index: true,
                //       element: <UserIndex />,
                //       state: "user.index"
                //     },
                //     {
                //       path: "/user/create",
                //       element: <UserCreatePage />,
                //       state: "user.create",
                //       sidebarProps: {
                //         displayText: "Create User"
                //       },
                //     }
                //   ]
                // },
                //{
                //  path: "/admin",
                //  element: <AdminPageLayout />,
                //  state: "admin",
                //  sidebarProps: {
                //    displayText: "Admin",
                //    icon: <AdminPanelSettingsOutlinedIcon />
                //  },
                //  child: [
                //    {
                //      index: true,
                //      element: <AdminIndex />,
                //      state: "admin.index"
                //    },
                //     {
                //       path: "/user/create",
                //       element: <UserCreatePage />,
                //       state: "user.create",
                //       sidebarProps: {
                //         displayText: "Create User"
                //       },
                //     }
                //  ]
                //},
                {
                    path: "/dialRestrictions",
                    element: <AdminPageLayout />,
                    state: "dr",
                    sidebarProps: {
                        displayText: "Dial Restrictions",
                        icon: <PhoneDisabledOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <DialRestrictionsIndex />,
                            state: "dr.index"
                        },
                        {
                            path: "/dialRestrictions/donotcall",
                            element: <DNC />,
                            state: "dr.donotcall",
                            sidebarProps: {
                                displayText: "Do Not Call"
                            },
                        },
                        //{
                        //  path: "/dialRestrictions/federaltime",
                        //  element: <FederalTime />,
                        //  state: "dr.federaltime",
                        //  sidebarProps: {
                        //    displayText: "Federal Time"
                        //  },
                        //},
                        {
                            path: "/dialRestrictions/federalholidays",
                            element: <FederalHolidays />,
                            state: "dr.federalholidays",
                            sidebarProps: {
                                displayText: "Federal Holidays"
                            },
                        },
                        {
                            path: "/dialRestrictions/states",
                            element: <States />,
                            state: "dr.states",
                            sidebarProps: {
                                displayText: "States"
                            },
                        },
                        {
                            path: "/dialRestrictions/prefixes",
                            element: <Prefixes />,
                            state: "dr.prefixes",
                            sidebarProps: {
                                displayText: "Prefixes"
                            },
                        }
                    ]
                },
                {
                    path: "/system",
                    element: <SystemPageLayout />,
                    state: "system",
                    sidebarProps: {
                        displayText: "System",
                        icon: <SettingsOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <SystemIndex />,
                            state: "system.index"
                        },
                        {
                            path: "/system/file",
                            element: <SystemFileInput />,
                            state: "system.file",
                            sidebarProps: {
                                displayText: "File Type"
                            },
                        },
                        {
                            path: "/system/schedule",
                            element: <SystemSchedule />,
                            state: "system.schedule",
                            sidebarProps: {
                                displayText: "Schedule"
                            },
                        },
                        {
                            path: "/system/callEvents",
                            element: <SystemCallEvents />,
                            state: "system.callEvents",
                            sidebarProps: {
                                displayText: "Call Events"
                            },
                        },
                        {
                            path: "/system/tts",
                            element: <SystemTtsMessageInput />,
                            state: "system.tts",
                            sidebarProps: {
                                displayText: "Text to Speech Messages"
                            },
                        },
                        {
                            path: "/system/override",
                            element: <SystemOverride />,
                            state: "system.override",
                            sidebarProps: {
                                displayText: "Manual Override"
                            },
                        }
                    ]
                },
                {
                    path: "/reporting",
                    element: <ReportingPageLayout />,
                    state: "reporting",
                    sidebarProps: {
                        displayText: "Reporting",
                        icon: <FolderOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <ReportingIndex />,
                            state: "reporting.index"
                        },
                        {
                            path: "/reporting/campaignfiles",
                            element: <CampaignFilesPage />,
                            state: "reporting.campaignfiles",
                            sidebarProps: {
                                displayText: "Campaign Files"
                            },
                        },
                        {
                            path: "/reporting/ttsarchive",
                            element: <TTSMessageArchivePage />,
                            state: "reporting.ttsarchive",
                            sidebarProps: {
                                displayText: "Message Archive"
                            },
                        }
                    ]
                },
                // UNCOMMENT ONCE READY FOR LOB AND SMS/EMAIL
                //{
                //    path: "/projects",
                //    element: <ProjectPageLayout />,
                //    state: "projects",
                //    sidebarProps: {
                //        displayText: "Communication",
                //        icon: <AccountTreeOutlinedIcon />
                //    },
                //    child: [
                //        {
                //            index: true,
                //            element: <ProjectIndex />,
                //            state: "projects.index"
                //        },
                //        {
                //            path: "/projects/campaigns",
                //            element: <CampaignCreate />,
                //            state: "projects.campaigns",
                //            sidebarProps: {
                //                displayText: "Campaigns"
                //            },
                //        },
                //        //{
                //        //    path: "/projects/journeys",
                //        //    element: <SMSTemplateCreate />,
                //        //    state: "projects.journeys",
                //        //    sidebarProps: {
                //        //        displayText: "Journeys"
                //        //    },
                //        //},
                //        {
                //            path: "/projects/segments",
                //            element: <SegmentCreate />,
                //            state: "projects.segments",
                //            sidebarProps: {
                //                displayText: "Segments"
                //            },
                //        }
                //    ]
                //},
                //{
                //    path: "/templates",
                //    element: <ReportingPageLayout />,
                //    state: "templates",
                //    sidebarProps: {
                //        displayText: "Templates",
                //        icon: <DevicesOutlinedIcon />
                //    },
                //    child: [
                //        {
                //            index: true,
                //            element: <TemplateIndex />,
                //            state: "templates.index"
                //        },
                //        {
                //            path: "/templates/email",
                //            element: <EmailTemplateCreate />,
                //            state: "templates.email",
                //            sidebarProps: {
                //                displayText: "Email",
                //                /*icon: <MailOutlineIcon />*/
                //            },
                //        },
                //        {
                //            path: "/templates/sms",
                //            element: <SMSTemplateCreate />,
                //            state: "templates.sms",
                //            sidebarProps: {
                //                displayText: "SMS",
                //                /*icon: <SmsOutlinedIcon />*/
                //            },
                //        }
                //    ]
                //},
                {
                    path: "/404",
                    element: <Redirect404 />,
                    state: "404",
                },
                {
                    path: "/*",
                    element: <Navigate to="index.html" />,
                    state: "default",
                }]
            break;
        case "AutoMsg":
            console.log("Inside Auto routes")
            appRoutes = [{
                index: true,
                element: <DefaultPage />,
                state: "home"
            },
                {
                    path: "/",
                    element: <DashboardPageLayout />,
                    state: "dashboard",
                },
                {
                    path: "",
                    element: <DashboardPageLayout />,
                    state: "dashboard",
                },
                {
                    path: "/dashboard",
                    element: <DashboardPageLayout />,
                    state: "dashboard",
                    sidebarProps: {
                        displayText: "Dashboard",
                        icon: <DashboardOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <DashboardIndex />,
                            state: "dashboard.index"
                        },
                        {
                            path: "/dashboard/default",
                            element: <DefaultPage />,
                            state: "dashboard.default",
                            sidebarProps: {
                                displayText: "Dashboard (Auto Msg)"
                            },
                        }
                    ]
                },
                {
                    path: "/campaign",
                    element: <CampaignPageLayout />,
                    state: "campaign",
                    sidebarProps: {
                        displayText: "Campaigns",
                        icon: <FileDownloadOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <CampaignIndex />,
                            state: "campaign.index"
                        },
                        {
                            path: "/campaign/active",
                            element: <CampaignActivePage />,
                            state: "campaign.active",
                            sidebarProps: {
                                displayText: "Campaign List"
                            },
                        },
                        {
                            path: "/campaign/create",
                            element: <CampaignCreatePage />,
                            state: "campaign.create",
                            sidebarProps: {
                                displayText: "Create"
                            },
                        },
                        {
                            path: "/campaign/history",
                            element: <CampaignLogsPage />,
                            state: "campaign.logs",
                            sidebarProps: {
                                displayText: "Change History"
                            },
                        },
                    ]
                },
                {
                    path: "/dialRestrictions",
                    element: <AdminPageLayout />,
                    state: "dr",
                    sidebarProps: {
                        displayText: "Dial Restrictions",
                        icon: <PhoneDisabledOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <DialRestrictionsIndex />,
                            state: "dr.index"
                        },
                        {
                            path: "/dialRestrictions/donotcall",
                            element: <DNC />,
                            state: "dr.donotcall",
                            sidebarProps: {
                                displayText: "Do Not Call"
                            },
                        },
                        {
                            path: "/dialRestrictions/federalholidays",
                            element: <FederalHolidays />,
                            state: "dr.federalholidays",
                            sidebarProps: {
                                displayText: "Federal Holidays"
                            },
                        },
                        {
                            path: "/dialRestrictions/states",
                            element: <States />,
                            state: "dr.states",
                            sidebarProps: {
                                displayText: "States"
                            },
                        },
                        {
                            path: "/dialRestrictions/prefixes",
                            element: <Prefixes />,
                            state: "dr.prefixes",
                            sidebarProps: {
                                displayText: "Prefixes"
                            },
                        }
                    ]
                },
                {
                    path: "/system",
                    element: <SystemPageLayout />,
                    state: "system",
                    sidebarProps: {
                        displayText: "System",
                        icon: <SettingsOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <SystemIndex />,
                            state: "system.index"
                        },
                        {
                            path: "/system/file",
                            element: <SystemFileInput />,
                            state: "system.file",
                            sidebarProps: {
                                displayText: "File Type"
                            },
                        },
                        {
                            path: "/system/schedule",
                            element: <SystemSchedule />,
                            state: "system.schedule",
                            sidebarProps: {
                                displayText: "Schedule"
                            },
                        },
                        {
                            path: "/system/callEvents",
                            element: <SystemCallEvents />,
                            state: "system.callEvents",
                            sidebarProps: {
                                displayText: "Call Events"
                            },
                        },
                        {
                            path: "/system/tts",
                            element: <SystemTtsMessageInput />,
                            state: "system.tts",
                            sidebarProps: {
                                displayText: "Text to Speech Messages"
                            },
                        },
                        {
                            path: "/system/override",
                            element: <SystemOverride />,
                            state: "system.override",
                            sidebarProps: {
                                displayText: "Manual Override"
                            },
                        }
                    ]
                },
                {
                    path: "/reporting",
                    element: <ReportingPageLayout />,
                    state: "reporting",
                    sidebarProps: {
                        displayText: "Reporting",
                        icon: <FolderOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <ReportingIndex />,
                            state: "reporting.index"
                        },
                        {
                            path: "/reporting/campaignfiles",
                            element: <CampaignFilesPage />,
                            state: "reporting.campaignfiles",
                            sidebarProps: {
                                displayText: "Campaign Files"
                            },
                        },
                        {
                            path: "/reporting/ttsarchive",
                            element: <TTSMessageArchivePage />,
                            state: "reporting.ttsarchive",
                            sidebarProps: {
                                displayText: "Message Archive"
                            },
                        }
                    ]
                },
                {
                    path: "/404",
                    element: <Redirect404 />,
                    state: "404",
                },
                {
                    path: "/*",
                    element: <Navigate to="index.html" />,
                    state: "default",
                }]
            break;
        case "Other":
            console.log("Inside Other routes")
            appRoutes = [{
                index: true,
                element: <DashboardTwo />,
                state: "home"
            },
            {
                path: "/",
                element: <DashboardPageLayout />,
                state: "dashboard",
            },
            {
                path: "",
                element: <DashboardPageLayout />,
                state: "dashboard",
            },
            {
                path: "/dashboard",
                element: <DashboardPageLayout />,
                state: "dashboard",
                sidebarProps: {
                    displayText: "Dashboard",
                    icon: <DashboardOutlinedIcon />
                },
                child: [
                    {
                        index: true,
                        element: <DashboardIndex />,
                        state: "dashboard.index"
                    },
                    {
                        path: "/dashboard/dashboardtwo",
                        element: <DashboardTwo />,
                        state: "dashboard.dashboardtwo",
                        sidebarProps: {
                            displayText: "Dashboard (Email / SMS / Push)"
                        },
                    }
                ]
            },
            {
                path: "/projects",
                element: <ProjectPageLayout />,
                state: "projects",
                sidebarProps: {
                    displayText: "Communication",
                    icon: <AccountTreeOutlinedIcon />
                },
                child: [
                    {
                        path: "/projects/campaigns",
                        element: <CampaignCreate />,
                        state: "projects.campaigns",
                        sidebarProps: {
                            displayText: "Campaigns"
                        },
                    },
                    //{
                    //    path: "/projects/journeys",
                    //    element: <SMSTemplateCreate />,
                    //    state: "projects.journeys",
                    //    sidebarProps: {
                    //        displayText: "Journeys"
                    //    },
                    //},
                    {
                        path: "/projects/segments",
                        element: <SegmentCreate />,
                        state: "projects.segments",
                        sidebarProps: {
                            displayText: "Segments"
                        },
                    },
                ]
            },
            {
                path: "/templates",
                element: <ReportingPageLayout />,
                state: "templates",
                sidebarProps: {
                    displayText: "Templates",
                    icon: <DevicesOutlinedIcon />
                },
                child: [
                    {
                        index: true,
                        element: <TemplateIndex />,
                        state: "templates.index"
                    },
                    {
                        path: "/templates/email",
                        element: <EmailTemplateCreate />,
                        state: "templates.email",
                        sidebarProps: {
                            displayText: "Email",
                            icon: <MailOutlineIcon />
                        },
                    },
                    {
                        path: "/templates/sms",
                        element: <SMSTemplateCreate />,
                        state: "templates.sms",
                        sidebarProps: {
                            displayText: "SMS",
                            icon: <MailOutlineIcon />
                        },
                    }
                ]
            },
            {
                path: "/404",
                element: <Redirect404 />,
                state: "404",
            },
            {
                path: "/*",
                element: <Navigate to="index.html" />,
                state: "default",
            }
            ]
            break;
        default:
            console.log("Inside default routes")
            appRoutes = [{
                index: true,
                element: <DefaultPage />,
                state: "home"
            },
                {
                    path: "/",
                    element: <DashboardPageLayout />,
                    state: "dashboard",
                },
                {
                    path: "",
                    element: <DashboardPageLayout />,
                    state: "dashboard",
                },
                {
                    path: "/dashboardtwo",
                    element: <DashboardPageLayout />,
                    state: "dashboard",
                    sidebarProps: {
                        displayText: "Dashboard",
                        icon: <DashboardOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <DashboardIndex />,
                            state: "dashboard.index"
                        },
                        {
                            path: "/dashboardtwo/dashboard",
                            element: <DashboardTwo />,
                            state: "dashboard.dashboardtwo",
                            sidebarProps: {
                                displayText: "Dashboard"
                            },
                        }
                    ]
                },
                {
                    path: "/projects",
                    element: <ProjectPageLayout />,
                    state: "projects",
                    sidebarProps: {
                        displayText: "Communication",
                        icon: <AccountTreeOutlinedIcon />
                    },
                    child: [
                        {
                            path: "/projects/campaigns",
                            element: <CampaignCreate />,
                            state: "projects.campaigns",
                            sidebarProps: {
                                displayText: "Campaigns"
                            },
                        },
                        //{
                        //    path: "/projects/journeys",
                        //    element: <SMSTemplateCreate />,
                        //    state: "projects.journeys",
                        //    sidebarProps: {
                        //        displayText: "Journeys"
                        //    },
                        //},
                        {
                            path: "/projects/segments",
                            element: <SegmentCreate />,
                            state: "projects.segments",
                            sidebarProps: {
                                displayText: "Segments"
                            },
                        },
                    ]
                },
                {
                    path: "/templates",
                    element: <ReportingPageLayout />,
                    state: "templates",
                    sidebarProps: {
                        displayText: "Templates",
                        icon: <DevicesOutlinedIcon />
                    },
                    child: [
                        {
                            index: true,
                            element: <TemplateIndex />,
                            state: "templates.index"
                        },
                        {
                            path: "/templates/email",
                            element: <EmailTemplateCreate />,
                            state: "templates.email",
                            sidebarProps: {
                                displayText: "Email",
                                icon: <MailOutlineIcon />
                            },
                        },
                        //{
                        //    path: "/templates/sms",
                        //    element: <SMSTemplateCreate />,
                        //    state: "templates.sms",
                        //    sidebarProps: {
                        //        displayText: "SMS",
                        //        icon: <SmsOutlinedIcon />
                        //    },
                        //}
                    ]
                },
                {
                    path: "/404",
                    element: <Redirect404 />,
                    state: "404",
                },
                {
                    path: "/*",
                    element: <Navigate to="index.html" />,
                    state: "default",
                }
            ]
            break;
    }
}

const Sidebar = () => {
    const { userGroup }: any = useContext(AppContext);
    useEffect(() => {
        setSideBar(userGroup)
    }, [userGroup])


  return (
    <><Drawer
          variant="permanent"
          sx={{
              width: sizeConfigs.sidebar.width,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                  width: sizeConfigs.sidebar.width,
                  boxSizing: "border-box",
                  borderRight: "0px",
                  backgroundColor: colorConfigs.sidebar.bg,
                  color: colorConfigs.sidebar.color
              }
          }}
      >
          <List disablePadding style={{ maxHeight: '97vh', overflow: 'auto' }}>
              <Toolbar sx={{ marginBottom: "20px" }}>
                  <Stack
                      sx={{ width: "100%" }}
                      direction="row"
                      justifyContent="center"
                  >
                      <Avatar src={assets.images.logo} />
                  </Stack>
              </Toolbar>
              {appRoutes.map((route, index) => (
                  route.sidebarProps ? (
                      route.child ? (
                          <SidebarItemCollapse item={route} key={index} />
                      ) : (
                          <SidebarItem item={route} key={index} />
                      )
                  ) : null
              ))}
          </List>
          <div style={{ position: 'absolute', bottom: '0', alignSelf: 'center', fontWeight:'bold', minHeight: '1em', backgroundColor: colorConfigs.sidebar.bg, width: "100%", textAlign: "center" }}>v1.3.0.00</div>
      </Drawer>
         </>
  );
};

export default Sidebar;