import { Box, Button, Divider, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react'
import { buttonPrimary, dishFont } from '../../assets/styles/sxProp';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Check, Close, Delete, Edit } from '@mui/icons-material';



interface Props {
    kvps: string;
    emailHtmlPart: string;
    subject: string;
    onKvpChange: (newKvpString: string) => void;
    valueCheckAlert: boolean;
    onNoValueFlagChange: (newFlag: boolean) => void;
    newList: any[];
    keyName: string;
    keyLabel: string;
}

function KVPTable({ kvps, onKvpChange, emailHtmlPart, subject, valueCheckAlert, onNoValueFlagChange }: Props) {
    const [kvpPairs, setKvpPairs] = useState<Record<string, string>>(kvps ? JSON.parse(kvps) : {});
    const [addedKey, setAddedKey] = useState<string>("");
    const [addedValue, setAddedValue] = useState<string>("");
    const [editingKey, setEditingKey] = useState<Record<string, boolean>>({});
    const [tempValue, setTempValue] = useState<Record<string, string>>(kvpPairs);
    const [inputError, setInputError] = useState<Record<string, boolean>>({});
    const [tempError, setTempError] = useState<Record<string, boolean>>({});
    const [addedError, setAddedError] = useState<number[]>([0, 0, 0]);    
    
    async function fetchSubstitutions(inputKvp: string, inputHtml: string, inputSubject: string){
        let substitutionsObject: Record<string, string> = {};
        let substitutionsJsonTempFetchUpperCases: Record<string, string> = {};
        let sortedSubstitutions: Record<string, string> = {};

        // Adding Default Substitutions
        try {
            let substitutionsJsonTemp: Record<string, string> = inputKvp ? JSON.parse(inputKvp) : {};
            Object.entries(substitutionsJsonTemp).forEach(([key, value]) => {
                const match = key.match(/\s([A-Z0-9_]+)/);
                if (match) {
                    const newKey = match[1];
                    const { [key]: _, ...restPairs } = substitutionsJsonTemp;
                    substitutionsJsonTempFetchUpperCases = { ...restPairs, [newKey]: value };
                } else {
                    substitutionsJsonTempFetchUpperCases[key] = value;
                }
            });
            
        } catch (error) {
            console.error("KVPTable - Error parsing KVPs: ", error);
        }

        // Starting to fetch conditionalKvpPairs from Html & subject
        const outerPattern = /{{([\s\S]*?)}}/g;
        let outerMatch;
        while ((outerMatch = outerPattern.exec(inputSubject + inputHtml)) !== null) {
            const innerContent = outerMatch[1].replace(/['"]([^'"]+)['"]/g, "");
            const innerPattern = /[A-Z0-9_]+/g;
            let innerMatch;
            while ((innerMatch = innerPattern.exec(innerContent)) !== null) {
                const key = innerMatch[0];
                if (!substitutionsJsonTempFetchUpperCases.hasOwnProperty(key) && key.length > 1 && !/^\d+$/.test(key)) {
                    substitutionsJsonTempFetchUpperCases[key] = "";
                }
            }
        }

        // Combining all attributes & sort by alphabetical order
        substitutionsObject = {
            ...substitutionsObject, ...substitutionsJsonTempFetchUpperCases
        };     

        if ((Object.keys(substitutionsObject).length > 0) && (substitutionsObject !== null)) {
            const sortedKeys = Object.keys(substitutionsObject).sort();
            sortedKeys.forEach(key => {
                sortedSubstitutions[key] = substitutionsObject[key];
            })
        }        

        return sortedSubstitutions;

    }; 

    useEffect(() => {
        fetchSubstitutions(kvps, emailHtmlPart, subject).then(fetchSubstitutions => {
            setKvpPairs(fetchSubstitutions)
        }).catch(error => {
            console.error("Error fetching substitutions: ", error)
        });
    }, [kvps, emailHtmlPart]);
     
    useEffect(() => {
        let newError: Record<string, boolean> = {};
        newError = { ...newError, ...validateValues(kvpPairs) };
        setInputError(newError);
    }, [kvpPairs]);

    useEffect(() => {
        setInputError(inputError);
    }, [inputError])

    useEffect(() => {
        let newTempError: Record<string, boolean> = {};
        newTempError = { ...newTempError, ...validateValues(tempValue) };
        setTempError(newTempError);
    }, [tempValue]);

    useEffect(() => {
        setTempError(tempError);
    }, [tempError]);

    useEffect(() => {
        onKvpChange(JSON.stringify(kvpPairs));
    }, [kvpPairs]);

    useEffect(() => {
        onNoValueFlagChange(noValueFlagCheck(inputError));        
    }, [inputError])

    useEffect(() => {
        setAddedError(addedError);
    }, [addedError])

    const noValueFlagCheck = (errors: Record<string, boolean>) => {
        if (Object.values(errors).some(flag => flag === true)) {
            return true;
        } else {
            return false;
        }
    };

    const validateValues = (values: Record<string, string>) => {
        const newErrors: Record<string, boolean> = {};
        Object.keys(values).forEach(key => {
            newErrors[key] = !((values[key] || "").trim());
        });
        return newErrors;
    };

    // Temporarily saving the Value into tempValue
    const handleEditValue = (key: string, value: string) => {
        let editingKeyTemp: Record<string, boolean> = { ...editingKey };
        editingKeyTemp = { ...editingKeyTemp, [key]: true };
        setEditingKey(editingKeyTemp);
        const inputTempValue: Record<string, string> = { ...tempValue, [key]: value };
        setTempValue(inputTempValue); 
        if (!value) {
            setTempError(prev => ({...prev, [key]: true}));
        }
    };

    // Adding the tempValue into kvpPairs after confirming
    const handleConfirmEdit = (key: string) => {
        const newErrors: Record<string, boolean> = { ...inputError, [key]: tempError[key] };
        if (newErrors[key] && valueCheckAlert) {
            setInputError(newErrors);
            onNoValueFlagChange(noValueFlagCheck(inputError));
        } else {
            if (tempValue[key] !== kvpPairs[key]) {
                const inputKvpPairs: Record<string, string> = { ...kvpPairs, [key]: tempValue[key] };
                setKvpPairs(inputKvpPairs);
            }
            setEditingKey((prev => ({ ...prev, [key]: false })));
            onKvpChange(JSON.stringify(kvpPairs));
            onNoValueFlagChange(noValueFlagCheck(inputError));
        }        
    };

    // Canceling Editing
    const handleCancelEdit = (key: string) => {
        setInputError(prev => ({ ...prev, [key]: false }));
        setTempError(prev => ({ ...prev, [key]: false }));
        const cancelTempValues: Record<string, string> = { ...kvpPairs };
        setTempValue(cancelTempValues);
        const cancelEditingKey: Record<string, boolean> = { ...editingKey, [key]: false };
        setEditingKey(cancelEditingKey);
    };
    
    // Deleting the selected Attribute key value pair from kvpPairs
    const handleDeletePair = (key: string) => {
        const { [key]: _, ...restPairs } = kvpPairs;
        console.log("Deleting Attribute of ", key);
        setKvpPairs(restPairs);
        onKvpChange(JSON.stringify(restPairs));               
    }

    // Adding the new Attribute key value pair into kvpPairs
    const handleAddPair = () => {
        let updatedPairs: Record<string, string> = { ...kvpPairs };
        const addedErrorTemp: number[] = [0, 0, 0];
        if (addedKey.trim() && addedValue.trim()) {
            if (kvpPairs.hasOwnProperty(addedKey)) {
                addedErrorTemp[0] = 1;
            } else {
                updatedPairs = { ...kvpPairs, [addedKey]: addedValue };
                setKvpPairs(updatedPairs);
                setAddedKey("");
                setAddedValue("");
                setAddedError([0, 0, 0]);
            }            
        } else if (!addedKey.trim()) {
            addedErrorTemp[1] = 1;
        } else if (!addedValue.trim()) {
            addedErrorTemp[2] = 1;
            
        }
        setAddedError(addedErrorTemp);
        onKvpChange(JSON.stringify(updatedPairs));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, key: string) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleConfirmEdit(key);
            e.currentTarget.blur();
            setEditingKey((prev => ({ ...prev, [key]: false })));
        } else if (e.key === 'Escape') {
            handleCancelEdit(key);
        }
    }
/*
    const handleOnBlur = (key: string) => {
        handleConfirmEdit(key);
        setEditingKey((prev => ({ ...prev, [key]: false })));
    }*/


    return (<>

        {(Object.keys(kvpPairs).length > 0) && (kvpPairs !== null) && <>
            <Divider variant="middle" />Current Variables:
        </>}
        {(Object.keys(kvpPairs).length > 0) && (kvpPairs !== null) && Object.entries(kvpPairs).map(([key, value]) => {
            return (
                <Box className="form-inline" key={key} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TextField
                        id="attrName"
                        sx={{ minWidth: '16rem', width: "49.5%" }}
                        label={"Key: "}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ readOnly: true }}
                        value={key || ""}
                        disabled
                    />               
                    <TextField                    
                        id="attrVal"
                        sx={{ minWidth: '16rem', width: "49.5%" }}
                        label={editingKey[key] ? `Editing Value:` : `Value:`}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => editingKey[key] ? handleEditValue(key, e.target.value) : undefined}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleEditValue(key, e.target.value)}
                        //onClick={() => handleEditValue(key, value)}
                        //onBlur={ () => handleOnBlur(key)}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, key)}
                        error={valueCheckAlert && tempError[key]}
                        helperText={tempError[key] ? (valueCheckAlert && 'Please input valid value') : ''}
                        placeholder={!valueCheckAlert ? 'optional' : ''}
                        value={editingKey[key] ? tempValue[key] : value || ""}
                        InputProps={{
                            readOnly: !editingKey[key],
                            endAdornment: (
                                <InputAdornment position="end">
                                    {editingKey[key] && tempValue[key] !== kvpPairs[key] && (
                                        <Tooltip title={"Confirm?"} >
                                            <IconButton onClick={() => editingKey[key] ? handleConfirmEdit(key) : handleEditValue(key, value)}>{(tempValue[key] !== kvpPairs[key]) && <Check />}</IconButton>
                                        </Tooltip>
                                    )}
                                    {editingKey[key] && tempValue[key] !== kvpPairs[key] && (
                                        <Tooltip title={ "Cancel?"}>
                                            <IconButton onClick={() => handleCancelEdit(key)}>{(tempValue[key] !== kvpPairs[key]) && <Close />}</IconButton>
                                        </Tooltip>
                                    )}                                    
                                        <Tooltip title="Delete Variable">
                                            <IconButton aria-label="delete" onClick={() => handleDeletePair(key)}><Delete /></IconButton>
                                        </Tooltip>                                   
                                </InputAdornment>
                            )
                        }}
                    /> 
                </Box>
                
        )})}
                <Divider variant="middle" />Add New Variable:
                <Box className="form-inline" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TextField
                        id="newAttrName"
                        sx={{ minWidth: '16rem', width: "49.5%" }}
                        label={"Add New Key: "}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setAddedKey(e.target.value)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && handleAddPair();
                        }}
                        error={addedError[0] !== 0 || addedError[1] !== 0}
                        helperText={(addedError[1] !== 0 && 'Please input valid key') || (addedError[0] !==0 && 'Key is duplicated!')}
                        value={addedKey || ""}                 
                        
                    />
                    <TextField
                        id="newAttrVal"
                        sx={{ minWidth: '16rem', width: "49.5%" }}
                        label={"Add New Value: "}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setAddedValue(e.target.value)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && handleAddPair();                    
                        }}
                        error={addedError[2] !== 0}
                        helperText={addedError[2] !== 0 && 'Please input valid value'}
                        value={addedValue || ""}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title='Add Variable'>
                                        <IconButton onClick={() => handleAddPair()}><AddCircleOutlinedIcon /></IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                    />                
                </Box>
 

       
    </>
    )
}

export default KVPTable