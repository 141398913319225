//@ts-nocheck
import { createContext, useState } from "react";

const AppContext = createContext();

export function AppContextProvider({ children }) {
    const [instance, setInstance] = useState(null);
    const [newFwt, setNewFwt] = useState<int>(1);
    const [newTts, setNewTts] = useState<int>(1);
    const [username, setUsername] = useState<string>("")
    const [userGroup, setUserGroup] = useState<string>("")
    const [userGroups, setUserGroups] = useState(null)
    const [userRole, setUserRole] = useState<string>("")
    const [lob, setLob] = useState<string | "">("")

    const contextObject = {
        instance,
        setInstance,
        newFwt,
        setNewFwt,
        newTts,
        setNewTts,
        username,
        setUsername,
        userGroup,
        setUserGroup,
        userGroups,
        setUserGroups,
        userRole,
        setUserRole,
        lob,
        setLob

    }

    return (
        <AppContext.Provider value={contextObject}>
            {children}
        </AppContext.Provider>
    )



}

export default AppContext;
