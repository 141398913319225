//@ts-nocheck

import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { fixedWidth } from '../../configs/tableData'
import { useMemo } from 'react'
import { Typography, Box, Tooltip, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { dishFontSx } from '../../assets/styles/sxProp'

export default function FixedWidthTable (
    props
) {
    

    const columns = useMemo<MRT_ColumnDef<fixedWidth>[]>(() => [
        {
            header: 'Field Name',
            accessorKey: 'name'
        },
        {
            header: 'Starts On',
            accessorKey: "start"
        },
        {
            header: 'Ends On',
            accessorKey: 'end'
        },
        {
            header: 'Length',
            accessorKey: 'length'
        },
        {
            header: 'Order',
            accessorKey: 'order'
        }
    ],
    [])


    return (
        <>
            < br />
            <Typography variant='h4'>{props.formatName}</Typography>
            <Typography variant='caption'><b>Created By:</b> {props.lastUpdateBy}</Typography>
            < br />
            <Typography variant='caption'><b>Created Date:</b> {props.lastUpdateDate}</Typography>
            
            <br />
            <MaterialReactTable 
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={props.tableData}
                enableColumnFilters={true}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                //Top tool bar with add and search
                enableTopToolbar={true}
                enableColumnActions={false}
                enableBottomToolbar={true}
                enableSorting={false}
                initialState={{
                    pagination: {
                        pageIndex: 0,
                        pageSize: 100
                      },
                }}
                //Action Colums
                //enableEditing
                muiTableHeadCellProps={{
                    sx: dishFontSx
                }}
                muiTableBodyCellProps={{
                    sx: dishFontSx
                }}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            />
        </>
    )
}